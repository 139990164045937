import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import parse from "html-react-parser";
import { AllContext } from "../../context";
import { apiDeleteMethod, apiGetMethod } from "../../api/rest";
import Avatar from "../../assets/img/avatar.png";
import { formatMonthNameAndDate, handleOffSetDateTime, handleGetTime } from "../../shared/functions";
import { useEffect } from "react";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import Select from "react-select";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PageLoader from "../../components/pageLoader";

const ViewTicket = (props) => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [deleteId, setDeleteId] = useState();
  const [ticketStatus, setTicketStatus] = useState("");
  const [ticket, setTicket] = useState({
    assignedUsers: [],
    cc: [],
    date: "",
    message: "",
    note: "",
    relatedOrder: [],
    status: "open",
    subject: "",
    tags: [],
    to: "",
  });
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const [otherTickets, setOtherTickets] = useState([]);
  const [ccUser, setCcUser] = useState([]);
  const editorRef = useRef(null);
  const [ticketSubject, setTicketSuject] = useState("");
  const [ticketNote, setTicketNote] = useState("");
  const [allTeam, setAllTeam] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [selectedFile, setSelectedFile] = useState({});
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [filterTags, setFilterTags] = useState([]);
  let { id } = useParams();
  const context = useContext(AllContext);
  const { allApiUrl, apiPutMethod, toastMessage, apiPostMethod } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const userInfoRoles = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );

  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const [showReplyFrom, setShowReplyFrom] = useState(false);
  const [ccClient, setCcClient] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messageCotent, setMessageCotent] = useState("");
  const [logs, setLogs] = useState([]);
  const [replyTo, setReplyTo] = useState("Client");
  const {
    FILTER_FILES,
    GET_TICKET,
    DELETE_TICKET,
    EDIT_TICKET,
    GET_ALL_CLIENT,
    GET_ALL_TEAM,
    TAGS,
    MESSAGE,
    FILTER_TICKET,
    FILTER_LOGS,
    ADD_CLIENT,
  } = allApiUrl;
  function getAllMessages() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(MESSAGE, "", header)
      .then((res) => {
        setMessages(res.data.data);
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }
  useEffect(() => {
    getAllMessages();
    getTickets();
    getAllTeam();
    getAllClient();
    getAttachedFiles();
  }, []);

  const viewTickets = (_id, data) => {
    navigate(`/edit-ticket/${id}`);
  };
  function getAllTeam() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAllTeam(res.data.team);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }
  function getClientTickits(id) {
    const data = { client: id };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(FILTER_TICKET, data, header)
      .then((res) => {
        if (res.status === 200) {
          setOtherTickets(res.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  function getName(client) {
    if (client.firstName && client.lastName) {
      return `${capitalizeFirstLowercaseRest(
        client.firstName
      )} ${capitalizeFirstLowercaseRest(client.lastName)}`;
    } else {
      if (client.firstName) {
        return `${capitalizeFirstLowercaseRest(client.firstName)}`;
      } else {
        return `${capitalizeFirstLowercaseRest(client.lastName)}`;
      }
    }
  }
  function capitalizeFirstLowercaseRest(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function getAllClient() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_ALL_CLIENT, "", header).then((res) => {
      if (res.status === 200) {
        let clientList = [];
        res.data.team.map((client) => {
          let label = "";
          if (client.userId.firstName && client.userId.lastName) {
            label =
              client.userId.firstName +
              " " +
              client.userId.lastName +
              " - " +
              client.userId.email;
          } else {
            if (client.userId.firstName || client.userId.lastName) {
              if (client.userId.firstName) {
                label = client.userId.firstName;
              } else {
                label = client.userId.lastName;
              }
            } else {
              label = client.userId.email;
            }
          }
          let data = {
            label: label,
            value: client.userId._id,
          };
          clientList.push(data);
        });
        setCcClient(clientList);
        // setAllClient(res.data.team);
      }
    });
  }

  const getTickets = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(GET_TICKET + "/" + id, "", header)
      .then((res) => {
        if (res.status === 200) {
          setTicket(res.data.details[0]);
          setTicketSuject(res.data.details[0].subject);
          setTicketNote(res.data.details[0].note);
          if (res.data.details[0].cc) {
            getSelectedCC(res.data.details[0].cc);
          }
          // setTicketCcMails(res.data.)
          if (
            res.data.details[0] &&
            res.data.details[0].client &&
            res.data.details[0].client._id
          ) {
            getClientTickits(res.data.details[0].client._id);
          }
          getLogs();
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        setInitialLoading(false);
      });
  };
  const navigate = useNavigate();

  const deleteTicket = (deleteId) => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(DELETE_TICKET, id, header)
      .then((res) => {
        if (res.status === 200) {
          navigate("/tickets");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  function handleEditorChange(e) {
    // var str = e.target.getContent();
    setTicket((prevData) => {
      return { ...prevData, note: e };
    });
  }
  function handleMessageEditorChange(e) {
    setMessageCotent(e);
  }
  function handleMessageUpdateEditorChange(e, index) {
    var str = e.target.getContent();
    ticket.message[index].message = str;
  }
  function updateTicket(data) {
    // const userInfoRolesTeam = userInfoRoles.ticketManagement.add_delete;

    // if (userInfoRolesTeam) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(EDIT_TICKET + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        getTickets();
        setShowForm(false);
        if (ticketStatus !== "") {
          setTicketStatus("");
        }
      })
      .catch((err) => {
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
    // } else {
    //   toastMessage(
    //     "warning",
    //     "You don't have permission to access this resource."
    //   );
    // }
  }

  function updateAssignedUser(userId) {
    if (ticket.assignedUsers.find((row) => row == userId)) {
      let index = ticket.assignedUsers.findIndex((row) => row == userId);
      ticket.assignedUsers.splice(index, 1);
    } else {
      ticket.assignedUsers.push(userId);
    }
    updateTicket({ assignedUsers: ticket.assignedUsers });
  }
  function getAssignedUsers() {
    let users = "";
    let assignedUsers = ticket.assignedUsers;
    assignedUsers.map((row, index) => {
      let data = allTeam.find((team) => team.userId._id == row);
      if (data) {
        if (data.userId.firstName && data.userId.lastName) {
          users += `${data.userId.firstName} ${data.userId.lastName}${index !== assignedUsers.length - 1 ? "," : ""
            } `;
        } else {
          users += `${data.userId.email}${index !== assignedUsers.length - 1 ? "," : ""
            } `;
        }
      }
    });
    if (users == "") {
      return "Anyone";
    }
    return users;
  }

  function getFilteredTags(value) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(TAGS, `/${value}`, header)
      .then((res) => {
        if (res.status === 200) {
          setFilterTags(res.data.tags);
        }
      })
      .catch((err) => {
        toastMessage("error", err);
      });
  }
  const addTag = () => {
    const data = {
      name: newTag,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(TAGS, data, header)
      .then((res) => {
        if (res.status === 201) {
          ticket.tags.push(newTag);
          updateTicket({ tags: ticket.tags });
          setNewTag("");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  function formatAMPM(date) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateString = monthNames[date.getMonth()] + " " + date.getDate() + "th";
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = dateString + " at " + hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const getLogs = () => {
    const data = {
      resourceId: id,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(FILTER_LOGS, data, header)
      .then((res) => {
        if (res.status === 200) {
          res.data.reverse();
          setLogs(res.data);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  };
  function getTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  function getSelectedCC(cc) {
    let listCc = [];
    cc.map((row) => {
      let label = "";
      if (row.firstName && row.lastName) {
        label = row.firstName + " " + row.lastName;
      } else {
        if (row.firstName || row.lastName) {
          if (row.firstName) {
            label = row.firstName;
          } else {
            label = row.lastName;
          }
        } else {
          label = row.email;
        }
      }
      let data = {
        label: label,
        value: row._id,
      };
      listCc.push(data);
    });
    setCcUser(listCc);
  }
  function CheckNewCC() {
    ccUser.map((cc, index) => {
      if (cc.value == "newCC") {
        const autoFirstName = cc.label.substring(0, cc.label.indexOf("@"));
        const data = {
          role: "contact",
          createdBy: userInfo.id,
          firstName: autoFirstName,
          email: cc.label,
        };
        let header = {
          Authorization: `Bearer ${loginUserAccessToken}`,
        };
        apiPostMethod(ADD_CLIENT, data, header)
          .then((res) => {
            if (res.status === 201) {
              ccUser[index].value = res.data.id;
              toastMessage("success", res.data.message);
              if (index == ccUser.length - 1) {
                if (ccUser.length > 0) {
                  ccUser.map((row, index) => {
                    ccUser[index] = row.value;
                  });
                }
                updateTicket({ cc: ccUser });
              }
            }
          })
          .catch((err) => {
            toastMessage("error", err.data.message);
          });
      } else {
        if (index == ccUser.length - 1) {
          if (ccUser.length > 0) {
            ccUser.map((row, index) => {
              ccUser[index] = row.value;
            });
          }
          updateTicket({ cc: ccUser });
        }
      }
    });
  }

  function updateTicketMessage(message) {
    setIsLoading(true);
    if (selectedFile.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append("files", selectedFile[i]);
      }
      formData.append("from", userInfo.id);
      formData.append("message", message);
      formData.append("date", new Date().getTime());
      formData.append("to", replyTo);
      formData.append("type", "Ticket");
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
        "Content-Type": "multipart/form-data",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, formData, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          getTickets();
          setSelectedFile({});
          setIsLoading(false);
          setShowForm(false);
          if (ticketStatus !== "") {
            setTicketStatus("");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    } else {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      let data = {
        from: userInfo.id,
        message: message,
        date: new Date().getTime(),
        to: replyTo,
        type: "Ticket",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, data, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          setSelectedFile({});
          getTickets();
          setIsLoading(false);
          setShowForm(false);
          if (ticketStatus !== "") {
            setTicketStatus("");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    }
  }
  function AttachmentFiles() {
    if (selectedFile && selectedFile.length > 0) {
      let files = [...selectedFile];
      return files.map((selected, index) => {
        return (
          <span>
            <a>
              {" "}
              <span className="innerIcon attachIcon me-1">
                <i class="fa-solid fa-link"></i>{" "}
              </span>
              {selected.name}{" "}
              <span className="innerIcon crossIcon ms-2">
                <i
                  class="fa-solid fa-xmark"
                  onClick={() => {
                    var dt = new DataTransfer();
                    let input = document.getElementById("myFileInput");
                    var { files } = input;
                    for (var i = 0; i < files.length; i++) {
                      var file = files[i];
                      if (index !== i) dt.items.add(file);
                      input.files = dt.files;
                    }
                    setSelectedFile(input.files);
                  }}
                ></i>
              </span>
            </a>
          </span>
        );
      });
    }
    return "";
  }
  function getAttachedFiles() {
    let data = { resourceId: [id] };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(FILTER_FILES, data, header)
      .then((res) => {
        if (res.status === 200) {
          setFiles(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  return (
    <div>
      <Sidebar
        activePage="tickets"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="viewOrders pt-3 pb-3 horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row mb-4">
              <div className="col-lg-6">
                <div className="section-back">
                  <a
                    href="javascript:void(0);"
                    onClick={() => navigate("/tickets")}
                  >
                    <i class="fa-solid fa-angle-left"></i> Tickets
                  </a>
                </div>
              </div>
              {/*<div className="col-lg-6">
             <div className="section-back text-end">
                <a href="#" className="mx-2">
                  <i className="fa-solid fa-angle-up"></i>
                </a>
                <a href="#" className="mx-2">
                  <i className="fa-solid fa-angle-down"></i>
                </a>
              </div>
            </div>*/}
            </div>

            <div className="container p90-large">
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="sectionHeading mb-2">
                    <h3 className="fs-4 mb-0">
                      {/* Ticket Name{" "} */}
                      {ticket.subject && ticket.subject.length
                        ? ticket.subject
                        : "Ticket Name"}
                      <span
                        className="editHeading"
                        data-bs-toggle="modal"
                        data-bs-target="#headingModal"
                      >
                        <i className="fa-solid fa-pen"></i>
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="status-btns text-end">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle btn-follow status-btn"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {ticket.status}
                      </button>
                      <ul className="dropdown-menu status-dropdown">
                        <p>
                          Changing ticket status does not trigger an email
                          notification
                        </p>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => updateTicket({ status: "Open" })}
                          >
                            <div>
                              <span className="circle circle-warning"></span>
                            </div>
                            <div>
                              Open <br />{" "}
                              <small>Initial status for a new ticket</small>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => updateTicket({ status: "Pending" })}
                          >
                            <div value="">
                              <span className="circle circle-spam"></span>
                            </div>
                            <div>
                              Pending <br />{" "}
                              <small>Waiting for a response from client</small>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => updateTicket({ status: "Closed" })}
                          >
                            <div>
                              <span className="circle circle-success"></span>
                            </div>
                            <div>
                              Closed <br />{" "}
                              <small>Final status for a resolved ticket</small>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => updateTicket({ status: "Spam" })}
                          >
                            <div>
                              <span className="circle circle-spam"></span>
                            </div>
                            <div>
                              Spam <br />{" "}
                              <small>
                                Future messages from spam senders will go
                                directly to spam
                              </small>
                            </div>
                          </a>
                        </li>
                      </ul>

                      {/* <select className="">
                      {allTeam.map((value, index) => {
                        return (
                          <option value={value.userId.firstName}>
                            {value.userId.firstName}
                          </option>
                        );
                      })}
                    </select> */}
                      <button
                        className={`btn btn-secondary dropdown-toggle btn-follow ${userInfoRoles.ticketManagement.assignToOthers
                          ? "status-btn"
                          : ""
                          } ticketUsersDropdown`}
                        type="button"
                        data-bs-toggle={
                          userInfoRoles.ticketManagement.assignToOthers &&
                          "dropdown"
                        }
                        aria-expanded="false"
                      >
                        {getAssignedUsers()}
                      </button>
                      <ul className="dropdown-menu status-dropdown">
                        {allTeam.map((value, index) => {
                          return (
                            <li
                              onClick={() =>
                                updateAssignedUser(value.userId._id)
                              }
                            >
                              <a className="dropdown-item">
                                {ticket.assignedUsers.length > 0 &&
                                  ticket.assignedUsers.find(
                                    (row) => row == value.userId._id
                                  ) && (
                                    <span
                                      class="fas fa-check check-mark"
                                      aria-hidden="true"
                                    ></span>
                                  )}
                                <div>
                                  {value.userId.firstName ||
                                    value.userId.lastName
                                    ? getName(value.userId)
                                    : value.userId.email}{" "}
                                  <br /> <small>{value.role}</small>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                      {/* <button className="btn btn-follow">
                      <span>
                        <i className="fa-solid fa-bell"></i>
                      </span>
                      <span className="d-none">
                        <i className="fa-solid fa-bell-slash"></i>
                      </span>
                      Follow
                    </button> */}
                      {userInfoRoles.ticketManagement.add_delete && (
                        <button
                          className="btn btn-secondary dropdown-toggle btn-follow px-3"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                        </button>
                      )}
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item"
                            onClick={() => viewTickets()}
                          >
                            Edit Ticket
                          </a>
                        </li>
                        {/* <li><a className="dropdown-item"  href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#importModal">Edit Client</a></li> */}
                        {/* <li>
                        <a
                          className="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#mergeModal"
                        >
                          Merge into another ticket
                        </a>
                      </li> */}
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                            onClick={() => setDeleteId()}
                          >
                            Delete ticket
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  {!showForm && (
                    <div className="teamNote mb-3 rounded-1">
                      {ticketNote && ticketNote.length > 0 ? (
                        <p>
                          <div
                            className="serviceDescription"
                            dangerouslySetInnerHTML={{
                              __html: ticketNote,
                            }}
                          ></div>
                        </p>
                      ) : (
                        <p>Add note for your team</p>
                      )}
                      <a
                        className=""
                        href="javascript:void(0);"
                        onClick={() => setShowForm(true)}
                      >
                        Edit
                      </a>
                    </div>
                  )}
                  {showForm && (
                    <div className="teamNote">
                      <div className="form-group">
                        {/* <textarea
                      className="form-control"
                      placeholder="Add a note for your team..."
                    ></textarea> */}
                        <Editor
                          className="form-control"
                          placeholder="Add a note for your team..."
                          apiKey="fjnv8pd6m40tkdl6tva9sklqdppliphhvofstqa48b92mxtb"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          value={ticket.note}
                          init={{
                            force_br_newlines: true,
                            force_p_newlines: false,
                            forced_root_block: "", // Needed for 3.x,
                            selector: "textarea",
                            height: 200,
                            plugins: "advlist link image lists",
                            menubar: false,
                            toolbar: false,
                            menubar: "custom",
                            menu: {
                              custom: {
                                title: "custom",
                                items: "basicitem nesteditem toggleitem",
                              },
                            },
                            toolbar:
                              "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                              "fullpage" +
                              "indent | blockquote | fontselect ",
                            font_formats:
                              "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                            block_formats:
                              "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                            plugins: ["image", "help", "link", "lists"],
                            branding: false,
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                            menubar: false,
                          }}
                          onEditorChange={(e) => handleEditorChange(e)}
                        />
                        <div className="text-end">
                          <button
                            className="btn btn-save-note btn-warning"
                            value={ticket.note}
                            onClick={(e) => updateTicket({ note: ticket.note })}
                          >
                            Save note
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="sectionMessage mt-5">
                    <h3>Messages</h3>
                    {ticket.message.length > 0 &&
                      ticket.message.map((singleMessage, index) => {
                        return (
                          <div
                            className={`clientMessage ${singleMessage.to == "Client" &&
                              userInfo.id == singleMessage.from._id
                              ? `grey`
                              : `${singleMessage.to == "Client"
                                ? "white"
                                : "yellow"
                              }`
                              }`}
                          >
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="messageUser">
                                  <div className="userImg">
                                    <img
                                      src={
                                        singleMessage.from &&
                                          singleMessage.from.image
                                          ? singleMessage.from.image
                                          : Avatar
                                      }
                                    />
                                  </div>
                                  <div className="userName">
                                    <h4
                                      className={
                                        singleMessage.to == "Client"
                                          ? `client`
                                          : `team`
                                      }
                                    >
                                      {singleMessage.from && (
                                        <>
                                          {singleMessage.from.firstName ||
                                            singleMessage.from.lastName ? (
                                            <>
                                              {singleMessage.from.firstName &&
                                                singleMessage.from.lastName ? (
                                                singleMessage.from.firstName +
                                                " " +
                                                singleMessage.from.lastName
                                              ) : (
                                                <>
                                                  {singleMessage.from.firstName
                                                    ? singleMessage.from
                                                      .firstName
                                                    : singleMessage.from
                                                      .lastName}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            singleMessage.from.email
                                          )}
                                        </>
                                      )}
                                      <span className="messageStatus">
                                        {singleMessage.to == "Client"
                                          ? `Replied`
                                          : `Added a note`}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="messageUser messageTiming">
                                  <div className="userName">
                                    <p>
                                      {handleOffSetDateTime(singleMessage.date, companyData.timezone)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="message-content ">
                              <p>{parse(singleMessage.message)}</p>
                            </div>
                            <div id="attachment" className="messageAttachment">
                              {singleMessage.attachments.map((attachment) => {
                                return (
                                  <span id="addedfiles">
                                    <a>
                                      {" "}
                                      <span className="innerIcon attachIcon">
                                        <i class="fa-solid fa-link"></i>{" "}
                                      </span>
                                      {attachment.split("/uploads/")[1]}
                                    </a>
                                    {/* <span className="innerIcon downloadIcon mx-2 ms-3" onClick={() => { onButtonClick(attachment) }}><i class="fa-solid fa-download"></i></span> */}
                                    <a href={attachment} target="_blank">
                                      <span className="innerIcon mx-2 ms-3">
                                        <i class="fa-solid fa-eye"></i>
                                      </span>
                                    </a>
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}

                    {userInfoRoles.messaging.messageTeam && (
                      <div className="reply-section mb-4">
                        {!showReplyFrom && (
                          <>
                            <button
                              className="btn mx-2"
                              onClick={() => {
                                setShowReplyFrom(true);
                                setReplyTo("Client");
                              }}
                            >
                              {" "}
                              <i className="fa-solid fa-reply"></i> Reply to
                              client
                            </button>
                            <button
                              className="btn"
                              onClick={() => {
                                setShowReplyFrom(true);
                                setReplyTo("Team");
                              }}
                            >
                              {" "}
                              <i className="fa-solid fa-plus"></i> Message Team
                            </button>
                          </>
                        )}

                        {showReplyFrom && (
                          <>
                            <form className="mt-4">
                              <div className="form-group">
                                <Editor
                                  className="form-control"
                                  placeholder="Add a note for your team..."
                                  apiKey="fjnv8pd6m40tkdl6tva9sklqdppliphhvofstqa48b92mxtb"
                                  onInit={(evt, editor) =>
                                    (editorRef.current = editor)
                                  }
                                  value={messageCotent}
                                  init={{
                                    selector: "textarea",
                                    height: 200,
                                    plugins: "advlist link image lists",
                                    menubar: false,
                                    toolbar: false,
                                    menubar: "custom",
                                    menu: {
                                      custom: {
                                        title: "custom",
                                        items:
                                          "basicitem nesteditem toggleitem",
                                      },
                                    },
                                    toolbar:
                                      "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                      "fullpage" +
                                      "indent | blockquote | codesample | fontselect ",
                                    font_formats:
                                      "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                    block_formats:
                                      "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                    plugins: ["image", "help", "link", "lists"],
                                    branding: false,
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                    menubar: false,
                                    setup: function (editor) {
                                      editor.ui.registry.addSplitButton(
                                        "myButton",
                                        {
                                          icon: "line",
                                          // tooltip: "This is an example split-button",
                                          onAction: function () {
                                            // editor.insertContent(
                                            //   "<p>You clicked the main button</p>"
                                            // );
                                          },
                                          onItemAction: function (api, value) {
                                            editor.insertContent(value);
                                          },
                                          fetch: function (callback) {
                                            let replaceDoubleBraces = (
                                              str,
                                              result
                                            ) => {
                                              return str.replace(
                                                /{{(.+?)}}/g,
                                                (_, g1) =>
                                                  result[
                                                  g1.replace(/\s/g, "")
                                                  ] || g1
                                              );
                                            };
                                            var message = messages.map(
                                              (value) => {
                                                let result = {
                                                  "user.lastName":
                                                    ticket.client &&
                                                      ticket.client.lastName
                                                      ? ticket.client.lastName
                                                      : "",
                                                  "user.firstName":
                                                    ticket.client?.firstName,
                                                  "user.email":
                                                    ticket.client?.email,
                                                  "user.password": "",
                                                  "'site_name'": "Agency CRM",
                                                };
                                                return {
                                                  type: "choiceitem",
                                                  text: value.name,
                                                  value: replaceDoubleBraces(
                                                    value.body,
                                                    result
                                                  ),
                                                };
                                              }
                                            );
                                            callback(message);
                                          },
                                        }
                                      );
                                    },
                                  }}
                                  onEditorChange={(e) =>
                                    handleMessageEditorChange(e)
                                  }
                                />
                                <div className="row mt-3">
                                  <div className="col-lg-6">
                                    <div className="text-start">
                                      <div
                                        id="attachment"
                                        className="messageAttachment mb-2"
                                      >
                                        <AttachmentFiles />
                                      </div>
                                      <span
                                        className="delete me-2"
                                        onClick={() => {
                                          setShowReplyFrom(false);
                                          setMessageCotent("");
                                        }}
                                      >
                                        <i className="fa-solid fa-trash"></i>
                                      </span>
                                      <input
                                        type="file"
                                        className="btn btn-secondary"
                                        hidden
                                        multiple
                                        id="myFileInput"
                                        onChange={(e) => {
                                          setSelectedFile(e.target.files);
                                        }}
                                      />
                                      <span
                                        className="delete"
                                        onClick={() => {
                                          document
                                            .getElementById("myFileInput")
                                            .click();
                                        }}
                                      >
                                        <i class="fa-solid fa-link"></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="send-btn">
                                      {replyTo == "Client" && (
                                        <select
                                          className="form-control"
                                          value={ticketStatus}
                                          onChange={(e) => {
                                            setTicketStatus(e.target.value);
                                          }}
                                        >
                                          <option value="Open">
                                            Set as open
                                          </option>
                                          <option value="Pending">
                                            Set as Pending
                                          </option>
                                          <option value="Closed">
                                            Set as close
                                          </option>
                                        </select>
                                      )}
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={
                                          messageCotent &&
                                          messageCotent.length < 0
                                        }
                                        onClick={() => {
                                          if (
                                            messageCotent &&
                                            messageCotent.length > 0
                                          ) {
                                            updateTicketMessage(messageCotent);
                                            setShowReplyFrom(false);
                                            setMessageCotent("");
                                            // if (ticketStatus !== "") {
                                            //   updateData.status = ticket.status;
                                            // }
                                            // updateTicket(updateData);
                                          } else {
                                            toastMessage(
                                              "error",
                                              "Please insert a message"
                                            );
                                          }
                                        }}
                                      >
                                        Send to {replyTo.toLowerCase()}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            {replyTo == "Client" && (
                              <p className="ccText">
                                CC:{" "}
                                <a
                                  className="ccIds"
                                  href="javascript:void(0);"
                                  data-bs-toggle="modal"
                                  data-bs-target="#ccModal"
                                >
                                  {ticket.cc.length > 0
                                    ? ticket.cc.map((value, index) => {
                                      return `${value.email}${index == ticket.cc.length - 1
                                        ? ""
                                        : `, `
                                        }`;
                                    })
                                    : "Add"}
                                </a>
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  {/* <div className="emojisection">
                  <h4>How did we do?</h4>
                  <div className="emojibutton">
                    <button>
                      <img src={Terrible} /> <br />
                      Terrible
                    </button>

                    <button>
                      <img src={Sad} /> <br />
                      Bad
                    </button>

                    <button>
                      <img src={Okay} /> <br />
                      Okay
                    </button>

                    <button>
                      <img src={Good} /> <br />
                      Good
                    </button>

                    <button>
                      <img src={Great} /> <br />
                      Great
                    </button>
                  </div>
                </div>*/}

                  <div className="section-history mt-5 rounded-1">
                    <h3>History</h3>
                    <div className="history-table">
                      <table className="table mb-0">
                        {logs &&
                          logs.map((log) => {
                            return (
                              <tr>
                                <td>
                                  {formatMonthNameAndDate(
                                    log.timestamp,
                                    companyData.timezone
                                  )}
                                </td>
                                <td>
                                  <div
                                    className="clientName"
                                    style={{ pointer: "none" }}
                                  >
                                    <p
                                      data-bs-toggle="modal"
                                      data-bs-target="#historyModal"
                                    >
                                      <span className="rounded-1 me-2 fw-normal fs-12">
                                        {log.accountId.firstName ||
                                          log.accountId.lastName ? (
                                          <>
                                            {log.accountId.firstName &&
                                              log.accountId.lastName ? (
                                              log.accountId.firstName +
                                              " " +
                                              log.accountId.lastName
                                            ) : (
                                              <>
                                                {log.accountId.firstName
                                                  ? log.accountId.firstName
                                                  : log.accountId.lastName}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          log.accountId.email
                                        )}
                                      </span>
                                      {log.eventDescription}
                                    </p>
                                  </div>
                                </td>
                                <td>{handleGetTime(log.timestamp, companyData.timezone)}</td>
                              </tr>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="ticketInfo">
                    <h4>General Info</h4>
                    <div className="row">
                      <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                        <p>Ticket Id </p>
                      </div>
                      <div className="col-xxl-7 col-lg-7 col-md-6 col-8">
                        <p className="">{ticket._id}</p>
                      </div>
                    </div>
                    {ticket && ticket.client && (
                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                          <p> Client</p>
                        </div>
                        <div className="col-xxl-7 col-lg-7 col-md-6 col-6">
                          <p className="infoText">
                            <a href={`/view-client/${ticket.client?._id}`}>
                              {ticket && ticket.client && (
                                <>
                                  {ticket.client?.firstName ||
                                    ticket.client?.lastName ? (
                                    <>{getName(ticket.client)}</>
                                  ) : (
                                    ticket.client.email
                                  )}
                                </>
                              )}
                            </a>
                          </p>
                        </div>
                      </div>
                    )}
                    {ticket && ticket.cc && ticket.cc.length > 0 && (
                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                          <p>CC </p>
                        </div>
                        <div
                          className="col-xxl-7 col-lg-7 col-md-6 col-6"
                          style={{ cursor: "pointer" }}
                        >
                          {ticket &&
                            ticket.cc.map((item) => {
                              return (
                                <p
                                  className="infoText"
                                  onClick={() => {
                                    navigate(`/view-client/${item?._id}`);
                                  }}
                                >
                                  {item.email}
                                </p>
                              );
                            })}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                        <p>IP Address </p>
                      </div>
                      <div className="col-xxl-7 col-lg-5 col-md-6 col-8">
                        <p className="">{ticket.ipAddress} </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                        <p>Date </p>
                      </div>
                      <div className="col-xxl-7 col-lg-5 col-md-6 col-8">
                        <p className="">
                          {moment(ticket.date).format("MMM DD")}
                        </p>
                      </div>
                    </div>

                    {ticket.relatedOrder !== undefined ? (
                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6">
                          <p>Related Order </p>
                        </div>
                        <div className="col-lg-6 col-md-4">
                          <p className="infoText">
                            {ticket?.relatedOrder?.orderId}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>

                  <div className="order-tags">
                    <div className="form-group">
                      <label>Tags</label>
                      <div className="input-group mb-3 position-relative">
                        <input
                          className="form-control"
                          placeholder="New Tag"
                          value={newTag}
                          aria-label="New Tag"
                          aria-describedby="basic-addon2"
                          onChange={(e) => {
                            setNewTag(e.target.value);
                            if (e.target.value) {
                              getFilteredTags(e.target.value);
                            }
                          }}
                        />
                        {filterTags && (
                          <ul className="dataDropdown">
                            {filterTags.length > 0 &&
                              filterTags.map((tag) => {
                                return (
                                  <li
                                    onClick={(e) => {
                                      if (
                                        !ticket.tags.find(
                                          (value) => value == tag.name
                                        )
                                      ) {
                                        ticket.tags.push(tag.name);
                                        updateTicket({ tags: ticket.tags });
                                        setFilterTags();
                                        setNewTag();
                                      }
                                    }}
                                  >
                                    {tag.name}
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                        <button
                          className="input-group-text tagsButton"
                          id="basic-addon2"
                          onClick={() => {
                            if (
                              newTag.length > 0 &&
                              !ticket.tags.find((tag) => tag == newTag)
                            ) {
                              addTag();
                            }
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div className="added-tags">
                      {ticket.tags.length > 0 &&
                        ticket.tags.map((tag) => {
                          return (
                            <a
                              onClick={() => {
                                let index = ticket.tags.findIndex(
                                  (value) => value == tag
                                );
                                ticket.tags.splice(index, 1);

                                updateTicket({ tags: ticket.tags });
                              }}
                            >
                              {tag}{" "}
                              <span className="cencelTag">
                                <i class="fa-solid fa-xmark"></i>
                              </span>
                            </a>
                          );
                        })}
                    </div>
                  </div>
                  {files && files.length > 0 && (
                    <div className="ticketInfo">
                      <h4>Files</h4>
                      <ul className="mb-0">
                        {files.map((file) => {
                          return (
                            <li>
                              <a href={file.url} target="_blank">
                                {file.fileName}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                  <div className="RecentTickets">
                    <div className="accordion" id="accordionExample">
                      {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Recent orders
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="ticketList">
                            <a href="">Ticket 1</a>
                            <p>Sep 22</p>
                          </div>

                          <div className="ticketList">
                            <a href="">Ticket 1</a>
                            <p>Sep 22</p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Recent Tickets
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {otherTickets.map((row) => {
                              return (
                                <div className="ticketList">
                                  <a href={`/view-ticket/${row._id}`}>
                                    {row.subject}
                                  </a>
                                  <p>Sep 22</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* history modal */}
      {/* <div
        className="modal fade history-modal"
        id="historyModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Changed ticket status to Open.
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <div className="form-group mb-4">
                  <label>Time</label>
                  <p>Sep 19th at 12:37 pm BST</p>
                </div>

                <div className="form-group mb-4">
                  <label>User</label>
                  <p>
                    <a href="">Client Name</a>
                  </p>
                </div>

                <div className="form-group mb-4">
                  <label>Order</label>
                  <p>
                    <a href="">6B3500E7</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* history modal */}

      {/* merge modal */}
      <div
        className="modal fade history-modal"
        id="mergeModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Merge 1O36O4
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>Select a ticket to merge into </label>
                    <select className="form-control">
                      <option>Select ticket</option>
                      <option>Team member</option>
                      <option>Team member</option>
                    </select>
                    <small>
                      All messages and form fields from “Test Ticket” will be
                      moved to selected ticket.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-danger">
                Merge Ticket
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* merge modal */}

      {/* merge modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Are you sure you want to delete ticket{" "}
                  <strong> #1O36O4</strong> along with all its messages,
                  attachments, and data?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => deleteTicket(deleteId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* merge modal */}

      {/* heading modal */}
      <div
        className="modal fade history-modal"
        id="headingModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>Ticket subject</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ticketSubject}
                      onChange={(e) => setTicketSuject(e.target.value)}
                    />
                    <small>
                      Renaming the ticket will change the subject of outgoing
                      emails.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                value={ticket.subject}
                onClick={(e) => updateTicket({ subject: ticketSubject })}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* heading modal */}
      <div
        className="modal fade history-modal"
        id="ccModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>CC</label>

                    <Select
                      className="my-react-select my-react-select--compact"
                      isMulti
                      options={ccClient}
                      placeholder="Add CC"
                      value={ccUser}
                      onChange={(e) => {
                        setCcUser(e);
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length > 6) {
                          let status = String(e.target.value)
                            .toLowerCase()
                            .match(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            );
                          if (status != null) {
                            let data = {
                              label: e.target.value,
                              value: "newCC",
                            };
                            ccUser.push(data);
                            setCcUser(ccUser);
                          } else {
                            toastMessage("error", "Invalid Email Address");
                          }
                        }
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  if (ccUser.length > 0) {
                    CheckNewCC();
                  } else {
                    updateTicket({
                      cc: [],
                    });
                  }
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewTicket;

import React, { useContext, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/avatar.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { apiGetMethod } from "../../api/rest";
import { AllContext } from "../../context";
const Checkout = () => {
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiPostMethod,
    apiPutMethod,
    apiGetMethod,
    redirectToLogin,
    toastMessage,
  } = context;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const paymentAddress = useSelector(
    (state) => state.loginReducer.paymentAddress
  );
  const navigate = useNavigate();
  const { EDIT_USER_DETAILS, PAYMENTSUCCESS } = allApiUrl;
  useEffect(() => {
    function getUser() {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      const data = {
        chargeId: paymentAddress.id,
        invoiceId: paymentAddress.invoiceId,
      };
      apiPostMethod(allApiUrl.INVOICESUCCESS, data, header)
        .then((res) => {})
        .catch((err) => {
          // alert("not working");
          // toastMessage("error", err.data.message);
        });
    }
    getUser();
  }, []);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  useEffect(() => {
    getInvoiceDetail();
  }, []);
  async function getInvoiceDetail() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.INVOICE + "/" + paymentAddress.invoiceId,
        "",
        header
      );
      if (response.status === 200) {
        setIsLoading(false);
        setInvoiceDetail(response.data.invoice);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  return (
    <div>
      <section className="checkOut-page">
        <div className="container-fluid pl-240 pe-0 overflow-hidden">
          <div className="row h-100vh align-items-center">
            <div className="col-lg-7">
              <div className="checkoutListing">
                <p className="agencyName">AgencyCRM</p>
                <h3>
                  Thank you,{" "}
                  {invoiceDetail &&
                    invoiceDetail?.userDetails &&
                    invoiceDetail?.userDetails?.firstName &&
                    invoiceDetail?.userDetails?.firstName +
                      " " +
                      invoiceDetail?.userDetails?.lastName}
                </h3>
                <p>
                  We've accepted your order, and we're getting it ready. A
                  confirmation email has been sent to{" "}
                  <strong>{invoiceDetail?.userDetails?.email}</strong>.
                </p>
                <p>
                  Go to the{" "}
                  <a
                    href=""
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    client portal
                  </a>{" "}
                  to check the status of this order and receive updates.
                </p>

                <div className="row billingRow">
                  <div className="col-lg-6">
                    <div className="billingAddress">
                      <p>
                        <strong>Billing address</strong>
                      </p>
                      <p>
                        {invoiceDetail &&
                          invoiceDetail.userDetails &&
                          invoiceDetail.userDetails.billingAddress}
                      </p>
                      <p>
                        {invoiceDetail &&
                          invoiceDetail.userDetails &&
                          invoiceDetail.userDetails.postal}
                      </p>
                      <p>
                        {invoiceDetail &&
                          invoiceDetail.userDetails &&
                          invoiceDetail.userDetails.country}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="billingAddress">
                      {/* <p>
                        <strong>Order date</strong>
                      </p>
                      <p>Oct 11, 2022</p> */}
                      <p className="mt-2">
                        <strong>Payment method</strong>
                      </p>
                      {/* <p>Account Balance — $0.00</p> */}
                      <p>{invoiceDetail && invoiceDetail.paymentMethod}</p>
                    </div>
                  </div>
                </div>

                <div className="sectionHelp">
                  <p>
                    Need help? <a href="">Contact us</a>
                  </p>
                  <a
                    className="continueBtn"
                    href=""
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    Continue to client portal
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="summaryHead h-100vh">
                <div className="summaryPage">
                  <h4>Summary</h4>
                  {invoiceDetail &&
                    invoiceDetail.product?.map((item) => {
                      return (
                        <div className="d-flex">
                          <div>
                            <p>{item.id.name}</p>
                            <p>
                              <span>Qty</span> {item.quantity}
                            </p>
                          </div>
                          <div>
                            <div>
                              <p>${item.id.price?.toFixed(2)}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {(invoiceDetail.paymentCredit ||
                    invoiceDetail.paymentDue) && (
                    <div className="d-flex my-4">
                      <div>
                        <p>
                          {invoiceDetail.paymentCredit
                            ? "Payment Credit"
                            : "Payment Due"}
                        </p>
                        <p>
                          <p>
                            <span>Qty</span> 1
                          </p>
                        </p>
                      </div>
                      <div>
                        <p>
                          - $
                          {invoiceDetail.paymentCredit
                            ? invoiceDetail.paymentCredit.toFixed(2)
                            : invoiceDetail.paymentDue.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="d-flex">
                    <div>
                      <p>Total</p>
                      <p>USD</p>
                    </div>
                    <div>
                      <div>
                        {invoiceDetail &&
                        (invoiceDetail.paymentCredit ||
                          invoiceDetail.paymentDue) ? (
                          <p>
                            $
                            {(
                              invoiceDetail?.totalPrice -
                              (invoiceDetail.paymentCredit
                                ? invoiceDetail.paymentCredit
                                : invoiceDetail.paymentDue)
                            ).toFixed(2)}
                          </p>
                        ) : (
                          <p>
                            $
                            {invoiceDetail &&
                              invoiceDetail?.totalPrice?.toFixed(2)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Checkout;

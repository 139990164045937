import React, { useContext, useState, useEffect, useRef } from "react";
import Sidebar from "../../widgets/sidebar";

import Header from "../../widgets/header";
import PageLoader from "../../components/pageLoader";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllContext } from "../../context";
import Item from "antd/lib/list/Item";
import { formatMonthNameAndDate, handleGetTime } from "../../shared/functions";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import copy from "copy-to-clipboard";
import { PdfDocument } from "./Invoicepdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { array } from "yup";
const ViewInvoice = () => {
  const inputRefPrivate = useRef(null);
  const inputRefPublic = useRef(null);

  const context = useContext(AllContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const fs = require("fs");
  const {
    allApiUrl,
    apiPostMethod,
    apiGetMethod1,
    redirectToLogin,
    apiDeleteMethod,
    toastMessage,
    apiPutMethod,
    apiGetMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfoRoles = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [status, setStatus] = useState("");
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [senderEmail, setSenderEmail] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [logs, setLogs] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  // const [serviceList]
  const [invoiceId, setInvoiceId] = useState();
  let { id } = useParams();
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  useEffect(() => {
    getInvoiceDetail();
    // getAllServices();
  }, []);

  // function getAllServices() {
  //   setIsLoading(true);
  //   let header = {
  //     Authorization: `Bearer ${loginUserAccessToken}`,
  //   };
  //   apiGetMethod(allApiUrl.SERVICES, "", header)
  //     .then((res) => {
  //       console.log("res", res);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       redirectToLogin(dispatch, navigate, err);
  //       setIsLoading(false);
  //       toastMessage("error", err.data.message);
  //     });
  // }
  let orderId = window.location.pathname.split("/")[2];
  let redirectUrl = window.location.href.split("view-invoice")[0]; //http://localhost:3000/

  async function getInvoiceDetail() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.INVOICE_BY_ORDER + "/" + orderId,
        "",
        header
      );
      if (response.status === 200) {
        // setIsLoading(false);
        setPrivateLink(
          `${redirectUrl}view-payment/${response.data.invoice.orderId}`
        );
        setPublicLink(
          `${redirectUrl}public-invoice/${response.data.invoice.orderId}`
        );

        setInvoiceDetail(response.data.invoice);
        setInitialLoading(false);
      }
    } catch (err) {
      if (err.data.message == "Invoice not found") {
        navigate("/invoices");
      }
      redirectToLogin(dispatch, navigate, err);
      // setIsLoading(false);
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
    getLogs();
  }
  const deleteInvoice = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.INVOICE + "/" + invoiceDetail._id, "", header)
      .then((res) => {
        if (res.status === 200) {
          navigate("/invoices");

          toastMessage("success", res.data.message);
        }
      })
      .catch((err) => {
        console.log("error", err);
        // toastMessage("error", err.);
      });
  };
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  function downloadToPdf() {
    const input = document.getElementById("DownloadHtml");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0, 240, 240);
      pdf.save(`${invoiceDetail.invoiceId}.pdf`);
    });
  }

  function sendEmailInvoice() {
    const data = {
      email: senderEmail,
      id: invoiceDetail._id,
    };

    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.INVOICE_EMAIL, data, header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage("success", res.data.message);
        }
      })
      .catch((err) => { });
  }

  function updateInvoice(id, data) {
    data = {
      status: status,
    };
    if (invoiceDetail && invoiceDetail.userDetails) {
      data["userId"] = invoiceDetail.userDetails._id;
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    if (invoiceDetail.hasOwnProperty("userDetails")) {
      apiPutMethod(
        allApiUrl.UPDATE_INVOICE_PAID + "/" + invoiceDetail._id,
        data,
        header
      )
        .then((res) => {
          toastMessage("success", res.data.message);
          // window.location.reload();
          getInvoiceDetail();
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    } else {
      toastMessage("error", "No client assigned to invoice.");
    }
  }

  function updateInvoiceStatus(id, data) {
    data = {
      status: status,
    };

    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(allApiUrl.EDIT_INVOICE + "/" + invoiceDetail._id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        // window.location.reload();
        getInvoiceDetail();
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  const [privateLink, setPrivateLink] = useState("");
  const [isLinkCopied, setIsLinkCopied] = useState({
    public: false,
    private: false,
  });
  const [publicLink, setPublicLink] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [logsLength, setLogsLength] = useState(0);
  const copyToClipBoard = (event, from) => {
    event.preventDefault();
    if (from == "public") {
      inputRefPublic.current.select();
      setIsLinkCopied({ ...isLinkCopied, public: true, private: false });
      copy(publicLink);
    } else {
      inputRefPrivate.current.select();
      setIsLinkCopied({ ...isLinkCopied, private: true, public: false });
      copy(privateLink);
    }
  };

  async function checkoutHandler(event) {
    event.preventDefault();
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {
      type: "invoice",
      invoiceId: invoiceDetail._id,
    };
    try {
      const response = await apiPostMethod(allApiUrl.CHECKOUT, data, header);
      if (response.status === 200) {
        // dispatch(saveAddToCartInformation(response.data));
        openInNewTab(response.data.url);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data?.message);
    }
  }

  // Function that downloads PDF
  function download(data) {
    const url = URL.createObjectURL(data);
    const a = document.createElement("a");
    a.download = "test.pdf";
    a.href = url;
    a.target = "_self";

    a.click();

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
  }

  function openInNewTab(url) {
    var win = window.open(url, "_self");
    win.focus();
  }

  const getLogs = () => {
    const data = {
      resourceId: id,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(allApiUrl.FILTER_LOGS, data, header)
      .then((res) => {
        if (res.status === 200) {
          setLogs(res.data);
          setLogsLength(res.data.length);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  function getTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return (
    <div>
      <Sidebar
        activePage="invoices"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="setting-form-section pt-3 horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-back">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      navigate("/invoices");
                    }}
                  >
                    <i class="fa-solid fa-angle-left"></i> Invoices
                  </a>
                </div>
              </div>

              <div className="col-xxl-8 col-lg-12 mx-auto" id="DownloadHtml">
                <div className="client-heading mt-5">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="d-flex align-items-center">
                        <div className="userName ms-0">
                          <h3 className="fs-4">
                            Invoice #{invoiceDetail.invoiceId}
                            {/* {invoiceDetail.status && ( */}
                            <span
                              className={`pill ${invoiceDetail.status == "unpaid"
                                ? "pill-grey"
                                : "pill-green"
                                } ms-2`}
                            >
                              {invoiceDetail.status}
                            </span>
                            {/* )} */}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="btns">
                        {/* <a
                        className="btn-edit"
                        onClick={() => {
                          getPDF();
                        }}
                      >
                        Download
                      </a> */}
                        {invoiceDetail.status && (
                          <PDFDownloadLink
                            className="btn-edit"
                            document={<PdfDocument data={invoiceDetail} />}
                            fileName={`${invoiceDetail.orderId}.pdf`}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? "Loading document..." : "Download PDF"
                            }
                          </PDFDownloadLink>
                        )}
                        <a
                          href="javacsript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#shareModal"
                          className="btn-edit"
                        >
                          Share
                        </a>

                        {/* <a className="btn-edit" href="/edit-client">
                        Edit
                      </a> */}
                        {(userInfoRoles.invoiceManagement.add_edit ||
                          userInfoRoles.invoiceManagement.charge_delete) && (
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle btn-info"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javacsript:void(0);"
                                    data-bs-toggle="modal"
                                    data-bs-target="#emailModal"
                                  >
                                    Email Invoice
                                  </a>
                                </li>
                                {/* <li>
                            <a
                              className="dropdown-item"
                              href="javacsript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#addressModal"
                            >
                              Update Address
                            </a>
                          </li> */}
                                {/* <li>
                            <a
                              className="dropdown-item"
                              href="javacsript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#chargeCustomerModal"
                            >
                              Charge Customer
                            </a>
                          </li> */}
                                {invoiceDetail.status != "paid" ? (
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javacsript:void(0);"
                                      data-bs-toggle="modal"
                                      data-bs-target="#markpaidModal"
                                      onClick={() => setStatus("paid")}
                                    >
                                      Mark as paid
                                    </a>
                                  </li>
                                ) : (
                                  ""
                                )}
                                {invoiceDetail.status == "unpaid" ? (
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        navigate(
                                          `/edit-invoice/${invoiceDetail.orderId}`,
                                          {
                                            state: {
                                              _id: invoiceDetail._id,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      Edit
                                    </a>
                                  </li>
                                ) : (
                                  ""
                                )}
                                {/* <li>
                            <a
                              className="dropdown-item"
                              href="javacsript:void(0);"
                            >
                              Duplicate
                            </a>
                          </li> */}
                                {userInfoRoles.invoiceManagement
                                  .charge_delete && (
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="javacsript:void(0);"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteModal"
                                        onClick={() => setInvoiceId()}
                                      >
                                        Delete
                                      </a>
                                    </li>
                                  )}
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javacsript:void(0);"
                                    data-bs-toggle="modal"
                                    data-bs-target="#voidModal"
                                    onClick={() => setStatus("void")}
                                  >
                                    Void
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="client-details">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h5 className="invoiceText">Invoiced To:</h5>

                      {invoiceDetail && invoiceDetail.userDetails && (
                        <p className="clientText">
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.firstName && <br />}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.firstName +
                            " " +
                            (invoiceDetail.userDetails.lastName || "")}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.email && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.email) ||
                            ""}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.billingAddress && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.billingAddress) ||
                            ""}

                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.city && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.city) ||
                            ""}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.state && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.state) ||
                            ""}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.postal && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.postal) ||
                            ""}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.country && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.country) ||
                            ""}
                        </p>
                      )}
                      {invoiceDetail.hasOwnProperty("userDetails") && (
                        <button
                          className="btn btn-clear"
                          onClick={() =>
                            navigate(
                              `/view-client/${invoiceDetail.userDetails._id}`
                            )
                          }
                          style={{
                            color: "#fff",
                            background: "rgb(90, 76, 220)",
                          }}
                        >
                          Account
                        </button>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 mobileSpace">
                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientText fw-semibold">Number</p>
                        </div>
                        <div className="col-lg-6 col-8">
                          <p className="clientText">
                            {invoiceDetail.invoiceId}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientText fw-semibold">Unique ID</p>
                        </div>
                        <div className="col-lg-6 col-8">
                          <p className="clientText">#{invoiceDetail.orderId}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientText fw-semibold">Issued</p>
                        </div>
                        <div className="col-lg-6 col-8">
                          <p className="clientText">
                            {formatMonthNameAndDate(
                              invoiceDetail.createdDate,
                              companyData.timezone,
                              true
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientText fw-semibold">Due</p>
                        </div>
                        <div className="col-lg-6 col-8">
                          <p className="clientText">
                            {formatMonthNameAndDate(
                              invoiceDetail?.dueDate
                                ? invoiceDetail?.dueDate
                                : invoiceDetail.createdDate,
                              companyData.timezone,
                              true
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="client-details mt-5">
                  <div className="common-table clients-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ITEM</th>
                          <th>PRICE</th>
                          <th>QUANTITY</th>
                          <th>ITEM TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceDetail &&
                          invoiceDetail.product &&
                          invoiceDetail.product.map((item) => {
                            return (
                              <tr>
                                <td>
                                  <a
                                    style={{
                                      color: `${item.id._id == "custom"
                                        ? "black"
                                        : "#0d6efd"
                                        }`,
                                    }}
                                  >
                                    {item.id.oneTimeService == true ? (
                                      <>
                                        {item.id._id == "custom" ? (
                                          <span>{item.id.name}</span>
                                        ) : (
                                          <span
                                            onClick={() => {
                                              navigate(
                                                `/edit-service/${item.id._id}`
                                              );
                                            }}
                                          >
                                            {item.id.name}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <span
                                        onClick={() => {
                                          navigate(
                                            `/edit-service/${item.id._id}`
                                          );
                                        }}
                                      >
                                        {item.id.name}
                                        <small
                                          style={{
                                            display: "block",
                                            color: "grey",
                                          }}
                                        >
                                          {`$${item.id.price.toFixed(2)}/${item.id?.recurringOrder
                                            ?.durationTime
                                            } ${item.id?.recurringOrder
                                              ?.durationPeriod
                                            } `}
                                        </small>
                                      </span>
                                    )}
                                  </a>
                                </td>
                                {item.id.originalPrice ? (
                                  <>
                                    <td>
                                      {item.id.originalPrice !==
                                        item.id.price && (
                                          <del>${item.id.originalPrice}</del>
                                        )}
                                      <td>${item.id.price}</td>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <td>${item.id.price}</td>
                                    </td>
                                  </>
                                )}

                                <td>x{item.quantity}</td>
                                <td>${item.quantity * item.id.price}</td>
                              </tr>
                            );
                          })}

                        {invoiceDetail.paymentCredit ||
                          invoiceDetail.paymentDue ? (
                          <tr>
                            <td>
                              {invoiceDetail.paymentCredit
                                ? "Payment Credit"
                                : "Payment Due"}
                            </td>
                            <td>
                              $
                              {invoiceDetail.paymentCredit
                                ? invoiceDetail.paymentCredit
                                : invoiceDetail.paymentDue}
                            </td>
                            <td>x1</td>
                            <td>
                              -$
                              {invoiceDetail.paymentCredit
                                ? invoiceDetail.paymentCredit
                                : invoiceDetail.paymentDue}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}

                        <tr>
                          <td></td>
                          <td></td>
                          <td>Sub total</td>
                          <td>
                            $
                            {invoiceDetail.paymentCredit ||
                              invoiceDetail.paymentDue
                              ? invoiceDetail.totalPrice -
                              (invoiceDetail.paymentCredit
                                ? invoiceDetail.paymentCredit
                                : invoiceDetail.paymentDue)
                              : invoiceDetail.totalPrice}
                          </td>
                        </tr>

                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>
                              {invoiceDetail.status == "unpaid" ||
                                invoiceDetail.status == "unpaid"
                                ? "	Payment due"
                                : "Total"}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              ${" "}
                              {invoiceDetail.paymentCredit ||
                                invoiceDetail.paymentDue
                                ? invoiceDetail.totalPrice -
                                (invoiceDetail.paymentCredit
                                  ? invoiceDetail.paymentCredit
                                  : invoiceDetail.paymentDue)
                                : invoiceDetail.totalPrice}
                              {invoiceDetail &&
                                invoiceDetail.recurringInvoice &&
                                invoiceDetail.recurringInvoice.status ==
                                true && (
                                  <small
                                    style={{
                                      display: "block",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    Then ${" "}
                                    {invoiceDetail.paymentCredit ||
                                      invoiceDetail.paymentDue
                                      ? invoiceDetail.totalPrice -
                                      (invoiceDetail.paymentCredit
                                        ? invoiceDetail.paymentCredit
                                        : invoiceDetail.paymentDue
                                      ).toFixed(2)
                                      : invoiceDetail.totalPrice.toFixed(
                                        2
                                      )}{" "}
                                    /{" "}
                                    {
                                      invoiceDetail.recurringInvoice
                                        .durationTime
                                    }{" "}
                                    {
                                      invoiceDetail.recurringInvoice
                                        .durationPeriod
                                    }
                                  </small>
                                )}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          {/* <td>Then $13.00 / 2 weeks</td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {invoiceDetail.note && (
                  <p className="invoiceNote">
                    <strong>Note:</strong> {invoiceDetail.note}
                  </p>
                )}

                <div className="heading mt-4">
                  <h2 className="fs-4 mb-3">History</h2>
                </div>

                <div className="history-table mb-5">
                  {logs &&
                    logs
                      .map((item, index, array) => {
                        return (
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-2">
                              <div className="history-date">
                                <p>
                                  {" "}
                                  {formatMonthNameAndDate(
                                    item.timestamp,
                                    companyData.timezone
                                  )}
                                </p>
                              </div>
                            </div>
                            <div
                              className="col-lg-8 col-md-8 col-8"
                              onClick={() => {
                                setModalData(item);
                              }}
                            >
                              <div className="history-detail">
                                <p
                                  data-bs-toggle="modal"
                                // data-bs-target="#historyModal"
                                >
                                  <span className="fs-12 fw-normal">
                                    {item?.accountId?.firstName &&
                                      `${item?.accountId?.firstName || " "} ${item?.accountId?.lastName
                                      }`}
                                  </span>{" "}
                                  {item?.eventDescription}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-2">
                              <div className="history-date">
                                {handleGetTime(
                                  item.timestamp,
                                  companyData.timezone
                                )}
                                {/* <p>{formatAMPM(item.timestamp)}</p> */}
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .reverse()
                      .slice(0, showAll ? logsLength : 10)}
                  <div className="row">
                    {/* <div className="col-lg-2 col-md-2 col-2"></div> */}

                    {logsLength > 8 && (
                      <div
                        className="col-lg-12 col-md-12 col-12 fs-12 fw-normal"
                        onClick={() => setShowAll(!showAll)}
                      >
                        <a className="btnShowmore" href="javascript:void(0);">
                          {!showAll ? "Show All" : "Show Less"}
                        </a>
                      </div>
                    )}
                    {/* <div className="col-lg-2 col-md-2 col-2"></div> */}
                  </div>
                </div>

                {/* <p className="invoiceBytext">
                Invoice created by <a href="">Meenu Gupta</a>
              </p> */}

                {/* <div className="heading">
                <h2>History</h2>
              </div> */}

                {/* <div className="history-table mb-5">
                <div className="row">
                  <div className="col-lg-2  col-md-2">
                    <div className="history-date">
                      <p>Sep 20</p>
                    </div>
                  </div>
                  <div className="col-lg-8  col-md-8">
                    <div className="history-detail">
                      <p data-bs-toggle="modal" data-bs-target="#historyModal">
                        <span>Client name</span> invoice created.
                      </p>
                      <p data-bs-toggle="modal" data-bs-target="#historyModal">
                        <span>Client name</span> invoice created.
                      </p>
                      <p data-bs-toggle="modal" data-bs-target="#historyModal">
                        <span>Client name</span> invoice created.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2  col-md-2">
                    <div className="history-date">
                      <p>1:47 pm</p>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* history modal */}
      <div
        className="modal fade history-modal"
        id="historyModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Invoice created.
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <div className="form-group">
                  <label>Time</label>
                  <p>Sep 19th at 12:37 pm BST</p>
                </div>

                <div className="form-group">
                  <label>User</label>
                  <p>
                    <a href="">Client Name</a>
                  </p>
                </div>

                <div className="form-group">
                  <label>Order</label>
                  <p>
                    <a href="">6B3500E7</a>
                  </p>
                </div>

                <div className="form-group">
                  <label>Invoice</label>
                  <p>4F6D84BE_2</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* history modal */}

      {/* address modal */}
      <div
        className="modal fade history-modal"
        id="addressModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit billing details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content shareModal-content">
                <form>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="form-group mb-3">
                        <label>First name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-6">
                      <div className="form-group mb-3">
                        <label>Last name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label>Billing Address</label>
                    <div className="row">
                      <div className="col-lg-8">
                        <input type="text" className="form-control" />
                        <small>Address</small>
                      </div>
                      <div className="col-lg-4">
                        <input type="text" className="form-control" />
                        <small>City</small>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group mb-3">
                        <select className="form-control">
                          <option>United States</option>
                        </select>
                        <small>Country</small>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group mb-3">
                        <select className="form-control">
                          <option>Pennsylvania</option>
                        </select>
                        <small>State / Province / Region</small>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group mb-3">
                        <input type="text" className="form-control" />
                        <small>Postal / Zip Code</small>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                      />
                      <label
                        className="form-check-label checkbox-label"
                        for="flexCheckChecked"
                      >
                        I'm purchasing for a company
                      </label>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="row">
                      <div className="col-lg-6 col-6">
                        <input type="text" className="form-control" />
                        <small>Company</small>
                      </div>
                      <div className="col-lg-6 col-6">
                        <input type="text" className="form-control" />
                        <small>Tax ID</small>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* address modal */}

      {/* email modal */}
      <div
        className="modal fade history-modal"
        id="emailModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Assign Gamanjit Singh to a team member</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="history-content shareModal-content">
                <form>
                  <div className="form-group">
                    <label>Send new invoice email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={senderEmail}
                      onChange={(e) => setSenderEmail(e.target.value)}
                    />
                    <small>
                      This <a href="">New Invoice</a> notification email will be
                      sent.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => sendEmailInvoice()}
              >
                Send email
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* email modal */}

      {/* assign manager modal */}
      <div
        className="modal fade history-modal"
        id="assignModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Assign Gamanjit Singh to a team member
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>Assign this customer's orders to </label>
                    <select className="form-control">
                      <option>Select a team member</option>
                      <option>Team member</option>
                      <option>Team member</option>
                    </select>
                    <small>
                      This customer's orders will be assigned to selected team
                      member.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* assign manager modal */}

      {/* merge modal */}
      <div
        className="modal fade history-modal"
        id="shareModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Get link
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content shareModal-content">
                <form>
                  {
                    <div className="form-group mb-3">
                      <label>Private link</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          value={privateLink}
                          ref={inputRefPrivate}
                          onChange={(e) => setPrivateLink(e.target.value)}
                        />
                        <button
                          onClick={(event) => copyToClipBoard(event, "private")}
                        >
                          {isLinkCopied.private ? "Copied" : "Copy Link"}
                        </button>
                      </div>
                      <small>
                        Client will need to sign in to view invoice.
                      </small>
                    </div>
                  }

                  {/* <div className="form-group mb-3">
                    <label>Public link</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        ref={inputRefPublic}
                        value={publicLink}
                        onChange={(e) => setPublicLink(e.target.value)}
                      />
                      <button
                        onClick={(event) => copyToClipBoard(event, "public")}
                      >
                        {isLinkCopied.public ? "Copied" : "Copy Link"}
                      </button>
                    </div>
                    <small>
                      Anybody with this link can view and pay the invoice.
                    </small>
                  </div> */}

                  {/* <div className="form-group mb-3">
                    <label>Payment link</label>
                    <div className="input-group">
                      <input type="text" className="form-control" />
                      <button>Copy Link</button>
                    </div>
                    <small>
                      Takes user directly to the invoice payment page.
                    </small>
                  </div> */}
                </form>
              </div>
            </div>
            {/* <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-danger">Merge Account</button>
                    </div> */}
          </div>
        </div>
      </div>
      {/* merge modal */}

      {/* delete modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>Are you sure you want to delete this invoice?</p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => deleteInvoice(invoiceId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal */}

      {/* mark paid modal */}
      <div
        className="modal fade history-modal"
        id="markpaidModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Did you receive a payment of $
                  {invoiceDetail &&
                    invoiceDetail.partialUpfront &&
                    invoiceDetail.partialUpfront.status === true
                    ? invoiceDetail.paymentDue
                      ? invoiceDetail.totalPrice != 0
                        ? invoiceDetail.totalPrice - invoiceDetail.paymentDue
                        : 0
                      : invoiceDetail.totalPrice != 0
                        ? invoiceDetail.totalPrice - invoiceDetail.paymentCredit
                        : 0
                    : invoiceDetail && invoiceDetail.totalPrice
                      ? invoiceDetail.totalPrice.toFixed(2)
                      : ""}{" "}
                  for this invoice? Marking invoice as paid will create new
                  orders and notify the customer.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => updateInvoice()}
              >
                Mark invoice as paid
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* mark paid modal */}

      {/* void modal */}
      <div
        className="modal fade history-modal"
        id="voidModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Would you like to void this invoice? This will keep it in the
                  system for accounting purposes. Alternatively, you can delete
                  the invoice.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => updateInvoiceStatus()}
              >
                Void
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* void modal */}

      {/* charge customer modal */}
      <div
        className="modal fade history-modal"
        id="chargeCustomerModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Charge invoice #4F6D84BE_2
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="delete-content">
                <p>No active payment method on file...</p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                href="#refundModal"
                className="btn btn-primary"
              >
                Charge $100.00
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* charge customer modal */}

      {/* refund invoice modal */}
      <div
        className="modal fade  history-modal"
        id="refundModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                Refund invoice #4F6D84BE_2
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group mb-3">
                    <label>Reason</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Refund Amount</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="basic-addon1">
                        $
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                href="#refundModal"
                className="btn btn-primary"
              >
                Add Refund
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* refund invoice modal */}
    </div>
  );
};
export default ViewInvoice;

import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/dummy-avatar.jpeg";
import noData from "../../assets/img/nodata-img.svg";
import { apiGetMethod } from "../../api/rest";
import { AllContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { formatMonthNameAndDate } from "../../shared/functions";
import ReactPaginate from "react-paginate";
import PageLoader from "../../components/pageLoader";

const Payments = () => {
  const navigate = useNavigate();
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const { allApiUrl, redirectToLogin, toastMessage } = context;
  const [invoiceListing, setInvoiceListing] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [initialLoading, setInitialLoading] = useState(true);
  // const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [limit, setPageLimit] = useState("");

  const [itemOffset, setItemOffset] = useState(0);

  // let itemsPerPage = 20;
  const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
  // const handlePageClick = (event) => {
  //   const newOffset = event.selected;
  //   setItemOffset(newOffset);
  // };
  useEffect(() => {
    getInvoiceListing();
  }, [itemOffset, itemsPerPage]);
  const getInvoiceListing = () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    // let itemsPerPage = 20;
    const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
    apiGetMethod(allApiUrl.CLIENT_INVOICE, "", header)
      .then((res) => {
        if (res.status === 200) {
          var result = res.data.invoice;
          var getPaymentList = result.length;
          const paymentResult = result.slice(itemOffset, endOffset);
          setCurrentPage(itemOffset);
          // setPageCount(Math.ceil(getPaymentList / itemsPerPage));
          setPageLimit(res.data.limit || itemsPerPage);
          setInvoiceListing(paymentResult);
          // setIsLoading(false);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const currentItems = invoiceListing.slice(itemOffset, endOffset);
  let pageCount = Math.ceil(invoiceListing.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % invoiceListing.length;
    setItemOffset(newOffset);
  };

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }

  return (
    <div>
      <Sidebar
        activePage="payments"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />

      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-12">
                <div className="heading">
                  <h2 className="fs-4">Payments</h2>
                </div>
              </div>
              {/* <div className="col-lg-6">
                            <div className="section-filter">
                                <div className="dropdown">
                                    <button className="btn btn-secondary dropdown-toggle btn-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa-solid fa-filter"></i> Filter
                                    </button>
                                    <ul className="dropdown-menu filter-drowdown">
                                        <div className="filter-btns">
                                            <button className="btn btn-clear">Clear</button>
                                            <button className="btn btn-update">Update filter</button>
                                        </div>

                                        <select className="form-control">
                                            <option>Any date</option>
                                            <option>Any date</option>
                                        </select>
                                        <select className="form-control">
                                            <option>Any Order</option>
                                            <option>Any Order</option>
                                            <option>Any Order</option>
                                        </select>

                                        <select className="form-control">
                                            <option>Any ticket</option>
                                            <option>Any ticket</option>
                                            <option>Any ticket</option>
                                        </select>

                                        <select className="form-control">
                                            <option>Any client</option>
                                            <option>Any client</option>
                                            <option>Any client</option>
                                        </select>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive common-table mt-3 files-table rounded-1">
                  <table className="table logs-table mb-0">
                    <thead>
                      <tr>
                        <th>Invoice ID</th>
                        <th>Date</th>
                        <th>Total Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* no record found */}
                      {/* <tr>
                        <td colSpan={4}>
                          <div className="noData-section">
                            <img src={noData} />
                            <p>No Data Found</p>
                          </div>
                        </td>
                      </tr> */}
                      {/* no record found */}
                      {currentItems.map((item) => {
                        return (
                          <tr>
                            <td>
                              <a
                                onClick={() => {
                                  navigate(`/view-payment/${item.orderId}`, {
                                    state: {
                                      _id: item._id,
                                    },
                                  });
                                }}
                              >
                                {item.orderId}
                              </a>
                            </td>
                            <td>
                              {formatMonthNameAndDate(
                                item.createdDate,
                                companyData.timezone,
                                true
                              )}
                            </td>
                            <td>
                              {" "}
                              ${" "}
                              {item &&
                                item.partialUpfront &&
                                item.partialUpfront.status === true
                                ? item.paymentDue || item.paymentDue == 0
                                  ? item.totalPrice != 0
                                    ? (
                                      item.totalPrice - item.paymentDue
                                    ).toFixed(2)
                                    : 0.0
                                  : item.totalPrice != 0
                                    ? (
                                      item.totalPrice - item.paymentCredit
                                    ).toFixed(2)
                                    : 0.0
                                : item.totalPrice.toFixed(2)}
                            </td>
                            <td>
                              <span
                                className={`fs-12 fw-normal pill ${item.status == "unpaid"
                                  ? "pill-grey"
                                  : "pill-green"
                                  }`}
                              >
                                {item.status}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                      {currentItems.length <= 0 && (
                        <tr>
                          <td colSpan={7}>
                            <div className="noData-section">
                              <img src={noData} />
                              <p>No Data Found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="sectionPagination">
                  <NoOfRows />
                  <ReactPaginate
                    nextLabel={<i class="fa-solid fa-angles-right"></i>}
                    previousLabel={<i class="fa-solid fa-angles-left"></i>}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination paginate"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Payments;

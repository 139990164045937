import React, { useContext, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/avatar.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { apiGetMethod } from "../../api/rest";
import { AllContext } from "../../context";
import cardIcon from "../../assets/img/card-img.png";
import visaIcon from "../../assets/img/card-visa.svg";
import payPal from "../../assets/img/paypal.png";
import { countries } from "../../shared/constants";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../CheckoutForm";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "../CartSection";
import { savePaymentAddress } from "../../redux/action/login";
import PageLoader from "../../components/pageLoader";
// import "../styles.css";
const Payment = () => {
  //constants
  const { state } = useLocation();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiPostMethod,
    apiPutMethod,
    apiGetMethod,
    redirectToLogin,
    toastMessage,
  } = context;
  const [isLoading, setIsLoading] = useState(false);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const dispatch = useDispatch();
  const { EDIT_USER_DETAILS, PAYMENTSUCCESS } = allApiUrl;
  const [isPurchasingForCompany, setIsPurchasingForCompany] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [userInformation, setUserInformation] = useState({});
  const [isCompletePurchased, setCompletePurchased] = useState(false);
  const [completePurchaseResponse, setCompletePurchaseReponse] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("newCard");
  const [saveCard, setSaveCard] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [addToCartList, setAddToCartList] = useState([]);

  const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_KEY);
  const navigate = useNavigate();
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  function getUserDetails(userProfileId) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(EDIT_USER_DETAILS, userProfileId, header)
      .then((res) => {
        setIsPurchasingForCompany(
          res.data.user[0].company || res.data.user[0].taxId ? true : false
        );
        setUserInformation(res.data.user[0]);
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }
  const [stripe, setStripe] = useState("");
  const [element, setElement] = useState("");
  function handleCheckout(event) {
    event.preventDefault();
    if (paymentMethod == "newCard") {
      handleSubmit(event, stripe, element);
    } else {
      setInitialLoading(true);
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      const data = {
        couponCode: addToCartList.coupon || "",
        invoiceId: invoiceId || "",
        type: state.from == "invoice" ? "invoice" : "cart",
        newCard: paymentMethod == "newCard" ? true : false,
        // saveCard,
        // cardToken: result?.token?.id,
        billingAddress: userInformation.billingAddress,
        city: userInformation.city,
        state: userInformation.state,
        postal: userInformation.postal,
        company: userInformation.company,
        country: userInformation.country,
        taxId: userInformation.taxId,
        firstName: userInformation.userId.firstName,
        email: userInformation.userId.email
          ? userInformation.userId.email
          : userInformation.email,
      };
      if (paymentMethod !== "newCard") {
        data["cardId"] = cardId;
      }

      apiPostMethod(allApiUrl.CHECKOUT, data, header)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              savePaymentAddress({
                ...data,
                id: res.data.docs.id,
                stripeData: res.data.stripeData,
                invoiceId,
              })
            );
            setCompletePurchased(true);
            setCompletePurchaseReponse(res.data);
            setIsLoading(false);
            setInitialLoading(false);
          }
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setInitialLoading(false);
        });
    }
  }
  const [partialPayment, setPartialPayment] = useState(0);
  async function getInvoiceDetail(data) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.INVOICE_BY_ORDER + "/" + data,
        "",
        header
      );
      if (response.status === 200) {
        setInvoiceId(response.data.invoice._id);

        setAddToCartList(response.data.invoice);
        let total =
          response.data.invoice &&
          response.data.invoice.product &&
          response.data.invoice.product.length > 0 &&
          response.data.invoice.product.map(
            (item) => item.id.price * item.quantity
          );

        setGrandTotal(
          total?.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          )
        );
        if (
          response.data.invoice.paymentCredit ||
          response.data.invoice.paymentDue
        ) {
          setPartialPayment(
            response.data.invoice.paymentCredit
              ? response.data.invoice.paymentCredit
              : response.data.invoice.paymentDue
          );
        }

        setIsPurchasingForCompany(
          response.data.invoice.userDetails.company ||
            response.data.invoice.userDetails.company
            ? true
            : false
        );

        setUserInformation({
          ...response.data.invoice.userDetails,
          userId: {
            firstName: response.data.invoice.userDetails.firstName,
            email: response.data.invoice.userDetails.email,
          },
        });

        response.data.invoice &&
          response.data.invoice.product &&
          response.data.invoice.product.length > 0 &&
          response.data.invoice.product.map((obj) => {
            if (obj.id.recurringOrder.status == true) {
              count = count + 1;
              setSubTotalSection(obj);
            }

            setIsMoreThanOneRecurring(count);
          });
      }
    } catch (err) {
      toastMessage("error", err.data.message);
    }
  }

  useEffect(() => {
    if (state && state.from == "cart") {
      getAddToCartList();
    }
    getListOfCards();
    if (state && state.from == "cart") {
      getUserDetails(loginUserInfo.id);
    } else {
      if (state && state.unAssigedInvoice == true) {
        getUserDetails(loginUserInfo.id);
      }
      getInvoiceDetail(state.orderId);
    }
  }, []);
  async function getAddToCartList() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.CART, "", header);
      if (response.status === 200) {
        if (response.data.couponData._id) {
          let fixedAmount = response.data.couponData.fixedAmount;
          response.data.couponData.applies_to.forEach((name) => {
            if (!Array.isArray(name.service) || !name.service.length) {
              response.data.cart[0]?.product.forEach((product, index) => {
                if (fixedAmount) {
                  response.data.cart[0].product[index].discountPrice =
                    response.data.cart[0].product[index].price - name.discount;
                  if (response.data.cart[0].product[index].discountPrice < 0) {
                    response.data.cart[0].product[index].discountPrice = 0;
                  }
                } else {
                  response.data.cart[0].product[index].discountPrice =
                    response.data.cart[0].product[index].price -
                    (
                      (name.discount / 100) *
                      response.data.cart[0].product[index].price
                    ).toFixed(2);
                  if (response.data.cart[0].product[index].discountPrice < 0) {
                    response.data.cart[0].product[index].discountPrice = 0;
                  }
                }
                // }
              });
            } else {
              name.service.forEach((item) => {
                response.data.cart[0]?.product.forEach((product, index) => {
                  if (item === product.id._id) {
                    if (fixedAmount) {
                      response.data.cart[0].product[index].discountPrice =
                        response.data.cart[0].product[index].price -
                        name.discount;
                      if (
                        response.data.cart[0].product[index].discountPrice < 0
                      ) {
                        response.data.cart[0].product[index].discountPrice = 0;
                      }
                    } else {
                      response.data.cart[0].product[index].discountPrice =
                        response.data.cart[0].product[index].price -
                        (
                          (name.discount / 100) *
                          response.data.cart[0].product[index].price
                        ).toFixed(2);
                      if (
                        response.data.cart[0].product[index].discountPrice < 0
                      ) {
                        response.data.cart[0].product[index].discountPrice = 0;
                      }
                    }
                  }
                });
              });
            }
          });
          setAddToCartList(response.data.cart[0]);
          let total =
            response.data.cart[0] &&
            response.data.cart[0].product &&
            response.data.cart[0].product.length > 0 &&
            response.data.cart[0].product.map(
              (item) =>
                (item.discountPrice ? item.discountPrice : item.price) *
                item.quantity
            );
          setGrandTotal(
            total?.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            )
          );
          response.data.cart[0] &&
            response.data.cart[0].product &&
            response.data.cart[0].product.length > 0 &&
            response.data.cart[0].product.map((obj) => {
              if (obj.id.recurringOrder.status == true) {
                count = count + 1;
                setSubTotalSection(obj);
              }

              setIsMoreThanOneRecurring(count);
            });
        } else {
          setAddToCartList(response.data.cart[0]);
          let total =
            response.data.cart[0] &&
            response.data.cart[0].product &&
            response.data.cart[0].product.length > 0 &&
            response.data.cart[0].product.map(
              (item) => item.price * item.quantity
            );
          setGrandTotal(
            total?.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            )
          );
          response.data.cart[0] &&
            response.data.cart[0].product &&
            response.data.cart[0].product.length > 0 &&
            response.data.cart[0].product.map((obj) => {
              if (obj.id.recurringOrder.status == true) {
                count = count + 1;
                setSubTotalSection(obj);
              }

              setIsMoreThanOneRecurring(count);
            });
        }
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
    }
  }

  let recurringServiceExist =
    addToCartList &&
    addToCartList.product &&
    addToCartList.product.some((obj) => obj.id.recurringOrder.status === true);
  let recurringInvoiceExist =
    addToCartList &&
    addToCartList.recurringInvoice &&
    addToCartList.recurringInvoice.status == true;
  let count = 0;
  const [isMoreThanOneRecurring, setIsMoreThanOneRecurring] = useState(null);
  const [subTotalSection, setSubTotalSection] = useState({});

  async function handleSubmit(event, stripe, elements) {
    setInitialLoading(true);
    event.preventDefault();
    setIsLoading(true);
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      toastMessage("error", result.error.message);
      setInitialLoading(false);
    } else {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      const data = {
        couponCode: addToCartList.coupon ? addToCartList.coupon : "",
        invoiceId: invoiceId ? invoiceId : "",
        type: state.from == "invoice" ? "invoice" : "cart",
        newCard:
          saveCard || recurringServiceExist || recurringInvoiceExist
            ? true
            : false,
        cardToken: result?.token?.id,
      };
      if (userInformation && userInformation.billingAddress) {
        data["billingAddress"] = userInformation.billingAddress || "";
      }
      if (userInformation && userInformation.city) {
        data["city"] = userInformation.city || "";
      }
      if (userInformation && userInformation.state) {
        data["state"] = userInformation.state || "";
      }
      if (userInformation && userInformation.postal) {
        data["postal"] = userInformation.postal || "";
      }

      if (userInformation && userInformation.company) {
        data["company"] = userInformation.company || "";
      }
      if (userInformation && userInformation.country) {
        data["country"] = userInformation.country || "";
      }
      if (userInformation && userInformation.taxId) {
        data["taxId"] = userInformation.taxId || "";
      }
      if (userInformation && userInformation.name) {
        data["name"] = userInformation.firstName || "";
      }
      if (
        (userInformation && userInformation.email) ||
        (userInformation && userInformation.userId.email)
      ) {
        data["email"] = userInformation.email
          ? userInformation.email
          : userInformation.userId.email || "";
      }

      if (paymentMethod !== "newCard") {
        data["cardId"] = cardId;
      }
      apiPostMethod(allApiUrl.CHECKOUT, data, header)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              savePaymentAddress({
                ...data,
                id: res.data.docs.id,
                stripeData: res.data.stripeData,
                invoiceId,
              })
            );
            setCompletePurchased(true);
            setCompletePurchaseReponse(res.data);
            setIsLoading(false);
            setInitialLoading(false);
          }
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setInitialLoading(false);
        });
    }
  }

  const [existingCardList, setExistingcardList] = useState([]);
  function getListOfCards() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.LIST_CARDS, "", header)
      .then((res) => {
        if (res.status == 200) {
          res.data.data = res.data.data.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.last4 === value.last4 &&
                  t.exp_month == value.exp_month &&
                  t.exp_year == value.exp_year
              )
          );
          setExistingcardList(res.data.data);
        }
      })
      .catch((err) => {
        // toastMessage("error", err.data.message);
      });
  }

  const [initialLoading, setInitialLoading] = useState(false);
  const [cardId, setCardId] = useState("");

  return (
    <>
      {isCompletePurchased ? (
        <Thankyou
          completePurchaseResponse={completePurchaseResponse}
          from={state.from}
        />
      ) : (
        <div>
          <PageLoader isLoading={initialLoading} />
          <section className="checkOut-page">
            <div className="container-fluid pl-240 pe-0 overflow-hidden">
              <div className="row">
                <div className="col-lg-7">
                  <div className="checkoutListing pr-80 mt-5 formCheckout">
                    <p className="agencyName">AgencyCRM</p>

                    <form onSubmit={handleCheckout}>
                      <h4>Email</h4>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <input
                              type="text"
                              className="form-control"
                              value={userInformation?.userId?.email}
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    userId: {
                                      ...prevData.userId,
                                      email: e.target.value,
                                    },
                                  };
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <h4>Billing Address</h4>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              value={userInformation.billingAddress}
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    billingAddress: e.target.value,
                                  };
                                })
                              }
                            />
                            <p>Address</p>
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              value={userInformation.city}
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    city: e.target.value,
                                  };
                                })
                              }
                            />
                            <p>City</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-4">
                            <select
                              className="form-control"
                              value={userInformation.country}
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    country: e.target.value,
                                  };
                                })
                              }
                            >
                              <option value="" selected>
                                Select Country<body></body>
                              </option>
                              {countries.map((val) => {
                                return (
                                  <option value={val.name}>{val.name}</option>
                                );
                              })}
                            </select>
                            <p>
                              <small>Country</small>
                            </p>
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              value={userInformation.state}
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    state: e.target.value,
                                  };
                                })
                              }
                            />
                            <p>State/Province/Region</p>
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              value={userInformation.postal}
                              onChange={(e) =>
                                setUserInformation((prevData) => {
                                  return {
                                    ...prevData,
                                    postal: e.target.value,
                                  };
                                })
                              }
                            />
                            <p>Postal/ Zip Code</p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onClick={() => {
                              setIsPurchasingForCompany(
                                !isPurchasingForCompany
                              );
                            }}
                            checked={isPurchasingForCompany}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            I'm purchasing for a company
                          </label>
                        </div>
                      </div>
                      {isPurchasingForCompany && (
                        <>
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={userInformation.company}
                                  onChange={(e) =>
                                    setUserInformation((prevData) => {
                                      return {
                                        ...prevData,
                                        company: e.target.value,
                                      };
                                    })
                                  }
                                />
                                <small>Company</small>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={userInformation.taxId}
                                  onChange={(e) =>
                                    setUserInformation((prevData) => {
                                      return {
                                        ...prevData,
                                        taxId: e.target.value,
                                      };
                                    })
                                  }
                                />
                                <small>Tax ID</small>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <h4 className="mt-3">Payment method</h4>

                      {existingCardList &&
                        existingCardList.length > 0 &&
                        existingCardList.map((cardItem) => {
                          return (
                            <div className="paymentOption">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name={`existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`}
                                  id={`existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`}
                                  className="form-check-input"
                                  value={`existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`}
                                  checked={
                                    paymentMethod ==
                                    `existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`
                                  }
                                  onChange={(e) => {
                                    setCardId(cardItem.id);
                                    setPaymentMethod(e.target.value);
                                  }}
                                />
                                <label
                                  class="form-check-label d-block"
                                  for={`existingCard-${cardItem.last4}${cardItem.exp_month}${cardItem.exp_year}`}
                                >
                                  <div className="d-flex justify-content-between">
                                    <p className="existingCard mb-0">
                                      <img src={visaIcon} /> **** **** ****
                                      {cardItem.last4}
                                    </p>
                                    <span className="expDel">
                                      <span className="cardExpire">
                                        Expires {cardItem.exp_month}/
                                        {cardItem.exp_year}
                                      </span>

                                      {/* <a href="javascript:void(0);"><i class="fa-solid fa-trash"></i></a> */}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}

                      <div
                        class="accordion"
                        id="accordionPanelsStayOpenExample"
                      >
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id="panelsStayOpen-headingOne"
                          >
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseOne"
                            >
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  value="newCard"
                                  checked={paymentMethod == "newCard"}
                                  onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                  }}
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  <img src={cardIcon} />
                                </label>
                              </div>
                            </button>
                          </h2>
                          {paymentMethod == "newCard" && (
                            <div
                              id="panelsStayOpen-collapseOne"
                              class="accordion-collapse collapse show"
                              aria-labelledby="panelsStayOpen-headingOne"
                            >
                              <div class="accordion-body">
                                {/* stripe card Details */}
                                <div className="form-group">
                                  <div className="row">
                                    <Elements stripe={stripePromise}>
                                      <ElementsConsumer>
                                        {({ stripe, elements }) => (
                                          <>
                                            {
                                              (setStripe(stripe),
                                              setElement(elements))
                                            }
                                            <CardSection />
                                          </>
                                        )}
                                      </ElementsConsumer>
                                    </Elements>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Card Name"
                                    value={userInformation.userId?.firstName}
                                    onChange={(e) =>
                                      setUserInformation((prevData) => {
                                        return {
                                          ...prevData,
                                          userId: {
                                            ...prevData.userId,
                                            firstName: e.target.value,
                                          },
                                        };
                                      })
                                    }
                                  />
                                  <p>Name on card</p>
                                </div>
                                <div className="form-group">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckDefault"
                                      onClick={() => {
                                        setSaveCard(!saveCard);
                                      }}
                                      checked={saveCard}
                                    />
                                    <label
                                      class="form-check-label"
                                      for="flexCheckDefault"
                                    >
                                      Save Card
                                    </label>
                                  </div>
                                </div>
                                {/* <p>
                        Payments are currently in test mode. Use one of the{" "}
                        <a href="javascript:void(0);">
                          sample card numbers
                        </a>{" "}
                        for testing.
                      </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <div className="paymentOption">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault2" />
                        <label class="form-check-label" for="flexRadioDefault2">
                            <img src={payPal} />
                        </label>
                    </div>
                </div> */}

                      <button className="btn bnt-save">
                        Complete Purchase
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="summaryHead h-100vh">
                    <div className="summaryPage">
                      <h4>Summary</h4>
                      {addToCartList &&
                        addToCartList.product &&
                        addToCartList.product.length > 0 &&
                        addToCartList.product.map((item) => {
                          return (
                            <div className="d-flex">
                              <div>
                                <p>{item.id.name}</p>
                                <p>
                                  <span>Qty</span> {item.quantity}
                                </p>
                              </div>
                              <div>
                                <div>
                                  <p>
                                    $
                                    {item.discountPrice
                                      ? (
                                          item.quantity * item.discountPrice
                                        ).toFixed(2)
                                      : (
                                          item.quantity *
                                          (state.from == "invoice"
                                            ? item.id.price
                                            : item.price)
                                        ).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {(addToCartList.paymentCredit ||
                        addToCartList.paymentDue) && (
                        <div className="d-flex my-4">
                          <div>
                            <p>
                              {addToCartList.paymentCredit
                                ? "Credit"
                                : "Payment Due Later"}
                            </p>
                            <p>
                              <p>
                                <span>Qty</span> 1
                              </p>
                            </p>
                          </div>
                          <div>
                            <p>
                              - $
                              {addToCartList.paymentCredit
                                ? addToCartList.paymentCredit.toFixed(2)
                                : addToCartList.paymentDue.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      )}
                      <hr />
                      <div className="d-flex">
                        <div>
                          <p>Total</p>
                          <p>USD</p>
                        </div>
                        <div>
                          <div>
                            {addToCartList.paymentCredit ||
                            addToCartList.paymentDue ? (
                              <p>${(grandTotal - partialPayment).toFixed(2)}</p>
                            ) : (
                              <p>${grandTotal?.toFixed(2)}</p>
                            )}
                            {isMoreThanOneRecurring == 1 && (
                              <small
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                ${grandTotal.toFixed(2)} now, then $
                                {(
                                  subTotalSection?.quantity *
                                  subTotalSection?.id?.price
                                ).toFixed(2)}
                                /
                                {subTotalSection.id.recurringOrder.durationTime}{" "}
                                {
                                  subTotalSection.id.recurringOrder
                                    .durationPeriod
                                }
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

const Thankyou = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (props.completePurchaseResponse.docs.status == "succeeded") {
        let url =
          props.from == "invoice"
            ? "/invoice/paymentSuccess"
            : "/product/paymentSuccess";
        navigate(url);
      } else {
        let url =
          props.from == "invoice"
            ? "/invoice/paymentFailed"
            : "/product/paymentFailed";
        navigate(url);
      }
    }, 4000);
  }, []);

  return (
    <main className="wrapper">
      <div className="section-login">
        <div className="container">
          <div className="row h-80vh mx-0 align-items-center">
            <div className="col-lg-6 mx-auto">
              <div className="loginForm">
                <a className="brandName">AgencyCRM</a>
                <h3>Thank you for choosing AgencyCRM</h3>

                <p className="text-muted mb-3 text-center">
                  Your payment was successful, we are currently processing your
                  order.
                </p>
                <div className="text-center mb-2">
                  <div className="payment-loader"></div>
                </div>
                <p className="text-muted mb-5 text-center loaderText">
                  If you stay on this page you'll be logged in as soon as the
                  order is done processing. you will also receive an email with
                  your login details.
                </p>
                <div className="sectionalert">
                  <p>
                    <strong>You are currently in test mode.</strong> if this
                    page does not refresh automatically it means we're not
                    receiving notification from your payment processor. please
                    follow the instructions to them for <strong>PayPal</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Payment;

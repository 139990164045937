import React, { useContext, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";
import { AllContext } from "../../context";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect } from "react";
import { useDebugValue } from "react";

const AddService = () => {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiPutMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { SERVICES, ADD_SERVICE } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const navigate = useNavigate();
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [services, setServices] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [addOn, setAddOn] = useState({});
  const [image, setImage] = useState();
  const [loader, setLoader] = useState(false);
  const [orderService, setOrderService] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [duration, setDuration] = useState("months");
  const [durationQuantity, setDurationQuantity] = useState(0);
  const [intakeFormStatus, setIntakeFormStatus] = useState(false);
  const [oneTimeService, setOneTimeService] = useState(true);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [selectedIntakeForm, setSelectedIntakeForm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [priceRecurring, setPriceRecurring] = useState(0);
  const [newOrder, setNewOrder] = useState("newOrder");
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  function handleFormSubmit() {
    // const data = {
    //   name: serviceName,
    //   description: description,
    //   imageUrl: "",
    //   price: price,
    //   currency: "USD",
    //   durationsTime: durationQuantity,
    //   durationPeriod: duration,
    //   orderCount: quantity,
    //   addOn: addOn,
    //   public: visibility,
    //   oneTimeService: oneTimeService,
    //   intakeForm: selectedIntakeForm,
    // };
    // let header = {
    //   Authorization: `Bearer ${loginUserAccessToken}`,
    // };
    // setIsLoading(true);
    // apiPostMethod(SERVICES, data, header)
    //   .then((res) => {
    //     if (res.status === 200) {
    //       toastMessage("success", res.data.message);
    //       navigate("/services");
    //       setIsLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     redirectToLogin(dispatch, navigate, err);
    //     setIsLoading(false);
    //     toastMessage("error", err.data.message);
    //   });
    updateProfile();
  }
  function handleEditorChange(e) {
    // var str = e.target.getContent();
    setDescription(e);
  }
  useEffect(() => {
    getAllServices();
  }, []);
  function getAllServices() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(SERVICES, "", header)
      .then((res) => {
        setServices(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  const updateProfile = async () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
      "Content-Type": "multipart/form-data",
    };
    let recurringOrder = {
      status: !oneTimeService && true,
      durationTime: durationQuantity, //value
      durationPeriod: duration, //type (month,days,weeks)
      newOrder: newOrder == "newOrder" ? false : true,
    };
    const formData = new FormData();
    formData.append("imageUrl", image ? image : "");
    formData.append("name", serviceName.trim());
    formData.append("price", oneTimeService == true ? price : priceRecurring);
    formData.append("currency", "USD");
    formData.append("description", description);
    formData.append("orderCount", quantity);
    formData.append("addOn", addOn);
    formData.append("public", visibility);
    formData.append("oneTimeService", oneTimeService);
    formData.append(
      "intakeForm",
      selectedIntakeForm != "" ? selectedIntakeForm : "no_form"
    );
    formData.append("recurringOrder", JSON.stringify(recurringOrder));
    setIsLoading(true);
    apiPostMethod(ADD_SERVICE, formData, header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage("success", res.data.message);
          setIsLoading(false);
          navigate("/services");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };
  return (
    <div>
      <Sidebar
        activePage="services"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <div className="setting-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-12">
              <div className="tabs-content">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        intakeFormStatus ? "nav-link" : "nav-link active"
                      }
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      onClick={() => setIntakeFormStatus(false)}
                    >
                      Edit
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        intakeFormStatus ? "nav-link active" : "nav-link"
                      }
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                      disabled={serviceName == ""}
                    >
                      Intake form
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-back">
                <a href="/services">
                  {" "}
                  <i class="fa-solid fa-angle-left"></i> Services{" "}
                </a>
              </div>
            </div>

            <div className="col-xxl-5 col-xl-8 mx-auto">
              <div className="setting-form">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      intakeFormStatus ? "" : "show active"
                    }`}
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <div className="commonForm rounded-1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Service Name</label>
                            <input
                              type="text"
                              required
                              className="form-control"
                              placeholder="My Service"
                              value={serviceName}
                              onChange={(e) => {
                                setServiceName(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>
                              Description <small>Optional</small>
                            </label>
                            <Editor
                              required
                              apiKey="fjnv8pd6m40tkdl6tva9sklqdppliphhvofstqa48b92mxtb"
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              value={description}
                              init={{
                                forced_root_block: "",
                                selector: "textarea",
                                height: 500,
                                branding: false,

                                plugins: "advlist link image lists",
                                menubar: false,
                                toolbar: false,
                                menubar: "custom",
                                menu: {
                                  custom: {
                                    title: "custom",
                                    items: "basicitem nesteditem toggleitem",
                                  },
                                },
                                toolbar:
                                  "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                  "fullpage" +
                                  "indent | blockquote | codesample | fontselect ",
                                font_formats:
                                  "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                block_formats:
                                  "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                plugins: [
                                  "image",
                                  "help",
                                  "link",
                                  "codesample",
                                  "lists",
                                ],
                                branding: false,
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                menubar: false,
                              }}
                              onEditorChange={(e) => handleEditorChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group mb-0">
                            <div className="d-flex">
                              <button className="btn btn-default">
                                Upload Image
                                <input
                                  type="file"
                                  id="uploadCaptureInputFile"
                                  onChange={(e) => {
                                    setImage(e.target.files[0]);
                                  }}
                                />
                              </button>
                            </div>
                            <div className="previewImg">
                              <span className="ImgSection">
                                {image && (
                                  <>
                                    <img
                                      src={
                                        image ? URL.createObjectURL(image) : ""
                                      }
                                    />
                                    <span className="Del">
                                      <i
                                        class="fa-solid fa-trash"
                                        onClick={() => {
                                          setImage();
                                          document.getElementById(
                                            "uploadCaptureInputFile"
                                          ).value = "";
                                        }}
                                      ></i>
                                    </span>
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="service-pricingForm">
                      <h3 className="mt-4">Pricing</h3>
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="home-tab-pane"
                            aria-selected="true"
                            onClick={() => setOneTimeService(true)}
                          >
                            One-time service
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="profile-tab-pane"
                            aria-selected="false"
                            onClick={() => setOneTimeService(false)}
                          >
                            Recurring service
                          </button>
                        </li>
                      </ul>
                      <div className="commonForm rounded-1">
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="home-tab-pane"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                            tabindex="0"
                          >
                            <div className="row">
                              <div className="col-xxl-7 col-lg-7">
                                <div className="form-group">
                                  <label>Price</label>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <input
                                        className="form-control mobileSpace"
                                        type="text"
                                        value="USD"
                                        disabled
                                        onClick={() => setPrice()}
                                      />
                                    </div>
                                    <div className="col-lg-8">
                                      <input
                                        className="form-control"
                                        type="number"
                                        value={price}
                                        onChange={(e) => {
                                          if (e.target.value < 0) {
                                            toastMessage(
                                              "error",
                                              "Price cannot be less than 0"
                                            );
                                          } else {
                                            setPrice(e.target.value);
                                          }
                                        }}
                                        onWheel={() =>
                                          document.activeElement.blur()
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <a href="">Create multiple orders?</a> */}

                                <div className="form-group">
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder=""
                                      value={quantity}
                                      onChange={(e) => {
                                        if (e.target.value < 1) {
                                          toastMessage(
                                            "error",
                                            "Order cannot be less than 1"
                                          );
                                        } else {
                                          setQuantity(e.target.value);
                                        }
                                      }}
                                      onWheel={() =>
                                        document.activeElement.blur()
                                      }
                                      aria-describedby="basic-addon2"
                                    />
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    >
                                      orders
                                    </span>
                                  </div>
                                  <small>
                                    {quantity} new orders will be created when
                                    this service is purchased.
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="profile-tab-pane"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                            tabindex="0"
                          >
                            <div className="row">
                              <div className="form-group">
                                <label>Price</label>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <input
                                          className="form-control mobileSpace"
                                          type="text"
                                          value="USD"
                                          disabled
                                        />
                                      </div>
                                      <div className="col-lg-8">
                                        <input
                                          className="form-control mobileSpace"
                                          type="number"
                                          value={priceRecurring}
                                          onChange={(e) => {
                                            if (e.target.value < 0) {
                                              toastMessage(
                                                "error",
                                                "Price cannot be less than 0"
                                              );
                                            } else {
                                              setPriceRecurring(e.target.value);
                                            }
                                          }}
                                          onWheel={() =>
                                            document.activeElement.blur()
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="input-group">
                                      <span
                                        className="input-group-text"
                                        id="basic-addon2"
                                      >
                                        every
                                      </span>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        aria-describedby="basic-addon2"
                                        value={durationQuantity}
                                        onChange={(e) => {
                                          if (e.target.value < 0) {
                                            toastMessage(
                                              "error",
                                              "Duration cannot be less than 0"
                                            );
                                          } else {
                                            setDurationQuantity(e.target.value);
                                          }
                                        }}
                                        onWheel={() =>
                                          document.activeElement.blur()
                                        }
                                      />
                                      <select
                                        className="form-control"
                                        onChange={(e) =>
                                          setDuration(e.target.value)
                                        }
                                      >
                                        <option value="months">Month</option>
                                        <option value="years">Year</option>
                                        <option value="weeks">Week</option>
                                        <option value="days">Day</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="alert alert-warning" role="alert">
                              A price under $0.50 may not be supported in your
                              payment processor.
                            </div>

                            <div className="form-group">
                              <label className="mb-3">
                                a recurring payment is received...
                              </label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault1"
                                  id="flexRadioDefault1"
                                  value="newOrder"
                                  checked={newOrder == "newOrder"}
                                  onChange={(e) => {
                                    setNewOrder(e.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  for="flexRadioDefalt1"
                                >
                                  Do nothing <br />
                                  <small>
                                    Order status and due date will not change.
                                  </small>
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault2"
                                  id="flexRadioDefault2"
                                  value="createNewOrder"
                                  checked={newOrder == "createNewOrder"}
                                  onChange={(e) => {
                                    setNewOrder(e.target.value);
                                  }}
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  for="flexRadioDefa"
                                >
                                  Create new orders <br />{" "}
                                  <small>
                                    If you want clients to fill out {quantity}{" "}
                                    new intake forms every day.
                                  </small>
                                </label>
                                {newOrder == "createNewOrder" && (
                                  <div className="col-lg-4">
                                    <div className="input-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        aria-describedby="basic-addon2"
                                        value={quantity}
                                        onChange={(e) => {
                                          if (e.target.value < 1) {
                                            toastMessage(
                                              "error",
                                              "Order cannot be less than 1"
                                            );
                                          } else {
                                            setQuantity(e.target.value);
                                          }
                                        }}
                                        onWheel={() =>
                                          document.activeElement.blur()
                                        }
                                      />
                                      <span
                                        className="input-group-text"
                                        id="basic-addon2"
                                      >
                                        orders
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h3 className="mt-4">Orders of this service</h3> */}
                    {/* <div className="commonForm rounded-1">
                      <div className="form-group">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="newOrders"
                            checked={orderService}
                            onChange={(e) => {
                              setOrderService(e.target.checked);
                            }}
                          />
                          <label
                            className="form-check-label checkbox-label"
                            for="newOrders"
                          >
                            This is an add-on, don't create a new order <br />
                            <small>
                              An order will not be created when this service is
                              purchased. Intake form will be appended to parent
                              service. You can select one or more parent
                              services below.
                            </small>
                            {orderService == true ? (
                              <select
                                className="form-control mt-2"
                                value={addOn}
                                onChange={(event) => {
                                  setAddOn(event.target.value);
                                }}
                              >
                                <option value="" selected>
                                  Select Parent Service<body></body>
                                </option>
                                {services.map((val, key) => {
                                  return (
                                    <option value={val.name}>{val.name}</option>
                                  );
                                })}
                                {/* <option>Select Parent Service</option> */}
                    {/* <option>Select Parent Service</option>
                                <option>Select Parent Service</option> */}
                    {/* </select>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    </div> */}

                    <h3 className="mt-4">Visibility</h3>
                    <div className="commonForm rounded-1">
                      <div className="form-group mb-0">
                        <div className="form-check">
                          <input
                            required
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="showServicePage"
                            checked={visibility}
                            onChange={(e) => {
                              setVisibility(e.target.checked);
                            }}
                          />
                          <label
                            className="form-check-label checkbox-label"
                            for="showServicePag"
                          >
                            Show in services page <br />
                            <small>
                              Choose whether to list this service in your Client
                              Portal's services page. Service can still be used
                              in order forms.
                            </small>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="btn-section text-end">
                      <button
                        className="btn btn-save"
                        disabled={serviceName == ""}
                        onClick={(e) => {
                          setIntakeFormStatus(true);
                        }}
                      >
                        Add Service
                      </button>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      !intakeFormStatus ? "" : "show active"
                    }`}
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabindex="0"
                  >
                    <div className="commonForm mb-3 rounded-1 mt-3">
                      <div className="form-group col-lg-4 mb-0 filter-drowdown">
                        <label>Select Intake Form</label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setSelectedIntakeForm(e.target.value);
                          }}
                        >
                          <option value="no_form" selected>
                            No Intake Form
                          </option>
                          <option value="intake_form_1">Intake Form 1</option>
                          <option value="intake_form_2">Intake Form 2</option>
                        </select>
                      </div>
                    </div>

                    <div class="tab-content" id="pills-tabContent">
                      {selectedIntakeForm == "intake_form_1" && (
                        <div className="intakeForm">
                          <h3>Intake form for writing service</h3>
                          <p>
                            Clients get access to this form after buying your
                            service. Their order will remain{" "}
                            <span>Pending</span> until the form is filled out.
                          </p>
                          <div className="commonForm rounded-1">
                            <div className="form-group">
                              <label>
                                Upload upto 3 photos. Guidelines: Make sure to
                                submit ONLY high quality landscape "horizontral"
                                photos
                              </label>

                              {/* <div className="form-group">
                                <label>
                                  Please tell us more about your brand that we
                                  won't be able to find on your website or any
                                  social media account: (ad least 100 words
                                  minimum) <small>Optional</small>
                                </label>
                                <textarea className="form-control"></textarea>
                              </div> */}
                              <div className="Upldimages">
                                <input type="file" />
                                <i class="fa-solid fa-file-arrow-up"></i>
                                <p>
                                  <span>Browse or</span> drag and drop
                                </p>
                              </div>

                              {/* <div className="previewImg">
                                <span className="ImgSection">
                                  <img src={DummyAvatar} />
                                  <span className="Del">
                                    <i class="fa-solid fa-trash"></i>
                                  </span>
                                </span>
                                <span className="ImgSection">
                                  <img src={DummyAvatar} />
                                  <span className="Del">
                                    <i class="fa-solid fa-trash"></i>
                                  </span>
                                </span>
                              </div> */}
                            </div>
                            <div className="form-group">
                              <label>
                                Who is your audience for this featured article ?{" "}
                                <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>
                            <div className="form-group">
                              <label>
                                What is the name of your brand?{" "}
                                <small>Optional</small>
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label>
                                Is there a special quote about your brand that
                                you want us to use? if yes, what is it?{" "}
                                <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please tell us more about your brand that we
                                won't be able to find on your website or any
                                social media account: (ad least 100 words
                                minimum) <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please provide us your website, social media
                                links <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please give us your brand bio (atleast 100 words
                                minimum) <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group mb-0">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="showServicePage"
                                />
                                <label
                                  className="form-check-label checkbox-label mb-0"
                                  for="showServicePage"
                                >
                                  Approval and Confirmation <br />
                                  <small>
                                    I approve this content to get publish, so
                                    long as it passes the publication's
                                    guidelines
                                  </small>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedIntakeForm == "intake_form_2" && (
                        <div className="intakeForm mb-4">
                          <h3>Intake form for writing service</h3>
                          <p>
                            Clients get access to this form after buying your
                            service. Their order will remain{" "}
                            <span>Pending</span> until the form is filled out.
                          </p>
                          <div className="commonForm rounded-1">
                            <div className="form-group">
                              <label>
                                Please pick the publication you want this
                                article to get published in
                              </label>
                              <select className="form-control">
                                <option>Astist Weekly</option>
                                <option>Astist Weekly</option>
                                <option>Astist Weekly</option>
                              </select>
                            </div>

                            <div className="form-group">
                              <label>
                                If you wrote your article. Paste a Google Doc
                                link here. (Make sure the link access is set to
                                "Anyone With the Link") Ignore this section if
                                you want us to write your article.{" "}
                                <small>Optional</small>
                              </label>

                              <textarea className="form-control"></textarea>
                              <small>
                                Click this link to see out photo submission
                                guide: <br />{" "}
                                <a
                                  className="text-decoration-none text-muted"
                                  href=""
                                  target="_blank"
                                >
                                  https://docs.google.com/document/d/1JqbZZdyzYkLjC95ps-N29sv1dDt-kEhtX9yEpVx5uw0/edit#
                                </a>
                              </small>
                            </div>

                            <div className="form-group">
                              <label>
                                Upload Up to 3 photos. Guidelines: Make sure to
                                submit ONLY high quality landscape "horizontal"
                                photos
                              </label>
                              <div class="Upldimages">
                                <input type="file" />
                                <i class="fa-solid fa-file-arrow-up"></i>
                                <p>
                                  <span>Browse or</span> drag and drop
                                </p>
                              </div>
                            </div>

                            <div className="form-group">
                              <label>
                                Who is your audience for this featured acticle?{" "}
                                <small>Optional</small>
                              </label>
                              <input type="" className="form-control" />
                            </div>

                            <div className="form-group">
                              <label>
                                What is your brand? <small>optional</small>
                              </label>
                              <input type="" className="form-control" />
                            </div>

                            <div className="form-group">
                              <label>
                                Is there a special quote about your brand that
                                you want us to use? If yes, what is it?{" "}
                                <small>optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please tell us more about your brand that we
                                won't be able to find on your website or any
                                social media account: (al least 100 word
                                minimum) <small>optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please provide us your website, social media
                                links <small>optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please give us your brand bio (at least 100 word
                                minimum) <small>optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group mb-0">
                              <div class="form-check">
                                <input
                                  class="form-check-input checkbox-label"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  class="form-check-label mb-0"
                                  for="flexCheckDefault"
                                >
                                  Approval and Confirmation <br />{" "}
                                  <small>
                                    I approve this content to get publish, so
                                    long as it passes the publication's
                                    guidelines
                                  </small>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="btn-section text-end">
                        <button
                          className="btn btn-save"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFormSubmit();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddService;

import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Sidebar from "../../widgets/sidebar";
import noData from "../../assets/img/nodata-img.svg";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";
import {
  apiGetMethod,
  apiPostMethod,
  apiPutMethod,
  apiDeleteMethod,
} from "../../api/rest";
import { AllContext } from "../../context";
import Modal from "../../components/modal";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/pageLoader";

const Tags = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  useEffect(() => {
    getTag();
  }, []);
  const context = useContext(AllContext);
  const { allApiUrl, apiPostMethod, redirectToLogin, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [event, setEvent] = useState("");
  const [tags, setTags] = useState([]);
  const [data, setData] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [stateId, setState] = useState();
  const [checkedItems, setCheckedItems] = useState({});
  const [users, setUsers] = useState([]);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const { TAGS } = allApiUrl;

  const refresh = () => {
    getTag();
  };

  const getTag = () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(TAGS, "", header)
      .then((res) => {
        if (res.status === 200) {
          setInitialLoading(false);
          // setIsLoading(false);
          setTags(res.data.tags);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const deleteTag = (id) => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(TAGS, id, header)
      .then((res) => {
        if (res.status === 200) {
          getTag();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  function deleteMultiple() {
    bulkDeleteId.map((row) => {
      deleteTag(row);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }

  function checkedAll() {
    if (tags.length == bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = tags.map((e) => {
        return e._id;
      });
      setBulkDeleteId(ids);
    }
  }

  function deleteIdList(status, id) {
    if (status) {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        bulkDeleteId.push(id);
        setBulkDeleteId(bulkDeleteId);
      }
    } else {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      let ids = bulkDeleteId.splice(exist + 1, 1);
      setBulkDeleteId(ids);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="setting-form-section pt-3 orders-section horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-back">
                  <a href="/settings">
                    <i class="fa-solid fa-angle-left"></i> Settings
                  </a>
                </div>
              </div>
              <div className="col-xxl-5 col-lg-7 mx-auto">
                <div className="setting-form">
                  <h3 className="tags-heading fs-4">
                    Tags{" "}
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#tagsModal"
                      href="javascript:void(0);"
                      onClick={() => {
                        setEvent("Add");
                        setData({});
                      }}
                    >
                      Add tag
                    </a>
                  </h3>

                  <form className="commonForm common-table p-0 mb-3 rounded-1">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="ps-4">
                              <div class="form-group mb-0">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="item1"
                                    checked={
                                      tags.length == bulkDeleteId.length &&
                                      tags.length > 0
                                    }
                                    onChange={() => checkedAll()}
                                  />
                                  <label
                                    for="item1"
                                    className="form-check-label"
                                  ></label>
                                </div>
                              </div>
                            </th>
                            <th>Tag</th>
                            <th>Orders</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tags.map((value, key) => {
                            return (
                              <tr>
                                <td className="ps-4">
                                  <div class="form-group mb-0">
                                    <div className="">
                                      <input
                                        type="checkbox"
                                        id={value._id}
                                        className="form-check-input"
                                        name={value.name}
                                        checked={bulkDeleteId.find(
                                          (e) => e == value._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            e.target.checked,
                                            value._id
                                          );
                                        }}
                                      />
                                      <label for={value._id}></label>
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#tagsModal"
                                    href="javascript:void(0);"
                                    onClick={() => {
                                      setEvent("edit");
                                      setData(value);
                                    }}
                                  >
                                    {value.name}
                                  </a>
                                </td>
                                <td>
                                  <a href=""></a>
                                </td>
                                <td>
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                  >
                                    <span
                                      className="iconDel"
                                      onClick={() => setDeleteId(value._id)}
                                    >
                                      <i class="fa-solid fa-trash"></i>
                                    </span>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                          {tags && tags.length <= 0 && (
                            <tr>
                              <td colSpan={7}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </form>

                  {(bulkDeleteId.length > 0 || isSingleChecked) && (
                    <div className="table-bottom-option mt-2">
                      <div className="row">
                        <div className="col-xxl-4 col-lg-5 col-md-4">
                          <div className="input-group">
                            <select
                              id="selectOption"
                              value={selectOption.target}
                              onChange={(e) => selectOption(e)}
                              className="form-control"
                            >
                              <option value="">With Selected...</option>
                              <option value="Delete">Delete</option>
                            </select>
                            <button
                              className="btn btn-apply"
                              onClick={() => {
                                if (selectedOption == "Delete") {
                                  deleteMultiple();
                                }
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade history-modal"
            id="deleteModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="delete-content">
                    <p>
                      Are you sure you want to delete this tag? The tag will be
                      removed from all orders that have it.
                    </p>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    onClick={() => deleteTag(deleteId)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal event={event} data={data} refresh={refresh} />
    </div>
  );
};
export default Tags;

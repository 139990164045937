import React, { useContext, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";
import noData from "../../assets/img/nodata-img.svg";
import { AllContext } from "../../context";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/pageLoader";

const Messaging = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiDeleteMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { MESSAGE } = allApiUrl;
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [messages, setMessages] = useState([]);
  const [messageId, setMessageId] = useState();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const dispatch = useDispatch();
  function getAllMessages() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(MESSAGE, "", header)
      .then((res) => {
        setMessages(res.data.data);
        // setIsLoading(false);
        setInitialLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  useEffect(() => {
    getAllMessages();
  }, []);

  const editMessage = async (_id) => {
    navigate("/edit-message/" + _id);
  };

  function deleteMessage(id) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(MESSAGE, id, header)
      .then((res) => {
        handleToClose();
        getAllMessages();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  const handleToClose = () => {
    setOpen(false);
  };

  function deleteMultiple() {
    setIsLoading(true);
    bulkDeleteId.map((row) => {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiDeleteMethod(MESSAGE, row, header)
        .then((res) => {
          handleToClose();
          getAllMessages();
          setIsLoading(false);
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    });
  }

  function checkedAll() {
    if (messages.length == bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = messages.map((e) => {
        return e._id;
      });
      setBulkDeleteId(ids);
    }
  }

  function deleteIdList(status, id) {
    if (status) {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        bulkDeleteId.push(id);
        setBulkDeleteId(bulkDeleteId);
      }
    } else {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      let ids = bulkDeleteId.splice(exist + 1, 1);
      setBulkDeleteId(ids);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="setting-form-section pt-3 orders-section horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="section-back ">
                <a href="/settings">
                  <i class="fa-solid fa-angle-left"></i> Settings
                </a>
              </div>
              <div className="col-lg-6 mx-auto">
                <div className="setting-form">
                  <h3 className="tags-heading fs-4">
                    Saved replies <a href="/add-message">Add reply template</a>
                  </h3>
                  <div className="commonForm common-table p-0 rounded-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="ps-4 w65-px">
                            <div className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="selectAll form-check-input"
                                id="selectAll"
                                checked={
                                  messages.length == bulkDeleteId.length &&
                                  messages.length > 0
                                }
                                defaultValue
                                onClick={() => checkedAll()}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="selectAll"
                              ></label>
                            </div>
                          </th>
                          <th>Name</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* no record found */}
                        {/* <tr>
                          <td colSpan={3}>
                            <div className="noData-section">
                              <img src={noData} />
                              <p>No Data Found</p>
                            </div>
                          </td>
                        </tr> */}
                        {/* no record found */}
                        {messages
                          .map((value) => {
                            return (
                              <tr>
                                <td className="ps-4 w65-px">
                                  <div className="form-check mb-0">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={value._id}
                                      checked={bulkDeleteId.find(
                                        (e) => e == value._id
                                      )}
                                      onChange={(e) => {
                                        deleteIdList(
                                          e.target.checked,
                                          value._id
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={value._id}
                                    ></label>
                                  </div>
                                </td>

                                <td>
                                  <a onClick={() => editMessage(value._id)}>
                                    {value.name}
                                  </a>
                                </td>
                                <td>
                                  <div className="dropdown text-center">
                                    <button
                                      className="btn btn-secondary dropdown-toggle btn-info"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                          onClick={() => editMessage(value._id)}
                                        >
                                          Edit
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:void(0);"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteModal"
                                          onClick={() => {
                                            setOpen(true);
                                            setMessageId(value._id);
                                          }}
                                        >
                                          Delete
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                          .reverse()}
                        {messages && messages.length <= 0 && (
                          <tr>
                            <td colSpan={7}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="table-bottom-option">
                    <div className="row">
                      <div className="col-lg-6 col-md-4">
                        <div className="input-group">
                          <select
                            id="selectOption"
                            value={selectOption.target}
                            onChange={(e) => selectOption(e)}
                            className="form-control"
                          >
                            <option value="">With Selected...</option>
                            <option value="Delete">Delete</option>
                          </select>
                          <button
                            className="btn btn-apply"
                            onClick={() => {
                              if (selectedOption == "Delete") {
                                deleteMultiple();
                              }
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* tags Modal */}
      <div
        className="modal fade tags-modal"
        id="tagsModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form className="commonForm">
                <div className="form-group">
                  <label>Tag name</label>
                  <input
                    type="text"
                    placeholder="Tag Name"
                    className="form-control"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Add tag
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* delete modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <p>Are you sure you want to delete this saved reply?</p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => deleteMessage(messageId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal */}
    </div>
  );
};
export default Messaging;

import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import noData from "../../assets/img/nodata-img.svg";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { apiGetMethod } from "../../api/rest";
import { AllContext } from "../../context";
import PageLoader from "../../components/pageLoader";
import { handleOffSetDateTime } from "../../shared/functions";

const Logs = () => {
  const context = useContext(AllContext);
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  console.log('handleOffSetDateTime', companyData)
  const navigate = useNavigate();
  const location = useLocation();
  let { resource_id, resource } = queryString.parse(location.search);
  const { allApiUrl, apiPostMethod, redirectToLogin, toastMessage } = context;
  const [isLoading, setIsLoading] = useState(false);
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [limit, setPageLimit] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [accountType, setAccountType] = useState("");
  const [accountTypeList, setAccountTypeList] = useState("");
  const [resourceType, setResourceType] = useState(resource ? resource : "");
  const [accountId, setAccountId] = useState("");
  const [resourceId, setResourceId] = useState(resource_id ? resource_id : "");
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);
  const [users, setUsers] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [logs, setLogs] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [filterCount, setFilterCount] = useState(0);
  const [itemPerPageFilterApplied, setItemPerPageFilterApplied] =
    useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const { LOGS, FILTER_LOGS } = allApiUrl;
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };
  function getLogs() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    // setIsLoading(true);
    apiGetMethod(LOGS + "/" + itemOffset + "&" + itemsPerPage, "", header)
      .then((res) => {
        if (res.status === 200) {
          var getLogsList = res.data.allLogs;
          var logsCount = res.data.totallogs;
          setCurrentPage(res.data.currentPage);
          setPageCount(Math.ceil(logsCount / itemsPerPage));
          setPageLimit(res.data.limit || itemsPerPage);
          setLogs(getLogsList);
          let users = [];
          let accountType = [];
          res.data.allLogs.map((user) => {
            if (user.accountId) {
              if (!users.find((e) => e._id == user.accountId._id)) {
                users.push(user.accountId);
              }
            }
            if (!accountType.find((e) => e == user.accountType)) {
              accountType.push(user.accountType);
            }
          });
          setUsers(users);
          setAccountTypeList(accountType);
          setInitialLoading(false);
          // setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        // setIsLoading(false);
      });
  }
  // let { resource_id, resource } = useParams();

  useEffect(() => {
    getLogs(itemOffset);
  }, [itemOffset, pageCount, itemsPerPage]);

  useEffect(() => {
    if (resource_id && resource) {
      filterLogs();
    } else {
      getLogs();
    }
  }, []);
  const dispatch = useDispatch();
  const [itemPerPageFilter, setItemPerPageFilter] = useState(10);

  const filterLogs = () => {
    let count = 0;
    if (itemPerPageFilter && itemPerPageFilterApplied) {
      setItemsPerPage(itemPerPageFilter);
      count += 1;
    }
    const data = {};
    if (accountType !== "") data.accountType = accountType;

    if (resourceType !== "") data.resourceType = resourceType;

    if (accountId !== "") data.accountId = accountId;
    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;
    if (resourceId != "") data.resourceId = resourceId;
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    for (let key in data) {
      count += 1;
    }
    setFilterCount(count);
    apiPostMethod(FILTER_LOGS, data, header)
      .then((res) => {
        if (res.status === 200) {
          setLogs(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };
  function formatAMPM(date) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateString = monthNames[date.getMonth()] + " " + date.getDate() + "th";
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pM" : "aM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = dateString + " at " + hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
            setItemPerPageFilterApplied(true);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-6">
                <div className="heading">
                  <h2 className="fs-4">Logs</h2>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-6">
                <div className="section-filter">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle btn-filter"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-filter"></i> Filter |{" "}
                      {filterCount}
                    </button>
                    <ul className="dropdown-menu filter-drowdown">
                      <div className="filter-btns">
                        <button
                          class="btn btn-clear"
                          onClick={() => {
                            if (resource_id) {
                              navigate("/logs");
                              getLogs();
                            } else {
                              setFilterCount(0);
                              setResourceType("");
                              setAccountType("");
                              setAccountId("");
                              setstartDateFilter(0);
                              setendDateFilter(0);
                              setShowDatePicker(false);
                              setUsers([]);
                              setItemPerPageFilterApplied(false);
                              setItemPerPageFilter(10);
                              getLogs();
                            }
                          }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-update"
                          onClick={() => filterLogs()}
                        >
                          Done
                        </button>
                      </div>

                      <select
                        className="form-control"
                        value={accountType}
                        onChange={(value) => setAccountType(value.target.value)}
                      >
                        <option value="">Any Account</option>
                        {accountTypeList.length > 0 &&
                          accountTypeList.map((account) => {
                            return (
                              <option key={account} value={account}>
                                {account.charAt(0).toUpperCase() +
                                  account.slice(1)}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        className="form-control"
                        value={resourceType}
                        onChange={(value) =>
                          setResourceType(value.target.value)
                        }
                      >
                        <option value="">Any resource</option>
                        <option value="account">Account</option>
                        <option value="order">Order</option>
                        <option value="ticket">Ticket</option>
                        <option value="invoice">Invoice</option>
                      </select>

                      <select
                        className="form-control"
                        onChange={(event) =>
                          setShowDatePicker("true" === event.target.value)
                        }
                      >
                        <option value={false}>Any date</option>
                        <option value={true}>Date Between</option>
                      </select>
                      {showDatePicker && (
                        <div className="d-flex dateBetween-filter">
                          <input
                            type="date"
                            name="startDate"
                            className="form-control"
                            onChange={(event) =>
                              setstartDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                            id=""
                          />
                          <span className="mx-2">and</span>
                          <input
                            className="form-control"
                            type="date"
                            name="endDate"
                            onChange={(event) =>
                              setendDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                            id=""
                          />
                        </div>
                      )}
                      <select
                        className="form-control"
                        value={accountId}
                        onChange={(value) => setAccountId(value.target.value)}
                      >
                        <option value="">Any User</option>
                        {users.length > 0 &&
                          users.map((user) => {
                            return (
                              <option value={user._id}>
                                {user.firstName + " " + user.lastName}
                              </option>
                            );
                          })}
                      </select>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="common-table mt-3 table-responsive rounded-1">
                  <table className="table logs-table mb-0">
                    <thead>
                      <tr>
                        <th>Date Time</th>
                        <th>Message</th>
                        <th>Account Type</th>
                        <th>Account Name</th>
                        <th>Resource ID</th>
                        <th>Resource Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs.length>0 && logs.map((value) => {
                          return (
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/logs/log/${value._id}`, {
                                  state: {
                                    dateAndTime: value?.timestamp || "",
                                    accountType: value?.accountType || "",
                                    firstName: value?.accountId.firstName || "",
                                    lastName: value?.accountId.lastName || "",
                                    resourceType: value?.resourceType || "",
                                    resourceId: value?.resourceId || "",
                                    message: value?.eventDescription || "",
                                    data: value?.data || "",
                                  },
                                });
                              }}
                            >
                              <td>
                                <a className="log-link">
                                  {handleOffSetDateTime(
                                    value.timestamp,
                                    companyData.timezone
                                  )}
                                </a>
                              </td>
                              <td>
                                <a
                                  className="log-link"
                                  onClick={() => {
                                    navigate(`/log/log/${value._id}`, {
                                      state: { id: 7, color: "green" },
                                    });
                                  }}
                                // href={`/logs/log/${value._id}`}
                                >
                                  {value.eventDescription}
                                </a>
                              </td>
                              <td>
                                <div className="clientDetail">
                                  <p className="name">
                                    <span>{value.accountType}</span>
                                  </p>
                                </div>
                              </td>
                              <td>
                                {value.accountId && value.accountId.firstName
                                  ? value.accountId.firstName ||
                                  " " + value.accountId.lastName
                                  : " "}
                              </td>
                              <td>
                                <div className="clientDetail">
                                  <p className="name">
                                    {value.resourceType == "account" &&
                                      (value.eventDescription ==
                                        "Account Created" ||
                                        value.eventDescription ==
                                        "Resource Deleted") &&
                                      value.data && (
                                        <span>
                                          {value.resourceType == "account" &&
                                            (value.eventDescription ==
                                              "Account Created" ||
                                              value.eventDescription ==
                                              "Resource Deleted") &&
                                            value.data
                                            ? value.data.firstName +
                                            " " +
                                            value.data.lastName
                                            : value.resourceId}
                                        </span>
                                      )}
                                    {value.resourceType != "account" && (
                                      <span>{value.resourceId}</span>
                                    )}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div className="clientDetail">
                                  <p className="name">
                                    <span>{value.resourceType}</span>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      {logs && logs.length <= 0 && (
                        <tr>
                          <td colSpan={7}>
                            <div className="noData-section">
                              <img src={noData} />
                              <p>No Data Found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="sectionPagination">
                  <NoOfRows />
                  <ReactPaginate
                    nextLabel={<i class="fa-solid fa-angles-right"></i>}
                    previousLabel={<i class="fa-solid fa-angles-left"></i>}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination paginate"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* add order modal stasts */}
          <div
            class="modal fade add-order-modals"
            id="addorderModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Add order
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="add-order-form">
                    <div className="add-alert">
                      <p>
                        Adding an order manually will not trigger any
                        notifications or payments. To add a paid order you can
                        create a new invoice and mark it as paid.
                      </p>
                    </div>
                    <form>
                      <div className="form-group">
                        <label>Clinet</label>
                        <select className="form-control">
                          <option>Client Name</option>
                          <option>Client Name</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label>Service</label>
                        <select className="form-control">
                          <option>Service 1</option>
                          <option>Service 2</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary close-btn"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" class="btn btn-primary">
                    Add order
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* add order modal ends */}
        </div>
      )}
    </div>
  );
};
export default Logs;

import React from "react";

const PageLoader = ({ isLoading }) => {
    return (
        <div>
            {
                isLoading &&
                <div className="innerLoader">
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>


                </div>
            }
        </div>
    );
};
PageLoader.defaultProps = {
    isLoading: false,
};
export default PageLoader;

import { useSelector } from "react-redux";
import moment from "moment";
import {
  // saveAddToCartInformation,
  saveLoginUserAccessToken,
  savePublicInvoice,
} from "../../redux/action/login";
export const convertToUpperCase = (value) => {
  const labels = value.replace(/(^\w{1})|(\s+\w{1})/g, (item) =>
    item.toUpperCase()
  );
  return labels;
};
const redirectPages = [
  "view-purchase",
  "view-payment",
  "portal",
  "cart",
  "view-orders",
  "view-ticket",
];

export const monthNameAndDateFormat = (value, year) => {
  const currDate = new Date(value);
  const currMonthName = currDate.toLocaleString("default", {
    month: "short",
  });
  const currDay = currDate.getDate();
  if (year) {
    const year = currDate.getFullYear();
    return `${currMonthName} ${currDay}, ${year}`;
  }

  return `${currMonthName} ${currDay}`;
};
export const redirectToLogin = (dispatch, navigate, err, page) => {
  if (err.status === 403 || err?.data?.message == "User not exist.") {
    dispatch(saveLoginUserAccessToken(""));
    // dispatch(savePublicInvoice(false));
    // dispatch(saveAddToCartInformation(false));
    localStorage.removeItem("userLoggedIn");
    if (page && page.length > 0 && redirectPages.includes(page[1])) {
      if (page[3]) {
        navigate(`/login?redirection=${page[1] + "/" + page[2]}&id=${page[3]}`);
      } else {
        navigate(
          `/login?redirection=${page[1]}${page[2] ? "&id=" + page[2] : ""}`
        );
      }
    } else {
      navigate("/login");
    }
  }
};

export const formatMonthNameAndDate = (dateTime, timezone, year) => {
  let offset = "";
  if (timezone) {
    offset = JSON.parse(timezone).offset;
  }
  const date = moment.utc(dateTime).utcOffset(offset);
  const monthName = date.format("MMM");
  const dayDate = date.format("D");
  if (year) {
    const year = date.format("YYYY");
    return `${monthName} ${dayDate}, ${year}`;
  }
  return `${monthName} ${dayDate}`;
};

export const handleOffSetDateTime = (dateTime, timezone) => {
  let offset = "";
  if (timezone) {
    offset = JSON.parse(timezone).offset;
  }
  const date = moment.utc(dateTime).utcOffset(offset);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const hours = date.format("hh:mm A");
  const month = date.format("M");
  const dayDate = date.format("Do");
  return `${monthNames[month - 1]} ${dayDate} at ${hours}`;
};

export const handleGetTime = (dateTime, timezone) => {
  let offset = "";
  if (timezone) {
    offset = JSON.parse(timezone).offset;
  }
  const date = moment.utc(dateTime).utcOffset(offset);
  const hours = date.format("hh:mm A");
  return hours;
};

import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/avatar.png";
import { formatMonthNameAndDate } from "../../shared/functions";

import PageLoader from "../../components/pageLoader";
import { AllContext } from "../../context";

const ViewClient = () => {
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  //constants
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const {
    apiPostMethod,
    apiPutMethod,
    apiGetMethod,
    allApiUrl,
    apiDeleteMethod,
    toastMessage,
  } = context;
  const {
    GET_SPECIFIC_CLIENT,
    DELETE_CLIENT,
    GET_ALL_TEAM,
    EDIT_CLIENT,
    CLIENT_DATA_LISTING
  } =
    allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const rolesList = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const userInfoRoles = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );

  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  var specificClientId = window.location.pathname.split("/")[2];
  //useState
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [clientData, setClientData] = useState([]);
  const [clientId, setClientId] = useState("");
  const [teamAccountList, setTeamAccountList] = useState([]);
  const [teamMemberName, setTeamMemberName] = useState("");
  const [assignTeamMember, setAssignTeamMember] = useState("");
  const [additionalData, setAdditionalData] = useState({})
  //   //functions
  useEffect(() => {
    setIsLoading(true);
    getSpecificClientDataHandler();
    getAllTeam();
    getAdditionalInformation()
    setIsLoading(false);
  }, []);
  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  function getSpecificClientDataHandler() {
    setIsLoading(true);
    apiGetMethod(`${GET_SPECIFIC_CLIENT}/${specificClientId}`, "", header)
      .then((res) => {
        if (res.status === 200) {
          setClientData(res.data.user[0]);
          setAssignTeamMember(res.data.user[0].accountManager);
          setIsLoading(false);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        navigate("/clients");
        setInitialLoading(false);
        setIsLoading(false);
      });
  }
  function getAdditionalInformation() {
    setIsLoading(true);
    apiGetMethod(`${CLIENT_DATA_LISTING}/${specificClientId}`, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAdditionalData(res.data)
          setIsLoading(false);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        setInitialLoading(false);
        setIsLoading(false);
      });
  }

  const date = new Date(clientData?.userId?.createdOn);
  const monthName = date.toLocaleString("default", {
    month: "short",
  });
  const day = date.getDate();
  const createdOn = `${monthName} ${day}`;

  function deleteClient(couponId) {
    apiDeleteMethod(DELETE_CLIENT, couponId, header)
      .then((res) => {
        navigate("/clients");
        toastMessage("success", res.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        toastMessage("error", err.data.toastMessage);
        setIsLoading(false);
      });
  }
  function getAllTeam() {
    setIsLoading(true);
    apiGetMethod(GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setTeamAccountList(res.data.team);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const assignTeamMemberHandler = (event) => {
    event.preventDefault();
    const data = {
      accountManager: assignTeamMember,
    };
    apiPutMethod(`${EDIT_CLIENT}/${specificClientId}`, data, header)
      .then((res) => {
        toastMessage("success", `Client Assigned to ${teamMemberName}`);
        getSpecificClientDataHandler();
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        console.log(err);
      });
  };
  function getTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  return (
    <div>
      <Sidebar
        activePage="clients"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          {!initialLoading && (
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="section-back"
                  onClick={() => navigate("/clients")}
                >
                  <a href="javascript:void(0);">
                    <i class="fa-solid fa-angle-left"></i> Clients{" "}
                  </a>
                </div>
              </div>

              <div className="col-xxl-8 col-lg-12 mx-auto">
                <div className="client-heading mt-3">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="d-flex align-items-center">
                        <div className="userImg">
                          <img
                            src={
                              clientData.userId && clientData.userId.image
                                ? clientData.userId.image
                                : DummyAvatar
                            }
                          />
                        </div>
                        <div className="userName">
                          {clientData?.userId?.firstName &&
                            clientData?.userId?.lastName ? (
                            <h3 className="fs-4">
                              {clientData?.userId?.firstName +
                                " " +
                                clientData?.userId?.lastName}
                            </h3>
                          ) : (
                            <h3 className="fs-4">
                              {clientData?.userId?.firstName}
                            </h3>
                          )}
                        </div>
                      </div>
                    </div>
                    {rolesList.clients.add_edit_login && (
                      <div className="col-lg-6 col-md-6">
                        <div className="btns">
                          {/* <a className="btn-edit" href="">
                          Sign in as user
                        </a> */}
                          {userInfoRoles.clients.add_edit_login && (
                            <a
                              className="btn-edit"
                              onClick={() => {
                                setIsLoading(true);
                                navigate(`/edit-client/${specificClientId}`);
                                setIsLoading(false);
                              }}
                            >
                              Edit
                            </a>
                          )}
                          <div className="dropdown">
                            <button
                              className="btn btn-secondary dropdown-toggle btn-info px-3"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setIsLoading(true);
                                    navigate(`/add-invoice`, {
                                      state: {
                                        selectedUser: specificClientId,
                                      },
                                    });
                                    setIsLoading(false);
                                  }}
                                >
                                  New invoice
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setIsLoading(true);
                                    navigate("/add-ticket", {
                                      state: {
                                        mailTo: clientData.userId,
                                        from: "client",
                                      },
                                    });
                                    setIsLoading(false);
                                  }}
                                >
                                  New ticket
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javacsript:void(0);"
                                  data-bs-toggle="modal"
                                  data-bs-target="#assignModal"
                                >
                                  Assign a manager
                                </a>
                              </li>
                              {/* <li>
                              <a
                                className="dropdown-item"
                                href="javacsript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#mergeModal"
                              >
                                Merge
                              </a>
                            </li> */}
                              {rolesList.clients._delete && (
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javacsript:void(0);"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteModal"
                                    onClick={() => {
                                      setClientId(specificClientId);
                                    }}
                                  >
                                    Delete
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="client-details rounded-1">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-4">
                      <p className="clientLabel">Status</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-8">
                      <p className="clientText text-capitalize">
                        {clientData?.role}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-4">
                      <p className="clientLabel">Email</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-8">
                      <p className="clientText">{clientData?.userId?.email}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-4">
                      <p className="clientLabel">Created On</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-8">
                      <p className="clientText">{createdOn}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-4">
                      <p className="clientLabel">Last login</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-8">
                      <p className="clientText">
                        {clientData?.userId?.lastLogin ? (
                          <>
                            {clientData?.userId?.lastLogin.date ? (
                              <>
                                {" "}
                                {clientData?.userId?.lastLogin.date
                                  ? formatMonthNameAndDate(
                                    clientData?.userId?.lastLogin.date,
                                    companyData.timezone
                                  )
                                  : ""}
                                <br />
                                {clientData?.userId?.lastLogin.ipAddress}
                              </>
                            ) : (
                              clientData?.userId?.lastLogin
                            )}
                          </>
                        ) : (
                          "--"
                        )}{" "}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-4">
                      <p className="clientLabel">Phone</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-8">
                      <p className="clientText">{clientData?.phone}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-4">
                      <p className="clientLabel">Company</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-8">
                      <p className="clientText text-capitalize">
                        {clientData?.company}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-4">
                      <p className="clientLabel">Tax ID</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-8">
                      <p className="clientText">{clientData?.taxId}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-4">
                      <p className="clientLabel">Address</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-8">
                      {/* <p className="clientText"></p> */}
                      <p className="clientText text-capitalize">
                        {clientData?.billingAddress}
                      </p>
                    </div>
                  </div>

                  {/* <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <p className="clientLabel">Balance</p>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <p className="clientText">$0.00</p>
                  </div>
                </div> */}
                </div>

                {/* <div className="notes mt-5  mb-5">
                <p>Notes</p>
                <div className="edid-note">
                  <a href="">Edit</a>
                </div>
              </div> */}

                <div className="">
                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Invoices</h2>
                    </div>
                    {additionalData &&
                      additionalData?.invoices &&
                      additionalData?.invoices?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Invoice</th>
                              <th>Create Date</th>
                              <th>Total ($)</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.invoices.slice(0, 10).slice(0, 10).map((item, idk) => {
                                return (
                                  <tr key={idk}>
                                    <td>
                                      <a
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`
                                          )
                                        }
                                      >
                                        {item.invoiceId}
                                      </a>
                                    </td>
                                    <td>{(new Date(item.createdDate).toLocaleString()).split(',')[0]}</td>
                                    <td>${item.totalPrice.toFixed(2)}</td>
                                    <td autoCapitalize="none">
                                      <span className="pill pill-grey fw-normal fs-12">
                                        {item.status}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : <div className="common-table invoiceTable rounded-1">There is no invoice added</div>}
                  </div>


                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Orders</h2>
                    </div>
                    {additionalData &&
                      additionalData?.orders &&
                      additionalData?.orders?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Order No.</th>
                              <th>Title</th>
                              <th>Paid Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.orders
                                .slice(0, 10)
                                ?.map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a
                                          onClick={() =>
                                            navigate(
                                              `/view-orders/${item?.orderId}`,
                                              {
                                                state: {
                                                  _id: item._id,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          {item?.orderId}
                                        </a>
                                      </td>
                                      <td>{item?.title}</td>
                                      <td>{(new Date(item.createdDate).toLocaleString()).split(',')[0]}</td>
                                      <td>
                                        <span className="pill pill-grey orderStatus fw-normal">
                                          {item.status.toUpperCase()}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    ) : <div className="common-table invoiceTable rounded-1">There is no order added</div>}
                  </div>

                  {/* <div className="tickets-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Tickets</h2>
                    </div>
                    {additionalData &&
                      additionalData?.tickets &&
                      additionalData?.tickets?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Subject</th>
                              <th>Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.tickets
                                .map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a
                                          onClick={() =>
                                            navigate(`/view-ticket/${item._id}`)
                                          }
                                        >
                                          {item.subject}
                                        </a>
                                      </td>
                                      <td>
                                        {" "}

                                      </td>
                                      <td>
                                        <span className="pill pill-grey orderStatus fw-normal">
                                          {item.status}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                                .reverse()}
                          </tbody>
                        </table>
                      </div>
                    ) : <div>There is no ticket added</div>}
                  </div>

                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Subscriptions</h2>
                    </div>
                    {additionalData &&
                      additionalData?.subscriptions &&
                      additionalData?.subscriptions?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Service</th>
                              <th>Started</th>
                              <th>Next Payment</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.subscriptions
                                .map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a
                                          onClick={() => {
                                            navigate(
                                              `/view-subscription/${item?.orderId}`
                                            );
                                          }}
                                        >
                                          {item?.subscriptionId
                                            ? item?.subscriptionId
                                            : item.orderId}
                                        </a>
                                      </td>
                                      <td>
                                        <a>
                                          {item?.product &&
                                            item?.product[0] &&
                                            item.product[0]?.name}
                                        </a>
                                      </td>
                                      <td>

                                      </td>
                                      <td>

                                      </td>
                                      <td>
                                        <span
                                          className={`pill ${item.paymentStatus === "past due"
                                            ? "pill-blue"
                                            : item.serviceStatus == true
                                              ? "pill-green"
                                              : "pill-red"
                                            } fs-12`}
                                        >
                                          {item.paymentStatus === "past due"
                                            ? "Past Due"
                                            : item.serviceStatus == true
                                              ? "Active"
                                              : "Cancelled"}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                                .reverse()}
                          </tbody>
                        </table>
                      </div>
                    ) : <div>There is no subscription added</div>}
                  </div> */}

                  {/* <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Orders</h2>
                    </div>
                    {additionalData &&
                      additionalData?.orders &&
                      additionalData?.orders?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Order No.</th>
                              <th>Title</th>
                              <th>Paid Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.orders
                                .slice(0, 10)
                                ?.map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a
                                          onClick={() =>
                                            navigate(
                                              `/view-orders/${item?.orderId}`,
                                              {
                                                state: {
                                                  _id: item._id,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          {item?.orderId}
                                        </a>
                                      </td>
                                      <td>{item?.title}</td>
                                      <td>{(new Date(item.createdDate).toLocaleString()).split(',')[0]}</td>
                                      <td>
                                        <span className="pill pill-grey orderStatus fw-normal">
                                          {item.status.toUpperCase()}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    ) : <div>There is no order added</div>}
                  </div> */}

                  <div className="orders-section mt-4">
                    <div className="heading">
                      <h2 className="fs-5 mb-3">Files</h2>
                    </div>
                    {additionalData &&
                      additionalData?.files &&
                      additionalData?.files?.length > 0 ? (
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Size</th>
                              <th>resource type</th>
                              <th>resource Id</th>
                              <th>Created Date</th>
                            </tr>
                          </thead>

                          <tbody>
                            {additionalData &&
                              additionalData?.files.slice(0, 10)
                                .map((value, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <a href={value?.url} target="_blank">
                                          {value?.fileName}
                                        </a>
                                      </td>
                                      <td>{formatBytes(value?.fileSize)}</td>
                                      <td>{value.eventType}</td>
                                      <td>{value.resourceId}</td>
                                      <td>{new Date(value.createdOn).toLocaleString("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                      })}</td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    ) : <div className="common-table invoiceTable rounded-1">There is no files added</div>}
                  </div>
                </div>
                <div className="section-history">
                  <h3>History</h3>
                  <div className="history-table rounded-1">
                    <table className="table">
                      {additionalData.logs && additionalData.logs
                        .map((log) => {
                          return (
                            <tr>
                              <td>
                                {formatMonthNameAndDate(
                                  log.timestamp,
                                  companyData.timezone
                                )}
                              </td>
                              <td>
                                <div className="clientName">
                                  <p
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#historyModal"
                                  >
                                    <span className="pill pill-grey me-1 fw-normal fs-12">
                                      {log?.accountId?.firstName ||
                                        log?.accountId?.lastName ? (
                                        <>
                                          {log?.accountId?.firstName &&
                                            log?.accountId?.lastName ? (
                                            log?.accountId?.firstName +
                                            " " +
                                            log?.accountId?.lastName
                                          ) : (
                                            <>
                                              {log?.accountId?.firstName
                                                ? log?.accountId
                                                  ?.firstName
                                                : log?.accountId
                                                  ?.lastName}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        log?.accountId?.email
                                      )}
                                    </span>
                                    {log?.eventDescription}
                                  </p>
                                </div>
                              </td>
                              <td>{getTime(new Date(log.timestamp))}</td>
                            </tr>
                          );
                        })
                      }
                    </table>
                    <a
                      className="btnShowmore"
                      onClick={() =>
                        navigate(`/logs?resource=Order&resource_id=${specificClientId}`)
                      }
                    >
                      Show All
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* history modal */}
      <div
        className="modal fade history-modal"
        id="historyModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Order Completed
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <div className="form-group">
                  <label>Time</label>
                  <p>Sep 19th at 12:37 pm BST</p>
                </div>

                <div className="form-group">
                  <label>User</label>
                  <p>
                    <a href="">Client Name</a>
                  </p>
                </div>

                <div className="form-group">
                  <label>Order</label>
                  <p>
                    <a href="">6B3500E7</a>
                  </p>
                </div>

                <div className="form-group">
                  <label>Data</label>
                  <p>Data</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* history modal */}

      {/* assign manager modal */}
      <div
        className="modal fade history-modal"
        id="assignModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Assign {clientData?.userId?.firstName}`to a team member
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  {/* <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>Country</label>
                          <select
                            className="form-control"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            {countries.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div> */}

                  <div className="form-group">
                    <label>Assign this customer's orders to </label>
                    <select
                      className="form-control"
                      value={assignTeamMember}
                      onChange={(e) => {
                        setAssignTeamMember(e.target.value);
                        teamAccountList.forEach((item) => {
                          if (e.target.value == item?.userId?._id) {
                            setTeamMemberName(item?.userId?.firstName);
                          }
                        });
                      }}
                    >
                      {teamAccountList.map((account) => {
                        return (
                          <option value={account?.userId?._id}>
                            <div>
                              <span>{account?.userId?.firstName}</span>
                              {/* <small>{account.role}</small> */}
                            </div>
                          </option>
                        );
                      })}
                    </select>
                    <small>
                      This customer's orders will be assigned to selected team
                      member.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={assignTeamMemberHandler}
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* assign manager modal */}

      {/* merge modal */}
      <div
        className="modal fade history-modal"
        id="mergeModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Merge Gamanjit Singh
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>Select an account to merge into </label>
                    <select className="form-control">
                      <option>Select a team member</option>
                      <option>Team member</option>
                      <option>Team member</option>
                    </select>
                    <small>
                      All orders, messages, invoices, and tickets from Gamanjit
                      Singh will be moved to selected account.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-danger">
                Merge Account
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* merge modal */}

      {/* merge modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Are you sure you want to delete this account along with all
                  their orders and messages:
                  <strong>{clientData?.userId?.firstName}</strong>? Their
                  invoices will still be preserved for accounting purposes.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => deleteClient(clientId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* merge modal */}
    </div>
  );
};
export default ViewClient;

//auth
const ADMIN_LOGIN_IN = "/login";
const REQUEST_RESET_EMAIL = "/forgot-password";
const PASSWORD_RESET = "/reset-password";
// ADMIN ROUTEs
//settings
const TAGS = "/settings/tags";
const FILES = "/settings/files";
const FILTER_FILES = "/settings/filterFiles";
const PAYMENT_METHOD = "/settings/payments";
const COUPON = "/settings/coupon";
const COUPON_LIST = "/settings/couponList";

const MESSAGE = "/settings/message";
const SERVICES = "/settings/services";
const LIST_SERVICES = "/settings/listServices";

const ADD_SERVICE = "/settings/service";
//roles
const GET_ALL_ROLE = "/settings/roles";
const ADD_ROLE = "/settings/roles ";
const EDIT_ROLE = "/settings/roles";
const GET_SPECIFIC_ROLE = "/settings/specific-role";
//teams
const ADD_TEAM_MEMBER = "/signup/1";
const GET_ALL_TEAM = "/settings/team";
const GET_SPECIFIC_TEAM = "/accounts/edit";
const EDIT_TEAM_MEMBER = "/accounts/edit-admin-team";
const DELETE_TEAM_MEMBER = "/settings/team";
//email-tempate
const EMAIL_TEMPLATE = "/settings/email-template";
// logs
const LOGS = "/settings/logs";
const FILTER_LOGS = "/settings/filterLogs";
// profile
const EMAIL_PROFILE = "/change-email";
const CHANGE_PASSWORD = "/change-password";
const UPDATE_PROFILE = "/accounts/profile";
const EDIT_USER_DETAILS = "/accounts/edit/";
//Client Module
const GET_ALL_CLIENT = "/accounts";
const ADD_CLIENT = "/signup/2";
const GET_SPECIFIC_CLIENT = "/accounts/edit";
const EDIT_CLIENT = "/accounts/edit-client";
const DELETE_CLIENT = "/accounts";
const SESSION = "/session";
const SENDGRID = "/settings/sendgrid";
// Tickets
const ADD_TICKET = "/settings/addTicket";
const EDIT_TICKET = "/settings/updateTicket";
const GET_TICKET = "/settings/tickets";
const DELETE_TICKET = "/settings/ticket";
const FILTER_TICKET = "/settings/filterTickets";
//Orders
const ORDER = "/admin/order";
const GET_SINGLE_ORDER = "/admin/view-order";
const EDIT_ORDER = "/admin/order";
const FILTER_ORDER = "/admin/filterOrder";
const ORDERBYID = "/admin/orderBy";
//Invoice
const INVOICE = "/admin/invoice";
const EDIT_INVOICE = "/admin/invoice";
const UPDATE_INVOICE_PAID = "/admin/mark-paid";
const GET_INVOICE = "/admin/edit";
const INVOICE_BY_ORDER = "/admin/orderId";
const GET_PDF = "/settings/pdf";
const DASHBOARD = "/settings/dashboard";
const SALES = "/settings/sales";
const SALES_DATE_FILTER = "/settings/salesDateFilter";
const DASHBOARD_DATE_FILTER = "/settings/dashboardFilter";
//subscription
const SUB = "/admin/specific-subscriptions";
const CLIENT_DATA_LISTING = "/accounts/client-data-listing";
// CLIENT ROUTE

//shop-articles
const SHOP_ARTICLE = "/portal/services";
const CART = "/portal/cart";
const CLIENT_COUPON = "/portal/coupon";
const CHECKOUT = "/portal/checkout";
const PAYMENTSUCCESS = "/portal/paymentSuccess";
const INVOICESUCCESS = "/portal/invoiceSuccess";
const CLIENT_ORDER = "/portal/order";
const CLIENT_ORDER_ID = "/portal/client-order";
const FILTER_INVOICES = "/admin/filter-invoice";
const CLIENT_INVOICE = "/portal/invoice";
const ADD_PROJECT_DATA = "/portal/add-project-data";
const GET_ALL_TEAM_MEMBERS = "/portal/teamMemberData";
const GET_CLIENT_TICKET = "/settings/client-tickets";
const GET_ALL_CLIENT_TEAM_MEMBERS = "/portal/team";
const INVOICE_EMAIL = "/admin/sendEmailInvoice";
const GET_ORDER_DETAILS = "/admin/orderId";
const Subscription = "/admin/subscription/1";
const DELETE_SUBSCRIPTION = "/admin/subscription";
const CANCEL_SUBSCRIPTION = "/portal/cancel-subscription";
const LIST_CARDS = "/portal/list-cards";
const HOMEPAGE = "/portal/homePageViewList";
const GET_ACTIVE_CLIENTS = "/admin/active-clients";
const COMPANY = "/settings/company";
export const allApiUrl = {
  LIST_SERVICES,
  COMPANY,
  COUPON_LIST,
  LIST_CARDS,
  GET_ACTIVE_CLIENTS,
  HOMEPAGE,
  ORDER,
  DELETE_SUBSCRIPTION,
  INVOICESUCCESS,
  CANCEL_SUBSCRIPTION,
  SUB,
  ADD_PROJECT_DATA,
  Subscription,
  INVOICE,
  INVOICE_BY_ORDER,
  CLIENT_INVOICE,
  CLIENT_ORDER,
  CLIENT_ORDER_ID,
  ORDERBYID,
  EDIT_ORDER,
  ADMIN_LOGIN_IN,
  PAYMENTSUCCESS,
  REQUEST_RESET_EMAIL,
  PASSWORD_RESET,
  CLIENT_DATA_LISTING,
  TAGS,
  FILES,
  SERVICES,
  PAYMENT_METHOD,
  GET_ALL_ROLE,
  EDIT_USER_DETAILS,
  COUPON,
  MESSAGE,
  ADD_ROLE,
  EDIT_ROLE,
  GET_SPECIFIC_ROLE,
  EMAIL_TEMPLATE,
  SENDGRID,
  GET_ALL_TEAM,
  ADD_TEAM_MEMBER,
  GET_SPECIFIC_TEAM,
  EDIT_TEAM_MEMBER,
  UPDATE_INVOICE_PAID,
  LOGS,
  FILTER_LOGS,
  GET_ALL_CLIENT,
  ADD_CLIENT,
  EDIT_TICKET,
  EDIT_CLIENT,
  GET_SPECIFIC_CLIENT,
  DELETE_CLIENT,
  DELETE_TEAM_MEMBER,
  EMAIL_PROFILE,
  CHANGE_PASSWORD,
  UPDATE_PROFILE,
  ADD_TICKET,
  GET_TICKET,
  DELETE_TICKET,
  FILTER_TICKET,
  SESSION,
  FILTER_FILES,
  FILTER_INVOICES,
  SHOP_ARTICLE,
  CART,
  CLIENT_COUPON,
  CHECKOUT,
  ADD_SERVICE,
  GET_CLIENT_TICKET,
  GET_ALL_CLIENT_TEAM_MEMBERS,
  EDIT_INVOICE,
  GET_INVOICE,
  GET_SINGLE_ORDER,
  GET_ALL_TEAM_MEMBERS,
  GET_PDF,
  DASHBOARD,
  SALES,
  SALES_DATE_FILTER,
  DASHBOARD_DATE_FILTER,
};

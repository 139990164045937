import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { AllContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { array } from "yup";
import PageLoader from "../../components/pageLoader";
const Coupons = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const [isLoading, setIsLoading] = useState(false);
  const [couponId, setCouponId] = useState();
  const [couponCode, setCouponCode] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const {
    allApiUrl,
    apiGetMethod,
    apiDeleteMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { COUPON, COUPON_LIST } = allApiUrl;
  const [coupon, setCoupon] = useState([]);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [limit, setPageLimit] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  function clearCheckAll() {
    isSingleChecked(false);
    setIsReset(!isReset);
  }

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };

  function getAllCoupon() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(
      COUPON_LIST + "/" + itemOffset + "&" + itemsPerPage,
      "",
      header
    )
      .then((res) => {
        var getCouponList = res.data.docs;
        var couponsCount = res.data.totalCoupons;
        setCurrentPage(res.data.currentPage);
        setPageCount(Math.ceil(couponsCount / itemsPerPage));
        setPageLimit(res.data.limit || 20);
        setCoupon(getCouponList);
        setInitialLoading(false);
        // setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  useEffect(() => {
    getAllCoupon(itemOffset);
  }, [itemOffset, pageCount, itemsPerPage]);

  const editCoupon = async (_id) => {
    navigate("/edit-coupon/" + _id);
  };

  function deleteCoupon(couponId) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(COUPON, couponId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        handleToClose();
        getAllCoupon();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  const handleToClose = () => {
    setOpen(false);
  };
  function deleteMultiple() {
    setIsLoading(true);
    bulkDeleteId.map((row) => {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiDeleteMethod(COUPON, row, header)
        .then((res) => {
          handleToClose();
          getAllCoupon();
          setIsLoading(false);
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }

  function checkedAll() {
    if (coupon.length == bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = coupon.map((e) => {
        return e._id;
      });
      setBulkDeleteId(ids);
    }
  }

  function deleteIdList(id, status) {
    if (status) {
      setBulkDeleteId([id]);
    } else {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        bulkDeleteId.push(id);
        setBulkDeleteId(bulkDeleteId);
      } else {
        let exist = bulkDeleteId.findIndex((e) => e == id);
        let ids = bulkDeleteId.splice(exist + 1, 1);
        setBulkDeleteId(ids);
      }
    }

    // if (status) {
    //   let exist = bulkDeleteId.findIndex((e) => e == id);
    //   if (exist == -1) {
    //     bulkDeleteId.push(id);
    //     setBulkDeleteId(bulkDeleteId);
    //   }
    // } else {
    //   let exist = bulkDeleteId.findIndex((e) => e == id);
    //   let ids = bulkDeleteId.splice(exist + 1, 1);
    //   setBulkDeleteId(ids);
    // }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }
  return (
    <div>
      <Sidebar
        activePage="coupons"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-6">
                <div className="heading">
                  <h2 className="fs-4">Coupons</h2>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-6">
                <div className="section-filter">
                  <div className="dropdown">
                    <a className="add-btn" href="/add-coupon">
                      Add Coupons
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="common-table clients-table mt-3 files-table rounded-1">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="ps-4 w65-px">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="selectAll form-check-input"
                              id="selectAll"
                              checked={
                                coupon.length == bulkDeleteId.length &&
                                coupon.length > 0
                              }
                              defaultValue
                              onClick={() => checkedAll()}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="selectAll"
                            ></label>
                          </div>
                        </th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Type</th>
                        <th>expires</th>
                        <th>single use</th>
                        <th>times used</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* no record found */}
                      {/* <tr>
                        <td colSpan={7}>
                          <div className="noData-section">
                            <img src={noData} />
                            <p>No Data Found</p>
                          </div>
                        </td>
                      </tr> */}
                      {/* no record found */}
                      {coupon.map((value, index) => {
                        var single_use =
                          value.redemption_limit_one_use_per_customer === true
                            ? "Yes"
                            : "No";
                        let publishdate = moment(value.expiryDate).format(
                          "MMM DD , YYYY"
                        );
                        return (
                          <tr key={index}>
                            <td className="ps-4 w65-px">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={value._id}
                                  checked={bulkDeleteId.find(
                                    (e) => e == value._id
                                  )}
                                  onClick={(e) => {
                                    setIsSingleChecked(e.target.checked);
                                    deleteIdList(
                                      value._id,
                                      coupon.length == bulkDeleteId.length
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={value._id}
                                ></label>
                              </div>
                            </td>
                            <td>
                              <a onClick={() => editCoupon(value._id)}>
                                {value.couponCode}
                              </a>
                            </td>
                            <td>{value.description}</td>
                            <td>
                              <span>
                                {value.fixedAmount === false ? "%" : "$"}
                              </span>
                            </td>
                            <td>
                              {value.expiryDate == null ? "" : publishdate}
                            </td>
                            <td>{single_use}</td>
                            <td>{value.timesUsed}</td>
                            <td className="text-end pe-4">
                              <div className="dropdown">
                                <button
                                  className="btn btn-secondary dropdown-toggle btn-info"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() => editCoupon(value._id)}
                                    >
                                      Edit
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                      onClick={() => {
                                        setOpen(true);
                                        setCouponId(value._id);
                                        setCouponCode(value.couponCode);
                                      }}
                                    >
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      {coupon && coupon.length <= 0 && (
                        <tr>
                          <td colSpan={7}>
                            <div className="noData-section">
                              <img src={noData} />
                              <p>No Data Found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="sectionPagination">
                  <NoOfRows />
                  <ReactPaginate
                    nextLabel={<i class="fa-solid fa-angles-right"></i>}
                    previousLabel={<i class="fa-solid fa-angles-left"></i>}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination paginate"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>

                {(bulkDeleteId.length > 0 || isSingleChecked) && (
                  <div className="table-bottom-option">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <div className="input-group">
                          <select
                            id="selectOption"
                            value={selectOption.target}
                            onChange={(e) => selectOption(e)}
                            className="form-control"
                          >
                            <option value="">With Selected...</option>
                            <option value="Delete">Delete</option>
                          </select>
                          <button
                            className="btn btn-apply"
                            onClick={() => {
                              if (selectedOption == "Delete") {
                                deleteMultiple();
                              }
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* delete modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Are you sure you want to delete coupon{" "}
                  <strong>{couponCode}</strong>?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={() => deleteCoupon(couponId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* delete modal */}
    </div>
  );
};
export default Coupons;

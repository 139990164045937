import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import parse from "html-react-parser";
import { AllContext } from "../../context";
import PageLoader from "../../components/pageLoader";
import { apiDeleteMethod, apiGetMethod } from "../../api/rest";
import Avatar from "../../assets/img/avatar.png";
import {
  handleGetTime,
  formatMonthNameAndDate,
  handleOffSetDateTime,
  redirectToLogin,
} from "../../shared/functions";
import { useEffect } from "react";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import Select from "react-select";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { saveOrderNeedAttention } from "../../redux/action/login";

const ViewOrders = () => {
  const { state } = useLocation();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [order, setOrder] = useState({
    assignedTo: [],
    projectData: [],
    cc: [],
    date: "",
    message: "",
    note: "",
    relatedOrder: [],
    status: "open",
    subject: "",
    tags: [],
    to: "",
  });
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const [otherOrders, setOtherOrders] = useState([]);
  const [ccUser, setCcUser] = useState([]);
  const editorRef = useRef(null);
  const [orderSubject, setOrderSubject] = useState("");
  const [orderNote, setOrderNote] = useState("");
  const [allTeam, setAllTeam] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [files, setFiles] = useState([]);
  let { id } = useParams();
  const [_id, setId] = useState(null);
  const showForm1 = () => {
    setShowForm(!showForm);
  };
  const [initialLoading, setInitialLoading] = useState(true);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [filterTags, setFilterTags] = useState([]);
  const context = useContext(AllContext);
  const { allApiUrl, apiPutMethod, toastMessage, apiPostMethod } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const userInfoRoles = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );

  const [showReplyFrom, setShowReplyFrom] = useState(false);
  const [ccClient, setCcClient] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messageCotent, setMessageCotent] = useState("");
  const [logs, setLogs] = useState([]);
  const [replyTo, setReplyTo] = useState("Client");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const {
    FILTER_FILES,
    GET_ALL_CLIENT,
    GET_ALL_TEAM,
    TAGS,
    MESSAGE,
    FILTER_TICKET,
    FILTER_LOGS,
  } = allApiUrl;
  const { EDIT_ORDER, ADD_CLIENT } = allApiUrl;

  function getMessagesTemplates() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(MESSAGE, "", header)
      .then((res) => {
        setMessages(res.data.data);
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }

  useEffect(() => {
    getMessagesTemplates();
    getAllTeam();
    getAllClient();
  }, []);
  useEffect(() => {
    getOrders();
    getAttachedFiles();
  }, [id]);

  useEffect(() => {
    // dispatch(saveOrderNeedAttention({ orderNeedAttention: false, id: "" }));
  }, []);
  function getAllTeam() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAllTeam(res.data.team);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }
  function getClientTickits(id) {
    const data = { client: id };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(FILTER_TICKET, data, header)
      .then((res) => {
        if (res.status === 200) {
          setOtherOrders(res.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  function getAllClient() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(GET_ALL_CLIENT, "", header).then((res) => {
      if (res.status === 200) {
        let clientList = [];
        res.data.team.map((client) => {
          let label = "";
          if (client.userId.firstName && client.userId.lastName) {
            label =
              client.userId.firstName +
              " " +
              client.userId.lastName +
              " - " +
              client.userId.email;
          } else {
            if (client.userId.firstName || client.userId.lastName) {
              if (client.userId.firstName) {
                label = client.userId.firstName;
              } else {
                label = client.userId.lastName;
              }
            } else {
              label = client.userId.email;
            }
          }
          let data = {
            label: label,
            value: client.userId._id,
          };
          clientList.push(data);
        });
        setCcClient(clientList);
        // setAllClient(res.data.team);
      }
    });
  }

  const getOrders = () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.GET_SINGLE_ORDER + "/" + id, "", header)
      .then((res) => {
        if (res.status === 200) {
          setOrder(res.data.order);
          setId(res.data.order._id);
          setOrderSubject(res.data.order.title);
          setOrderNote(res.data.order.note);
          if (res.data.order.cc) {
            getSelectedCC(res.data.order.cc);
          }
          // setOrderCcMails(res.data.)
          if (
            res.data.order &&
            res.data.order.client &&
            res.data.order.client._id
          ) {
            getClientTickits(res.data.order.client._id);
          }
          getLogs(res.data.order.orderId);
          // setIsLoading(false);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        navigate("/orders");
      });
  };
  const navigate = useNavigate();

  function handleEditorChange(e) {
    // var str = e.target.getContent();
    setOrder((prevData) => {
      return { ...prevData, note: e };
    });
  }
  function handleMessageEditorChange(e) {
    var str = e.target.getContent();
    setMessageCotent(str);
  }
  function updateOrder(data) {
    const userInfoRolesTeam = userInfoRoles.orderManagement.editData;
    if (userInfoRolesTeam) {
      setIsLoading(true);
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiPutMethod(EDIT_ORDER + "/" + _id, data, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          getOrders();
          setIsLoading(false);
          setShowForm(false);
          if (orderStatus !== "") {
            setOrderStatus("");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    } else {
      toastMessage(
        "warning",
        "You don't have permission to access this resource."
      );
    }
  }
  function updateAssignedUser(userId) {
    if (
      order.assignedTo != [] &&
      order.assignedTo.find((row) => row == userId)
    ) {
      let index = order.assignedTo.findIndex((row) => row == userId);
      order.assignedTo.splice(index, 1);
    } else {
      order && order.assignedTo && order.assignedTo.push(userId);
    }
    updateOrder({ assignedTo: order.assignedTo });
  }
  function getAssignedUsers() {
    let users = "";
    let assignedTo = order.assignedTo;
    assignedTo &&
      assignedTo.map((row, index) => {
        let data = allTeam.find((team) => team.userId._id == row);
        if (data) {
          if (data.userId.firstName && data.userId.lastName) {
            users += `${data.userId.firstName} ${data.userId.lastName}${index !== assignedTo.length - 1 ? "," : ""
              } `;
          } else {
            users += `${data.userId.email}${index !== assignedTo.length - 1 ? "," : ""
              } `;
          }
        }
      });
    if (users == "") {
      return "Anyone";
    }
    return users;
  }

  function getFilteredTags(value) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(TAGS, `/${value}`, header)
      .then((res) => {
        if (res.status === 200) {
          setFilterTags(res.data.tags);
        }
      })
      .catch((err) => {
        toastMessage("error", err);
      });
  }
  const addTag = () => {
    const data = {
      name: newTag,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(TAGS, data, header)
      .then((res) => {
        if (res.status === 201) {
          order.tags.push(newTag);
          updateOrder({ tags: order.tags });
          setNewTag("");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  function formatAMPM(date) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateString = monthNames[date.getMonth()] + " " + date.getDate() + "th";
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = dateString + " at " + hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const getLogs = (accountId) => {
    const data = {
      resourceId: accountId,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(FILTER_LOGS, data, header)
      .then((res) => {
        if (res.status === 200) {
          setLogs(res.data);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  };
  function getTime(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  function getSelectedCC(cc) {
    let listCc = [];
    cc.map((row) => {
      let label = "";
      if (row.firstName && row.lastName) {
        label = row.firstName + " " + row.lastName;
      } else {
        if (row.firstName || row.lastName) {
          if (row.firstName) {
            label = row.firstName;
          } else {
            label = row.lastName;
          }
        } else {
          label = row.email;
        }
      }
      let data = {
        label: label,
        value: row._id,
      };
      listCc.push(data);
    });
    setCcUser(listCc);
  }
  function CheckNewCC() {
    ccUser.map((cc, index) => {
      if (cc.value == "newCC") {
        const autoFirstName = cc.label.substring(0, cc.label.indexOf("@"));
        const data = {
          role: "contact",
          createdBy: userInfo.id,
          firstName: autoFirstName,
          lastName: "",
          email: cc.label,
        };
        let header = {
          Authorization: `Bearer ${loginUserAccessToken}`,
        };
        apiPostMethod(ADD_CLIENT, data, header)
          .then((res) => {
            if (res.status === 201) {
              ccUser[index].value = res.data.id;
              toastMessage("success", res.data.message);
              if (index == ccUser.length - 1) {
                if (ccUser.length > 0) {
                  ccUser.map((row, index) => {
                    ccUser[index] = row.value;
                  });
                }
                updateOrder({ cc: ccUser });
              }
            }
          })
          .catch((err) => {
            if (err.data.message == "Account already exist") {
              ccUser[index].value = err.data._id;
              if (index == ccUser.length - 1) {
                if (ccUser.length > 0) {
                  ccUser.map((row, index) => {
                    ccUser[index] = row.value;
                  });
                }
                updateOrder({ cc: ccUser });
              }
            } else {
              toastMessage("error", err.data.message);
            }
          });
      } else {
        if (index == ccUser.length - 1) {
          if (ccUser.length > 0) {
            ccUser.map((row, index) => {
              ccUser[index] = row.value;
            });
          }
          updateOrder({ cc: ccUser });
        }
      }
    });
  }
  const dispatch = useDispatch();
  function deleteOrder() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.ORDER, order._id, header)
      .then((res) => {
        toastMessage("success", "Order Deleted");
        navigate("/orders");
      })
      .catch((err) => {
        // redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }
  function updateOrderMessage(message) {
    setIsLoading(true);
    if (selectedFile.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append("files", selectedFile[i]);
      }
      formData.append("from", userInfo.id);
      formData.append("message", message);
      formData.append("date", new Date().getTime());
      formData.append("to", replyTo);
      formData.append("type", "Order");
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
        "Content-Type": "multipart/form-data",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, formData, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          getOrders();
          setSelectedFile({});
          setIsLoading(false);
          setShowForm(false);
          if (orderStatus !== "") {
            setOrderStatus("");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    } else {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      let data = {
        from: userInfo.id,
        message: message,
        date: new Date().getTime(),
        to: replyTo,
        type: "Order",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, data, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          setSelectedFile({});
          getOrders();
          setIsLoading(false);
          setShowForm(false);
          if (orderStatus !== "") {
            setOrderStatus("");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    }
  }
  function AttachmentFiles() {
    if (selectedFile && selectedFile.length > 0) {
      let files = [...selectedFile];
      return files.map((selected, index) => {
        return (
          <span>
            <a>
              {" "}
              <span className="innerIcon attachIcon me-1">
                <i class="fa-solid fa-link"></i>{" "}
              </span>
              {selected.name}{" "}
              <span className="innerIcon crossIcon ms-2">
                <i
                  class="fa-solid fa-xmark"
                  onClick={() => {
                    var dt = new DataTransfer();
                    let input = document.getElementById("myFileInput");
                    var { files } = input;
                    for (var i = 0; i < files.length; i++) {
                      var file = files[i];
                      if (index !== i) dt.items.add(file);
                      input.files = dt.files;
                    }
                    setSelectedFile(input.files);
                  }}
                ></i>
              </span>
            </a>
          </span>
        );
      });
    }
    return "";
  }

  function getAttachedFiles() {
    let data = { resourceId: [id] };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(FILTER_FILES, data, header)
      .then((res) => {
        if (res.status === 200) {
          setFiles(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        // redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  return (
    <div>
      <Sidebar
        activePage="orders"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="viewOrders pt-3 pb-3 horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row mb-4">
              <div className="col-lg-6">
                <div className="section-back">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      navigate("/orders");
                    }}
                  >
                    <i class="fa-solid fa-angle-left"></i> Orders
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="section-back text-end">
                  <a href="#" className="mx-2">
                    <i className="fa-solid fa-angle-up"></i>
                  </a>
                  <a href="#" className="mx-2">
                    <i className="fa-solid fa-angle-down"></i>
                  </a>
                </div>
              </div> */}
            </div>

            {!isLoading && (
              <div className="container p90-large">
                <div className="row mb-2">
                  <div className="col-lg-6">
                    <div className="sectionHeading mb-2">
                      <h3 className="mb-0 text-capitalize fs-4">
                        {order.title ? order.title : "Order Name"}
                        {userInfoRoles.orderManagement.editData && (
                          <span
                            className="editHeading"
                            data-bs-toggle="modal"
                            data-bs-target="#headingModal"
                          >
                            <i className="fa-solid fa-pen"></i>
                          </span>
                        )}
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="status-btns text-end">
                      <div className="dropdown">
                        <button
                          className={`btn btn-secondary dropdown-toggle btn-follow ${userInfoRoles.orderManagement.editData
                            ? "status-btn"
                            : ""
                            } orderStatus`}
                          type="button"
                          data-bs-toggle={
                            userInfoRoles.orderManagement.editData && "dropdown"
                          }
                          aria-expanded="false"
                        >
                          {capitalizeFirstLetter(order.status)}
                        </button>
                        <ul className="dropdown-menu status-dropdown">
                          <p>
                            Change order status does not trigger an email
                            notification
                          </p>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => updateOrder({ status: "pending" })}
                            >
                              <div>
                                <span className="circle circle-spam"></span>
                              </div>
                              <div>
                                Pending <br />{" "}
                                <small>
                                  Waiting for client to submit project details
                                </small>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() =>
                                updateOrder({ status: "complete" })
                              }
                            >
                              <div>
                                <span className="circle circle-spam"></span>
                              </div>
                              <div>
                                Complete <br />{" "}
                                <small>Order has been delivered</small>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() =>
                                updateOrder({ status: "submitted" })
                              }
                            >
                              <div>
                                <span className="circle circle-success"></span>
                              </div>
                              <div>
                                Submitted <br />{" "}
                                <small>
                                  Client has submitted project details
                                </small>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() =>
                                updateOrder({ status: "info lacking" })
                              }
                            >
                              <div>
                                <span className="circle circle-spam"></span>
                              </div>
                              <div>Info lacking</div>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() =>
                                updateOrder({ status: "draft for approval" })
                              }
                            >
                              <div>
                                <span className="circle circle-spam"></span>
                              </div>
                              <div>Draft for approval</div>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() =>
                                updateOrder({ status: "client approves" })
                              }
                            >
                              <div>
                                <span className="circle circle-spam"></span>
                              </div>
                              <div>Client approves</div>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() =>
                                updateOrder({ status: "sent to publish" })
                              }
                            >
                              <div>
                                <span className="circle circle-spam"></span>
                              </div>
                              <div>Sent to Publish</div>
                            </a>
                          </li>


                        </ul>
                        <button
                          className={`btn btn-secondary dropdown-toggle btn-follow  ${userInfoRoles.orderManagement.assignToOthers
                            ? "status-btn"
                            : ""
                            } ticketUsersDropdown`}
                          type="button"
                          data-bs-toggle={
                            userInfoRoles.orderManagement.assignToOthers &&
                            "dropdown"
                          }
                          aria-expanded="false"
                        >
                          {getAssignedUsers()}
                        </button>
                        {/*  */}
                        <ul className="dropdown-menu status-dropdown assignDropdown">
                          {allTeam.map((value, index) => {
                            return (
                              <li
                                onClick={() =>
                                  updateAssignedUser(value.userId._id)
                                }
                              >
                                <a className="dropdown-item position-relative">
                                  {order &&
                                    order.assignedTo &&
                                    order.assignedTo.length > 0 &&
                                    order.assignedTo.find(
                                      (row) => row == value.userId._id
                                    ) && (
                                      <>
                                        <span
                                          class="fas fa-check check-mark"
                                          aria-hidden="true"
                                        ></span>
                                      </>
                                    )}
                                  <div>
                                    {value.userId.firstName &&
                                      value.userId.lastName
                                      ? value.userId.firstName +
                                      "" +
                                      value.userId.lastName
                                      : value.userId.email}{" "}
                                    <br /> <small>{value.role}</small>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        {userInfoRoles.orderManagement._delete && (
                          <button
                            className="btn btn-secondary dropdown-toggle btn-follow px-3"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                        )}
                        <ul className="dropdown-menu">
                          {/* <li>
                        <a
                          className="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#mergeModal"
                        >
                          Edit Order
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/add-service">
                          Add Project Data
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#publishModal"
                        >
                          Publish Content
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="javascript:void(0);">
                          Create Invoice
                        </a>
                      </li> */}
                          <li>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModal"
                            >
                              Delete Order
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    {!showForm && (
                      <div className="teamNote mb-3">
                        {orderNote && orderNote.length > 0 ? (
                          <p>
                            <div
                              className="serviceDescription"
                              dangerouslySetInnerHTML={{
                                __html: orderNote,
                              }}
                            ></div>
                          </p>
                        ) : (
                          <p>Add note for your team</p>
                        )}
                        <a
                          className=""
                          href="javascript:void(0);"
                          onClick={() => setShowForm(true)}
                        >
                          Edit
                        </a>
                      </div>
                    )}
                    {showForm && (
                      <div className="teamNote">
                        <div className="form-group">
                          {/* <textarea
                      className="form-control"
                      placeholder="Add a note for your team..."
                    ></textarea> */}
                          <Editor
                            className="form-control"
                            placeholder="Add a note for your team..."
                            apiKey="fjnv8pd6m40tkdl6tva9sklqdppliphhvofstqa48b92mxtb"
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            value={order.note}
                            init={{
                              selector: "textarea",
                              height: 200,
                              plugins: "advlist link image lists",
                              menubar: false,
                              toolbar: false,
                              menubar: "custom",
                              menu: {
                                custom: {
                                  title: "custom",
                                  items: "basicitem nesteditem toggleitem",
                                },
                              },
                              toolbar:
                                "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                "fullpage" +
                                "indent | blockquote | fontselect ",
                              font_formats:
                                "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                              block_formats:
                                "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                              plugins: ["image", "help", "link", "lists"],
                              branding: false,
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                              menubar: false,
                            }}
                            onEditorChange={(e) => handleEditorChange(e)}
                          />
                          <div className="text-end">
                            <button
                              className="btn btn-save-note btn-warning"
                              value={order.note}
                              onClick={(e) => updateOrder({ note: order.note })}
                            >
                              Save note
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {((order && order.status == "pending") ||
                      (order && order.status == "Pending")) && (
                        <div className="projectData mt-4">
                          <div className="sectionHeading">
                            <h3>Project Data </h3>
                            <div className="dropdown">
                              <span
                                className="pill pill-grey"
                                style={{
                                  cursor: "pointer",
                                  background: "#5a4cdc",
                                  color: "white",
                                }}
                                onClick={() => {
                                  navigate(
                                    `/portal/intakeForm/${order.inTakeForm}`,
                                    {
                                      state: {
                                        orderId: order._id,
                                        mode: "add",
                                        page: "view-orders",
                                      },
                                    }
                                  );
                                }}
                              >
                                Start Order
                              </span>
                              <ul className="dropdown-menu"></ul>
                            </div>
                          </div>
                        </div>
                      )}

                    {order &&
                      order.projectData &&
                      order.projectData.length > 0 && (
                        <div className="projectData mt-4">
                          <div className="sectionHeading">
                            <h3 className="m-0">Project Data </h3>
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle btn-follow"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => {
                                      navigate(
                                        `/portal/intakeForm/${state.formType}`,
                                        {
                                          state: {
                                            mode: "edit",
                                            _id: order._id,
                                            // formType: state.formType,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    Edit
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="projectdataContent">
                            {order.projectData.map((item) => {
                              return (
                                <div className="questionSection">
                                  <div className="">
                                    <div className="">
                                      {item.type !== "file" && (
                                        <h3>{item.key}</h3>
                                      )}
                                    </div>
                                  </div>
                                  <div className="">
                                    <div className="d-flex">
                                      {item.type === "file" ? (
                                        item &&
                                        item.value &&
                                        item.value.map((images) => {
                                          // img src={typeof form.imageUrl === 'string' ? form.imageUrl : URL.createObjectURL(form.imageUrl)}
                                          return (
                                            <img
                                              className="mx-1"
                                              src={images}
                                              style={{
                                                width: "70px",
                                                height: "70px",
                                                objectFit: "cover",
                                              }}
                                            />
                                          );
                                        })
                                      ) : (
                                        <p>
                                          {handleOffSetDateTime(item.value, companyData.timezone)}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}

                    <div className="sectionMessage mt-5">
                      {order.message.length > 0 && <h3>Messages</h3>}
                      {order.message.length > 0 &&
                        order.message.map((singleMessage, index) => {
                          return (
                            <div
                              className={`clientMessage ${singleMessage.to == "Client" &&
                                userInfo.id == singleMessage.from._id
                                ? `grey`
                                : `${singleMessage.to == "Client"
                                  ? "white"
                                  : "yellow"
                                }`
                                }`}
                            >
                              <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                  <div className="messageUser">
                                    <div className="userImg">
                                      <img
                                        src={
                                          singleMessage.from &&
                                            singleMessage.from.image
                                            ? singleMessage.from.image
                                            : Avatar
                                        }
                                      />
                                    </div>
                                    <div className="userName">
                                      <h4
                                        className={
                                          singleMessage.to == "Client"
                                            ? `client`
                                            : `team`
                                        }
                                      >
                                        {singleMessage.from && (
                                          <>
                                            {singleMessage.from.firstName ||
                                              singleMessage.from.lastName ? (
                                              <>
                                                {singleMessage.from.firstName &&
                                                  singleMessage.from.lastName ? (
                                                  singleMessage.from.firstName +
                                                  " " +
                                                  singleMessage.from.lastName
                                                ) : (
                                                  <>
                                                    {singleMessage.from
                                                      .firstName
                                                      ? singleMessage.from
                                                        .firstName
                                                      : singleMessage.from
                                                        .lastName}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              singleMessage.from.email
                                            )}
                                          </>
                                        )}
                                        <span className="messageStatus">
                                          {singleMessage.to == "Client"
                                            ? `Replied`
                                            : `Added a note`}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div className="messageUser messageTiming">
                                    <div className="userName">
                                      <p>
                                        {handleOffSetDateTime(singleMessage.date, companyData.timezone)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="message-content ">
                                <p>{parse(singleMessage.message)}</p>
                              </div>
                              <div
                                id="attachment"
                                className="messageAttachment"
                              >
                                {singleMessage.attachments.map((attachment) => {
                                  return (
                                    <span id="addedfiles">
                                      <a>
                                        {" "}
                                        <span className="innerIcon attachIcon">
                                          <i class="fa-solid fa-link"></i>{" "}
                                        </span>
                                        {attachment.split("/uploads/")[1]}
                                      </a>
                                      {/* <span className="innerIcon downloadIcon mx-2 ms-3" onClick={() => { onButtonClick(attachment) }}><i class="fa-solid fa-download"></i></span> */}
                                      <a href={attachment} target="_blank">
                                        <span className="innerIcon mx-2 ms-3">
                                          <i class="fa-solid fa-eye"></i>
                                        </span>
                                      </a>
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}

                      <div className="reply-section mb-4">
                        {userInfoRoles.messaging.messageClient &&
                          !showReplyFrom && (
                            <>
                              <button
                                className="btn mx-2"
                                onClick={() => {
                                  setShowReplyFrom(true);
                                  setReplyTo("Client");
                                }}
                              >
                                {" "}
                                <i className="fa-solid fa-reply"></i> Reply to
                                client
                              </button>
                              <button
                                className="btn"
                                onClick={() => {
                                  setShowReplyFrom(true);
                                  setReplyTo("Team");
                                }}
                              >
                                {" "}
                                <i className="fa-solid fa-plus"></i> Message
                                Team
                              </button>
                            </>
                          )}
                        {showReplyFrom && (
                          <>
                            <form className="mt-4">
                              <div className="form-group">
                                <Editor
                                  className="form-control"
                                  placeholder="Add a note for your team..."
                                  apiKey="fjnv8pd6m40tkdl6tva9sklqdppliphhvofstqa48b92mxtb"
                                  onInit={(evt, editor) =>
                                    (editorRef.current = editor)
                                  }
                                  initialValue={messageCotent}
                                  init={{
                                    selector: "textarea",
                                    height: 200,
                                    plugins: "advlist link image lists",
                                    menubar: false,
                                    toolbar: false,
                                    menubar: "custom",
                                    menu: {
                                      custom: {
                                        title: "custom",
                                        items:
                                          "basicitem nesteditem toggleitem",
                                      },
                                    },
                                    toolbar:
                                      "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                      "fullpage" +
                                      "indent | blockquote | codesample | fontselect ",
                                    font_formats:
                                      "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                    block_formats:
                                      "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                    plugins: ["image", "help", "link", "lists"],
                                    branding: false,
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                    menubar: false,
                                    setup: function (editor) {
                                      editor.ui.registry.addSplitButton(
                                        "myButton",
                                        {
                                          icon: "line",
                                          // tooltip: "This is an example split-button",
                                          onAction: function () {
                                            // editor.insertContent(
                                            //   "<p>You clicked the main button</p>"
                                            // );
                                          },
                                          onItemAction: function (api, value) {
                                            editor.insertContent(value);
                                          },
                                          fetch: function (callback) {
                                            let replaceDoubleBraces = (
                                              str,
                                              result
                                            ) => {
                                              return str.replace(
                                                /{{(.+?)}}/g,
                                                (_, g1) =>
                                                  result[
                                                  g1.replace(/\s/g, "")
                                                  ] || g1
                                              );
                                            };
                                            var message = messages.map(
                                              (value) => {
                                                let result = {
                                                  "user.lastName":
                                                    order.userId &&
                                                      order.userId.lastName
                                                      ? order.userId.lastName
                                                      : "",
                                                  "user.firstName":
                                                    order.userId?.firstName,
                                                  "user.email":
                                                    order.userId?.email,
                                                  "user.password": "",
                                                  "'site_name'": "Agency CRM",
                                                };
                                                return {
                                                  type: "choiceitem",
                                                  text: value.name,
                                                  value: replaceDoubleBraces(
                                                    value.body,
                                                    result
                                                  ),
                                                };
                                              }
                                            );
                                            callback(message);
                                          },
                                        }
                                      );
                                    },
                                  }}
                                  onChange={(e) => handleMessageEditorChange(e)}
                                />
                                <div className="row mt-3">
                                  <div className="col-lg-6">
                                    <div className="text-start">
                                      <div
                                        id="attachment"
                                        className="messageAttachment mb-2"
                                      >
                                        <AttachmentFiles />
                                      </div>
                                      <span
                                        className="delete me-2"
                                        onClick={() => {
                                          setShowReplyFrom(false);
                                          setMessageCotent("");
                                        }}
                                      >
                                        <i className="fa-solid fa-trash"></i>
                                      </span>
                                      <input
                                        type="file"
                                        className="btn btn-secondary"
                                        hidden
                                        multiple
                                        id="myFileInput"
                                        onChange={(e) => {
                                          setSelectedFile(e.target.files);
                                        }}
                                      />
                                      <span
                                        className="delete"
                                        onClick={() => {
                                          document
                                            .getElementById("myFileInput")
                                            .click();
                                        }}
                                      >
                                        <i class="fa-solid fa-link"></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="send-btn">
                                      <button
                                        type="button"
                                        className="btn btn-primary ms-2"
                                        disabled={
                                          messageCotent &&
                                          messageCotent.length < 0
                                        }
                                        onClick={() => {
                                          if (
                                            messageCotent &&
                                            messageCotent.length > 0
                                          ) {
                                            updateOrderMessage(messageCotent);
                                            setShowReplyFrom(false);
                                            setMessageCotent("");
                                          } else {
                                            toastMessage(
                                              "error",
                                              "Please insert a message"
                                            );
                                          }
                                        }}
                                      >
                                        Send to {replyTo.toLowerCase()}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            {replyTo == "Client" && (
                              <p className="ccText d-flex">
                                Add:{" "}
                                <a
                                  href="javascript:void(0);"
                                  data-bs-toggle="modal"
                                  data-bs-target="#ccModal"
                                  className="ccIds"
                                >
                                  {order.cc.length > 0
                                    ? order.cc.map((value, index) => {
                                      return (
                                        value.email +
                                        `${index == order.cc.length - 1
                                          ? ""
                                          : ", "
                                        }`
                                      );
                                    })
                                    : "CC"}
                                </a>
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {/* <div className="emojisection">
                            <h4>How did we do?</h4>
                            <div className="emojibutton">
                                <button>
                                    <img src={Terrible} /> <br />
                                    Terrible
                                </button>

                                <button>
                                    <img src={Sad} /> <br />
                                    Bad
                                </button>

                                <button>
                                    <img src={Okay} /> <br />
                                    Okay
                                </button>

                                <button>
                                    <img src={Good} /> <br />
                                    Good
                                </button>

                                <button>
                                    <img src={Great} /> <br />
                                    Great
                                </button>
                            </div>
                        </div> */}

                    <div className="section-history">
                      <h3>History</h3>
                      <div className="history-table rounded-1">
                        <table className="table">
                          {logs &&
                            logs
                              .map((log) => {
                                return (
                                  <tr>
                                    <td>{handleGetTime(log.timestamp, companyData.timezone)}</td>
                                    <td>
                                      <div className="clientName">
                                        <p
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#historyModal"
                                        >
                                          <span className="pill pill-grey me-1 fw-normal fs-12">
                                            {log?.accountId?.firstName ||
                                              log?.accountId?.lastName ? (
                                              <>
                                                {log?.accountId?.firstName &&
                                                  log?.accountId?.lastName ? (
                                                  log?.accountId?.firstName +
                                                  " " +
                                                  log?.accountId?.lastName
                                                ) : (
                                                  <>
                                                    {log?.accountId?.firstName
                                                      ? log?.accountId
                                                        ?.firstName
                                                      : log?.accountId
                                                        ?.lastName}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              log?.accountId?.email
                                            )}
                                          </span>
                                          {log?.eventDescription}
                                        </p>
                                      </div>
                                    </td>
                                    <td>{getTime(new Date(log.timestamp))}</td>
                                  </tr>
                                );
                              })
                              .reverse()}
                        </table>

                        <a
                          className="btnShowmore"
                          onClick={() =>
                            navigate(`/logs?resource=Order&resource_id=${id}`)
                          }
                        >
                          Show All
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="ticketInfo">
                      <h4>General Info</h4>
                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                          <p>Order Id </p>
                        </div>
                        <div className="col-xxl-7 col-lg-7 col-md-6 col-8">
                          <p>{order.orderId} </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                          <p>Invoice Id </p>
                        </div>
                        <div className="col-xxl-7 col-lg-7 col-md-6 col-8">
                          <p>{order.invoiceId} </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                          <p> Client Name</p>
                        </div>
                        <div
                          className="col-xxl-7 col-lg-7 col-md-6 col-8"
                          onClick={() => {
                            navigate(`/view-client/${order.userId?._id}`);
                          }}
                        >
                          <p className="infoText" style={{ cursor: "pointer" }}>
                            {order.userId && order.userId.firstName
                              ? order.userId.firstName +
                              " " +
                              order.userId.lastName
                              : order.userId && order.userId.email}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                          <p>Service </p>
                        </div>
                        <div
                          className="col-xxl-7 col-lg-7 col-md-6 col-8"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(
                              `/edit-service/${order && order.product && order.product._id
                              }`
                            );
                          }}
                        >
                          <p className="infoText">
                            {order && order.product && order.product.name}
                          </p>
                        </div>
                      </div>
                      {order && order.cc && order.cc.length > 0 && (
                        <div className="row">
                          <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                            <p>CC </p>
                          </div>
                          <div
                            className="col-xxl-7 col-lg-7 col-md-6 col-8"
                            style={{ cursor: "pointer" }}
                          >
                            {order &&
                              order.cc.map((item) => {
                                return (
                                  <p
                                    className="infoText"
                                    onClick={() => {
                                      navigate(`/view-client/${item?._id}`);
                                    }}
                                  >
                                    {item.email}
                                  </p>
                                );
                              })}
                          </div>
                        </div>
                      )}
                      {order && order.invoiceId && (
                        <div className="row">
                          <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                            <p>Invoice </p>
                          </div>
                          <div className="col-xxl-7 col-lg-7 col-md-6 col-8">
                            <p>{order.invoiceId} </p>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                          <p>Origin </p>
                        </div>
                        <div className="col-xxl-7 col-lg-7 col-md-6 col-8">
                          <p>{order.origin} </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                          <p>Created </p>
                        </div>
                        <div className="col-xxl-7 col-lg-7 col-md-6 col-8">
                          <p>
                            {formatMonthNameAndDate(
                              order.createdDate,
                              companyData.timezone
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-5 col-lg-5 col-md-6 col-4">
                          <p>Started </p>
                        </div>
                        <div className="col-xxl-7 col-lg-7 col-md-6 col-8">
                          <p>
                            {formatMonthNameAndDate(
                              order.createdDate,
                              companyData.timezone
                            )}
                          </p>
                        </div>
                      </div>
                      {order && order.otherItems && (
                        <div className="row">
                          <div className="col-lg-5 col-md-6 col-4">
                            <p> Other Items</p>
                          </div>
                          <div
                            className="col-lg-7 col-md-6 col-8"
                            onClick={() => {
                              const orderId = order.orderId.split("_");
                              navigate(`/orders/`, {
                                state: { orderId: orderId[0] },
                              });
                            }}
                          >
                            <p
                              className="infoText"
                              style={{ cursor: "pointer" }}
                            >
                              {order.otherItems} items
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="order-tags">
                      <div className="form-group">
                        <label>Tags</label>
                        <div className="input-group mb-3 position-relative">
                          <input
                            className="form-control"
                            placeholder="New Tag"
                            value={newTag}
                            aria-label="New Tag"
                            aria-describedby="basic-addon2"
                            onChange={(e) => {
                              setNewTag(e.target.value);
                              if (e.target.value) {
                                getFilteredTags(e.target.value);
                              }
                            }}
                          />
                          {filterTags && (
                            <ul className="dataDropdown">
                              {filterTags.length > 0 &&
                                filterTags.map((tag) => {
                                  return (
                                    <li
                                      onClick={(e) => {
                                        if (
                                          !order.tags.find(
                                            (value) => value == tag.name
                                          )
                                        ) {
                                          order.tags.push(tag.name);
                                          updateOrder({ tags: order.tags });
                                          setFilterTags();
                                          setNewTag("");
                                        }
                                      }}
                                    >
                                      {tag.name}
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                          <button
                            className="input-group-text tagsButton"
                            id="basic-addon2"
                            onClick={() => {
                              if (
                                newTag.length > 0 &&
                                !order.tags.find((tag) => tag == newTag)
                              ) {
                                addTag();
                              }
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      <div className="added-tags">
                        {order.tags.length > 0 &&
                          order.tags.map((tag) => {
                            return (
                              <a
                                onClick={() => {
                                  let index = order.tags.findIndex(
                                    (value) => value == tag
                                  );
                                  order.tags.splice(index, 1);

                                  updateOrder({ tags: order.tags });
                                }}
                              >
                                {tag}{" "}
                                <span className="cencelTag">
                                  <i class="fa-solid fa-xmark"></i>
                                </span>
                              </a>
                            );
                          })}
                      </div>
                    </div>

                    {files && files.length > 0 && (
                      <div className="ticketInfo">
                        <h4>Files</h4>
                        <ul>
                          {files.map((file) => {
                            return (
                              <li>
                                <a href={file.url} target="_blank">
                                  {file.fileName}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* history modal */}
      <div
        className="modal fade history-modal"
        id="historyModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Logs
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <div className="form-group mb-4">
                  <label>Time</label>
                  <p>Sep 19th at 12:37 pm BST</p>
                </div>

                <div className="form-group mb-4">
                  <label>User</label>
                  <p>
                    <a href="">Client Name</a>
                  </p>
                </div>

                <div className="form-group mb-4">
                  <label>Order</label>
                  <p>
                    <a href="">6B3500E7</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* history modal */}

      {/* merge modal */}
      <div
        className="modal fade history-modal"
        id="mergeModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Order
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group mb-2">
                    <label>Title </label>
                    <input type="text" className="form-control" />
                  </div>

                  <div className="form-group mb-2">
                    <label>Title </label>
                    <select className="form-control">
                      <option>Service Name</option>
                      <option>Service Name</option>
                      <option>Service Name</option>
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label>Client </label>
                    <select className="form-control">
                      <option>Client Name</option>
                      <option>Client Name</option>
                      <option>Client Name</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* merge modal */}

      {/* publish content modal */}
      <div
        className="modal fade history-modal"
        id="publishModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Publish Content
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group mb-2">
                    <label>Content </label>
                    <textarea className="form-control"></textarea>
                  </div>

                  <div className="form-group mb-2">
                    <label>Status </label>
                    <select className="form-control">
                      <option>Status</option>
                      <option>Status</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* publish content modal */}

      {/* delete modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Are you sure you want to delete Order{" "}
                  <strong> #{order.orderId}</strong> along with all its
                  messages, attachments, and project information?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={deleteOrder}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal */}

      {/* delete message modal */}
      <div
        className="modal fade history-modal"
        id="deletemessageModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>Are you sure you want to delete this messages?</p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete message modal */}

      {/* heading modal */}
      <div
        className="modal fade history-modal"
        id="headingModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>Order title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={orderSubject}
                      onChange={(e) => setOrderSubject(e.target.value)}
                    />
                    <small>
                      Default order title is the service name. You can rename
                      the order to make it easier to find.
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                value={order.title}
                onClick={(e) => updateOrder({ title: orderSubject })}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* heading modal */}

      {/* cc modal */}
      <div
        className="modal fade history-modal"
        id="ccModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>CC</label>
                    {/* <input
                      type="text"
                      className="form-control"
                      value={order.cc}
                      onChange={(e) => {
                        setOrder((prevData) => {
                          return { ...prevData, cc: [e.target.value] };
                        });
                      }}
                    /> */}
                    <Select
                      className="my-react-select my-react-select--compact"
                      isMulti
                      options={ccClient}
                      placeholder="Add CC"
                      value={ccUser}
                      onChange={(e) => {
                        setCcUser(e);
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length > 6) {
                          let status = String(e.target.value)
                            .toLowerCase()
                            .match(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            );
                          if (status != null) {
                            let data = {
                              label: e.target.value,
                              value: "newCC",
                            };
                            ccUser.push(data);
                            setCcUser(ccUser);
                          } else {
                            toastMessage("error", "Invalid Email Address");
                          }
                        }
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  if (ccUser.length > 0) {
                    CheckNewCC();
                  } else {
                    updateOrder({
                      cc: [],
                    });
                  }
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* cc modal */}
    </div>
  );
};
export default ViewOrders;

import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
// import "../../assets/css/custom.css";
import "../../assets/css/customNew.css";
import forgotImg from "../../assets/img/forgot-password.png";
import { useFormik } from "formik";
import * as Yup from "yup";

import { AllContext } from "../../context";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const { toastMessage, allApiUrl, apiPostMethod } = context;
  const { REQUEST_RESET_EMAIL } = allApiUrl;
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });
  const initialValues = {
    email: "",
  };
  const [isResetPasswordAlertVisible, setIsResetPasswordAlertVisible] =
    useState(false);
  function handleFormSubmit(values) {
    const emailAddress = {
      email: values.email,
    };
    apiPostMethod(REQUEST_RESET_EMAIL, emailAddress, {})
      .then((res) => {
        if (res.status == 200) {
          toastMessage("success", res.data.message);
          setIsResetPasswordAlertVisible(true);
        }
      })
      .catch((err) => {
        toastMessage("warn", "Email address not found.");
        console.log("error");
      });
  }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  return (
    <main className="wrapper">
      <div className="section-login">
        <div className="container">
          <div className="row h-100vh mx-0 align-items-center">
            <div className="col-lg-6 formOrder">
              <div className="loginForm">
                <a className="brandName">AgencyCRM</a>
                {/* <h3>Account recovery</h3> */}
                <form className="formLogin" onSubmit={handleSubmit}>
                <h3>Account Recovery</h3>
                  <div className="formInner">
                    {isResetPasswordAlertVisible && (
                      <p>Reset successful, please check your email.</p>
                    )}
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                        onChange={handleChange}
                        value={values.email}
                        required
                      />
                      <label for="floatingInput">Email</label>
                    </div>
                    <div className="input-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-submit"
                      >
                        Send recovery link
                      </button>
                    </div>
                    <a
                      onClick={() => navigate("/login")}
                      className="forgotLink d-block mt-3 text-start"
                    >
                      Sign In ?
                    </a>
                  </div>
                </form>
                <p className="copyrightText">© AgencyCRM. All Rights Reserved. </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="loginImg">
                <img src={forgotImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default ForgotPassword;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";
import { AllContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";

const Email = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { SENDGRID } = allApiUrl;

  const [publicKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    getEmailCredential();
  }, []);

  function getEmailCredential() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(SENDGRID, "", header)
      .then((res) => {
        setPublicKey(res.data.keys[0].publicKey);
        setPrivateKey(res.data.keys[0].privateKey);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    const data = {
      publicKey: publicKey,
      privateKey: privateKey,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(SENDGRID, data, header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage("success", res.data.message);
          navigate("/email");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }

  const navigate = useNavigate();
  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />

      <div className="setting-form-section pt-3 orders-section horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="section-back ">
              <a href="/settings">
                <i class="fa-solid fa-angle-left"></i> Settings
              </a>
            </div>
            <div className="col-xxl-5 col-lg-7 mx-auto">
              <div className="setting-form">
                <h3 className="tags-heading fs-4">SendGrid</h3>
                <div className="commonForm rounded-1">
                  <div className="form-group">
                    <label>Secret Key</label>
                    <input
                      type="text"
                      className="form-control"
                      value={publicKey}
                      placeholder="E.G. Ahu6bebbB5s4d"
                      onChange={(e) => setPublicKey(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label>API Key</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="E.G. Chu6be4bB6sd"
                      value={privateKey}
                      onChange={(e) => setPrivateKey(e.target.value)}
                    />
                  </div>
                </div>
                <div className="btn-section text-end">
                  <button
                    className="btn btn-save"
                    onClick={(e) => handleFormSubmit(e)}
                  >
                    Save
                  </button>
                </div>
              </div>

              {/* <div className="setting-form mt-5">
                        <h3 className="tags-heading">Incoming emails</h3>
                        <div className="commonForm">
                            <div className="form-group">
                                <label>Reply email</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        
                    </div> */}

              <div className="setting-form mt-5 mb-5">
                <h3 className="tags-heading fs-4">Account notifications</h3>
                <div className="commonForm rounded">
                  <div className="table-responsive emailTable">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/client_welcome"
                              )
                            }
                          >
                            <a href="">Welcome email</a>
                          </td>
                          <td>
                            Sent to new users when their account is created.
                          </td>
                        </tr>

                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/client_password_reset"
                              )
                            }
                          >
                            <a href="">Password reset</a>
                          </td>
                          <td>
                            Sent to users when they request a password reset.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="setting-form mt-5 mb-5">
                <h3 className="tags-heading fs-4">Payment notifications</h3>
                <div className="commonForm rounded">
                  <div className="table-responsive emailTable">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/client_order_incomplete"
                              )
                            }
                          >
                            <a href="">Abandoned checkout</a>
                          </td>
                          <td>
                            Sent to client 24 hours after they've abandoned
                            checkout.
                          </td>
                        </tr>

                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/payment_receipt"
                              )
                            }
                          >
                            <a href="">Payment receipt</a>
                          </td>
                          <td>Sent to client when they complete a purchase.</td>
                        </tr>
                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/subscription_payment_receipt"
                              )
                            }
                          >
                            <a href="">Subscription payment receipt</a>
                          </td>
                          <td>
                            Sent to client when their subscription is charged.
                          </td>
                        </tr>
                        {/* <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/subscription_failed"
                              )
                            }
                          >
                            <a href="">Subscription failed</a>
                          </td>
                          <td>
                            Sent to client if their subscription payment fails.
                          </td>
                        </tr> */}
                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/subscription_cancelled"
                              )
                            }
                          >
                            <a href="">Subscription cancelled</a>
                          </td>
                          <td>
                            Sent to client when their subscription is canceled.
                          </td>
                        </tr>

                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/subscription_failed"
                              )
                            }
                          >
                            <a href="">Subscription failed</a>
                          </td>
                          <td>
                            Sent to team members with access to the subscription
                            invoice. .
                          </td>
                        </tr>
                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/payment_refunded"
                              )
                            }
                          >
                            <a href="">Payment refunded</a>
                          </td>
                          <td>
                            Sent to client when their payment is refunded.
                          </td>
                        </tr>
                        <tr>
                          <td
                            onClick={() =>
                              navigate("/settings/email/template/new_invoice")
                            }
                          >
                            <a href="">New invoice</a>
                          </td>
                          <td>
                            Sent to client when a new unpaid invoice is issued
                            in their account.
                          </td>
                        </tr>
                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/upcoming_payment_reminder"
                              )
                            }
                          >
                            <a href="">Upcoming payment reminder</a>
                          </td>
                          <td>
                            Sent to client a week before their subscription is
                            charged if last charge was over 6 months ago.
                          </td>
                        </tr>
                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/unpaid_invoice_reminder"
                              )
                            }
                          >
                            <a href="">Unpaid invoice reminder</a>
                          </td>
                          <td>
                            Sent to client when they have an overdue invoice in
                            their account.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="setting-form mt-5 mb-5">
                <h3 className="tags-heading fs-4">Order notifications</h3>
                <div className="commonForm rounded">
                  <div className="table-responsive emailTable">
                    <table className="table">
                      <tbody>
                        {/* <tr>
                          <td
                            onClick={() =>
                              navigate("/settings/email/template/order_updated")
                            }
                          >
                            <a href="">Order updated</a>
                          </td>
                          <td>
                            Sent to client when they receive a new order
                            message.
                          </td>
                        </tr> */}

                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/intake_form_reminder"
                              )
                            }
                          >
                            <a href="">Intake form reminder</a>
                          </td>
                          <td>
                            Sent to client if they haven't filled out their
                            project data within 24 hours.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="setting-form mt-5 mb-5">
                <h3 className="tags-heading fs-4">Internal notifications</h3>
                <div className="commonForm rounded">
                  <div className="table-responsive emailTable">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/order_needs_attention"
                              )
                            }
                          >
                            <a href="">Order needs attention</a>
                          </td>
                          <td>Sent to team when order is assigned.</td>
                        </tr>

                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/order_data_submitted"
                              )
                            }
                          >
                            <a href="">Order data submitted</a>
                          </td>
                          <td>
                            Sent to order followers when client has filled out
                            their project data.
                          </td>
                        </tr>
                        {/* <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/order_canceled"
                              )
                            }
                          >
                            <a href="">Order canceled</a>
                          </td>
                          <td>
                            Sent to order followers when order is canceled or
                            refunded.
                          </td>
                        </tr> */}
                        {/* <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/recurring_payment"
                              )
                            }
                          >
                            <a href="">Recurring payment</a>
                          </td>
                          <td>
                            Sent to order followers when a subscription order is
                            re-opened on payment.
                          </td>
                        </tr> */}
                        {/* <tr>
                          <td
                            onClick={() =>
                              navigate("/settings/email/template/invoice_paid")
                            }
                          >
                            <a href="">Invoice paid</a>
                          </td>
                          <td>
                            Sent to team members with access to the invoice.
                          </td>
                        </tr>

                        <tr>
                          <td
                            onClick={() =>
                              navigate(
                                "/settings/email/template/subscription_failed"
                              )
                            }
                          >
                            <a href="">Subscription failed</a>
                          </td>
                          <td>
                            Sent to team members with access to the subscription
                            invoice. .
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* tags Modal */}
      <div
        className="modal fade tags-modal"
        id="tagsModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form className="commonForm">
                <div className="form-group">
                  <label>Tag name</label>
                  <input
                    type="text"
                    placeholder="Tag Name"
                    className="form-control"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Add tag
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Email;

import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/dummy-avatar.jpeg";
import noData from "../../assets/img/nodata-img.svg";
import { AllContext } from "../../context";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import PageLoader from "../../components/pageLoader";

const Services = () => {
  const dispatch = useDispatch();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiDeleteMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { LIST_SERVICES, SERVICES } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const [loader, setLoader] = useState(false);
  const [services, setServices] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [bulkDeleteId, setBulkDeleteId] = React.useState([]);
  const [serviceId, setServiceId] = useState();
  const [serviceName, setServiceName] = useState();
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [limit, setPageLimit] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [initialLoading, setInitialLoading] = useState(true);
  const editService = async (_id) => {
    navigate("/edit-service/" + _id);
  };

  function deleteService(serviceId) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(SERVICES, serviceId, header)
      .then((res) => {
        handleToClose();
        getAllServices();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  const handleToClose = () => {
    setOpen(false);
  };
  function getAllServices() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(
      LIST_SERVICES + "/" + itemOffset + "&" + itemsPerPage,
      "",
      header
    )
      .then((res) => {
        var getServicesList = res.data.docs;
        var servicesCount = res.data.totalServices;
        setCurrentPage(res.data.currentPage);
        setPageCount(Math.ceil(servicesCount / itemsPerPage));
        setPageLimit(res.data.limit || itemsPerPage);
        setServices(getServicesList);

        // setIsLoading(false);
        setInitialLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getAllServices(itemOffset);
  }, [itemOffset, pageCount, itemsPerPage]);

  function deleteMultiple() {
    bulkDeleteId.map((row) => {
      deleteService(row);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }

  function checkedAll() {
    if (services.length == bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = services.map((e) => {
        return e._id;
      });
      setBulkDeleteId(ids);
    }
  }

  function deleteIdList(id, status) {
    if (status) {
      setBulkDeleteId([id]);
    } else {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        bulkDeleteId.push(id);
        setBulkDeleteId(bulkDeleteId);
      } else {
        let ids = bulkDeleteId.splice(exist + 1, 1);
        setBulkDeleteId(ids);
      }
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }

  return (
    <div>
      <Sidebar
        activePage="services"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6">
                <div className="heading">
                  <h2 className="fs-4">Services</h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-filter">
                  <div className="dropdown">
                    <a
                      className="add-btn"
                      href=""
                      onClick={() => navigate("/add-service")}
                    >
                      Add Service
                    </a>
                    {/* <button
                    className="btn btn-import"
                    data-bs-toggle="modal"
                    data-bs-target="#importModal"
                  >
                    Import
                  </button> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="common-table clients-table mt-3 files-table rounded-1">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="ps-4 w65-px">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="item1"
                              checked={
                                services.length == bulkDeleteId.length &&
                                services.length > 0
                              }
                              onChange={() => checkedAll()}
                            />
                            <label
                              className="form-check-label"
                              for="item1"
                            ></label>
                          </div>
                        </th>
                        <th>Name</th>
                        {/* <th>Description</th> */}
                        <th>PRICE</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* no record found */}
                      {/* <tr>
                      <td colSpan={7}>
                        <div className="noData-section">
                          <img src={noData} />
                          <p>No Data Found</p>
                        </div>
                      </td>
                    </tr> */}
                      {/* no record found */}
                      {services.map((value) => {
                        return (
                          <tr>
                            <td className="ps-4 w65-px">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={value._id}
                                  checked={bulkDeleteId.find(
                                    (e) => e == value._id
                                  )}
                                  onClick={(e) => {
                                    setIsSingleChecked(e.target.checked);
                                    deleteIdList(
                                      value._id,
                                      services.length == bulkDeleteId.length
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for={value._id}
                                ></label>
                              </div>
                            </td>
                            <td>
                              <a onClick={() => editService(value._id)}>
                                {value.name}
                              </a>
                            </td>
                            {/* <td>{value.description}</td> */}
                            {/* <td>
                              {" "}
                              <div
                                className="serviceDescription"
                                dangerouslySetInnerHTML={{
                                  __html: value.description,
                                }}
                              ></div>
                            </td> */}
                            <td>
                              <span>${value.price}</span>
                            </td>
                            <td className="text-end pe-4">
                              <div className="dropdown">
                                <button
                                  className="btn btn-secondary dropdown-toggle btn-info"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      // href="/add-service"
                                      onClick={() => editService(value._id)}
                                    >
                                      Edit
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                      onClick={() => {
                                        setOpen(true);
                                        setServiceId(value._id);
                                        setServiceName(value.name);
                                      }}
                                    >
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      {services && services.length <= 0 && (
                        <tr>
                          <td colSpan={7}>
                            <div className="noData-section">
                              <img src={noData} />
                              <p>No Data Found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="sectionPagination">
                  <NoOfRows />
                  <ReactPaginate
                    nextLabel={<i class="fa-solid fa-angles-right"></i>}
                    previousLabel={<i class="fa-solid fa-angles-left"></i>}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination paginate"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
                {(bulkDeleteId.length > 0 || isSingleChecked) && (
                  <div className="table-bottom-option">
                    <div className="row">
                      <div className="col-lg-3 col-md-4">
                        <div className="input-group">
                          <select
                            id="selectOption"
                            value={selectOption.target}
                            onChange={(e) => selectOption(e)}
                            className="form-control"
                          >
                            <option value="">With Selected...</option>
                            <option value="Delete">Delete</option>
                          </select>
                          <button
                            className="btn btn-apply"
                            onClick={() => {
                              if (selectedOption == "Delete") {
                                deleteMultiple();
                              }
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* delete modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p className="mb-0">
                  Are you sure you want to delete this service:{" "}
                  <strong>{serviceName}</strong>?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => deleteService(serviceId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal */}

      {/* import modal stasts */}
      <div
        className="modal fade add-order-modals"
        id="importModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Import services
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-order-form">
                <form>
                  <div className="form-group">
                    <label>Select a csv file to import</label>
                    <div className="upload-file">
                      <i className="fa-solid fa-file-arrow-up"></i>
                      <p>
                        <span>Upload a file</span> or drag and drop
                      </p>
                      <input className="inputfile" type="file" />
                    </div>
                    <small>
                      Download the{" "}
                      <a target="_blank" href="">
                        import template here.
                      </a>
                    </small>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary close-btn"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Preview
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* import modal ends */}
    </div>
  );
};
export default Services;

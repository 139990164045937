import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../widgets/sidebar";
import { AllContext } from "../../../context";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../widgets/header";
import DummyAvatar from "../../../assets/img/dummy-avatar.jpeg";
import originalTimeZone from "../../../api/timezone.json";
import { Select } from "antd";
import { allApiUrl } from "../../../api/apiRoute";
import { saveCompanyData } from "../../../redux/action/login";
import { apiGetMethod, apiPostMethod } from "../../../api/rest";
import toastMessages from "../../toastMessages";
const { Option } = Select;
const Company = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [timezone, setTimeZone] = useState([])
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [form, setForm] = useState({
    timezone: {},
  });
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    fetchCompanyDetails()
    let expandedTimeZone = [];
    originalTimeZone.map((data) => {
      let dataClone = { ...data };
      data.utc.map((utc) => {
        let newTimeZoneData = { ...dataClone };
        newTimeZoneData.utc = utc;
        expandedTimeZone.push(newTimeZoneData);
      });
    });
    console.log('------25-----', expandedTimeZone)
    setTimeZone(expandedTimeZone);

  }, [])
  function fetchCompanyDetails() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    // setLoader(true);
    apiGetMethod(allApiUrl.COMPANY, "", header).then((res) => {
      if (res.status === 200) {
        setForm(res.data);
        const companyDetail = { ...res.data };
        dispatch(saveCompanyData(companyDetail));
      } else {
        console.log("error in apis==>", res);
      }
    });
  }
  function submitForm() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    // setLoader(true);
    apiPostMethod(allApiUrl.COMPANY, form, header).then((res) => {
      console.log("this is the response ====>", res);
      if (res.status == 201) {
        toastMessages("success", res.data.message);
        fetchCompanyDetails();
      }
    });
  }
  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <div className="setting-tab">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-content">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <div
                      className="section-back"
                      onClick={() => navigate("/settings")}
                    >
                      <a href="javascript:void(0);" className="nav-link">
                        <i className="fa-solid fa-chevron-left"></i> Settings
                      </a>
                    </div>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => navigate("settings/company/")}
                  >
                    <button
                      className="nav-link active"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Company
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => navigate("/settings/team")}
                  >
                    <button
                      className="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Team
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-back pl-240 mt-3">
        <a href="/settings">
          <i className="fa-solid fa-chevron-left"></i> Settings
        </a>
      </div>

      <div className="setting-form-section mt-5">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-xxl-5 col-lg-8 mx-auto">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div className="setting-form">
                    <form className="commonForm">
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group">
                            <label>Timezone</label>
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              placeholder="Search to Select"
                              value={form.timezone}
                              onChange={(e) => {
                                setForm((prevData) => {
                                  prevData.timezone = e;
                                  return { ...prevData };
                                });
                              }}
                            >
                              {timezone.map((data, idx) => {
                                return (
                                  <Option
                                    key={idx}
                                    value={JSON.stringify(data)}
                                  >
                                    {data.utc}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="btn-section text-end">
                      <button
                        className="btn btn-save"
                        onClick={() => submitForm()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Company;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllContext } from "../../context";

import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { ViewCartItem } from "../../components/ViewCart";
import {
  // saveAddToCartInformation,
  savePublicInvoice,
} from "../../redux/action/login";
import PageLoader from "../../components/pageLoader";
const ViewCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    toastMessage,
    redirectToLogin,
    apiPostMethod,
    apiGetMethod,
    apiDeleteMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [addToCartList, setAddToCartList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isHaveCoupon, setisHaveCoupon] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState({});
  const [currCoupon, setCurrCoupon] = useState("");
  const [couponCodeAppliedSuccessfully, setCouponCodeAppliedSuccessfully] =
    useState(false);
  const [totalDiscountOffered, setTotalDiscountOffered] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  useEffect(() => {
    getAddToCartList();
  }, []);
  async function getAddToCartList() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.CART, "", header);
      if (response.status === 200) {
        // if (!userInfo) {
        //   await dispatch(saveAddToCartInformation(true));
        // }
        if (response.data.couponData._id) {
          setCouponCode(response.data.cart[0].coupon);
          setCurrCoupon(response.data.cart[0].coupon);
          setisHaveCoupon(true);
          setCouponData(response.data.couponData);
          let fixedAmount = response.data.couponData.fixedAmount;
          response.data.couponData.applies_to.forEach((name) => {
            if (!Array.isArray(name.service) || !name.service.length) {
              response.data.cart[0]?.product.forEach((product, index) => {
                // if (item === product.id._id) {
                if (fixedAmount) {
                  response.data.cart[0].product[index].discountPrice =
                    response.data.cart[0].product[index].price - name.discount;
                  if (response.data.cart[0].product[index].discountPrice < 0) {
                    response.data.cart[0].product[index].discountPrice = 0;
                  }
                } else {
                  response.data.cart[0].product[index].discountPrice =
                    response.data.cart[0].product[index].price -
                    (
                      (name.discount / 100) *
                      response.data.cart[0].product[index].price
                    ).toFixed(2);
                  if (response.data.cart[0].product[index].discountPrice < 0) {
                    response.data.cart[0].product[index].discountPrice = 0;
                  }
                }
                // }
              });
            } else {
              name.service.forEach((item) => {
                response.data.cart[0]?.product.forEach((product, index) => {
                  if (item === product.id._id) {
                    // if()
                    if (fixedAmount) {
                      response.data.cart[0].product[index].discountPrice =
                        response.data.cart[0].product[index].price -
                        name.discount;
                      if (
                        response.data.cart[0].product[index].discountPrice < 0
                      ) {
                        response.data.cart[0].product[index].discountPrice = 0;
                      }
                    } else {
                      response.data.cart[0].product[index].discountPrice =
                        response.data.cart[0].product[index].price -
                        (
                          (name.discount / 100) *
                          response.data.cart[0].product[index].price
                        ).toFixed(2);
                      if (
                        response.data.cart[0].product[index].discountPrice < 0
                      ) {
                        response.data.cart[0].product[index].discountPrice = 0;
                      }
                    }
                  }
                });
              });
            }
          });
          setAddToCartList(response.data.cart[0]);
          setCouponCodeAppliedSuccessfully(true);
        } else {
          setAddToCartList(response.data.cart[0]);
        }
      }
      setInitialLoading(false);
      // setIsLoading(false);
    } catch (err) {
      console.log("error", err.data.message);
      // redirectToLogin(dispatch, navigate, err);
      // dispatch(saveAddToCartInformation(true));
      setInitialLoading(false);
      // setIsLoading(false);
      if (err.data.message === "Cart is empty") {
        setAddToCartList([]);
      }
      // toastMessage("error", err.data?.message);
    }
  }
  async function updateQtyFunc(value) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const data = {
        product: {
          id: value.id._id,
          price: value.price,
          name: value.id.name,
          quantity: parseInt(value.quantity),
        },
        addToCart: false,
      };
      if (parseInt(value.quantity) == 0 || value.quantity === "") {
        deleteHandler(value.id._id);
      } else {
        const response = await apiPostMethod(allApiUrl.CART, data, header);
        if (response.status === 200) {
          getAddToCartList();
        }
      }
    } catch (err) {
      // redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data?.message);
    }
  }
  async function deleteHandler(productId) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiDeleteMethod(allApiUrl.CART, productId, header);
      if (response.status === 200) {
        getAddToCartList();
        setIsLoading(false);
      }
    } catch (err) {
      // redirectToLogin(dispatch, navigate, err);
      getAddToCartList();
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  async function applyCouponHandler() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {
      couponCode,
    };
    try {
      const response = await apiPostMethod(
        allApiUrl.CLIENT_COUPON,
        data,
        header
      );
      if (response.status === 200) {
        setCouponList(response.data);
        setCouponStatus(false);
        getAddToCartList();
        if (response.data.message === "No coupon found") {
          setCouponCodeAppliedSuccessfully(false);
        }

        // await dispatch(
        //   saveAddToCartInformation({
        //     ...addToCartList,
        //     couponCodeAppliedSuccessfully: true,
        //     couponCode,
        //   })
        // );

        //   if (couponData.fixedAmount) {
        //     newPrice = product.id.price - coupon.discount;
        // } else {
        //     newPrice =
        //     product.id.price - ((coupon.discount / 100) * product.id.price).toFixed(2);
        // }
        // response.data.coupons[0].applies_to[0].service.forEach((item) => {
        //   addToCartList[0]?.product.forEach((product, index) => {
        //     if (item === product.id._id) {
        //       setAddToCartList((prevData) => {
        //         prevData[0].product[index].discountPrice =
        //           prevData[0].product[index].price -
        //           response.data.coupons[0].applies_to[0].discount;
        //         return [...prevData];
        //       });
        //     }
        //   });
        // });

        setIsLoading(false);
      }
    } catch (err) {
      // redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      if (
        err.data.message === "Invalid coupon code" ||
        err.data.message === "No coupon found" ||
        err.data.message === "Already Avail benefits" ||
        err.data.message === "Coupon not applicable on services" ||
        err.data.message === "Coupon expired" ||
        err.data.message === "Valid for new customer only"
      ) {
        setCouponStatus(true);
        setCouponErrMsg(err.data.message);
        setCouponCodeAppliedSuccessfully(false);
      }
      getAddToCartList();
      // toastMessage("error", err.data?.message);
    }
  }
  const [couponStatus, setCouponStatus] = useState(false);
  const [couponErrMsg, setCouponErrMsg] = useState("");
  useEffect(() => {
    if (
      addToCartList &&
      addToCartList.product &&
      addToCartList.product.length > 0
    ) {
      let total = addToCartList?.product.map((item) => {
        if (item.discountPrice || item.discountPrice === 0) {
          return item.discountPrice * item.quantity;
        } else {
          return item.price * item.quantity;
        }
      });

      setGrandTotal(
        total.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
      );
    } else {
      setGrandTotal(0);
    }
    savedOnDiscountHandler(addToCartList?.product);
  }, [addToCartList]);
  // const [disableCheckoutButton, setDisableCheckoutButton] = useState(false);
  async function checkoutHandler() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const newArray = addToCartList?.product.map(
      ({ discountPrice, ...keepAttrs }) => keepAttrs
    );
    const productItem = newArray.map((obj) => ({ ...obj, id: obj.id._id }));
    let data = {
      couponCode,
      // { id: "63451d2a9e82f10e4f596267", quantity: 5, price: 9 },
      // { id: "63353ae41efff3e5356875f8", quantity: 5, price: 9 },
      // { id: "63353aed1efff3e5356875fa", quantity: 1, price: 10 }

      product: productItem,
      type: "cart",
    };
    // if (currCoupon == couponCode) {
    try {
      // setDisableCheckoutButton(false);
      const response = await apiPostMethod(allApiUrl.CHECKOUT, data, header);
      if (response.status === 200) {
        // dispatch(saveAddToCartInformation(response.data));
        openInNewTab(response.data.url);
        setIsLoading(false);
      }
    } catch (err) {
      // redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data?.message);
    }
    // } else {
    //   setDisableCheckoutButton(true);
    //   setIsLoading(false);
    // }
  }
  function checkoutRedirect() {
    navigate("/payment", {
      state: {
        from: "cart",
      },
    });
  }
  function openInNewTab(url) {
    var win = window.open(url, "_self");
    win.focus();
  }
  const [particularDiscount, setParticularDiscount] = useState([]);
  function savedOnDiscountHandler(value) {
    let savedPriceOnDiscount = value?.map((item) => {
      if (item.discountPrice || item.discountPrice === 0) {
        // if (item.discountPrice === 0) return 0;
        return item.price * item.quantity - item.discountPrice * item.quantity;
      } else {
        return 0;
      }
    });
    setParticularDiscount(savedPriceOnDiscount);
    setTotalDiscountOffered(
      savedPriceOnDiscount?.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );
  }
  return (
    <div>
      <Sidebar
        activePage="shop"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="cart-modal viewCart-section p-4">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-xxl-12 mx-auto">
                <div className="section-back">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      navigate("/shop");
                    }}
                  >
                    <i class="fa-solid fa-angle-left"></i> Shop Articles
                  </a>
                </div>
              </div>
              <div className="col-xxl-12 mx-auto">
                <div className="heading mt-5">
                  <h2 className="fs-4">Shopping Cart</h2>
                </div>
                {/* <ViewCartItem
                addToCartList={addToCartList}
                couponCodeAppliedSuccessfully={couponCodeAppliedSuccessfully}
                setisHaveCoupon={setisHaveCoupon}
                isHaveCoupon={isHaveCoupon}
                setCouponCode={setCouponCode}
                couponCode={couponCode}
                setAddToCartList={setAddToCartList}
                grandTotal={grandTotal}
                updateQtyFunc={updateQtyFunc}
                deleteHandler={deleteHandler}
                applyCouponHandler={applyCouponHandler}
                discount={discount}
                navigate={navigate}
                setGrandTotal={setGrandTotal}
              /> */}
                <div className="cart-content mt-3 rounded-1">
                  {addToCartList && addToCartList?.product?.length > 0 ? (
                    <>
                      <table className="table">
                        <tbody>
                          {addToCartList &&
                            // addToCartList.length > 0 &&
                            addToCartList?.product.map((item, index) => {
                              return (
                                <tr>
                                  <td>
                                    <strong>{item?.id?.name}</strong> <br />
                                    {item.hasOwnProperty("discountPrice") &&
                                      couponCodeAppliedSuccessfully && (
                                        <del
                                          className="mb-0 mt-1"
                                          style={{
                                            color: "grey",
                                            fontZize: "0.8em",
                                          }}
                                        >
                                          {"$" + item?.price?.toFixed(2)}
                                        </del>
                                      )}
                                    <p
                                      className="mb-0 mt-1"
                                      style={{
                                        color: "grey",
                                        fontSize: "0.8em",
                                      }}
                                    >
                                      {"$" +
                                        `${
                                          item.hasOwnProperty(
                                            "discountPrice"
                                          ) && couponCodeAppliedSuccessfully
                                            ? item?.discountPrice?.toFixed(2)
                                            : item.id.oneTimeService == true
                                            ? item?.price?.toFixed(2)
                                            : item.price?.toFixed(2) +
                                              "/" +
                                              item.id.recurringOrder
                                                ?.durationPeriod
                                        }`}
                                    </p>
                                    {couponCodeAppliedSuccessfully && (
                                      <>
                                        {particularDiscount &&
                                        particularDiscount[index] > 0 ? (
                                          <div className="successMsg">
                                            Success! You save $
                                            {particularDiscount &&
                                              particularDiscount[index].toFixed(
                                                2
                                              )}
                                          </div>
                                        ) : (
                                          <div
                                            className="errorMessage"
                                            style={{
                                              color: "#f30d0d",
                                              backgroundColor:
                                                "rgb(242 209 209)",
                                            }}
                                          >
                                            This coupon is not applied on this
                                            service.
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    <div className="text-end cartQty">
                                      <a>
                                        <i className="fa-solid fa-xmark"></i>
                                      </a>
                                      <input
                                        className="rounded-1"
                                        type="text"
                                        onBlur={(e) => {
                                          updateQtyFunc(item);
                                        }}
                                        onChange={(e) => {
                                          setAddToCartList((prevData) => {
                                            prevData.product[index].quantity =
                                              e.target.value;
                                            return { ...prevData };
                                          });
                                        }}
                                        value={item.quantity}
                                      />
                                      <a
                                        style={{ color: "#201b50" }}
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                          deleteHandler(item?.id?._id);
                                        }}
                                      >
                                        Remove
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}

                          <tr>
                            <td>
                              {/* {(couponCodeAppliedSuccessfully ||
                              addToCartList[1] === true) && (
                              <>
                                  {totalDiscountOffered > 0 ? (
                                    <div
                                      style={{
                                        borderLeft: "6px solid #28a745",
                                        color: "#138c2f",
                                        marginTop: "1rem!important",
                                        backgroundColor: "#d4edda",
                                        borderColor: "#c3e6cb",
                                      }}
                                    >
                                      Success! You save $
                                      {totalDiscountOffered.toFixed(2)}
                                    </div>
                                ) : (
                                  <div
                                    style={{
                                      borderLeft: "6px solid red",
                                      color: "red",
                                    }}
                                  >
                                    {
                                      "This coupon is not applied on this service."
                                    }
                                  </div>
                                )}
                              </>
                            )} */}
                              <small
                                onClick={() => setisHaveCoupon(!isHaveCoupon)}
                                style={{
                                  cursor: "pointer",
                                  color: "grey",
                                  fontSize: "0.775em",
                                }}
                              >
                                Have a coupon ?
                              </small>

                              {isHaveCoupon && (
                                <div className="input-group col-lg-5">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      // if (e.target.value === "") {
                                      //   setCouponCodeAppliedSuccessfully(false);
                                      //   getAddToCartList();
                                      // }
                                      setCouponCode(e.target.value);
                                    }}
                                    value={couponCode}
                                  />
                                  <button
                                    className="input-group-text"
                                    id="basic-addon2"
                                    onClick={applyCouponHandler}
                                  >
                                    Apply
                                  </button>
                                </div>
                              )}
                              {couponStatus && (
                                <div
                                  className="mt-3 errorMessage"
                                  style={{
                                    color: "#f30d0d",
                                    backgroundColor: "rgb(242 209 209)",
                                  }}
                                >
                                  {couponErrMsg}
                                </div>
                              )}
                            </td>
                            <td>
                              <div className="text-end cartQty">
                                <strong>Total</strong>
                                <strong>${grandTotal?.toFixed(2)}</strong>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div></div>
                      <div className="modalBtns">
                        <div className="row">
                          <div
                            className="col-lg-6"
                            onClick={() => navigate("/shop")}
                          >
                            <a href="" onClick={() => {}}>
                              Continue Shopping
                            </a>
                          </div>
                          <div className="col-lg-6">
                            {grandTotal <= 0 ? (
                              <div
                                className="text-end text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                {
                                  "Minimum cart total should be greater than $ 1"
                                }
                              </div>
                            ) : (
                              <div className="text-end">
                                <button
                                  // disabled={checkOutButtonDisable}
                                  className="btn btn-primary"
                                  // onClick={checkoutHandler}
                                  onClick={checkoutRedirect}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#modalConfirmation"
                                >
                                  Checkout
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <small>No items in your cart yet...</small>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* checkout confirmation modal */}
      <div
        class="modal fade"
        id="modalConfirmation"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            {/* <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> */}
            <div class="modal-body">
              <div className="history-modal">
                <p className="text-center mb-0">
                  The coupon you enter is not applied Do you wish to continue.
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-primary">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewCart;

import React, { useContext, useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import DummyAvatar from "../../assets/img/dummyImg.jpeg";
import PageLoader from "../../components/pageLoader";
import noData from "../../assets/img/nodata-img.svg";

import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import { tabList } from "../../shared/constants";
import { apiDeleteMethod, apiPutMethod } from "../../api/rest";
import { formatMonthNameAndDate } from "../../shared/functions";
import ReactPaginate from "react-paginate";
import ReactTooltip from "react-tooltip";

const Orders = () => {
  //constants
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const { state } = useLocation();
  useEffect(() => {
    if (state && state.orderId) {
      getOrderListingOrderBy();
    }
  }, [state, state && state.orderId]);
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    allApiUrl,
    toastMessage,
    redirectToLogin,
    apiGetMethod,
    apiPostMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  //useState
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [currActiveTab, setCurrActiveTab] = useState("All");
  const [isReset, setIsReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientListArr, setClientListArr] = useState([]);
  const [serviceListArr, setServiceListArr] = useState([]);
  const [addClientList, setAddClientList] = useState({
    client: "",
    service: "",
  });
  const [orderAddedSuccessfully, setOrderAddedSuccessfully] = useState(false);
  const [services, setServices] = useState([]);
  const [isActive, setActive] = useState(false);
  const [isAssignedActive, setAssignedActive] = useState(false);
  const [isServiceActive, setServiceActive] = useState(false);
  const [isStatusActive, setIsStatusActive] = useState(false);
  const [isTagActive, setIsTagActive] = useState(false);
  const [assignedList, setAssignedList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [allTeam, setAllTeam] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [allClient, setAllClient] = useState([]);
  const [tags, setTags] = useState([]);
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [tablistArr, setTabListArr] = useState([]);
  const [serviceListArrBkup, setServiceListArrBkup] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    getAllClientList();
    getAllServices();
    getAllServicesList();
    getAllTeam();
    getClients();
    getTag();
  }, []);

  useEffect(() => {
    if (!(state && state.orderId)) {
      getOrderListing();
    }
  }, [orderAddedSuccessfully]);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  function clearCheckAll() {
    setItemOffset(0);
    setIsReset(!isReset);
  }
  //functions
  async function getOrderListingOrderBy() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.ORDERBYID + "/",
        state.orderId,
        header
      );
      if (response.status === 200) {
        setOrderList(response.data.order);
        let Status = [];
        response.data.order.forEach((item) => {
          Status.push(item.status);
        });

        const array = [...new Set([...Status])];
        array.push("all");
        setTabListArr(
          tabList.filter((e) => array.indexOf(e.toLowerCase()) !== -1)
        );
        // setIsLoading(false);
        setInitialLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      // setIsLoading(false);
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  async function getOrderListing() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.ORDER, "", header);
      if (response.status === 200) {
        setOrderList([...response.data.order]);
        let Status = [];
        response.data.order.forEach((item) => {
          Status.push(item.status);
        });

        const array = [...new Set([...Status])];
        array.push("all");
        setTabListArr(
          tabList.filter((e) => array.indexOf(e.toLowerCase()) !== -1)
        );
        setInitialLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  const getTag = () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.TAGS, "", header)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setTags(res.data.tags);
        }
      })
      .catch((err) => {
        // redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }

  function updateClient(Details) {
    if (clientList.find((row) => row.value == Details._id)) {
      let index = clientList.findIndex((row) => row.value == Details._id);
      clientList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      clientList.push(data);
    }
    setClientList(clientList);
    // filterTickets({ client: client });
  }
  const getClients = () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.GET_ALL_CLIENT, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAllClient(res.data.team);
          // setIsLoading(false);
        }
      })
      .catch((err) => {
        // redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  function listSelectedClient() {
    let selectedList = "";
    clientList.map((list, index) => {
      selectedList +=
        list.label + `${index !== clientList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function listSelectedStatus() {
    let selectedList = "";
    statusList.map((list, index) => {
      selectedList +=
        list.label + `${index !== statusList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function getAllTeam() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAllTeam(res.data.team);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }

  function updateTag(Details) {
    if (tagList.find((row) => row.label.name == Details.name)) {
      let index = tagList.findIndex((row) => row.label.name == Details.name);
      tagList.splice(index, 1);
    } else {
      let label = "";
      if (Details) {
        label = Details;
      }
      let data = {
        label: label,
        value: Details.name,
      };
      tagList.push(data);
    }
    setTagList(tagList);
  }

  function updateAssignedUser(Details) {
    if (assignedList.find((row) => row.value == Details._id)) {
      let index = assignedList.findIndex((row) => row.value == Details._id);
      assignedList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      assignedList.push(data);
    }
    setAssignedList(assignedList);
  }

  function updateService(Details) {
    if (serviceList.find((row) => row.value == Details._id)) {
      let index = serviceList.findIndex((row) => row.value == Details._id);
      serviceList.splice(index, 1);
    } else {
      let label = "";
      if (Details.name) {
        label = Details.name;
      }
      let data = {
        label: label,
        value: Details._id,
      };
      serviceList.push(data);
    }
    setServiceList(serviceList);
  }
  function updateStatus(Details) {
    if (statusList.find((row) => row.value == Details)) {
      let index = statusList.findIndex((row) => row.value == Details);
      statusList.splice(index, 1);
    } else {
      let label = "";
      if (Details) {
        label = Details;
      }
      let data = {
        label: label,
        value: Details,
      };
      statusList.push(data);
    }
    setStatusList(statusList);
  }

  function listSelectedAssignedUser() {
    let selectedList = "";
    assignedList.map((list, index) => {
      selectedList +=
        list.label + `${index !== assignedList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function listSelectedTag() {
    let selectedList = "";
    tagList.map((list, index) => {
      selectedList +=
        list.label.name + `${index !== tagList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function listSelectedService() {
    let selectedList = "";
    serviceList.map((list, index) => {
      selectedList +=
        list.label + `${index !== serviceList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  const filterTickets = () => {
    let count = 0;
    if (itemPerPageFilter && itemPerPageFilterApplied) {
      setItemsPerPage(itemPerPageFilter);
      count += 1;
    }
    const data = {};
    if (assignedList.length > 0) {
      let userlist = [...assignedList];
      userlist.map((list, index) => {
        userlist[index] = list.value;
      });
      data.assignedTo = userlist;
    }
    if (clientList.length > 0) {
      let clientSelectedList = [...clientList];
      clientSelectedList.map((list, index) => {
        clientSelectedList[index] = list.value;
      });
      data.client = clientSelectedList;
    }
    if (serviceList.length > 0) {
      let serviceSelectedList = [...serviceList];
      serviceSelectedList.map((list, index) => {
        serviceSelectedList[index] = list.value;
      });
      data.service = serviceSelectedList;
    }
    if (statusList.length > 0) {
      let statusSelectedList = [...statusList];
      statusSelectedList.map((list, index) => {
        statusSelectedList[index] = list.value.toLowerCase();
      });
      data.status = statusSelectedList;
    }
    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    if (tagList.length > 0) {
      let userlist = [...tagList];
      userlist.map((list, index) => {
        userlist[index] = list.value;
      });
      data.tags = userlist;
    }
    for (let key in data) {
      count += 1;
    }
    setFilterCount(count);
    apiPostMethod(allApiUrl.FILTER_ORDER, data, header)
      .then((res) => {
        if (res.status === 200) {
          setOrderList(res.data);
          let Status = [];
          res.data.forEach((item) => {
            Status.push(item.status);
          });
          const array = [...new Set([...Status])];
          array.push("all");
          setTabListArr(
            tabList.filter((e) => array.indexOf(e.toLowerCase()) !== -1)
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const ToggleAssignedClass = () => {
    setAssignedActive(!isAssignedActive);
    setIsTagActive(false);
    setIsStatusActive(false);
    setServiceActive(false);
    setActive(false);
  };
  const ToggleTagClass = () => {
    setAssignedActive(false);
    setIsTagActive(!isTagActive);
    setIsStatusActive(false);
    setServiceActive(false);
    setActive(false);
  };

  const ToggleStatusClass = () => {
    setAssignedActive(false);
    setIsTagActive(false);
    setIsStatusActive(!isStatusActive);
    setServiceActive(false);
    setActive(false);
  };

  const ToggleServiceClass = () => {
    setAssignedActive(false);
    setIsTagActive(false);
    setIsStatusActive(false);
    setServiceActive(!isServiceActive);
    setActive(false);
  };

  const ToggleClass = () => {
    setAssignedActive(false);
    setIsTagActive(false);
    setIsStatusActive(false);
    setServiceActive(false);
    setActive(!isActive);
  };

  function getAllServices() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.SERVICES, "", header)
      .then((res) => {
        setServices(res.data.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  async function getAllClientList() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.GET_ALL_CLIENT, "", header);
      if (response.status === 200) {
        let options = [];
        response.data.team.map((item) => {
          if (item.userId)
            options.push({
              value: item.userId._id,
              label: `${item.userId.firstName || " "} ${item.userId.lastName || ""
                } - ${item.userId.email}`,
            });
        });
        setClientListArr(options);
        // setIsLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      // setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  async function getAllServicesList() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.SERVICES + "/", "", header);
      if (response.status === 200) {
        let options = [];
        response.data.data.map((item) => {
          options.push({
            value: item._id,
            label: (
              <>
                {item.name || " "} {"\n"}
                <small>${item.price.toFixed(2)}</small>
              </>
            ),
            name: item.name,
          });
        });
        setServiceListArrBkup(options);
        setServiceListArr(options);
        // setIsLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      // setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  async function addOrderHandler() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const data = {
      userId: addClientList.client.value,
      productId: addClientList.service.value,
    };
    try {
      const response = await apiPostMethod(allApiUrl.ORDER, data, header);
      if (response.status === 200) {
        toastMessage("success", response.data.message);
        setOrderAddedSuccessfully(!orderAddedSuccessfully);
        setAddClientList({
          client: "",
          service: "",
        });
        setIsLoading(false);
        navigate(`/view-orders/${response.data.orderId}`, {
          state: {
            _id: response.data._id,
          },
        });
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  // Order Table
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [multiDeleteId, setMultiDeleteId] = useState([]);
  const [isChangeStatusVisible, setIsChangeStatusVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [orderStatus, setOrderStatus] = useState("pending");
  const [itemPerPageFilter, setItemPerPageFilter] = useState(10);
  useEffect(() => {
    setMultiDeleteId([]);
  }, [isReset, currActiveTab]);
  function deleteIdList(status, id) {
    if (status) {
      let exist = multiDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        multiDeleteId.push(id);
        setMultiDeleteId(multiDeleteId);
      }
    } else {
      let exist = multiDeleteId.findIndex((e) => e == id);
      let ids = multiDeleteId.splice(exist + 1, 1);
      setMultiDeleteId(ids);
    }
  }
  function selectOption(selected) {
    if (selected.target.value === "changeStatus") {
      setIsChangeStatusVisible(true);
    } else {
      setIsChangeStatusVisible(false);
    }
    setSelectedOption(selected.target.value);
  }
  function orderStatusOption(selected) {
    setOrderStatus(selected.target.value);
  }

  function deleteMultiple() {
    setIsLoading(true);
    multiDeleteId.map((row) => {
      if (row != undefined) {
        let header = {
          Authorization: `Bearer ${loginUserAccessToken}`,
        };
        apiDeleteMethod(allApiUrl.ORDER, row, header)
          .then((res) => {
            toastMessage("success", res.data.message);
            getOrderListing();
            setIsSingleChecked(false);
            setMultiDeleteId([]);
            setIsLoading(false);
          })
          .catch((err) => {
            redirectToLogin(dispatch, navigate, err);
            setIsLoading(false);
            toastMessage("error", err.data.message);
          });
      }
    });
  }
  function updateMultiple(status) {
    let data = {
      status: status,
    };
    setCurrActiveTab("All");
    multiDeleteId.map((row) => {
      if (row !== undefined) {
        updateTicket(row, data);
      }
    });
    setMultiDeleteId([]);
    setIsSingleChecked(false);
  }

  function updateTicket(id, data) {
    // if (data !== undefined) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(allApiUrl.EDIT_ORDER + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        setOrderAddedSuccessfully(!orderAddedSuccessfully);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
    // }
  }

  function checkedAll() {
    if (orderList.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = orderList.map((e) => {
        if (currActiveTab.toLowerCase() == "all") {
          return e._id;
        } else {
          if (e.status.toLowerCase() == currActiveTab.toLowerCase()) {
            return e._id;
          }
        }
      });
      // ids = ids.filter(function (element) {
      //   return element !== undefined;
      // });
      setMultiDeleteId(ids);
    }
  }
  //pagination
  const [itemOffset, setItemOffset] = useState(0);

  // let itemsPerPage = 10;
  let updated =
    currActiveTab == "All"
      ? orderList
      : orderList.filter(
        (item) => item.status.toLowerCase() == currActiveTab.toLowerCase()
      );

  const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
  const currentItemsAll = updated?.slice(itemOffset, endOffset);
  let pageCountOpen = Math.ceil(updated.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % updated.length;
    setItemOffset(newOffset);
  };
  const [itemPerPageFilterApplied, setItemPerPageFilterApplied] =
    useState(false);
  return (
    <div>
      <Sidebar
        activePage="orders"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />

      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6 col-6">
                <div className="heading">
                  <h2>Orders</h2>
                </div>
              </div>
              <div className="col-lg-6 col-6">
                <div className="section-filter">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle btn-filter"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-filter"></i> Filter |{" "}
                      {filterCount}
                    </button>
                    <ul className="dropdown-menu filter-drowdown">
                      <div className="filter-btns">
                        <button
                          className="btn btn-clear"
                          onClick={() => {
                            setIsLoading(true);
                            setFilterCount(0);
                            setClientList([]);
                            setServiceList([]);
                            setStatusList([]);
                            setTagList([]);
                            setAssignedList([]);
                            // setAssignedActive
                            setstartDateFilter(0);
                            setendDateFilter(0);
                            setShowDatePicker("");
                            setOrderAddedSuccessfully(!orderAddedSuccessfully);
                            getOrderListing();
                            setCurrActiveTab("All");
                            setItemsPerPage(10);
                            setItemPerPageFilterApplied(false);
                            setItemPerPageFilter(10);
                            setTimeout(() => {
                              setIsLoading(false);
                            }, 1000);
                          }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-update"
                          onClick={() => filterTickets()}
                        >
                          Update filters
                        </button>
                      </div>

                      <div
                        className="assignedUser Anyclient"
                        onClick={(e) => {
                          e.stopPropagation();
                          ToggleStatusClass();
                        }}
                      >
                        <p>
                          {statusList.length > 0
                            ? listSelectedStatus()
                            : "Any Status"}
                        </p>
                        <ul className={isStatusActive ? "active" : ""}>
                          {[
                            "Pending",
                            "Submitted",
                            "Working",
                            "Completed",
                            "Cancelled",
                            "Pending Update",
                            "Void",
                            "Info lacking",
                            "Preparing Draft",
                            "Draft for approval",
                            "Editor is reviewing draft",
                            "Edits are being made",
                            "Edits Done",
                            "Draft for publication",
                            "Order is done",
                            "Needs response",
                          ].map((value, index) => {
                            return (
                              <li onClick={() => updateStatus(value)}>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  {statusList.find(
                                    (row) => row.value == value
                                  ) && (
                                      <span
                                        class="fas fa-check check-mark"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                  <div>
                                    {value}
                                    <br />
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <div
                        className="assignedUser Anyclient"
                        onClick={(e) => {
                          e.stopPropagation();
                          ToggleServiceClass();
                        }}
                      >
                        <p>
                          {serviceList.length > 0
                            ? listSelectedService()
                            : "Any Service"}
                        </p>
                        <ul className={isServiceActive ? "active" : ""}>
                          {services.map((value, index) => {
                            return (
                              <li onClick={() => updateService(value)}>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  {serviceList.find(
                                    (row) => row.value == value._id
                                  ) && (
                                      <span
                                        class="fas fa-check check-mark"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                  <div>
                                    {value.name}
                                    <br />
                                    <small>
                                      {"$" + value.price.toFixed(2)}
                                    </small>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div
                        className="assignedUser Anyclient"
                        onClick={(e) => {
                          e.stopPropagation();
                          ToggleTagClass();
                        }}
                      >
                        <p>
                          {tagList.length > 0 ? listSelectedTag() : "Any Tag"}
                        </p>
                        <ul className={isTagActive ? "active" : ""}>
                          {tags.map((value, index) => {
                            return (
                              <li onClick={() => updateTag(value)}>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  {tagList.find(
                                    (row) => row.label.name == value.name
                                  ) && (
                                      <span
                                        class="fas fa-check check-mark"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                  <div>{value.name}</div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <div
                        className="assignedUser"
                        onClick={(e) => {
                          e.stopPropagation();
                          ToggleAssignedClass();
                        }}
                      >
                        <p>
                          {assignedList.length > 0
                            ? listSelectedAssignedUser()
                            : "Any Assigned User"}
                        </p>
                        <ul className={isAssignedActive ? "active" : ""}>
                          {allTeam.map((value, index) => {
                            return (
                              <li
                                onClick={() => updateAssignedUser(value.userId)}
                              >
                                <a className="dropdown-item">
                                  {assignedList.length > 0 &&
                                    assignedList.find(
                                      (row) => row.value == value.userId._id
                                    ) && (
                                      <span
                                        class="fas fa-check check-mark"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                  <div>
                                    {value.userId.firstName &&
                                      value.userId.lastName
                                      ? value.userId.firstName +
                                      "" +
                                      value.userId.lastName
                                      : value.userId.email}{" "}
                                    <br /> <small>{value.role}</small>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div
                        className="assignedUser Anyclient"
                        onClick={(e) => {
                          e.stopPropagation();
                          ToggleClass();
                        }}
                      >
                        <p>
                          {clientList.length > 0
                            ? listSelectedClient()
                            : "Any Client"}
                        </p>
                        <ul className={isActive ? "active" : ""}>
                          {allClient.map((value, index) => {
                            if (value.userId) {
                              return (
                                <li
                                  onClick={() => {
                                    updateClient(value.userId);
                                  }}
                                >
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    {clientList.length > 0 &&
                                      clientList.find(
                                        (row) => row.value == value.userId._id
                                      ) && (
                                        <span
                                          class="fas fa-check check-mark"
                                          aria-hidden="true"
                                        ></span>
                                      )}
                                    <div>
                                      {value.userId.firstName &&
                                        value.userId.lastName
                                        ? value.userId.firstName +
                                        "" +
                                        value.userId.lastName
                                        : value.userId.email}{" "}
                                      <br /> <small>{value.role}</small>
                                    </div>
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                      <select
                        className="form-control"
                        onChange={(event) =>
                          setShowDatePicker(event.target.value)
                        }
                        value={setShowDatePicker}
                      >
                        <option value={""}>Any date</option>
                        <option value={"date"}>Date Between</option>
                      </select>

                      {showDatePicker == "date" && (
                        <div className="d-flex dateBetween-filter">
                          <input
                            type="date"
                            name="startDate"
                            className="form-control"
                            onChange={(event) =>
                              setstartDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                          />
                          <span className="mx-2">and</span>
                          <input
                            className="form-control"
                            type="date"
                            name="endDate"
                            onChange={(event) =>
                              setendDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                            id=""
                          />
                        </div>
                      )}

                      <select
                        className="form-control"
                        onClick={(e) => {
                          setItemPerPageFilter(e.target.value);
                          setItemPerPageFilterApplied(true);
                        }}
                      >
                        <option value={10}>Items per page</option>
                        <option value={20}>20 Items per page</option>
                        <option value={30}>30 Items per page</option>
                        <option value={50}>50 Items per page</option>
                        <option value={100}>100 Items per page</option>
                      </select>
                    </ul>

                    <button
                      className="btn btn-secondary dropdown-toggle btn-info"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#addorderModal"
                        >
                          Add Order
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs orderTabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    {tablistArr.map((tabItem) => {
                      return (
                        <li
                          className="nav-item"
                          role="presentation"
                          onClick={() => {
                            setCurrActiveTab(tabItem);
                            setIsSingleChecked(false)

                          }}
                        >
                          <button
                            className={`nav-link ${activeTab === tabItem && "active"
                              }`}
                            id="pills-overdue-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-overdue"
                            type="button"
                            role="tab"
                            aria-controls="pills-overdue"
                            aria-selected="true"
                            onClick={() => clearCheckAll()}
                          >
                            <span>
                              {tabItem === "All"
                                ? orderList?.length
                                : orderList &&
                                orderList?.filter(
                                  (item) =>
                                    item.status.toLowerCase() ===
                                    tabItem.toLowerCase()
                                ).length}
                            </span>
                            {tabItem}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-overdue"
                      role="tabpanel"
                      aria-labelledby="pills-overdue-tab"
                      tabindex="0"
                    >
                      <div className="common-table TableOrders rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked"
                                    checked={
                                      orderList?.length ==
                                      multiDeleteId?.length && //change
                                      orderList?.length > 0
                                    }
                                    defaultValue
                                    onClick={() => checkedAll()}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckChecked"
                                  ></label>
                                </div>
                              </th>
                              <th>Client</th>
                              <th>Assigned Team Member</th>
                              <th>Status</th>
                              <th>Added</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {/* no record found */}
                            {/* <tr>
                    <td colSpan={7}>
                      <div className="noData-section">
                        <img src={noData} />
                        <p>No Data Found</p>
                      </div>
                    </td>
                  </tr> */}
                            {/* no record found */}
                            {currentItemsAll && currentItemsAll.length > 0 ? (
                              currentItemsAll.map((item) => {
                                if (
                                  currActiveTab == "All"
                                  //  &&
                                  // item.userId !== null
                                ) {
                                  return (
                                    <tr>
                                      <td className="ps-4 w65-px">
                                        <div class="form-check">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id={"order" + item.id}
                                            checked={multiDeleteId.find(
                                              (e) => e === item._id
                                            )}
                                            onClick={(e) => {
                                              setIsSingleChecked(
                                                e.target.checked
                                              );
                                              deleteIdList(
                                                e.target.checked,
                                                item._id
                                              );
                                            }}
                                          />
                                          <label
                                            class="form-check-label"
                                            for={"order" + item.userId?._id}
                                          ></label>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className="clientDetail"
                                          onClick={() =>
                                            navigate(
                                              `/view-orders/${item.orderId}`,
                                              {
                                                state: {
                                                  _id: item._id,
                                                  formType: item.inTakeForm,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          <p className="name mb-1">
                                            <a>
                                              {item.userId &&
                                                item.userId.firstName
                                                ? `${item.userId.firstName} ${item.userId?.lastName !=
                                                  undefined
                                                  ? item.userId.lastName
                                                  : ""
                                                }`
                                                : item?.userId?.email}

                                              {item.message.length > 0 && (
                                                <>
                                                  <span className="ms-1">
                                                    <i class="fa-solid fa-envelope"></i>
                                                    {item.message.length}
                                                  </span>
                                                </>
                                              )}
                                              {/* </span> */}
                                              {Object.values(item.tags).map(
                                                (tagItem) => {
                                                  return (
                                                    <span
                                                      className="mx-1"
                                                      style={{}}
                                                    >
                                                      {tagItem}
                                                    </span>
                                                  );
                                                }
                                              )}
                                            </a>
                                          </p>
                                          <p className="subject">
                                            <a href="">{item.title} </a>
                                            {item.price && (
                                              <span>${item.price}</span>
                                            )}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="assignedImg">
                                          {item.assignedTo.map((item) => {
                                            return (
                                              <img
                                                className="ms-n1"
                                                src={
                                                  item.image
                                                    ? item.image
                                                    : DummyAvatar
                                                }
                                                data-tip={
                                                  item.firstName +
                                                  " " +
                                                  item.lastName
                                                }
                                              />
                                            );
                                          })}
                                          <ReactTooltip />
                                        </div>
                                      </td>
                                      <td>
                                        <span className="pill pill-grey orderStatus fw-normal">
                                          {item.status.toUpperCase()}
                                        </span>
                                      </td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.createdDate,
                                          companyData.timezone
                                        )}
                                        {/* <br /> <small>due 23th from now</small> */}
                                      </td>
                                    </tr>
                                  );
                                } else {
                                  if (
                                    currActiveTab.toLowerCase() ==
                                    item.status.toLowerCase()
                                  ) {
                                    return (
                                      <tr>
                                        <td className="ps-4 w65-px">
                                          <div class="form-check">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              id={"order" + item.id}
                                              checked={multiDeleteId.find(
                                                (e) => e === item._id
                                              )}
                                              onClick={(e) => {
                                                setIsSingleChecked(
                                                  e.target.checked
                                                );
                                                deleteIdList(
                                                  e.target.checked,
                                                  item._id
                                                );
                                              }}
                                            />
                                            <label
                                              class="form-check-label"
                                              for={"order" + item.userId?._id}
                                            ></label>
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            className="clientDetail"
                                            onClick={() =>
                                              navigate(
                                                `/view-orders/${item.orderId}`,
                                                {
                                                  state: {
                                                    _id: item._id,
                                                    formType: item.inTakeForm,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            <p className="name mb-1">
                                              <a>
                                                {item.userId &&
                                                  item.userId.firstName
                                                  ? `${item.userId.firstName} ${item.userId?.lastName !=
                                                    undefined
                                                    ? item.userId.lastName
                                                    : ""
                                                  }`
                                                  : item?.userId?.email}

                                                {item.message.length > 0 && (
                                                  <>
                                                    <span className="ms-1">
                                                      <i class="fa-solid fa-envelope"></i>
                                                      <span>
                                                        {item.message.length}
                                                      </span>
                                                    </span>
                                                  </>
                                                )}
                                                {/* </span> */}
                                                {Object.values(item.tags).map(
                                                  (tagItem) => {
                                                    return (
                                                      <span
                                                        className="mx-1"
                                                        style={{}}
                                                      >
                                                        {tagItem}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </a>
                                            </p>
                                            <p className="subject">
                                              <a href="">{item.title} </a>
                                              {item.price && (
                                                <span>${item.price}</span>
                                              )}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="assignedImg">
                                            {item.assignedTo.map((item) => {
                                              return (
                                                <img
                                                  className="ms-n1"
                                                  src={
                                                    item.image
                                                      ? item.image
                                                      : DummyAvatar
                                                  }
                                                  data-tip={
                                                    item.firstName +
                                                    " " +
                                                    item.lastName
                                                  }
                                                />
                                              );
                                            })}
                                            <ReactTooltip />
                                          </div>
                                        </td>
                                        <td>
                                          <span className="pill pill-grey">
                                            {item.status.toUpperCase()}
                                          </span>
                                        </td>
                                        <td>
                                          {formatMonthNameAndDate(
                                            item.createdDate,
                                            companyData.timezone
                                          )}
                                          {/* <br /> <small>due 23th from now</small> */}
                                        </td>
                                      </tr>
                                    );
                                  }
                                }
                              })
                            ) : (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClick}
                          pageCount={pageCountOpen}
                          pageRangeDisplayed={5}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                      {(multiDeleteId.length > 0 || isSingleChecked) && (
                        <div className="table-bottom-option">
                          <div className="row">
                            <div className="col-lg-3 col-md-4">
                              <div className="input-group">
                                <select
                                  id="selectOption"
                                  value={selectOption.target}
                                  onChange={(e) => selectOption(e)}
                                  className="form-control"
                                >
                                  <option value="">With selection</option>
                                  <option value="changeStatus">
                                    Change Status
                                  </option>
                                  <option value="Delete">Delete</option>
                                </select>

                                {isChangeStatusVisible && (
                                  <select
                                    id="orderStatusOption"
                                    value={orderStatusOption.target}
                                    onChange={(e) => orderStatusOption(e)}
                                    style={{
                                      marginLeft: "4px",
                                      marginRight: "4px",
                                    }}
                                    className="form-control"
                                  >
                                    <option value="pending">Pending</option>
                                    <option value="submitted">Submitted</option>
                                    <option value="completed">Completed</option>
                                    <option value="info lacking">Info Lacking</option>
                                    <option value="draft for approval">Draft For Approval</option>
                                    <option value="client approves">Client Approves</option>
                                    <option value="sent to publish">Sent to Publish</option>
                                  </select>
                                )}
                                <button
                                  className="btn btn-apply"
                                  onClick={() => {
                                    if (selectedOption == "Delete") {
                                      deleteMultiple();
                                    } else if (
                                      selectedOption == "changeStatus"
                                    ) {
                                      updateMultiple(orderStatus);

                                    }
                                  }}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* add order modal stasts */}
      <div
        className="modal fade add-order-modals"
        id="addorderModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add order
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-order-form">
                <div className="add-alert">
                  <p>
                    Adding an order manually will not trigger any notifications
                    or payments. To add a paid order you can create a new
                    invoice and mark it as paid.
                  </p>
                </div>
                <form>
                  <div className="form-group">
                    <label>Client</label>
                    {/* <Select
                      classNamePrefix="lp-copy-sel"
                      isSearchable
                      value={addClientList.client}
                      options={clientListArr}
                      onChange={(e) =>
                        setAddClientList((prev) => {
                          return { ...prev, client: e };
                        })
                      }
                    /> */}

                    <Select
                      className="my-react-select my-react-select--compact"
                      isSearchable
                      // value={multipleDiscountList[index]?.service}
                      id={"select"}
                      value={addClientList.client}
                      options={clientListArr}
                      placeholder="Add orders"
                      onChange={(e) => {
                        setAddClientList((prevData) => {
                          return { ...prevData, client: e };
                        });
                      }}
                    // onBlur={(e) => {
                    //   if (e.target.value.length > 0) {

                    //     let status = String(e.target.value)
                    //       .toLowerCase()
                    //       .match(
                    //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    //       );
                    //     if (status != null) {
                    //       let data = {
                    //         label: e.target.value,
                    //         value: "neworders",
                    //       };
                    //       addClientList.push(data);
                    //       setAddClientList(addClientList);
                    //     } else {
                    //       toastMessage("error", "Invalid Email Address");
                    //     }
                    //   }
                    // }}
                    />
                  </div>

                  <div className="form-group">
                    <label>Service</label>
                    <Select
                      classNamePrefix="lp-copy-sel"
                      // isSearchable
                      value={addClientList.service}
                      options={serviceListArr}
                      onInputChange={(e) => {
                        if (e.length > 0) {
                          let list = [];
                          list = serviceListArrBkup.filter((data) => {
                            if (data.name.toLowerCase().includes(e)) {
                              return data;
                            }
                          });
                          setServiceListArr([...list]);
                        } else {
                          setServiceListArr([...serviceListArrBkup]);
                        }
                      }}
                      filterOption={false}
                      onChange={(e) => {
                        setAddClientList((prev) => {
                          return { ...prev, service: e };
                        });
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary close-btn"
                data-bs-dismiss="modal"
                onClick={() => {
                  setAddClientList({
                    client: {},
                    service: "",
                  });
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={addOrderHandler}
              >
                Add order
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* add order modal ends */}
    </div>
  );
};
export default Orders;

import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import { apiGetMethod, apiPostMethod, apiDeleteMethod } from "../../api/rest";
import { AllContext } from "../../context";
import { useNavigate, Link, useParams } from "react-router-dom";
import moment from "moment";
import DummyAvatar from "../../assets/img/avatar.png";
import ReactTooltip from "react-tooltip";
import ReactPaginate from "react-paginate";
import PageLoader from "../../components/pageLoader";

const Tickets = () => {
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [tickets, setTickets] = useState([]);
  const [open, setOpen] = useState("");
  const [tag, setTag] = useState("");
  const [allClient, setAllClient] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [assignedList, setAssignedList] = useState([]);
  const [rating, setRating] = useState("");
  const [items, setItems] = useState("");
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [pendingTickets, setPendingTickets] = useState([]);
  const [openTickets, setOpenTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const [spamTickets, setSpamTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Open");
  const [tags, setTags] = useState([]);
  const [allTeam, setAllTeam] = useState([]);
  const [teamAccountList, setTeamAccountList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [itemPerPageFilterApplied, setItemPerPageFilterApplied] =
    useState(false);
  const [inititalLoading, setInititalLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [isActive, setActive] = useState(false);
  const [isAssignedActive, setAssignedActive] = useState(false);
  const ToggleClass = () => {
    setActive(!isActive);
  };
  const ToggleAssignedClass = () => {
    setAssignedActive(!isAssignedActive);
  };

  let { id } = useParams();
  const context = useContext(AllContext);
  const { allApiUrl, apiPutMethod, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    setBulkDeleteId([]);
  }, [activeTab]);

  const {
    GET_TICKET,
    FILTER_TICKET,
    DELETE_TICKET,
    GET_ALL_CLIENT,
    TAGS,
    GET_ALL_TEAM,
    EDIT_TICKET,
  } = allApiUrl;

  useEffect(() => {
    getTickets();
    getClients();
    getTag();
    getAllTeam();
  }, []);

  const getTag = () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(TAGS, "", header)
      .then((res) => {
        if (res.status === 200) {
          // setIsLoading(false);
          setTags(res.data.tags);
        }
      })
      .catch((err) => {
        // redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  function getAllTeam() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAllTeam(res.data.team);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }

  const getTickets = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(GET_TICKET, "", header)
      .then((res) => {
        if (res.status === 200) {
          setTickets(res.data);
          setPendingTickets(
            res.data.filter((item) => {
              return item.status === "Pending";
            })
          );

          setOpenTickets(
            res.data.filter((item) => {
              return item.status === "Open";
            })
          );
          setClosedTickets(
            res.data.filter((item) => {
              return item.status === "Closed";
            })
          );
          setSpamTickets(
            res.data.filter((item) => {
              return item.status === "Spam";
            })
          );
          setInititalLoading(false);
        }
      })
      .catch((err) => {
        setInititalLoading(false);
      });
  };

  const getClients = () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(GET_ALL_CLIENT, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAllClient(res.data.team);
          // setIsLoading(false);
        }
      })
      .catch((err) => {
        // redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const viewTickets = (_id, data) => {
    navigate("/view-ticket/" + _id, { state: { data: data } });
  };
  const [itemPerPageFilter, setItemPerPageFilter] = useState(10);
  const filterTickets = () => {
    let count = 0;
    if (itemPerPageFilter && itemPerPageFilterApplied) {
      setItemsPerPage(itemPerPageFilter);
      count += 1;
    }
    const data = {};
    if (open !== "") data.status = open;
    if (tag !== "") data.tags = tag;
    if (assignedList.length > 0) {
      let userlist = [...assignedList];
      userlist.map((list, index) => {
        userlist[index] = list.value;
      });
      data.assignedUsers = userlist;
    }

    if (clientList.length > 0) {
      let clientSelectedList = [...clientList];
      clientSelectedList.map((list, index) => {
        clientSelectedList[index] = list.value;
      });
      data.client = clientSelectedList;
    }

    if (rating !== "") data.rating = rating;

    if (items !== "") data.items = items;
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    // let count = 0;
    for (let key in data) {
      count += 1;
    }
    setFilterCount(count);
    apiPostMethod(FILTER_TICKET, data, header)
      .then((res) => {
        if (res.status === 200) {
          setPendingTickets(
            res.data.filter((item) => {
              return item.status === "Pending";
            })
          );

          setOpenTickets(
            res.data.filter((item) => {
              return item.status === "Open";
            })
          );

          setClosedTickets(
            res.data.filter((item) => {
              return item.status === "Closed";
            })
          );
          setSpamTickets(
            res.data.filter((item) => {
              return item.status === "Spam";
            })
          );
          setActiveTab("Open");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  function deleteMultiple() {
    bulkDeleteId.map((row) => {
      deleteTicket(row);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
    getTickets();
  }

  function checkedAll() {
    let data = [];
    if (activeTab == "Open") {
      data = currentItemsOpen;
    }
    if (activeTab == "Closed") {
      data = currentItemsClosed;
    }
    if (activeTab == "Pending") {
      data = currentItemsPending;
    }
    if (activeTab == "Spam") {
      data = currentItemsSpam;
    }
    if (data.length == bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = data.map((e) => {
        return e._id;
      });
      setBulkDeleteId(ids);
    }
  }

  function deleteIdList(id, status) {
    if (status) {
      setBulkDeleteId([id]);
    } else {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        bulkDeleteId.push(id);
        setBulkDeleteId(bulkDeleteId);
      } else {
        let exist = bulkDeleteId.findIndex((e) => e == id);
        let ids = bulkDeleteId.splice(exist + 1, 1);
        setBulkDeleteId(ids);
      }
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }

  const deleteTicket = (id) => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(DELETE_TICKET, id, header)
      .then((res) => {
        if (res.status === 200) {
          getTickets();
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  function updateMultiple(status) {
    let data = {
      status: status,
    };
    bulkDeleteId.map((row) => {
      updateTicket(row, data);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }

  function updateTicket(id, data) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(EDIT_TICKET + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        getTickets();
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function updateAssignedUser(Details) {
    if (assignedList.find((row) => row.value == Details._id)) {
      let index = assignedList.findIndex((row) => row.value == Details._id);
      assignedList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      assignedList.push(data);
    }
    setAssignedList(assignedList);
  }

  function updateClient(Details) {
    if (clientList.find((row) => row.value == Details._id)) {
      let index = clientList.findIndex((row) => row.value == Details._id);
      clientList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      clientList.push(data);
    }
    setClientList(clientList);
    // filterTickets({ client: client });
  }
  function listSelectedClient() {
    let selectedList = "";
    clientList.map((list, index) => {
      selectedList +=
        list.label + `${index !== clientList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }
  function listSelectedAssignedUser() {
    let selectedList = "";
    assignedList.map((list, index) => {
      selectedList +=
        list.label + `${index !== assignedList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }

  //pagination
  const [itemOffsetOpen, setItemOffsetOpen] = useState(0);
  const [itemOffsetPending, setItemOffsetPending] = useState(0);
  const [itemOffsetClosed, setItemOffsetClosed] = useState(0);
  const [itemOffsetSpam, setItemOffsetSpam] = useState(0);

  // const [itemsPerPage, setItemsPerPage] = useState(10);

  // Closed;
  const endOffsetClosed = parseInt(itemOffsetClosed) + parseInt(itemsPerPage);
  const currentItemsClosed = closedTickets.slice(
    itemOffsetClosed,
    endOffsetClosed
  );
  let pageCountClosed = Math.ceil(closedTickets.length / itemsPerPage);
  const handlePageClickClosed = (event) => {
    const newOffset = (event.selected * itemsPerPage) % closedTickets.length;
    setItemOffsetClosed(newOffset);
  };
  //Open
  const endOffsetOpen = parseInt(itemOffsetOpen) + parseInt(itemsPerPage);
  const currentItemsOpen = openTickets.slice(itemOffsetOpen, endOffsetOpen);
  let pageCountOpen = Math.ceil(openTickets.length / itemsPerPage);
  const handlePageClickOpen = (event) => {
    const newOffset = (event.selected * itemsPerPage) % openTickets.length;
    setItemOffsetOpen(newOffset);
  };
  // pending
  const endOffsetPending = parseInt(itemOffsetPending) + parseInt(itemsPerPage);
  const currentItemsPending = pendingTickets.slice(
    itemOffsetPending,
    endOffsetPending
  );
  let pageCountPending = Math.ceil(pendingTickets.length / itemsPerPage);
  const handlePageClickPending = (event) => {
    const newOffset = (event.selected * itemsPerPage) % pendingTickets.length;
    setItemOffsetPending(newOffset);
  };
  //Spam
  const endOffsetSpam = parseInt(itemOffsetSpam) + parseInt(itemsPerPage);
  const currentItemsSpam = spamTickets.slice(itemOffsetSpam, endOffsetSpam);
  let pageCountSpam = Math.ceil(spamTickets.length / itemsPerPage);
  const handlePageClickSpam = (event) => {
    const newOffset = (event.selected * itemsPerPage) % spamTickets.length;
    setItemOffsetSpam(newOffset);
  };

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }

  return (
    <div>
      <Sidebar
        activePage="tickets"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <PageLoader isLoading={inititalLoading} />
      {!inititalLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6">
                <div className="heading">
                  <h2 className="fs-4">Tickets</h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-filter">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle btn-filter"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-filter"></i> Filter |{" "}
                      {filterCount}
                    </button>
                    <div className="dropdown-menu filter-drowdown">
                      <div className="filter-btns">
                        <button
                          className="btn btn-clear"
                          onClick={() => {
                            setFilterCount(0);
                            setOpen("");
                            setTag("");
                            setClientList([]);
                            setAssignedList([]);
                            setRating("");
                            setItems("");
                            getTickets();
                            setItemPerPageFilterApplied(false);
                            // setItemsPerPage(10);
                            setItemPerPageFilter(10);
                          }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-update"
                          onClick={() => filterTickets()}
                        >
                          Update filters
                        </button>
                      </div>

                      <select
                        className="form-control"
                        value={open}
                        onChange={(value) => setOpen(value.target.value)}
                      >
                        {/* <option value="All Status">All Status</option> */}
                        <option value="Open">Open</option>
                        <option value="Pending">Pending</option>
                        <option value="Closed">Closed</option>
                        <option value="Spam">Spam</option>
                      </select>
                      <select
                        className="form-control"
                        value={tag}
                        onChange={(value) => setTag(value.target.value)}
                      >
                        <option>Any Tags</option>
                        {tags.map((value) => {
                          return <option>{value.name}</option>;
                        })}
                      </select>

                      <div
                        className="assignedUser"
                        onClick={(e) => {
                          e.stopPropagation();
                          ToggleAssignedClass();
                        }}
                      >
                        <p>
                          {assignedList.length > 0
                            ? listSelectedAssignedUser()
                            : "Any Assigned User"}
                        </p>
                        <ul className={isAssignedActive ? "active" : ""}>
                          {allTeam.map((value, index) => {
                            return (
                              <li
                                onClick={() => updateAssignedUser(value.userId)}
                              >
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  {assignedList.length > 0 &&
                                    assignedList.find(
                                      (row) => row.value == value.userId._id
                                    ) && (
                                      <span
                                        class="fas fa-check check-mark"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                  <div>
                                    {value.userId.firstName &&
                                    value.userId.lastName
                                      ? value.userId.firstName +
                                        "" +
                                        value.userId.lastName
                                      : value.userId.email}{" "}
                                    <br /> <small>{value.role}</small>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div
                        className="assignedUser Anyclient"
                        onClick={(e) => {
                          e.stopPropagation();
                          ToggleClass();
                        }}
                      >
                        <p>
                          {clientList.length > 0
                            ? listSelectedClient()
                            : "Any Client"}
                        </p>
                        <ul className={isActive ? "active" : ""}>
                          {allClient.map((value, index) => {
                            return (
                              <li
                                onClick={() => {
                                  updateClient(value.userId);
                                }}
                              >
                                <a className="dropdown-item">
                                  {clientList.length > 0 &&
                                    clientList.find(
                                      (row) => row.value == value.userId._id
                                    ) && (
                                      <span
                                        class="fas fa-check check-mark"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                  <div>
                                    {value.userId.firstName &&
                                    value.userId.lastName
                                      ? value.userId.firstName +
                                        "" +
                                        value.userId.lastName
                                      : value.userId.email}{" "}
                                    <br /> <small>{value.role}</small>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <select
                        className="form-control"
                        onClick={(e) => {
                          setItemPerPageFilter(e.target.value);
                          setItemPerPageFilterApplied(true);
                        }}
                      >
                        <option value={10}>Items per page</option>
                        <option value={20}>20 Items per page</option>
                        <option value={30}>30 Items per page</option>
                        <option value={50}>50 Items per page</option>
                        <option value={100}>100 Items per page</option>
                      </select>
                    </div>
                    <a
                      className="add-btn ticketAdd-btn"
                      onClick={() =>
                        navigate("/add-ticket", {
                          state: {
                            mailTo: "",
                            from: "add-ticket",
                          },
                        })
                      }
                    >
                      Add Ticket
                    </a>
                    {/* <button className="btn btn-secondary dropdown-toggle btn-info" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item"  href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#importModal">Import</a></li>
                                </ul> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          activeTab == "Open" ? "active" : ""
                        }`}
                        id="pills-overdue-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-overdue"
                        type="button"
                        role="tab"
                        aria-controls="pills-overdue"
                        aria-selected="true"
                        onClick={() => setActiveTab("Open")}
                      >
                        {" "}
                        <span>{openTickets.length}</span>Open
                      </button>
                    </li>

                    <li className="nav-item" role="presentation">
                      <button
                        //   className="nav-link active"
                        className={`nav-link ${
                          activeTab == "Pending" ? "active" : ""
                        }`}
                        id="pills-submitted-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-submitted"
                        type="button"
                        role="tab"
                        aria-controls="pills-submitted"
                        aria-selected="false"
                        onClick={() => setActiveTab("Pending")}
                      >
                        {" "}
                        <span>{pendingTickets.length}</span>Pending
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        //   className="nav-link"
                        className={`nav-link ${
                          activeTab == "Closed" ? "active" : ""
                        }`}
                        checked={
                          tickets.length == bulkDeleteId.length &&
                          tickets.length > 0
                        }
                        id="pills-working-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-working"
                        type="button"
                        role="tab"
                        aria-controls="pills-working"
                        aria-selected="false"
                        onClick={() => setActiveTab("Closed")}
                      >
                        {" "}
                        <span>{closedTickets.length}</span>Closed
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        //   className="nav-link"
                        className={`nav-link ${
                          activeTab == "Spam" ? "active" : ""
                        }`}
                        id="pills-complete-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-complete"
                        type="button"
                        role="tab"
                        aria-controls="pills-complete"
                        aria-selected="false"
                        onClick={() => setActiveTab("Spam")}
                      >
                        <span>{spamTickets.length}</span>Spam
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="false">All</button>
                            </li> */}
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={`tab-pane fade ${
                        activeTab == "Open" ? "show active" : ""
                      }`}
                      id="pills-overdue"
                      role="tabpanel"
                      aria-labelledby="pills-overdue-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    class="selectAll form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                    checked={
                                      currentItemsOpen.length ==
                                        bulkDeleteId.length &&
                                      currentItemsOpen.length > 0 &&
                                      activeTab == "Open"
                                    }
                                    defaultValue
                                    onChange={() => checkedAll()}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="selectAll"
                                  ></label>
                                </div>
                              </th>
                              <th>Client</th>
                              <th>Assigned User</th>
                              <th>Status</th>
                              <th>Setup(Waiting since)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* no record found */}
                            {/* <tr>
                            <td colSpan={5}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr> */}
                            {/* no record found */}
                            {currentItemsOpen?.map((value, key) => {
                              return (
                                <tr>
                                  <td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="item2"
                                        checked={bulkDeleteId.find(
                                          (e) => e == value._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            value._id,
                                            currentItemsOpen.length ==
                                              bulkDeleteId.length
                                          );
                                        }}
                                      />
                                      <label
                                        class="form-check-label"
                                        for="item2"
                                      ></label>
                                    </div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    <div className="ticketDetail">
                                      <p>
                                        <a className="ticketClientEmail">
                                          {value.to}
                                        </a>
                                        <span className="pill pill-grey ms-2">
                                          {value.message.length}
                                        </span>
                                        {/* <span className="pill pill-grey">
                                        {value.ipAddress}
                                      </span> */}
                                        {/* <span className="pill pill-blue">{value.message}</span> */}
                                        {/* <span className="pill pill-blue">{{__html:value.message}}</span>  */}
                                        {value.tags.map((tag) => (
                                          <span
                                            className="pill pill-blue mx-1" //   className="serviceDescription"
                                            dangerouslySetInnerHTML={{
                                              __html: tag,
                                            }}
                                          ></span>
                                        ))}
                                      </p>

                                      <p>
                                        <span>{value.subject}</span>
                                      </p>
                                    </div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    <div className="assignedImg">
                                      {value.assignedUsers.map((item) => {
                                        return (
                                          <img
                                            className="ms-n1"
                                            src={
                                              item.image
                                                ? item.image
                                                : DummyAvatar
                                            }
                                            data-tip={
                                              item.firstName +
                                              " " +
                                              item.lastName
                                            }
                                          />
                                        );
                                      })}
                                      <ReactTooltip />
                                    </div>
                                  </td>

                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {value.status}
                                    </span>
                                  </td>
                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    {moment(value.date).format("MMM DD")}
                                  </td>
                                </tr>
                              );
                            })}
                            {currentItemsOpen.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClickOpen}
                          pageCount={pageCountOpen}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                    <div
                      // className="tab-pane fade"
                      className={`tab-pane fade ${
                        activeTab == "Pending" ? "show active" : ""
                      }`}
                      id="pills-submitted"
                      role="tabpanel"
                      aria-labelledby="pills-submitted-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4">
                                <div className="form-check">
                                  <input
                                    class="selectAll form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                    checked={
                                      currentItemsPending.length ==
                                        bulkDeleteId.length &&
                                      currentItemsPending.length > 0 &&
                                      activeTab == "Pending"
                                    }
                                    defaultValue
                                    onClick={() => checkedAll()}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="selectAll"
                                  ></label>
                                </div>
                              </th>
                              <th>Client</th>
                              <th>Assigned User</th>
                              <th>Status</th>
                              <th>Setup(Waiting since)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItemsPending?.map((value, key) => {
                              return (
                                <tr>
                                  <td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="item2"
                                        checked={bulkDeleteId.find(
                                          (e) => e == value._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            value._id,
                                            currentItemsPending.length ==
                                              bulkDeleteId.length
                                          );
                                        }}
                                      />
                                      <label
                                        class="form-check-label"
                                        for="item2"
                                      ></label>
                                    </div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    <div className="ticketDetail">
                                      <p>
                                        <a className="ticketClientEmail">
                                          {value.to}
                                        </a>
                                        <span className="pill pill-grey ms-2">
                                          {value.message.length}
                                        </span>
                                        {/* <span className="pill pill-grey">
                                        {value.ipAddress}
                                      </span> */}
                                        {/* <span className="pill pill-blue">{value.message}</span> */}
                                        {/* <span className="pill pill-blue">{{__html:value.message}}</span>  */}

                                        <span
                                          className="pill pill-blue  ms-2" //   className="serviceDescription"
                                          dangerouslySetInnerHTML={{
                                            __html: value.subject,
                                          }}
                                        ></span>
                                      </p>

                                      <p>
                                        <span>{value.subject}</span>
                                      </p>
                                    </div>
                                  </td>

                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    <div className="assignedImg">
                                      {value.assignedUsers.map((item) => {
                                        return (
                                          <img
                                            className="ms-n1"
                                            src={
                                              item.image
                                                ? item.image
                                                : DummyAvatar
                                            }
                                            data-tip={
                                              item.firstName +
                                              " " +
                                              item.lastName
                                            }
                                          />
                                        );
                                      })}
                                      <ReactTooltip />
                                    </div>
                                  </td>

                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {value.status}
                                    </span>
                                  </td>
                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    {" "}
                                    {moment(value.date).format("MMM DD")}
                                  </td>
                                </tr>
                              );
                            })}
                            {currentItemsPending.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClickPending}
                          pageCount={pageCountPending}
                          pageRangeDisplayed={5}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>

                    <div
                      // className="tab-pane fade"
                      className={`tab-pane fade ${
                        activeTab == "Closed" ? "show active" : ""
                      }`}
                      id="pills-working"
                      role="tabpanel"
                      aria-labelledby="pills-working-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4  w65-px">
                                <div className="form-input">
                                  <input
                                    class="selectAll form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                    checked={
                                      currentItemsClosed.length ==
                                        bulkDeleteId.length &&
                                      currentItemsClosed.length > 0 &&
                                      activeTab == "Closed"
                                    }
                                    defaultValue
                                    onClick={() => checkedAll()}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="selectAll"
                                  ></label>
                                </div>
                              </th>
                              <th>Client</th>
                              <th>Assigned User</th>
                              <th>Status</th>
                              <th>Setup(Waiting since)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItemsClosed?.map((value, key) => {
                              return (
                                <tr>
                                  <td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="item2"
                                        checked={bulkDeleteId.find(
                                          (e) => e == value._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            value._id,
                                            currentItemsClosed.length ==
                                              bulkDeleteId.length
                                          );
                                        }}
                                      />
                                      <label
                                        class="form-check-label"
                                        for="item2"
                                      ></label>
                                    </div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    <div className="ticketDetail">
                                      <p>
                                        <a className="ticketClientEmail">
                                          {value.to}
                                        </a>
                                        {/* <span className="pill pill-grey">
                                        {value.ipAddress}
                                      </span> */}
                                        {/* <span className="pill pill-blue">{value.message}</span> */}
                                        {/* <span className="pill pill-blue">{{__html:value.message}}</span>  */}

                                        <span
                                          className="pill pill-blue" //   className="serviceDescription"
                                          dangerouslySetInnerHTML={{
                                            __html: value.subject,
                                          }}
                                        ></span>
                                      </p>

                                      <p>
                                        <span>{value.subject}</span>
                                      </p>
                                    </div>
                                  </td>

                                  <td>
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {value.status}
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    {moment(value.date).format("MMM DD")}
                                  </td>
                                </tr>
                              );
                            })}
                            {currentItemsClosed.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClickClosed}
                          pageRangeDisplayed={5}
                          pageCount={pageCountClosed}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                    <div
                      // className="tab-pane fade"
                      className={`tab-pane fade ${
                        activeTab == "Spam" ? "show active" : ""
                      }`}
                      id="pills-complete"
                      role="tabpanel"
                      aria-labelledby="pills-complete-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    class="selectAll form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                    checked={
                                      currentItemsSpam.length ==
                                        bulkDeleteId.length &&
                                      currentItemsSpam.length > 0 &&
                                      activeTab == "Spam"
                                    }
                                    defaultValue
                                    onClick={() => checkedAll()}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="selectAll"
                                  ></label>
                                </div>
                              </th>
                              <th>Client</th>
                              <th>Status</th>
                              <th>Setup(Waiting since)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItemsSpam?.map((value, key) => {
                              return (
                                <tr>
                                  <td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="item2"
                                        checked={bulkDeleteId.find(
                                          (e) => e == value._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            value._id,
                                            currentItemsSpam.length ==
                                              bulkDeleteId.length
                                          );
                                        }}
                                      />
                                      <label
                                        class="form-check-label"
                                        for="item2"
                                      ></label>
                                    </div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      viewTickets(value._id, value)
                                    }
                                  >
                                    <div className="ticketDetail">
                                      <p>
                                        <a className="ticketClientEmail">
                                          {value.to}
                                        </a>
                                        <span className="pill pill-grey  ms-2">
                                          {value.message.length}
                                        </span>
                                        {value.tags &&
                                          value.tags.map((tag) => {
                                            return (
                                              <span className="pill pill-grey  ms-2">
                                                {tag}
                                              </span>
                                            );
                                          })}
                                        {/* <span className="pill pill-blue">{value.message}</span> */}
                                        {/* <span className="pill pill-blue">{{__html:value.message}}</span>  */}

                                        <span
                                          className="pill pill-blue  ms-2" //   className="serviceDescription"
                                          dangerouslySetInnerHTML={{
                                            __html: value.subject,
                                          }}
                                        ></span>
                                      </p>
                                      {/* 
                                    <p>
                                        <span>{value.subject}</span>
                                    </p> */}
                                    </div>
                                  </td>

                                  <td>
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {value.status}
                                    </span>
                                  </td>

                                  <td>
                                    {" "}
                                    {moment(value.expiryDate).format("MMM DD")}
                                  </td>
                                </tr>
                              );
                            })}
                            {currentItemsSpam.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClickSpam}
                          pageCount={pageCountSpam}
                          pageRangeDisplayed={5}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                    {/* <div className="tab-pane fade" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0">
                                <div className="common-table">
                                <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="item1" />
                                                        <label for="item1"></label>
                                                    </div>
                                                </th>
                                                <th>Name</th>
                                                <th>Company</th>
                                                <th>Account Manager</th>
                                                <th>Created on</th>
                                                <th>Status</th>
                                                <th>Balance</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="form-group">
                                                        <input type="checkbox" id="item2" />
                                                        <label for="item2"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="clientSection">
                                                        <div className="img">
                                                            <img src={Avatar} />
                                                        </div>
                                                        <div className="clientName">
                                                            <p><a href="/view-client">Client Name</a></p>
                                                            <p>abc@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                    
                                                <td> Company ABC</td>
                                                <td>Manager Name</td>
                                                <td>Sep 20</td>
                                                <td>
                                                    <span className="pill pill-grey">Lead</span>
                                                </td>
                                                <td>$0.00</td>
                                                
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-secondary dropdown-toggle btn-info" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="/edit-client">Edit</a></li>
                                                            <li><a className="dropdown-item" href="#">Sign in as user</a></li>
                                                            <li><a className="dropdown-item" href="#">New invoice</a></li>
                                                            <li><a className="dropdown-item" href="#">New ticket</a></li>
                                                            <li><a className="dropdown-item" href="#">Merge</a></li>
                                                            <li><a className="dropdown-item" href="#">Delete</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div> */}
                  </div>

                  {(bulkDeleteId.length > 0 || isSingleChecked) && (
                    <div className="table-bottom-option">
                      <div className="row">
                        <div className="col-lg-3 col-md-4">
                          <div className="input-group">
                            <select
                              id="selectOption"
                              value={selectOption.target}
                              onChange={(e) => selectOption(e)}
                              className="form-control"
                            >
                              <option>With Selected...</option>
                              {/* <option>Assign</option>
                                                        <option>Unassign</option> */}
                              <option value="Open">Set as Open</option>
                              <option value="Pending">Set as Pending</option>
                              <option value="Closed">Set as Closed</option>
                              <option value="Spam">Set as Spam</option>
                              <option value="Delete">Delete</option>
                            </select>
                            <button
                              className="btn btn-apply"
                              onClick={() => {
                                if (selectedOption == "Delete") {
                                  deleteMultiple();
                                }
                                if (
                                  selectedOption == "Open" ||
                                  selectedOption == "Pending" ||
                                  selectedOption == "Closed" ||
                                  selectedOption == "Spam"
                                ) {
                                  updateMultiple(selectedOption);
                                }
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Tickets;

import React from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
// import "../../assets/css/custom.css";
import "../../assets/css/customNew.css";
import signoutImg from "../../assets/img/signout.svg";
import smile from "../../assets/img/okay.png";

const Logout = () => {
  const navigate = useNavigate();
  function SignBackInHandler() {
    navigate("/login");
  }
  return (
    <main className="wrapper">
      <div className="section-login">
        <div className="container">
          <div className="row h-80vh mx-0 align-items-center">
            <div className="col-lg-4 mx-auto">
              <div className="loginForm logoutLayout">
                <a className="brandName">AgencyCRM</a>
                <h3>Have a great day!</h3>
                <img src={signoutImg} />

                <p className="text-muted mb-4 text-center">
                  <img src={smile} /> You have signed out successfully.
                </p>
                <p className="text-center">
                  <a
                  className="btn-backSignin forgotLink mt-3 text-center fs-6"
                  onClick={SignBackInHandler}
                >
                  Sign back in
                </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Logout;

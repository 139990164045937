import React, { useState, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/dummy-avatar.jpeg";
import noData from "../../assets/img/nodata-img.svg";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { AllContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useDebugValue } from "react";
import Select from "react-select";
import moment from "moment";
import ReactPaginate from "react-paginate";
import PageLoader from "../../components/pageLoader";

const Help = () => {
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [clientOrder, setClientOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState("");
  const [subjectTicket, setSubjectTicket] = useState("");
  const [realtedTicket, setRelatedTicket] = useState("");
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [limit, setPageLimit] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [relatedOrder, setRelatedOrder] = useState("");
  const [clientOrderBackUp, setClientOrderBackup] = useState([]);
  const editorRef = useRef(null);
  const [initialLoading, setInitialLoading] = useState(true);

  const [tickets, setTickets] = useState([]);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const { allApiUrl, toastMessage } = context;
  const { GET_CLIENT_TICKET } = allApiUrl;

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const { ADD_TICKET, CLIENT_ORDER_ID } = allApiUrl;

  function handleEditorChange(e) {
    // var str = e.target.getContent();
    setContent(e);
  }

  const addTicket = () => {
    if (content.length < 0 || content == "") {
      toastMessage("error", "The message field is required.");
    }

    const data = {
      to: userInfo.email,
      subject: "",
      relatedOrder: "",
      message: [],
      rating: null,
      client: userInfo.id,
    };
    if (subjectTicket) {
      // data.to = addTickets;
      data.subject = subjectTicket;
      data.relatedOrder = realtedTicket;
      data.message = [
        {
          from: userInfo.id,
          date: new Date().getTime(),
          message: content,
          to: "Client",
        },
      ];
    }
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(ADD_TICKET, data, header)
      .then((res) => {
        if (res.status === 201) {
          toastMessage("success", res);
          navigate("/help");
          getAllTickets();
          setSubjectTicket("");
          setRelatedTicket("");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setItemOffset(newOffset);
  };
  function getAllTickets() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    // let itemsPerPage = 20;
    const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
    apiGetMethod(GET_CLIENT_TICKET + `/${userInfo.id}`, "", header)
      .then((res) => {
        if (res.status === 200) {
          var result = res.data.details;
          var getTicketList = result.length;
          const ticketsResult = result.slice(itemOffset, endOffset);
          setCurrentPage(itemOffset);
          setPageCount(Math.ceil(getTicketList / itemsPerPage));
          setPageLimit(res.data.limit || itemsPerPage);
          setTickets(ticketsResult);
          setIsLoading(false);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        setInitialLoading(false);
      });
  }

  useEffect(() => {
    getAllTickets();
    getClientOrders();
  }, [itemOffset, itemsPerPage]);

  function getClientOrders() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(CLIENT_ORDER_ID + "/" + userInfo.id, "", header)
      .then((res) => {
        let clientOrder = [];
        res.data.order.map((item) => {
          clientOrder.push({
            title: item.title || " ",
            serviceName: item.serviceName || " ",
            label: (
              <>
                {item.title || " "} {"\n"}{" "}
                <small>{item.serviceName || " "}</small>
              </>
            ),
            value: item._id,
          });
        });
        // setClientOrder(res.data.order);
        setClientOrder(clientOrder);
        setClientOrderBackup(clientOrder);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }
  document.addEventListener("focusin", function (e) {
    if (
      e.target.closest(
        ".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
      ) !== null
    ) {
      e.stopImmediatePropagation();
    }
  });
  return (
    <div>
      <Sidebar
        activePage="help"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />

      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6">
                <div className="heading">
                  <h2 className="fs-4">Help</h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-filter">
                  <button
                    className="btn btn-import"
                    data-bs-toggle="modal"
                    data-bs-target="#ticketModal"
                  >
                    New Ticket
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive common-table mt-3 files-table rounded-1">
                  <table className="table logs-table mb-0">
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* no record found */}
                      {/* <tr>
                        <td colSpan={3}>
                          <div className="noData-section">
                            <img src={noData} />
                            <p>No Data Found</p>
                          </div>
                        </td>
                      </tr> */}
                      {/* no record found */}
                      {tickets.map((value) => {
                        return (
                          <tr>
                            <td>
                              <a
                                onClick={() =>
                                  navigate(`/portal/view-ticket/${value._id}`)
                                }
                              >
                                {value.subject}
                              </a>
                            </td>
                            <td>{moment(value.date).format("MMM DD")}</td>
                            <td>
                              <span className="pill pill-orange">
                                {value.status}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                      {tickets.length <= 0 && (
                        <tr>
                          <td colSpan={7}>
                            <div className="noData-section">
                              <img src={noData} />
                              <p>No Data Found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="sectionPagination">
                  <NoOfRows />
                  <ReactPaginate
                    nextLabel={<i class="fa-solid fa-angles-right"></i>}
                    previousLabel={<i class="fa-solid fa-angles-left"></i>}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination paginate"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* add order modal stasts */}
      <div
        className="modal fade add-order-modals newTicket-modal"
        id="ticketModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                New Ticket
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-order-form">
                <div className="form-group">
                  <label>Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setSubjectTicket(e.target.value);
                    }}
                    value={subjectTicket}
                  />
                </div>

                <div className="form-group">
                  <label>
                    Related Order <small>(Optional)</small>
                  </label>
                  {/* <input type="se" className="form-control" 
                    value={realtedTicket}
                    onChange={(event) => {
                      setRelatedTicket(event.target.value);
                    }} 
                    />*/}
                  {/* <select
                    className="form-control"
                    onChange={(event) => {
                      setRelatedTicket(event.target.value);
                    }}
                  >
                    <option value="">Please select...</option>
                    {clientOrder.map((service) => {
                      return (
                        <option value={service._id}>{service.title}{service.serviceName}</option>
                      );
                    })}
                  </select> */}
                  <Select
                    className="my-react-select my-react-select--compact relatedOrder"
                    classNamePrefix="lp-copy-sel"
                    isSearchable
                    value={relatedOrder}
                    options={clientOrder}
                    onInputChange={(e) => {
                      if (e.length > 0) {
                        let list = [];
                        list = clientOrderBackUp.filter((data) => {
                          if (
                            data.title.includes(e) ||
                            data.serviceName.includes(e)
                          ) {
                            return data;
                          }
                        });
                        setClientOrder(list);
                      } else {
                        setClientOrder(clientOrderBackUp);
                      }
                    }}
                    filterOption={false}
                    onChange={(e) => {
                      setRelatedOrder(e);
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>Message</label>
                  {/* <textarea className="form-control">
                    </textarea> */}
                  <Editor
                    required
                    apiKey="fjnv8pd6m40tkdl6tva9sklqdppliphhvofstqa48b92mxtb"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={content}
                    init={{
                      selector: "textarea",
                      height: 200,
                      plugins: "advlist link image lists",
                      menubar: false,
                      toolbar: false,
                      menubar: "custom",
                      menu: {
                        custom: {
                          title: "custom",
                          items: "basicitem nesteditem toggleitem",
                        },
                      },
                      toolbar:
                        "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                        "fullpage" +
                        "indent | blockquote | codesample | fontselect ",
                      font_formats:
                        "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                      block_formats:
                        "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                      plugins: ["image", "help", "link", "codesample", "lists"],
                      branding: false,
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                      menubar: false,
                    }}
                    onEditorChange={(e) => handleEditorChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary close-btn"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => addTicket()}
              >
                Create Ticket
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* add order modal ends */}
    </div>
  );
};
export default Help;

import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { useFormik } from "formik";
// import * as Yup from "yup";

import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import { AllContext } from "../../../context";
import { toast } from "react-toastify";

const Payment = () => {
  const dispatch = useDispatch();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [isLoading, setIsLoading] = useState(false);
  const {
    allApiUrl,
    apiPostMethod,
    toastMessage,
    apiGetMethod,
    redirectToLogin,
  } = context;
  const { PAYMENT_METHOD } = allApiUrl;
  let header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const [paypalPublicKey, setPaypalPublicKey] = useState("");
  const [paypalSecretKey, setPaypalSecretKey] = useState("");
  const [stripePublicKey, setStripePublicKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [inputPrefixNumber, setInputPrefixNumber] = useState("SPP-");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [companyDetails, setCompanyDetails] = useState("");
  useEffect(() => {
    setIsLoading(true);
    apiGetMethod(PAYMENT_METHOD, "", header)
      .then((res) => {
        if (res.status === 200) {
          updateExistingPaymentValues(res.data.values);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }, []);
  function updateExistingPaymentValues(values) {
    setPaypalPublicKey(values.paypal.publicKey);
    setPaypalSecretKey(values.paypal.privateKey);
    setStripePublicKey(values.stripe.publicKey);
    setStripeSecretKey(values.stripe.privateKey);
    setCompanyDetails(values.address);
    if (values.invoice) {
      values.invoice.forEach((item) => {
        if (item.activeStatus) {
          setInputPrefixNumber(item.prefix);
          setInvoiceNumber(item.invoiceNumber);
        }
      });
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    let prefix = "";
    if (inputPrefixNumber) {
      prefix = inputPrefixNumber;
    }
    const data = {
      stripe: {
        publicKey: stripePublicKey,
        privateKey: stripeSecretKey,
      },
      paypal: {
        publicKey: paypalPublicKey,
        privateKey: paypalSecretKey,
      },
      invoice: {
        prefix,
        invoiceNumber: invoiceNumber,
        activeStatus: true,
      },
      address: companyDetails,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiPostMethod(PAYMENT_METHOD, data, header)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          toastMessage("success", res.data.message);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };
  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="setting-tab">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-content">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <div
                      className="section-back"
                      onClick={() => navigate("/settings")}
                    >
                      <a href="#" className="nav-link">
                        <i className="fa-solid fa-chevron-left"></i> Settings
                      </a>
                    </div>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    on
                    onClick={() => navigate("/settings/general")}
                  >
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Payments
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Payments
                    </button>
                  </li> */}
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => navigate("/settings/team")}
                  >
                    <button
                      className="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Team
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-back pl-240 mt-3">
        <a href="/settings" className="horizontalPadding">
          <i class="fa-solid fa-angle-left"></i> Settings
        </a>
      </div>

      <div className="setting-form-section mt-5 mb-3">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-xxl-5 col-lg-8 mx-auto">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabindex="0"
                >
                  {/* Payment Mode */}
                  <div className="setting-form">
                    <form
                      className="commonForm rounded-1"
                      onSubmit={handleSubmit}
                    >
                      {/* <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Currency</label>
                            <input
                              type="text"
                              className="form-control"
                              value="USD"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <h3>Paypal Method</h3>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>API Key</label>
                            <input
                              required
                              name="paypalPublicKey"
                              type="text"
                              className="form-control"
                              value={paypalPublicKey}
                              onChange={(e) =>
                                setPaypalPublicKey(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Secret Key</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              value={paypalSecretKey}
                              onChange={(e) =>
                                setPaypalSecretKey(e.target.value)
                              }
                              name="paypalSecretKey"
                            />
                          </div>
                        </div>
                      </div> */}
                      <h3 className="fs-5">Stripe Integration</h3>
                      <div className="row mb-4">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>API Key</label>
                            <input
                              required
                              name="stripePublicKey"
                              type="text"
                              className="form-control"
                              value={stripePublicKey}
                              onChange={(e) =>
                                setStripePublicKey(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Secret Key</label>
                            <input
                              required
                              name="stripeSecretKey"
                              type="text"
                              className="form-control"
                              value={stripeSecretKey}
                              onChange={(e) =>
                                setStripeSecretKey(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <h3 className="fs-5">Invoice</h3>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              required
                              value={inputPrefixNumber}
                              name="invoiceNumberPrefix"
                              type="text"
                              className="form-control"
                              placeholder="INV-"
                              onChange={(e) =>
                                setInputPrefixNumber(e.target.value)
                              }
                            />
                            <small>Invoice number prefix</small>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              required
                              name="invoiceNumber"
                              type="number"
                              className="form-control"
                              placeholder="e.g. 1000"
                              value={invoiceNumber}
                              onChange={(e) => setInvoiceNumber(e.target.value)}
                            />
                            <small>Starting invoice number</small>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              required
                              name="companyDetails"
                              className="form-control"
                              placeholder="Company name, address, VAT number etc."
                              value={companyDetails}
                              onChange={(e) =>
                                setCompanyDetails(e.target.value)
                              }
                            ></textarea>
                            <small>Company details</small>
                          </div>
                          <div className="btn-section text-end mb-0">
                            <button className="btn btn-save">Save</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Payment;

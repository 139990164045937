import "./App.css";
import React from "react";
import "antd/dist/antd.min.css";
// import "../src/assets/css/custom.css";
// import "../src/assets/css/customNew.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// client
import Home from "./screens/home";
import Purchases from "./screens/purchases";
import ViewPurchase from "./screens/view-purchase";
import Shop from "./screens/shop";
import ViewCart from "./screens/view-cart";
import Payments from "./screens/payments";
import ViewPayment from "./screens/view-payment";
import Profile from "./screens/profile";
import ClientTeam from "./screens/client-team";
import Help from "./screens/help";
import ViewTickets from "./screens/view-tickets";
import Checkout from "./screens/checkout";
import PaymentFailed from "./screens/paymentFailed";
import CheckoutInvoice from "./screens/checkoutInvoice";
import Thankyou from "./screens/thankyou";
import InTakeForm from "./screens/intakeForm";
import Payment from "./screens/payment";
// admin
import Dashboard from "./screens/dashboard";
import ServiceReport from "./screens/service-report";
import ClientReport from "./screens/client-report";
import CompletedOrder from "./screens/completed-order";
import OrderStatus from "./screens/order-status";
import Revenue from "./screens/revenue";
import Sales from "./screens/sales";
import Orders from "./screens/orders";
import Clients from "./screens/clients";
import AddClient from "./screens/add-client";
import EditClient from "./screens/edit-client";
import ViewClient from "./screens/view-client";
import Tickets from "./screens/tickets";
import AddTicket from "./screens/add-ticket";
import ViewTicket from "./screens/view-ticket";
import EditTicket from "./screens/edit-ticket";
import Files from "./screens/files";
import Coupons from "./screens/coupons";
import AddCoupon from "./screens/add-coupon";
import EditCoupon from "./screens/edit-coupon";
import Messaging from "./screens/messaging";
import AddMessage from "./screens/add-message";
import EditMessage from "./screens/edit-message";
import Services from "./screens/services";
import AddService from "./screens/add-service";
import EditService from "./screens/edit-service";
import Settings from "./screens/settings";
import CompanySetting from "./components/settings/company";
import PaymentSetting from "./components/settings/payment";
import TeamSetting from "./components/settings/team/index.jsx";
import AddRole from "./screens/add-role";
import EditRole from "./screens/edit-role";
import AddTeamMember from "./screens/add-team-member";
import EditTeam from "./screens/edit-team";
import Logs from "./screens/logs";
import ViewLogs from "./screens/view-logs";
import ViewOrders from "./screens/view-orders";
import Tags from "./screens/tags";
import Email from "./screens/email";
import Invoices from "./screens/invoices";
import EditInvoice from "./screens/edit-invoice";
import AddInvoice from "./screens/add-invoice";
import ViewInvoice from "./screens/view-invoice";
import ViewPublicInvoice from "./screens/public-invoice";
import Subscription from "./screens/subscription";
import ViewSubscription from "./screens/view-subscription";
import Login from "./screens/login";
import ForgotPassword from "./screens/forgot-password";
import ResetPassword from "./screens/reset-password";
import Logout from "./screens/logout";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { PermissionDenied } from "./components/PermissionDenied";
import ViewTeamMember from "./screens/view-team";
import PageNotFound from "./components/pageNotFound";
import IncorrectInvoice from "./components/IncorrectInvoice";
import "react-toastify/dist/ReactToastify.css";
import EmailTemplates from "./screens/email-templates";
import IncorrectOrder from "./components/IncorrectOrder";
// import NotAssigned from "./components/NotAssigned";

function App() {
  const authRoleList = useSelector(
    (state) => state.loginReducer.loginUserInfo?.roles
  );
  let loggedIn = localStorage.getItem("userLoggedIn");
  const allTrueChecker = (value) => {
    let result = Object.values(value).every((v) => v === false);
    return result;
  };
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        newestOnTop={false}
        autoClose={5000}
        bodyClassName="toastBody"
      />
      <Router>
        <Routes>
          {/* Auth */}
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/product/paymentSuccess" element={<Checkout />} />
          <Route path="/public-invoice/:id" element={<ViewPublicInvoice />} />
          <Route path="/incorrectInvoice" element={<IncorrectInvoice />} />
          <Route path="/incorrectOrder" element={<IncorrectOrder />} />
          {/* ticket */}
          <Route
            path="/tickets"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ? (
                <Tickets />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/add-ticket"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ? (
                <AddTicket />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/view-ticket/:id"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ? (
                <ViewTicket />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/edit-ticket/:id"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ? (
                <EditTicket />
              ) : (
                <Dashboard />
              )
            }
          />
          {/* files */}
          <Route path="/files" element={<Files />} />

          {/* coupons */}
          <Route
            path="/coupons"
            element={
              authRoleList?.coupons?.view_add_edit_delete ? (
                <Coupons />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/add-coupon"
            element={
              authRoleList?.coupons?.view_add_edit_delete ? (
                <AddCoupon />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/edit-coupon/:id"
            element={
              authRoleList?.coupons?.view_add_edit_delete ? (
                <EditCoupon />
              ) : (
                <Dashboard />
              )
            }
          />

          {/* messaging */}
          <Route path="/messaging" element={<Messaging />} />
          <Route path="/add-message" element={<AddMessage />} />

          {/* services */}
          <Route
            path="/services"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <Services />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/add-service"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <AddService />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/edi-t-service/:id"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <EditService />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route path="/edit-message/:id" element={<EditMessage />} />
          <Route path="/permissionDenied" element={<PermissionDenied />} />
          {/* services */}
          <Route path="/services" element={<Services />} />
          <Route path="/add-service" element={<AddService />} />

          {/* invoices */}
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/add-invoice" element={<AddInvoice />} />
          <Route path="/view-invoice/:id" element={<ViewInvoice />} />

          {/* subscription */}
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/view-subscription/:id" element={<ViewSubscription />} />

          {/* home */}
          <Route path="/home" element={<Home />} />

          {/* purchases */}
          <Route path="/purchases" element={<Purchases />} />
          <Route path="/view-purchase" element={<ViewPurchase />} />

          {/* {authRoleList && authRoleList?.type === "admin" ? (
            <> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/service-report" element={<ServiceReport />} />
          <Route path="/client-report" element={<ClientReport />} />
          <Route path="/completed-order" element={<CompletedOrder />} />
          <Route path="/order-status" element={<OrderStatus />} />
          <Route path="/revenue" element={<Revenue />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/role/role" element={<AddRole />} />
          <Route path="/role/role/:id" element={<EditRole />} />
          <Route path="/accounts/add/:id" element={<AddTeamMember />} />
          <Route path="/accounts/edit/:id" element={<EditTeam />} />
          <Route path="/accounts/team/:id" element={<ViewTeamMember />} />
          <Route path="/view-logs" element={<ViewLogs />} />
          <Route path="/email" element={<Email />} />
          <Route
            path="/orders"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.orderAccess) ||
                !allTrueChecker(authRoleList?.orderManagement)) ? (
                <Orders />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/view-orders/:id"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.orderAccess) ||
                !allTrueChecker(authRoleList?.orderManagement)) ? (
                <ViewOrders />
              ) : (
                <Dashboard />
              )
            }
          />
          {/* settings */}
          <Route
            path="/settings"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <Settings />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/general"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <CompanySetting />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/payment"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <PaymentSetting />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/tags"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <Tags />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="settings/team"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <TeamSetting />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/tags"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <Tags />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <Email />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/client_welcome"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"client_welcome"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/client_password_reset"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"client_password_reset"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/client_order_incomplete"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"client_order_incomplete"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/client_receipt"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"client_receipt"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/subscription_failed"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"subscription_failed"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/subscription_cancelled"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"subscription_cancelled"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/payment_refunded"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"payment_refunded"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/new_invoice"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"new_invoice"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/upcoming_payment_reminder"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"upcoming_payment_reminder"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/unpaid_invoice_reminder"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"unpaid_invoice_reminder"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/order_updated"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"order_updated"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/intake_form_reminder"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"intake_form_reminder"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/order_needs_attention"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"order_needs_attention"} />
              ) : (
                <Dashboard />
              )
            }
          />

          <Route
            path="/settings/email/template/order_data_submitted"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"order_data_submitted"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/order_canceled"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"order_canceled"} />
              ) : (
                <Dashboard />
              )
            }
          />

          <Route
            path="/settings/email/template/recurring_payment"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"recurring_payment"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/invoice_paid"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"invoice_paid"} />
              ) : (
                <Dashboard />
              )
            }
          />

          <Route
            path="/settings/email/template/subscription_canceled_team"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"subscription_canceled_team"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/subscription_failed_team"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"subscription_failed_team"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/payment_receipt"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"payment_receipt"} />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/settings/email/template/subscription_payment_receipt"
            element={
              authRoleList?.settingsAndTeam?.view_add_edit ? (
                <EmailTemplates dataKey={"subscription_payment_receipt"} />
              ) : (
                <Dashboard />
              )
            }
          />
          {/* client */}
          <Route
            path="/clients"
            element={authRoleList?.clients?.view ? <Clients /> : <Dashboard />}
          />
          <Route
            path="/add-client"
            element={
              authRoleList?.clients?.view ? <AddClient /> : <Dashboard />
            }
          />
          <Route
            path="/edit-client/:id"
            element={
              authRoleList?.clients?.view ? <EditClient /> : <Dashboard />
            }
          />
          <Route
            path="/view-client/:id"
            element={
              authRoleList?.clients?.view ? <ViewClient /> : <Dashboard />
            }
          />
          {/* <Route path="/accounts/import" element={<Import />} /> */}
          {/* Logs */}
          <Route path="/logs" element={<Logs />} />
          <Route path="/logs/log/:id" element={<ViewLogs />} />
          <Route path="*" element={<Login />} />
          {/* ticket */}
          <Route
            path="/tickets"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ? (
                <Tickets />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/add-ticket"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ? (
                <AddTicket />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/view-ticket"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ? (
                <ViewTicket />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/edit-ticket"
            element={
              loggedIn &&
              (!allTrueChecker(authRoleList?.ticketAccess) ||
                !allTrueChecker(authRoleList?.ticketManagement)) ? (
                <EditTicket />
              ) : (
                <Dashboard />
              )
            }
          />
          {/* files */}
          <Route path="/files" element={<Files />} />

          {/* coupons */}
          <Route
            path="/coupons"
            element={
              authRoleList?.coupons?.view_add_edit_delete ? (
                <Coupons />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/add-coupon"
            element={
              authRoleList?.coupons?.view_add_edit_delete ? (
                <AddCoupon />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/edit-coupon/:id"
            element={
              authRoleList?.coupons?.view_add_edit_delete ? (
                <EditCoupon />
              ) : (
                <Dashboard />
              )
            }
          />

          {/* messaging */}
          <Route path="/messaging" element={<Messaging />} />
          <Route path="/add-message" element={<AddMessage />} />

          {/* services */}
          <Route
            path="/services"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <Services />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/add-service"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <AddService />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route
            path="/edit-service/:id"
            element={
              authRoleList?.services?.view_add_edit_delete ? (
                <EditService />
              ) : (
                <Dashboard />
              )
            }
          />
          <Route path="/edit-message/:id" element={<EditMessage />} />
          <Route path="/permissionDenied" element={<PermissionDenied />} />
          <Route path="/services" element={<Services />} />
          <Route path="/add-service" element={<AddService />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/add-invoice" element={<AddInvoice />} />
          <Route path="/view-invoice/:id" element={<ViewInvoice />} />
          <Route path="/public-invoice/:id" element={<ViewPublicInvoice />} />
          <Route path="/edit-invoice/:id" element={<EditInvoice />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/view-tickets" element={<ViewTickets />} />
          <Route path="/view-subscription" element={<ViewSubscription />} />
          {/* </>
          ) : (
            <> */}
          <Route path="/home" element={<Home />} />
          <Route path="/purchases" element={<Purchases />} />
          <Route path="/view-purchase/:id" element={<ViewPurchase />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/cart" element={<ViewCart />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/view-payment/:id" element={<ViewPayment />} />
          <Route path="/clientTeam" element={<ClientTeam />} />
          <Route path="/help" element={<Help />} />
          <Route path="/product/paymentSuccess" element={<Checkout />} />
          <Route path="/product/paymentFailed" element={<PaymentFailed />} />
          <Route path="/invoice/paymentSuccess" element={<CheckoutInvoice />} />
          <Route path="/portal/view-ticket/:id" element={<ViewTickets />} />
          <Route path="/portal/intakeForm/:id" element={<InTakeForm />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/thankyou" element={<Thankyou />} />
          {/* </> */}
          {/* )} */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;

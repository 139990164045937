import React, { useContext, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";
import { AllContext } from "../../context";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";

const EditService = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiDeleteMethod,
    apiPutMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { SERVICES } = allApiUrl;
  const { id } = useParams();

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [intakeFormStatus, setIntakeFormStatus] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [image, setImage] = useState("");
  const [services, setServices] = useState([]);
  const [addServiceList, setAddServiceList] = useState({
    service: [{}],
  });

  const [form, setForm] = useState({
    name: "",
    description: "",
    imageUrl: "",
    price: 0,
    currency: "USD",
    durationTime: 0,
    durationPeriod: "months",
    orderCount: 0,
    addOn: [],
    public: false,
    oneTimeService: false,
    intakeForm: "",
    orderService: false,
  });
  function handleFormSubmit() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    var formData = new FormData();
    let addOnList = addServiceList.service.map(({ value, name }) => ({
      value,
      name,
    }));

    addOnList = addOnList.map(({ name: name, ...rest }) => ({
      name,
      ...rest,
    }));

    let recurringOrder = {
      status: !form.oneTimeService && true,
      durationTime: form.recurringOrder.durationTime,
      durationPeriod: form.recurringOrder.durationPeriod,
      newOrder: form.recurringOrder.newOrder,
    };
    formData.append("imageUrl", form.imageUrl);
    if (currentServiceName !== form.name) {
      formData.append("name", form.name);
    }
    formData.append("price", form.price);
    formData.append("currency", "USD");
    formData.append("description", form.description);
    formData.append(
      "orderCount",
      form.recurringOrder.newOrder == "newOrder" ? 1 : form.orderCount
    );
    formData.append("addOn", JSON.stringify(addOnList));
    formData.append("public", form.public);
    formData.append("oneTimeService", form.oneTimeService);
    formData.append(
      "intakeForm",
      form.intakeForm != "" ? form.intakeForm : "no_form"
    );
    formData.append("recurringOrder", JSON.stringify(recurringOrder));

    apiPutMethod(SERVICES + "/" + id, formData, header)
      .then((res) => {
        if (res.status == 200) {
          toastMessage("success", res.data.message);
          navigate("/services");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function handleEditorChange(e) {
    // var str = e.target.getContent();
    setForm((prevData) => {
      return {
        ...prevData,
        description: e,
      };
    });
  }

  useEffect(() => {
    getAllServices();
  }, []);
  function getAllServices() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setLoader(true);
    apiGetMethod(SERVICES, "", header)
      .then((res) => {
        let serviceList = res.data.data;
        setAllServices(
          serviceList.map((row) => {
            return {
              label: row.name,
              value: row._id,
              name: row.name,
              isDisabled: false,
            };
          })
        );
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  useEffect(() => {
    getServiceDetail();
  }, [id]);
  const [currentServiceName, setCurrentServiceName] = useState("");
  function getServiceDetail() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    // setLoader(true);
    apiGetMethod(SERVICES + "/" + id, "", header)
      .then((res) => {
        res.data.service["orderService"] =
          res.data.service &&
          res.data.service.addOn.length > 0 &&
          res.data.service.addOn[0] == ""
            ? false
            : true;
        setForm(res.data.service);
        setCurrentServiceName(res.data.service.name);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  return (
    <div>
      <Sidebar
        activePage="services"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <div className="setting-tab">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-12">
              <div className="tabs-content">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        intakeFormStatus ? "nav-link" : "nav-link active"
                      }
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      onClick={() => setIntakeFormStatus(false)}
                    >
                      Edit
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        intakeFormStatus ? "nav-link active" : "nav-link"
                      }
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                      disabled={form.name == ""}
                    >
                      Intake form
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-back mb-4">
                <a href="/services">
                  {" "}
                  <i class="fa-solid fa-angle-left"></i> Services{" "}
                </a>
              </div>
            </div>

            <div className="col-xxl-5 col-xl-8 mx-auto">
              <div className="setting-form">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      intakeFormStatus ? "" : "show active"
                    }`}
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <div className="commonForm rounded-1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Service Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="My Service"
                              value={form.name}
                              onChange={(e) => {
                                setForm((prevData) => {
                                  return {
                                    ...prevData,
                                    name: e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>
                              Description <small>Optional</small>
                            </label>
                            <Editor
                              required
                              apiKey="fjnv8pd6m40tkdl6tva9sklqdppliphhvofstqa48b92mxtb"
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              value={form.description}
                              init={{
                                selector: "textarea",
                                height: 500,
                                plugins: "advlist link image lists",
                                menubar: false,
                                toolbar: false,
                                menubar: "custom",
                                menu: {
                                  custom: {
                                    title: "custom",
                                    items: "basicitem nesteditem toggleitem",
                                  },
                                },
                                toolbar:
                                  "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                  "fullpage" +
                                  "indent | blockquote | codesample | fontselect ",
                                font_formats:
                                  "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                                block_formats:
                                  "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                plugins: [
                                  "image",
                                  "help",
                                  "link",
                                  "codesample",
                                  "lists",
                                ],
                                branding: false,
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                                menubar: false,
                              }}
                              onEditorChange={(e) => handleEditorChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="d-flex">
                              <button className="btn btn-default">
                                Upload Image
                                <input
                                  type="file"
                                  id="uploadCaptureInputFile"
                                  value={image}
                                  onChange={(e) =>
                                    setForm((prevData) => {
                                      return {
                                        ...prevData,
                                        imageUrl: e.target.files[0],
                                      };
                                    })
                                  }
                                />
                              </button>
                            </div>
                            <div className="previewImg">
                              <span className="ImgSection">
                                {form.imageUrl && (
                                  <>
                                    <img
                                      src={
                                        typeof form.imageUrl === "string"
                                          ? form.imageUrl
                                          : URL.createObjectURL(form.imageUrl)
                                      }
                                    />
                                    <span
                                      className="Del"
                                      onClick={(e) => {
                                        setForm((prevData) => {
                                          return {
                                            ...prevData,
                                            imageUrl: "",
                                          };
                                        });
                                        document.getElementById(
                                          "uploadCaptureInputFile"
                                        ).value = "";
                                      }}
                                    >
                                      <i class="fa-solid fa-trash"></i>
                                    </span>
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="service-pricingForm">
                      <h3 className="mt-4">Pricing</h3>
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${
                              form.oneTimeService && "active"
                            }`}
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="home-tab-pane"
                            aria-selected="true"
                            onClick={() =>
                              setForm((prevData) => {
                                return {
                                  ...prevData,
                                  oneTimeService: true,
                                };
                              })
                            }
                          >
                            One-time service
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${
                              !form.oneTimeService && "active"
                            }`}
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="profile-tab-pane"
                            aria-selected="false"
                            onClick={() =>
                              setForm((prevData) => {
                                return {
                                  ...prevData,
                                  oneTimeService: false,
                                };
                              })
                            }
                          >
                            Recurring service
                          </button>
                        </li>
                      </ul>
                      <div className="commonForm rounded-1">
                        <div className="tab-content" id="myTabContent">
                          <div
                            className={`tab-pane fade ${
                              form.oneTimeService && "show active"
                            }`}
                            id="home-tab-pane"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                            tabindex="0"
                          >
                            <div className="row">
                              <div className="col-xxl-7 col-lg-7">
                                <div className="form-group">
                                  <label>Price</label>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <input
                                        className="form-control mobileSpace"
                                        type="text"
                                        value="USD"
                                        disabled
                                      />
                                    </div>
                                    <div className="col-lg-8">
                                      <input
                                        className="form-control"
                                        type="number"
                                        value={form.price}
                                        onChange={(e) => {
                                          setForm((prevData) => {
                                            return {
                                              ...prevData,
                                              price: e.target.value,
                                            };
                                          });
                                        }}
                                        onWheel={() =>
                                          document.activeElement.blur()
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <a href="">Create multiple orders?</a> */}

                                <div className="form-group">
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder=""
                                      value={form.orderCount}
                                      onChange={(e) => {
                                        setForm((prevData) => {
                                          return {
                                            ...prevData,
                                            orderCount: e.target.value,
                                          };
                                        });
                                      }}
                                      onWheel={() =>
                                        document.activeElement.blur()
                                      }
                                      aria-describedby="basic-addon2"
                                    />
                                    <span
                                      className="input-group-text"
                                      id="basic-addon2"
                                    >
                                      orders
                                    </span>
                                  </div>
                                  <small>
                                    {form.orderCount} new orders will be created
                                    when this service is purchased.
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`tab-pane fade ${
                              !form.oneTimeService && "show active"
                            }`}
                            id="profile-tab-pane"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                            tabindex="0"
                          >
                            <div className="row">
                              <div className="form-group">
                                <label>Price</label>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <input
                                          className="form-control  mobileSpace"
                                          type="text"
                                          value="USD"
                                          disabled
                                        />
                                      </div>
                                      <div className="col-lg-8">
                                        <input
                                          className="form-control  mobileSpace"
                                          type="number"
                                          value={form.price}
                                          onChange={(e) => {
                                            setForm((prevData) => {
                                              return {
                                                ...prevData,
                                                price: e.target.value,
                                              };
                                            });
                                          }}
                                          onWheel={() =>
                                            document.activeElement.blur()
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="input-group">
                                      <span
                                        className="input-group-text"
                                        id="basic-addon2"
                                      >
                                        every
                                      </span>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        aria-describedby="basic-addon2"
                                        value={
                                          form.recurringOrder?.durationTime
                                        }
                                        onChange={(e) => {
                                          setForm((prevData) => {
                                            return {
                                              ...prevData,
                                              recurringOrder: {
                                                ...prevData.recurringOrder,
                                                durationTime: e.target.value,
                                              },
                                            };
                                          });
                                        }}
                                        onWheel={() =>
                                          document.activeElement.blur()
                                        }
                                      />
                                      <select
                                        value={
                                          form.recurringOrder?.durationPeriod
                                        }
                                        className="form-control"
                                        onChange={(e) => {
                                          setForm((prevData) => {
                                            return {
                                              ...prevData,
                                              recurringOrder: {
                                                ...prevData.recurringOrder,
                                                durationPeriod: e.target.value,
                                              },
                                            };
                                          });
                                        }}
                                      >
                                        <option value="months">Month</option>
                                        <option value="years">Year</option>
                                        <option value="weeks">Week</option>
                                        <option value="days">Day</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="alert alert-warning" role="alert">
                              A price under $0.50 may not be supported in your
                              payment processor.
                            </div>

                            <div className="form-group">
                              <label className="mb-3">
                                a recurring payment is received...
                              </label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  value={false}
                                  checked={
                                    form.recurringOrder?.newOrder == false
                                  }
                                  onChange={(e) => {
                                    setForm((prevData) => {
                                      return {
                                        ...prevData,
                                        orderCount: 0,
                                        recurringOrder: {
                                          ...prevData.recurringOrder,
                                          newOrder: e.target.value == "true",
                                        },
                                      };
                                    });
                                  }}
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  for="flexRadiot1"
                                >
                                  Do nothing <br />
                                  <small>
                                    Order status and due date will not change.
                                  </small>
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  checked={
                                    form.recurringOrder?.newOrder == true
                                  }
                                  value={true}
                                  onChange={(e) => {
                                    setForm((prevData) => {
                                      return {
                                        ...prevData,
                                        orderCount: 1,
                                        recurringOrder: {
                                          ...prevData.recurringOrder,
                                          newOrder: e.target.value == "true",
                                        },
                                      };
                                    });
                                  }}
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  for="flexRadioDet1"
                                >
                                  Create new orders <br />{" "}
                                  <small>
                                    If you want clients to fill out{" "}
                                    {form.quantity} new intake forms every day.
                                  </small>
                                </label>
                                {(form.recurringOrder?.newOrder == true ||
                                  form.recurringOrder?.newOrder ==
                                    "createNewOrder") && (
                                  <div className="col-lg-4">
                                    <div className="input-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        aria-describedby="basic-addon2"
                                        value={form?.orderCount}
                                        onChange={(e) => {
                                          setForm((prevData) => {
                                            return {
                                              ...prevData,
                                              orderCount: e.target.value,
                                            };
                                          });
                                        }}
                                        onWheel={() =>
                                          document.activeElement.blur()
                                        }
                                      />
                                      <span
                                        className="input-group-text"
                                        id="basic-addon2"
                                      >
                                        orders
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h3 className="mt-4">Orders of this service</h3>
                    <div className="commonForm rounded-1">
                      <div className="form-group">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            // id="newOrders"
                            checked={form.orderService}
                            onChange={(e) => {
                              setForm((prevData) => {
                                return {
                                  ...prevData,
                                  orderService:e.target.checked,
                                };
                              });
                            }}
                          />
                          <label
                            className="form-check-label checkbox-label"
                            for="newOrders"
                          >
                            This is an add-on, don't create a new order <br />
                            <small>
                              An order will not be created when this service is
                              purchased. Intake form will be appended to parent
                              service. You can select one or more parent
                              services below.
                            </small>
                            {form.orderService == true && (
                              // [...Array(form)].map(
                              //   (value, index) => {
                              <Select
                              id={"select"}
                              className="my-react-select my-react-select--compact"
                              isMulti
                              classNamePrefix="lp-copy-sel"
                              value={addServiceList.service}
                              placeholder="All Services"
                              options={allServices}
                              onChange={(e) => {
                                console.log('e',e)

                                setAddServiceList((prevData) => {
                                  return { ...prevData, service: e };
                                });
                              }}
                            />
                              // <select
                              //   className="form-control mt-2"
                              //   isMulti
                              //   value={form.addOn}
                              //   onChange={(event) => {
                              //     setForm((prevData) => {
                              //       return {
                              //         ...prevData,
                              //         addOn: event.target.value,
                              //       };
                              //     });

                              //     // let index = form.addOn.findIndex(
                              //     //   (e) => e == event.target.value
                              //     // );
                              //     // if (index >= 0) {
                              //     //   let data = [];
                              //     //   if (form.addOn.length > 0) {
                              //     //     data = form.addOn.slice(index, 1);
                              //     //   }
                              //     //   setForm((prevData) => {
                              //     //     return {
                              //     //       ...prevData,
                              //     //       addOn: data,
                              //     //     };
                              //     //   });
                              //     // } else {
                              //     //   setForm((prevData) => {
                              //     //     return {
                              //     //       ...prevData,
                              //     //       addOn: form.addOn.push(
                              //     //         event.target.value
                              //     //       ),
                              //     //     };
                              //     //   });
                              //     // }
                              //   }}
                              // >
                              //   <option value="" selected>
                              //     Select Parent Service<body></body>
                              //   </option>
                              //   {allServices.map((val, key) => {
                              //     return (
                              //       <option value={val.name}>{val.name}</option>
                              //     );
                              //   })}
                              // </select>
) }
                          </label>
                        </div>
                      </div>
                    </div> */}

                    <h3 className="mt-4">Visibility</h3>
                    <div className="commonForm rounded-1">
                      <div className="form-group mb-0">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="showServicePage"
                            checked={form.public}
                            onChange={(e) => {
                              setForm((prevData) => {
                                return {
                                  ...prevData,
                                  public: e.target.checked,
                                };
                              });
                            }}
                          />
                          <label
                            className="form-check-label checkbox-label"
                            for="showServePage"
                          >
                            Show in services page <br />
                            <small>
                              Choose whether to list this service in your Client
                              Portal's services page. Service can still be used
                              in order forms.
                            </small>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="btn-section text-end">
                      <button
                        className="btn btn-save"
                        disabled={form.name == ""}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIntakeFormStatus(true);
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      !intakeFormStatus ? "" : "show active"
                    }`}
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabindex="0"
                  >
                    <div className="commonForm mb-3 rounded-1">
                      <div className="form-group col-lg-4 mb-0 filter-drowdown">
                        <label>Select Intake Form</label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setForm((prevData) => {
                              return {
                                ...prevData,
                                intakeForm: e.target.value,
                              };
                            });
                          }}
                          value={form.intakeForm}
                        >
                          <option value="" default selected>
                            No Intake Form
                          </option>
                          <option value="intake_form_1">Intake Form 1</option>
                          <option value="intake_form_2">Intake Form 2</option>
                        </select>
                      </div>
                    </div>

                    <div class="tab-content" id="pills-tabContent">
                      {form.intakeForm == "intake_form_1" && (
                        <div className="intakeForm">
                          <h3>Intake form for writing service</h3>
                          <p>
                            Clients get access to this form after buying your
                            service. Their order will remain{" "}
                            <span>Pending</span> until the form is filled out.
                          </p>
                          <div className="commonForm rounded-1">
                            <div className="form-group">
                              <label>
                                Upload upto 3 photos. Guidelines: Make sure to
                                submit ONLY high quality landscape "horizontral"
                                photos
                              </label>

                              {/* <div className="form-group">
                                <label>
                                  Please tell us more about your brand that we
                                  won't be able to find on your website or any
                                  social media account: (ad least 100 words
                                  minimum) <small>Optional</small>
                                </label>
                                <textarea className="form-control"></textarea>
                              </div> */}
                              <div className="Upldimages">
                                <input type="file" />
                                <i class="fa-solid fa-file-arrow-up"></i>
                                <p>
                                  <span>Browse or</span> drag and drop
                                </p>
                              </div>

                              {/* <div className="previewImg">
                                <span className="ImgSection">
                                  <img src={DummyAvatar} />
                                  <span className="Del">
                                    <i class="fa-solid fa-trash"></i>
                                  </span>
                                </span>
                                <span className="ImgSection">
                                  <img src={DummyAvatar} />
                                  <span className="Del">
                                    <i class="fa-solid fa-trash"></i>
                                  </span>
                                </span>
                              </div> */}
                            </div>
                            <div className="form-group">
                              <label>
                                Who is your audience for this featured article ?{" "}
                                <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>
                            <div className="form-group">
                              <label>
                                What is the name of your brand?{" "}
                                <small>Optional</small>
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                              <label>
                                Is there a special quote about your brand that
                                you want us to use? if yes, what is it?{" "}
                                <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please tell us more about your brand that we
                                won't be able to find on your website or any
                                social media account: (ad least 100 words
                                minimum) <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please provide us your website, social media
                                links <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please give us your brand bio (atleast 100 words
                                minimum) <small>Optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group mb-0">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="showServicePage"
                                />
                                <label
                                  className="form-check-label checkbox-label mb-0"
                                  for="showServicePage"
                                >
                                  Approval and Confirmation <br />
                                  <small>
                                    I approve this content to get publish, so
                                    long as it passes the publication's
                                    guidelines
                                  </small>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {form.intakeForm == "intake_form_2" && (
                        <div className="intakeForm mb-4">
                          <h3>Intake form for writing service</h3>
                          <p>
                            Clients get access to this form after buying your
                            service. Their order will remain{" "}
                            <span>Pending</span> until the form is filled out.
                          </p>
                          <div className="commonForm">
                            <div className="form-group">
                              <label>
                                Please pick the publication you want this
                                article to get published in
                              </label>
                              <select className="form-control">
                                <option>Astist Weekly</option>
                                <option>Astist Weekly</option>
                                <option>Astist Weekly</option>
                              </select>
                            </div>

                            <div className="form-group">
                              <label>
                                If you wrote your article. Paste a Google Doc
                                link here. (Make sure the link access is set to
                                "Anyone With the Link") Ignore this section if
                                you want us to write your article.{" "}
                                <small>Optional</small>
                              </label>

                              <textarea className="form-control"></textarea>
                              <small>
                                Click this link to see out photo submission
                                guide: <br />{" "}
                                <a
                                  className="text-decoration-none text-muted"
                                  href=""
                                  target="_blank"
                                >
                                  https://docs.google.com/document/d/1JqbZZdyzYkLjC95ps-N29sv1dDt-kEhtX9yEpVx5uw0/edit#
                                </a>
                              </small>
                            </div>

                            <div className="form-group">
                              <label>
                                Upload Up to 3 photos. Guidelines: Make sure to
                                submit ONLY high quality landscape "horizontal"
                                photos
                              </label>
                              <div class="Upldimages">
                                <input type="file" />
                                <i class="fa-solid fa-file-arrow-up"></i>
                                <p>
                                  <span>Browse or</span> drag and drop
                                </p>
                              </div>
                            </div>

                            <div className="form-group">
                              <label>
                                Who is your audience for this featured acticle?{" "}
                                <small>Optional</small>
                              </label>
                              <input type="" className="form-control" />
                            </div>

                            <div className="form-group">
                              <label>
                                What is your brand? <small>optional</small>
                              </label>
                              <input type="" className="form-control" />
                            </div>

                            <div className="form-group">
                              <label>
                                Is there a special quote about your brand that
                                you want us to use? If yes, what is it?{" "}
                                <small>optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please tell us more about your brand that we
                                won't be able to find on your website or any
                                social media account: (al least 100 word
                                minimum) <small>optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please provide us your website, social media
                                links <small>optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group">
                              <label>
                                Please give us your brand bio (at least 100 word
                                minimum) <small>optional</small>
                              </label>
                              <textarea className="form-control"></textarea>
                            </div>

                            <div className="form-group mb-0">
                              <div class="form-check">
                                <input
                                  class="form-check-input checkbox-label"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  class="form-check-label mb-0"
                                  for="flexCheckDefault"
                                >
                                  Approval and Confirmation <br />{" "}
                                  <small>
                                    I approve this content to get publish, so
                                    long as it passes the publication's
                                    guidelines
                                  </small>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="btn-section text-end">
                        <button
                          className="btn btn-save"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFormSubmit();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditService;

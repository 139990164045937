import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import { saveLoginUserAccessToken } from "../../redux/action/login";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import moment from "moment";
import noData from "../../assets/img/nodata-img.svg";

const ServiceReport = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [service, setService] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiDeleteMethod,
    apiPutMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { DASHBOARD, DASHBOARD_DATE_FILTER } = allApiUrl;
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");
    getAllServices();
  }, []);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // if (end) {
    //   getDateFilterData(start, end);
    //   setOpen(false);
    // }
    getDateFilterData(start, end, 'datePicker');
    setOpen(false);
  };

  const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
  const currentList = service.slice(itemOffset, endOffset);
  let pageCount = Math.ceil(service.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % service.length;
    setItemOffset(newOffset);
  };

  function getAllServices() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiGetMethod(DASHBOARD, "", header)
      .then((res) => {
        let servicesList = []
        res.data.data.serviceData.forEach((item) => {
          if (item.unitsSold > 0) {
            servicesList.push(item)
          }
        })
        setService(servicesList);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function getDateFilterData(start, end, type) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {};
    if (type == "datePicker") {
      if (end && end != startDate) {
        data = {
          startDate: startDate,
          endDate: end,
        };
      } else {
        data = {
          startDate: start ? start : startDate,
        };
      }
    }
    else {
      data = {
        startDate: start,
        endDate: end,
      }
    }
    setIsLoading(true);
    apiPostMethod(DASHBOARD_DATE_FILTER, data, header)
      .then((res) => {
        let servicesList = []
        res.data.data.serviceData.forEach((item) => {
          if (item.unitsSold > 0) {
            servicesList.push(item)
          }
        })
        setService(servicesList);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function openCalender(e) {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }
  function customDateSelector(type) {
    if (type === "Last 7 Days") {
      const today = new Date();
      let start = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000))
      // setGraphTitle(type)
      setStartDate(start);
      setEndDate(today);
      getDateFilterData(start, today, "custom");
    }
    else if (type == "Previous Month") {
      // setGraphTitle(type)
      const today = new Date();
      const start = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);
      const end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
      setStartDate(start);
      setEndDate(end);
      getDateFilterData(start, end, "custom");
    }
    else if (type === "Last 3 Months") {
      // setGraphTitle(type)
      const today = new Date();
      const start = new Date(today.getFullYear(), today.getMonth() - 3, 1, 0, 0, 0);
      const end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
      setStartDate(start);
      setEndDate(end);
      getDateFilterData(start, end, "custom");
    }
    else if (type === "Month to Date") {
      // setGraphTitle(type)
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      getDateFilterData(firstDay, date, "custom");
    }
    else if (type == "Quarter to Date") {
      // setGraphTitle(type)
      const today = new Date();
      const currentQuarter = Math.floor((today.getMonth() / 3));
      const year = today.getFullYear();
      let quarterStart = new Date(year, currentQuarter * 3, 1);
      getDateFilterData(quarterStart, today, "custom");
    }
    else if (type == "Year to Date") {
      // setGraphTitle(type)
      let date = new Date()
      var startDate = new Date(date.getFullYear(), 0, 1);
      getDateFilterData(startDate, date, "custom");
    }
  }
  return (
    <div>
      <Sidebar
        activePage="dashboard"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      {!isLoading && (
        <div className="dashboardSection commonPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6">
                <div className="heading">
                  <h2>Services</h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="dashboardFilters">
                  <div className="dropdown">
                    <button
                      class="btn btn-secondary dropdown-toggle dashboardDropdown"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ width: "170px" }}
                    >
                      Services
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="/revenue">
                          Revenue
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/service-report">
                          Services
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/client-report">
                          Clients
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/sales">
                          Sale Performance
                        </a>
                      </li>
                      {/* <li><a class="dropdown-item" href="#">Account Balances</a></li>
                    <li><a class="dropdown-item" href="#">Coupon usage</a></li>
                    <li><a class="dropdown-item" href="#">Response times</a></li>
                    <li><a class="dropdown-item" href="#">Assigned orders</a></li>
                    <li><a class="dropdown-item" href="/completed-order">Completed orders</a></li>
                    <li><a class="dropdown-item" href="/order-status">Order statuses</a></li>
                    <li><a class="dropdown-item" href="#">Taxes</a></li> */}
                    </ul>
                    {/* <button
                    class="btn btn-secondary dropdown-toggle dashboardDropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Export
                  </button> */}
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="#">
                          Accounts
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Orders
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Transactions
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Invoices
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Form Date
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Revenue By Client
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Services
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Ticket Rating
                        </a>
                      </li>
                    </ul>
                    {/* <input type="date" className="form-control" /> */}
                    <input
                      className="form-control"
                      type="text"
                      id="expiryDate"
                      onClick={(e) => {
                        openCalender(e);
                      }}
                      value={`${startDate ? startDate : ""}, ${endDate ? endDate : ""
                        }`}
                    />
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa-solid fa-calendar"></i>
                    </button>
                    <ul class="dropdown-menu">
                      {["Last 7 Days", "Previous Month", "Last 3 Months", "Month to Date", "Quarter to Date", "Year to Date", "All Time"].map((item) => {
                        return (
                          <li
                            onClick={() => customDateSelector(item)}
                          >
                            <a class="dropdown-item" href="#">
                              {item}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                    {open === true ? (
                      <span className="tooltipdatepicker">
                        <DatePicker
                          maxDate={moment().toDate()}
                          className=""
                          selected={startDate}
                          onChange={onChange}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          inline
                        />
                        <span className="tooltipdatepickertext">
                          Get date range by dragging on "FROM" date
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xxl-12">
                <div className="listingSection mt-4">
                  <div className="common-table rounded-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Service Name</th>
                          <th>Units Sold</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentList &&
                          currentList.length > 0 &&
                          currentList.map((item) => {
                            return (
                              <tr>
                                <td>{item.serviceName}</td>
                                <td>{item.unitsSold}</td>
                                <td>${item.revenueGenerated}</td>
                              </tr>
                            );
                          })}
                        {currentList && currentList.length <= 0 && (
                          <tr>
                            <td colSpan={7}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="sectionPagination">
                  <ReactPaginate
                    nextLabel={<i class="fa-solid fa-angles-right"></i>}
                    previousLabel={<i class="fa-solid fa-angles-left"></i>}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination paginate"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ServiceReport;

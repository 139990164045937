import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { apiPutMethod, apiGetMethod } from "../../api/rest";
import { AllContext } from "../../context";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditTicket = (props) => {
  let location = useLocation();
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [value, setValue] = useState();
  const [editvalue, setEditValue] = useState([]);
  const [editId, setEditId] = useState();
  const [showToEmail, setShowToEmail] = useState(false);
  const [addTickets, setAddTickets] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [realtedTicket, setRelatedTicket] = useState("");
  const [clientOrder, setClientOrder] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [ticket, setTicket] = useState({
    subject: "",
    client: {},
  });
  let { id } = useParams();
  const context = useContext(AllContext);
  const { allApiUrl, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const { EDIT_TICKET, GET_ALL_CLIENT, GET_TICKET, CLIENT_ORDER_ID } =
    allApiUrl;
  useEffect(() => {
    getTickets();
    getAllClient();
  }, []);

  const getTickets = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_TICKET + "/" + id, "", header).then((res) => {
      if (res.status === 200) {
        setTicket(res.data.details[0]);
        setRelatedTicket(res.data.details[0].relatedOrder._id);
      }
    });
  };

  useEffect(() => {
    if (ticket.client._id) {
      getClientOrders(ticket.client._id);
    }
  }, [ticket.client._id]);

  function getClientOrders() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(CLIENT_ORDER_ID + "/" + ticket.client._id, "", header)
      .then((res) => {
        setClientOrder(res.data.order);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  function getAllClient() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(GET_ALL_CLIENT, "", header).then((res) => {
      if (res.status === 200) {
        setAllClient(res.data.team);
      }
    });
  }

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const editTickets = () => {
    const data = {
      subject: ticket.subject,
      relatedOrder: realtedTicket,
      client: ticket.client._id,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(EDIT_TICKET + "/" + id, data, header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage("success", res.data.message);
          navigate(`/view-ticket/${id}`);
        }
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Sidebar
        activePage="tickets"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />

      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-back">
                <a href="/tickets">
                  {" "}
                    <i class="fa-solid fa-angle-left"></i> Tickets{" "}
                </a>
              </div>
            </div>

            <div className="col-lg-5 mx-auto">
              <div className="setting-form">
                <h3 className="fs-4">Edit ticket</h3>
                  <form>
                  <div className="commonForm rounded-1">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Subject</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={ticket.subject}
                            onChange={(e) =>
                              setTicket((prevData) => {
                                return { ...prevData, subject: e.target.value };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>
                            Related order <small>optional</small>
                          </label>
                          <select
                            className="form-control"
                            value={realtedTicket}
                            onChange={(event) => {
                              setRelatedTicket(event.target.value);
                            }}
                          >
                            <option value="" selected>
                              Please Select... <body></body>
                            </option>
                            {clientOrder.map((value) => {
                              return (
                                <option value={value._id}>{value.title}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group position-relative">
                          <label>Select Client</label>
                          <input
                            className="form-control"
                            onClick={() => {
                              setShowToEmail(true);
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                setShowToEmail(false);
                              }, 300);
                            }}
                            value={ticket?.client?.email}
                          />
                          <ul className="dataDropdown">
                            {showToEmail &&
                              allClient &&
                              allClient.map((client) => {
                                if (client.userId?.email)
                                  return (
                                    <li
                                      onClick={() => {
                                        setShowToEmail(!showToEmail);
                                        setAddTickets(client.userId.email);
                                        setTicket((prevData) => {
                                          return {
                                            ...prevData,
                                            client: client.userId,
                                          };
                                        });
                                      }}
                                    >
                                      {client.userId.firstName ||
                                      client.userId.lastName ? (
                                        <>
                                          {client.userId.firstName &&
                                          client.userId.lastName ? (
                                            client.userId.firstName +
                                            " " +
                                            client.userId.lastName
                                          ) : (
                                            <>
                                              {client.userId.firstName
                                                ? client.userId.firstName
                                                : client.userId.lastName}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        client.userId.email
                                      )}
                                      <br />
                                      <small>{client.userId.email}</small>
                                    </li>
                                  );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div className="btn-section text-end">
                      <button
                        type="button"
                        className="btn btn-save"
                        onClick={() => {
                          editTickets(editId);
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditTicket;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCSVReader, useCSVDownloader } from "react-papaparse";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/avatar.png";
import noData from "../../assets/img/nodata-img.svg";
import { AllContext } from "../../context";
// import { saveCsvDataHandler } from "../../redux/action/login";
import { loginAPi } from "../../api/authApi/login";
import ReactPaginate from "react-paginate";
import PageLoader from "../../components/pageLoader";
import Progress_bar from "../../components/ProgressBar";
const Clients = () => {
  //useState
  const [isLoading, setIsLoading] = useState(false);
  const [allClientList, setAllClientList] = useState([]);
  const [contactClientList, setContactClientList] = useState([]);
  const [leadClientList, setLeadClientList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [clientTeamList, setClientTeamList] = useState([]);
  const [multiDeleteId, setMultiDeleteId] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [isReset, setIsReset] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [isImportModuleVisible, setIsImportModuleVisible] = useState(false);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [activeClientList, setActiveClientList] = useState([]);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [itemsPerPage, setItemsPerPage] = useState(20);
  //contents
  const navigate = useNavigate();
  const { CSVReader } = useCSVReader();
  const { CSVDownloader, Type } = useCSVDownloader();

  const context = useContext(AllContext);
  const {
    apiPostMethod,
    apiGetMethod,
    allApiUrl,
    apiDeleteMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { GET_ALL_CLIENT, DELETE_CLIENT } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const userInfoRoles = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const dispatch = useDispatch();
  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  //functions
  useEffect(() => {
    // setIsLoading(true);
    getClientList();
    // getActiveClients();
  }, []);
  function getActiveClients() {
    apiGetMethod(allApiUrl.GET_ACTIVE_CLIENTS, "", header)
      .then((res) => {
        if (res.status === 200) {
          setActiveClientList(res.data);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function deleteClient(couponId) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(DELETE_CLIENT, couponId, header)
      .then((res) => {
        // handleToClose();
        toastMessage("success", res.data.message);
        getClientList();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);

        // setIsLoading(false);
      });
  }
  function getClientList() {
    apiGetMethod(GET_ALL_CLIENT, "", header)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.team) {
            setAllClientList(res.data.team);
            setClientList(
              res.data.team.filter((item) => {
                return item.role === "client";
              })
            );
            setClientTeamList(
              res.data.team.filter((item) => {
                return item.role === "client team";
              })
            );
            setLeadClientList(
              res.data.team.filter((item) => {
                return item.role === "lead";
              })
            );
            setContactClientList(
              res.data.team.filter((item) => {
                return item.role === "contact";
              })
            );
          }
          // setIsLoading(false);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
        //  const dispatch = useDispatch();
      });
  }
  function deleteMultiple() {
    setIsLoading(true);
    multiDeleteId.map((row) => {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiDeleteMethod(DELETE_CLIENT, row, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          getClientList();
          setIsLoading(false);
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    });
  }

  function checkedAll() {
    if (contactClientList.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = contactClientList.map((e) => {
        return e.userId?._id;
      });
      setMultiDeleteId(ids);
    }
  }
  function checkedAllLead() {
    if (leadClientList.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = leadClientList.map((e) => {
        return e.userId?._id;
      });
      setMultiDeleteId(ids);
    }
  }

  function checkedAllClient() {
    if (clientList.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = clientList.map((e) => {
        return e.userId?._id;
      });
      setMultiDeleteId(ids);
    }
  }
  function checkedAllClientTeam() {
    if (clientTeamList.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = clientTeamList.map((e) => {
        return e.userId?._id;
      });
      setMultiDeleteId(ids);
    }
  }
  function checkedAllList() {
    if (allClientList.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = allClientList.map((e) => {
        return e.userId?._id;
      });
      setMultiDeleteId(ids);
    }
  }

  useEffect(() => {
    setMultiDeleteId([]);
  }, [isReset]);
  function clearCheckAll() {
    isSingleChecked(false);
    setIsReset(!isReset);
  }
  function deleteIdList(id, status) {

    if (status) {
      setMultiDeleteId([id]);
    } else {
      let exist = multiDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        multiDeleteId.push(id);
        setMultiDeleteId(multiDeleteId);
      } else {
        let exist = multiDeleteId.findIndex((e) => e == id);
        let ids = multiDeleteId.splice(exist + 1, 1);
        setMultiDeleteId(ids);
      }
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  const roleList = useSelector(
    (state) => state.loginReducer.loginUserInfo.roles
  );
  const { clients: clientRoles } = roleList;

  const [itemOffsetContact, setItemOffsetContact] = useState(0);
  const [itemOffsetLead, setItemOffsetLead] = useState(0);
  const [itemOffsetClient, setItemOffsetClient] = useState(0);
  const [itemOffsetClientTeam, setItemOffsetClientTeam] = useState(0);
  const [itemOffsetActiveClient, setItemOffsetActiveClient] = useState(0);
  const [itemOffsetAll, setItemOffsetAll] = useState(0);

  const endOffset = parseInt(itemOffsetAll) + parseInt(itemsPerPage);
  const currentItems = allClientList.slice(itemOffsetAll, endOffset);
  let pageCount = Math.ceil(allClientList.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allClientList.length;
    setItemOffsetAll(newOffset);
  };

  //for team member
  const endOffsetTeamMember =
    parseInt(itemOffsetClientTeam) + parseInt(itemsPerPage);
  const currentItemClietTeam = clientTeamList.slice(
    itemOffsetClientTeam,
    endOffsetTeamMember
  );
  let pageCountClientTeam = Math.ceil(clientTeamList.length / itemsPerPage);
  const handlePageClickClienTeam = (event) => {
    const newOffset = (event.selected * itemsPerPage) % clientTeamList.length;
    setItemOffsetClientTeam(newOffset);
  };

  //for  client
  const endOffsetClient =
    parseInt(itemOffsetClientTeam) + parseInt(itemsPerPage);
  const currentItemClient = clientList.slice(itemOffsetClient, endOffsetClient);
  let pageCountClientList = Math.ceil(clientList.length / itemsPerPage);
  const handlePageClickClientList = (event) => {
    const newOffset = (event.selected * itemsPerPage) % clientList.length;
    setItemOffsetClient(newOffset);
  };
  //for lead contact
  const endOffsetLead = parseInt(itemOffsetLead) + parseInt(itemsPerPage);
  const currenLeadList = leadClientList.slice(itemOffsetLead, endOffsetLead);
  let pageCountLeadList = Math.ceil(leadClientList.length / itemsPerPage);
  const handlePageClickLeadList = (event) => {
    const newOffset = (event.selected * itemsPerPage) % leadClientList.length;
    setItemOffsetLead(newOffset);
  };
  //for contact
  const endOffsetContact = parseInt(itemOffsetContact) + parseInt(itemsPerPage);
  const currenContactList = contactClientList.slice(
    itemOffsetContact,
    endOffsetContact
  );
  let pageCountContactList = Math.ceil(contactClientList.length / itemsPerPage);
  const handlePageClickContactList = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % contactClientList.length;
    setItemOffsetContact(newOffset);
  };
  // For active Client
  const endOffsetContactActive =
    parseInt(itemOffsetActiveClient) + parseInt(itemsPerPage);
  const currActiveClient = activeClientList.slice(
    itemOffsetActiveClient,
    endOffsetContactActive
  );
  let activeClientCount = Math.ceil(activeClientList.length / itemsPerPage);
  const handleActiveClient = (event) => {
    const newOffset = (event.selected * itemsPerPage) % activeClientList.length;
    setItemOffsetActiveClient(newOffset);
  };

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }

  return (
    <div>
      <Sidebar
        activePage="clients"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      {isImportModuleVisible ? (
        <ImportModule csvData={csvData} />
      ) : (
        <>
          {!initialLoading && (
            <div className="orders-section commonPadding horizontalPadding">
              <div className="container-fluid pl-240">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="heading">
                      <h2>Clients</h2>
                    </div>
                  </div>
                  {clientRoles.add_edit_login && (
                    <div className="col-lg-6">
                      <div className="section-filter">
                        <div className="dropdown">
                          <a className="add-btn" href="/add-client">
                            Add Client
                          </a>
                          {/* <button
                        className="btn btn-secondary dropdown-toggle btn-info"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#importModal"
                          >
                            Import
                          </a>
                        </li>
                      </ul> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="common-tabs">
                      <ul
                        className="nav nav-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li
                          className="nav-item"
                          role="presentation"
                        // onClick={() => setMultiDeleteId([])}
                        >
                          <button
                            className="nav-link "
                            id="pills-overdue-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-overdue"
                            type="button"
                            role="tab"
                            aria-controls="pills-overdue"
                            aria-selected="true"
                            onClick={() => clearCheckAll()}
                          >
                            <span>{contactClientList.length}</span>Contact
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-submitted-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-submitted"
                            type="button"
                            role="tab"
                            aria-controls="pills-submitted"
                            aria-selected="false"
                            onClick={() => clearCheckAll()}
                          >
                            <span>{leadClientList.length}</span>Lead
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-working-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-working"
                            type="button"
                            role="tab"
                            aria-controls="pills-working"
                            aria-selected="false"
                          // onClick={() => clearCheckAll()}
                          >
                            <span>{clientList.length}</span>Client
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-complete-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-complete"
                            type="button"
                            role="tab"
                            aria-controls="pills-complete"
                            aria-selected="false"
                          // onClick={() => clearCheckAll()}
                          >
                            <span>{clientTeamList.length}</span> Client Team
                          </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-active-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-active"
                            type="button"
                            role="tab"
                            aria-controls="pills-active"
                            aria-selected="false"
                          >
                            <span>{activeClientList.length}</span>
                            Active Clients
                          </button>
                        </li> */}
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-all-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-all"
                            type="button"
                            role="tab"
                            aria-controls="pills-all"
                            aria-selected="false"
                          >
                            All
                          </button>
                        </li>
                      </ul>

                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade"
                          id="pills-overdue"
                          role="tabpanel"
                          aria-labelledby="pills-overdue-tab"
                          tabindex="0"
                        >
                          <div className="common-table clients-table rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="ps-4 w65-px">
                                    {clientRoles._delete && (
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="item1"
                                          checked={
                                            contactClientList?.length ==
                                            multiDeleteId?.length &&
                                            contactClientList?.length > 0
                                          }
                                          onClick={() => checkedAll()}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="item1"
                                        ></label>
                                      </div>
                                    )}
                                  </th>
                                  <th>Name</th>
                                  {/* <th>Company</th>
                                  <th>Account Manager</th> */}
                                  <th>Created on</th>
                                  <th>Status</th>
                                  {/* <th>Progress Bar</th> */}
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* no record found */}
                                {/* <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr> */}
                                {/* no record found */}
                                {currenContactList.map((item) => {
                                  const date = new Date(
                                    item?.userId?.createdOn
                                  );
                                  const monthName = date.toLocaleString(
                                    "default",
                                    {
                                      month: "short",
                                    }
                                  );
                                  const day = date.getDate();
                                  const updatedDate = `${monthName} ${day}`;
                                  let percentage = 0;
                                  activeClientList.forEach((activeItem) => {
                                    if (
                                      activeItem.user._id == item.userId._id
                                    ) {
                                      if (
                                        activeItem.filter == 0 &&
                                        activeItem.total == 0
                                      ) {
                                        percentage = 0;
                                      } else {
                                        percentage = (
                                          (activeItem.filter /
                                            activeItem.total) *
                                          100
                                        ).toFixed(2);
                                      }
                                    }
                                  });
                                  return (
                                    <tr>
                                      <td className="ps-4 w65-px">
                                        {clientRoles._delete && (
                                          <div className="form-check">
                                            <input
                                              type="checkbox"
                                              class="form-check-input"
                                              id={"contact" + item.userId?._id}
                                              checked={multiDeleteId.find(
                                                (e) => e === item.userId?._id
                                              )}
                                              onClick={(e) => {
                                                setIsSingleChecked(
                                                  e.target.checked
                                                );
                                                deleteIdList(
                                                  item.userId?._id,
                                                  currenContactList.length ==
                                                  multiDeleteId.length
                                                );
                                              }}
                                            />
                                            <label
                                              class="form-check-label"
                                              for={"contact" + item.userId?._id}
                                            ></label>
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        <div className="clientSection"
                                          onClick={() => {
                                            navigate(
                                              `/view-client/${item.userId?._id}`
                                            )
                                          }
                                          }
                                        >
                                          <div className="img">
                                            <img
                                              src={
                                                item.userId && item.userId.image
                                                  ? item.userId.image
                                                  : Avatar
                                              }
                                            />
                                          </div>
                                          <div className="clientName">
                                            <p
                                            >
                                              <a>
                                                {`${item.userId?.firstName || ""
                                                  } ${item.userId?.lastName || ""
                                                  }`}
                                              </a>
                                            </p>
                                            <p className="clientEmail">
                                              {item.userId?.email}
                                            </p>
                                          </div>
                                        </div>
                                      </td>

                                      {/* <td>{item.company}</td> */}
                                      <td>
                                        {item.accountManager
                                          ? item.accountManager.firstName +
                                          " " +
                                          item.accountManager.lastName
                                          : ""}
                                      </td>
                                      {/* <td>
                                        {item.accountManager
                                          ? item.accountManager.firstName +
                                            " " +
                                            item.accountManager.lastName
                                          : ""}
                                      </td> */}
                                      <td>{updatedDate}</td>
                                      <td>
                                        <span className="pill pill-grey fw-normal fs-12">
                                          {item.role}
                                        </span>
                                      </td>
                                      {/* <td>$0.00</td> */}
                                      {/* <td>
                                        <Progress_bar
                                          bgcolor="#46850d"
                                          progress={percentage}
                                          height={10}
                                        />
                                      </td> */}
                                      <td>
                                        {clientRoles.add_edit_login && (
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-secondary dropdown-toggle btn-info"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/edit-client/${item.userId?._id}`
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Sign in as user
                                            </a>
                                          </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  onClick={() =>
                                                    navigate(`/add-invoice`, {
                                                      state: {
                                                        selectedUser:
                                                          item.userId?._id,
                                                      },
                                                    })
                                                  }
                                                >
                                                  New invoice
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  // href="#"
                                                  onClick={() =>
                                                    navigate("/add-ticket", {
                                                      state: {
                                                        mailTo: item.userId,
                                                        from: "client",
                                                      },
                                                    })
                                                  }
                                                >
                                                  New ticket
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Merge
                                            </a>
                                          </li> */}
                                              {clientRoles._delete && (
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal"
                                                    onClick={() => {
                                                      setOpen(true);
                                                      setClientId(
                                                        item.userId?._id
                                                      );
                                                      setClientName(
                                                        item.userId?.email
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                  </a>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {currenContactList.length <= 0 && (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="sectionPagination">
                            <NoOfRows />
                            <ReactPaginate
                              nextLabel={
                                <i class="fa-solid fa-angles-right"></i>
                              }
                              previousLabel={
                                <i class="fa-solid fa-angles-left"></i>
                              }
                              onPageChange={handlePageClickContactList}
                              pageCount={pageCountContactList}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination paginate"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                          {(multiDeleteId.length > 0 || isSingleChecked) && (
                            <div className="table-bottom-option">
                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  <div className="input-group">
                                    <select
                                      id="selectOption"
                                      value={selectOption.target}
                                      onChange={(e) => selectOption(e)}
                                      className="form-control"
                                    >
                                      <option value="">With Selected...</option>
                                      <option value="Delete">Delete</option>
                                      {/* <option value="Email">
                                    Send Welcome Email
                                  </option> */}
                                    </select>
                                    <button
                                      className="btn btn-apply"
                                      onClick={() => {
                                        if (selectedOption == "Delete") {
                                          deleteMultiple();
                                        }
                                      }}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-submitted"
                          role="tabpanel"
                          aria-labelledby="pills-submitted-tab"
                          tabindex="0"
                        >
                          <div className="common-table  clients-table  rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="ps-4 w65-px">
                                    {clientRoles._delete && (
                                      <div className="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="item2"
                                          checked={
                                            leadClientList.length ==
                                            multiDeleteId.length &&
                                            leadClientList.length > 0
                                          }
                                          onClick={() => checkedAllLead()}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="item2"
                                        ></label>
                                      </div>
                                    )}
                                  </th>
                                  <th>Name</th>
                                  {/* <th>Company</th> */}
                                  {/* <th>Account Manager</th> */}
                                  <th>Created on</th>
                                  <th>Status</th>
                                  {/* <th>Balance</th> */}
                                  {/* <th>Progress Bar</th> */}
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {currenLeadList.map((item) => {
                                  const date = new Date(
                                    item?.userId?.createdOn
                                  );
                                  const monthName = date.toLocaleString(
                                    "default",
                                    {
                                      month: "short",
                                    }
                                  );
                                  const day = date.getDate();
                                  const updatedDate = `${monthName} ${day}`;
                                  let percentage = 0;
                                  activeClientList.forEach((activeItem) => {
                                    if (
                                      activeItem.user._id == item.userId._id
                                    ) {
                                      if (
                                        activeItem.filter == 0 &&
                                        activeItem.total == 0
                                      ) {
                                        percentage = 0;
                                      } else {
                                        percentage = (
                                          (activeItem.filter /
                                            activeItem.total) *
                                          100
                                        ).toFixed(2);
                                      }
                                    }
                                  });
                                  return (
                                    <tr>
                                      <td className="ps-4 w65-px">
                                        {clientRoles._delete && (
                                          <div className="form-check">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              id={"lead" + item.userId?._id}
                                              checked={multiDeleteId.find(
                                                (e) => e === item.userId?._id
                                              )}
                                              onClick={(e) => {
                                                setIsSingleChecked(
                                                  e.target.checked
                                                );
                                                deleteIdList(
                                                  item.userId?._id,
                                                  currenLeadList.length ==
                                                  multiDeleteId.length
                                                );
                                              }}
                                            />
                                            <label
                                              class="form-check-label"
                                              for={"lead" + item.userId?._id}
                                            ></label>
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        <div className="clientSection"
                                          onClick={() =>
                                            navigate(
                                              `/view-client/${item.userId?._id}`
                                            )
                                          }
                                        >
                                          <div className="img">
                                            <img
                                              src={
                                                item.userId.image &&
                                                  item.userId.image != ""
                                                  ? item.userId.image
                                                  : Avatar
                                              }
                                            />
                                          </div>
                                          <div className="clientName">
                                            <p>
                                              <a>
                                                {`${item.userId?.firstName || ""
                                                  } ${item.userId?.lastName || ""
                                                  }`}
                                              </a>
                                            </p>
                                            <p className="clientEmail">
                                              {item.userId?.email}
                                            </p>
                                          </div>
                                        </div>
                                      </td>

                                      {/* <td>{item.company}</td> */}
                                      {/* <td>
                                        {item.accountManager
                                          ? item.accountManager.firstName +
                                          " " +
                                          item.accountManager.lastName
                                          : ""}
                                      </td> */}
                                      <td>{updatedDate}</td>
                                      <td>
                                        <span className="pill pill-grey fw-normal fs-12">
                                          {item.role}
                                        </span>
                                      </td>
                                      {/* <td>$0.00</td> */}
                                      {/* <td>
                                        <Progress_bar
                                          bgcolor="#46850d"
                                          progress={percentage}
                                          height={10}
                                        />
                                      </td> */}
                                      <td>
                                        {clientRoles.add_edit_login && (
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-secondary dropdown-toggle btn-info"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/edit-client/${item.userId?._id}`
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Sign in as user
                                            </a>
                                          </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  onClick={() =>
                                                    navigate("/add-invoice", {
                                                      state: {
                                                        selectedUser:
                                                          item.userId?._id,
                                                      },
                                                    })
                                                  }
                                                >
                                                  New invoice
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  // href="#"
                                                  onClick={() =>
                                                    navigate("/add-ticket", {
                                                      state: {
                                                        mailTo: item.userId,
                                                        from: "client",
                                                      },
                                                    })
                                                  }
                                                >
                                                  New ticket
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Merge
                                            </a>
                                          </li> */}
                                              {clientRoles._delete && (
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal"
                                                    onClick={() => {
                                                      setOpen(true);
                                                      setClientId(
                                                        item.userId?._id
                                                      );
                                                      setClientName(
                                                        item.userId?.email
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                  </a>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {currenLeadList.length <= 0 && (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="sectionPagination">
                            <NoOfRows />
                            <ReactPaginate
                              nextLabel={
                                <i class="fa-solid fa-angles-right"></i>
                              }
                              previousLabel={
                                <i class="fa-solid fa-angles-left"></i>
                              }
                              onPageChange={handlePageClickLeadList}
                              pageRangeDisplayed={5}
                              pageCount={pageCountLeadList}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination paginate"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                          {(multiDeleteId.length > 0 || isSingleChecked) && (
                            <div className="table-bottom-option">
                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  {/* <div className="input-group">
                            <select className="form-control">
                              <option>With Selected...</option>
                              <option>Delete</option>
                              <option>Send Welcome Email</option>
                            </select>
                            <button className="btn btn-apply">Apply</button>
                          </div> */}
                                  <div className="input-group">
                                    <select
                                      id="selectOption"
                                      value={selectOption.target}
                                      onChange={(e) => selectOption(e)}
                                      className="form-control"
                                    >
                                      <option value="">With Selected...</option>
                                      <option value="Delete">Delete</option>
                                      {/* <option value="Email">
                                    Send Welcome Email
                                  </option> */}
                                    </select>
                                    <button
                                      className="btn btn-apply"
                                      onClick={() => {
                                        if (selectedOption == "Delete") {
                                          deleteMultiple();
                                        }
                                      }}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-working"
                          role="tabpanel"
                          aria-labelledby="pills-working-tab"
                          tabindex="0"
                        >
                          <div className="common-table clients-table rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="ps-4 w65-px">
                                    {clientRoles._delete && (
                                      <div className="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="item3"
                                          checked={
                                            clientList.length ==
                                            multiDeleteId.length &&
                                            clientList.length > 0
                                          }
                                          onClick={() => checkedAllClient()}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="item3"
                                        ></label>
                                      </div>
                                    )}
                                  </th>
                                  <th>Name</th>
                                  {/* <th>Company</th> */}
                                  {/* <th>Account Manager</th> */}
                                  <th>Created on</th>
                                  <th>Status</th>
                                  {/* <th>Balance</th> */}
                                  {/* <th>Progress Bar</th> */}
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItemClient.map((item) => {
                                  const date = new Date(
                                    item?.userId?.createdOn
                                  );
                                  const monthName = date.toLocaleString(
                                    "default",
                                    {
                                      month: "short",
                                    }
                                  );
                                  const day = date.getDate();
                                  const updatedDate = `${monthName} ${day}`;
                                  let percentage = 0;
                                  activeClientList.forEach((activeItem) => {
                                    if (
                                      activeItem.user._id == item.userId._id
                                    ) {
                                      if (
                                        activeItem.filter == 0 &&
                                        activeItem.total == 0
                                      ) {
                                        percentage = 0;
                                      } else {
                                        percentage = (
                                          (activeItem.filter /
                                            activeItem.total) *
                                          100
                                        ).toFixed(2);
                                      }
                                    }
                                  });
                                  return (
                                    <tr>
                                      <td className="ps-4 w65-px">
                                        {clientRoles._delete && (
                                          <div className="form-check">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              id={"client" + item.userId?._id}
                                              checked={multiDeleteId.find(
                                                (e) => e === item.userId?._id
                                              )}
                                              onClick={(e) => {
                                                setIsSingleChecked(
                                                  e.target.checked
                                                );
                                                deleteIdList(
                                                  item.userId?._id,
                                                  currentItemClient.length ==
                                                  multiDeleteId.length
                                                );
                                              }}
                                            />
                                            <label
                                              class="form-check-label"
                                              for={"client" + item.userId?._id}
                                            ></label>
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        <div className="clientSection"
                                          onClick={() =>
                                            navigate(
                                              `/view-client/${item.userId?._id}`
                                            )
                                          }
                                        >
                                          <div className="img">
                                            <img
                                              src={
                                                item.userId.image &&
                                                  item.userId.image != ""
                                                  ? item.userId.image
                                                  : Avatar
                                              }
                                            />
                                          </div>
                                          <div className="clientName">
                                            <p

                                            >
                                              <a>
                                                {`${item.userId?.firstName || ""
                                                  } ${item.userId?.lastName || ""
                                                  }`}
                                              </a>
                                            </p>
                                            <p className="clientEmail">
                                              {item.userId?.email}
                                            </p>
                                          </div>
                                        </div>
                                      </td>

                                      {/* <td>{item.company}</td> */}
                                      {/* <td>
                                        {item.accountManager
                                          ? item.accountManager.firstName +
                                          " " +
                                          item.accountManager.lastName
                                          : ""}
                                      </td> */}
                                      <td>{updatedDate}</td>
                                      <td>
                                        <span className="pill pill-grey fw-normal fs-12">
                                          {item.role}
                                        </span>
                                      </td>
                                      {/* <td>$0.00</td> */}
                                      {/* <td>
                                        <Progress_bar
                                          bgcolor="#46850d"
                                          progress={percentage}
                                          height={10}
                                        />
                                      </td> */}
                                      <td>
                                        {clientRoles.add_edit_login && (
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-secondary dropdown-toggle btn-info"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/edit-client/${item.userId?._id}`
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Sign in as user
                                            </a>
                                          </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  // href="/add-invoice"
                                                  onClick={() =>
                                                    navigate("/add-invoice", {
                                                      state: {
                                                        selectedUser:
                                                          item.userId?._id,
                                                      },
                                                    })
                                                  }
                                                >
                                                  New invoice
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  // href="#"
                                                  onClick={() =>
                                                    navigate("/add-ticket", {
                                                      state: {
                                                        mailTo: item.userId,
                                                        from: "client",
                                                      },
                                                    })
                                                  }
                                                >
                                                  New ticket
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Merge
                                            </a>
                                          </li> */}
                                              {clientRoles._delete && (
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal"
                                                    onClick={() => {
                                                      setOpen(true);
                                                      setClientId(
                                                        item.userId?._id
                                                      );
                                                      setClientName(
                                                        item.userId?.email
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                  </a>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {currentItemClient.length <= 0 && (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="sectionPagination">
                            <NoOfRows />
                            <ReactPaginate
                              nextLabel={
                                <i class="fa-solid fa-angles-right"></i>
                              }
                              previousLabel={
                                <i class="fa-solid fa-angles-left"></i>
                              }
                              onPageChange={handlePageClickClientList}
                              pageRangeDisplayed={5}
                              pageCount={pageCountClientList}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination paginate"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                          {(multiDeleteId.length > 0 || isSingleChecked) && (
                            <div className="table-bottom-option">
                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  {/* <div className="input-group">
                            <select className="form-control">
                              <option>With Selected...</option>
                              <option>Delete</option>
                              <option>Send Welcome Email</option>
                            </select>
                            <button className="btn btn-apply">Apply</button>
                          </div> */}
                                  <div className="input-group">
                                    <select
                                      id="selectOption"
                                      value={selectOption.target}
                                      onChange={(e) => selectOption(e)}
                                      className="form-control"
                                    >
                                      <option value="">With Selected...</option>
                                      <option value="Delete">Delete</option>
                                      {/* <option value="Email">
                                    Send Welcome Email
                                  </option> */}
                                    </select>
                                    <button
                                      className="btn btn-apply"
                                      onClick={() => {
                                        if (selectedOption == "Delete") {
                                          deleteMultiple();
                                        }
                                      }}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-complete"
                          role="tabpanel"
                          aria-labelledby="pills-complete-tab"
                          tabindex="0"
                        >
                          <div className="common-table clients-table rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="ps-4 w65-px">
                                    {clientRoles._delete && (
                                      <div className="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="item4"
                                          checked={
                                            clientTeamList.length ==
                                            multiDeleteId.length &&
                                            clientTeamList.length > 0
                                          }
                                          onClick={() => checkedAllClientTeam()}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="item4"
                                        ></label>
                                      </div>
                                    )}
                                  </th>
                                  <th>Name</th>
                                  {/* <th>Company</th>
                                  <th>Account Manager</th> */}
                                  <th>Created on</th>
                                  <th>Status</th>
                                  {/* <th>Balance</th> */}
                                  {/* <th>Progress Bar</th> */}
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItemClietTeam.map((item) => {
                                  const date = new Date(
                                    item?.userId?.createdOn
                                  );
                                  const monthName = date.toLocaleString(
                                    "default",
                                    {
                                      month: "short",
                                    }
                                  );
                                  const day = date.getDate();
                                  const updatedDate = `${monthName} ${day}`;
                                  let percentage = 0;
                                  activeClientList.forEach((activeItem) => {
                                    if (
                                      activeItem.user._id == item.userId._id
                                    ) {
                                      if (
                                        activeItem.filter == 0 &&
                                        activeItem.total == 0
                                      ) {
                                        percentage = 0;
                                      } else {
                                        percentage = (
                                          (activeItem.filter /
                                            activeItem.total) *
                                          100
                                        ).toFixed(2);
                                      }
                                    }
                                  });
                                  return (
                                    <tr>
                                      <td className="ps-4 w65-px">
                                        {clientRoles._delete && (
                                          <div className="form-group">
                                            <input
                                              type="checkbox"
                                              id={
                                                "clientTeam" + item.userId?._id
                                              }
                                              checked={multiDeleteId.find(
                                                (e) => e === item.userId?._id
                                              )}
                                              onClick={(e) => {
                                                setIsSingleChecked(
                                                  e.target.checked
                                                );
                                                deleteIdList(
                                                  item.userId?._id,
                                                  currentItemClietTeam.length ==
                                                  multiDeleteId.length
                                                );
                                              }}
                                            />
                                            <label
                                              for={
                                                "clientTeam" + item.userId?._id
                                              }
                                            ></label>
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        <div className="clientSection"
                                          onClick={() =>
                                            navigate(
                                              `/view-client/${item.userId?._id}`
                                            )
                                          }
                                        >
                                          <div className="img">
                                            <img
                                              src={
                                                item.userId.image &&
                                                  item.userId.image != ""
                                                  ? item.userId.image
                                                  : Avatar
                                              }
                                            />
                                          </div>
                                          <div className="clientName">
                                            <p

                                            >
                                              <a>
                                                {`${item.userId?.firstName || ""
                                                  } ${item.userId?.lastName || ""
                                                  }`}
                                              </a>
                                            </p>
                                            <p className="clientEmail">
                                              {item.userId?.email}
                                            </p>
                                          </div>
                                        </div>
                                      </td>

                                      {/* <td>{item.company}</td>
                                      <td>
                                        {item.accountManager
                                          ? item.accountManager.firstName +
                                          " " +
                                          item.accountManager.lastName
                                          : ""}
                                      </td> */}
                                      <td>{updatedDate}</td>
                                      <td>
                                        <span className="pill pill-grey fw-normal fs-12">
                                          {item.role}
                                        </span>
                                      </td>
                                      {/* <td>$0.00</td> */}
                                      {/* <td>
                                        <Progress_bar
                                          bgcolor="#46850d"
                                          progress={percentage}
                                          height={10}
                                        />
                                      </td> */}
                                      <td>
                                        {clientRoles.add_edit_login && (
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-secondary dropdown-toggle btn-info"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/edit-client/${item.userId?._id}`
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Sign in as user
                                            </a>
                                          </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  onClick={() =>
                                                    navigate("/add-invoice", {
                                                      state: {
                                                        selectedUser:
                                                          item.userId?._id,
                                                      },
                                                    })
                                                  }
                                                >
                                                  New invoice
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  // href="#"
                                                  onClick={() =>
                                                    navigate("/add-ticket", {
                                                      state: {
                                                        mailTo: item.userId,
                                                        from: "client",
                                                      },
                                                    })
                                                  }
                                                >
                                                  New ticket
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Merge
                                            </a>
                                          </li> */}
                                              {clientRoles._delete && (
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal"
                                                    onClick={() => {
                                                      setOpen(true);
                                                      setClientId(
                                                        item.userId?._id
                                                      );
                                                      setClientName(
                                                        item.userId?.email
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                  </a>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {currentItemClietTeam.length <= 0 && (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="sectionPagination">
                            <NoOfRows />
                            <ReactPaginate
                              nextLabel={
                                <i class="fa-solid fa-angles-right"></i>
                              }
                              previousLabel={
                                <i class="fa-solid fa-angles-left"></i>
                              }
                              onPageChange={handlePageClickClienTeam}
                              pageRangeDisplayed={5}
                              pageCount={pageCountClientTeam}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination paginate"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                          {(multiDeleteId.length > 0 || isSingleChecked) && (
                            <div className="table-bottom-option">
                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  <div className="input-group">
                                    <select
                                      id="selectOption"
                                      value={selectOption.target}
                                      onChange={(e) => selectOption(e)}
                                      className="form-control"
                                    >
                                      <option value="">With Selected...</option>
                                      <option value="Delete">Delete</option>
                                      {/* <option value="Email">
                                    Send Welcome Email
                                  </option> */}
                                    </select>
                                    <button
                                      className="btn btn-apply"
                                      onClick={() => {
                                        if (selectedOption == "Delete") {
                                          deleteMultiple();
                                        }
                                      }}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Active Client */}
                        {/* <div
                          className="tab-pane fade"
                          id="pills-active"
                          role="tabpanel"
                          aria-labelledby="pills-active-tab"
                          tabindex="0"
                        >
                          <div className="common-table clients-table rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Company</th>
                                  <th>Account Manager</th>
                                  <th>Created On</th>
                                  <th>Status</th>
                                  <th>Progress Bar</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {currActiveClient &&
                                  currActiveClient.map((item) => {
                                    let percentage = 0;
                                    if (item.filter == 0 && item.total == 0) {
                                      percentage = 0;
                                    } else {
                                      percentage = (
                                        (item.filter / item.total) *
                                        100
                                      ).toFixed(2);
                                    }
                                    let data = {};
                                    allClientList.forEach((clientItem) => {
                                      if (
                                        item.user._id == clientItem.userId._id
                                      ) {
                                        data = clientItem;
                                      }
                                    });
                                    const date = new Date(
                                      data?.userId?.createdOn
                                    );
                                    const monthName = date.toLocaleString(
                                      "default",
                                      {
                                        month: "short",
                                      }
                                    );
                                    const day = date.getDate();
                                    const updatedDate = `${monthName} ${day}`;
                                    return (
                                      <tr>
                                        <td className="">
                                          <div className="clientSection">
                                            <div className="img">
                                              <img
                                                src={
                                                  item.user &&
                                                    item.user.image &&
                                                    item.user.image != null &&
                                                    item.user.image != ""
                                                    ? item.user.image
                                                    : Avatar
                                                }
                                              />
                                            </div>
                                            <div className="clientName">
                                              <p
                                                onClick={() =>
                                                  navigate(
                                                    `/view-client/${item.user?._id}`
                                                  )
                                                }
                                              >
                                                <a>
                                                  {`${item.user?.firstName || ""
                                                    } ${item.user?.lastName || ""
                                                    }`}
                                                </a>
                                              </p>
                                              <p className="clientEmail">
                                                {item.user?.email}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td>{data?.company}</td>
                                        <td>
                                          {data.accountManager
                                            ? data.accountManager.firstName +
                                            " " +
                                            data.accountManager.lastName
                                            : ""}
                                        </td>
                                        <td>{updatedDate}</td>
                                        <td>
                                          <span className="pill pill-grey fw-normal fs-12">
                                            {data.role}
                                          </span>
                                        </td>
                                        <td>
                                          <Progress_bar
                                            bgcolor="#46850d"
                                            progress={percentage}
                                            height={10}
                                          />
                                        </td>
                                        <td>
                                          {clientRoles.add_edit_login && (
                                            <div className="dropdown">
                                              <button
                                                className="btn btn-secondary dropdown-toggle btn-info"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                              </button>
                                              <ul className="dropdown-menu">
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                      navigate(
                                                        `/edit-client/${data.userId?._id}`
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </a>
                                                </li>

                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    onClick={() =>
                                                      navigate("/add-invoice", {
                                                        state: {
                                                          selectedUser:
                                                            data.userId?._id,
                                                        },
                                                      })
                                                    }
                                                  >
                                                    New invoice
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    // href="#"
                                                    onClick={() =>
                                                      navigate("/add-ticket", {
                                                        state: {
                                                          mailTo: data.userId,
                                                          from: "client",
                                                        },
                                                      })
                                                    }
                                                  >
                                                    New ticket
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {currActiveClient.length <= 0 && (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="sectionPagination">
                            <NoOfRows />
                            <ReactPaginate
                              nextLabel={
                                <i class="fa-solid fa-angles-right"></i>
                              }
                              previousLabel={
                                <i class="fa-solid fa-angles-left"></i>
                              }
                              onPageChange={handleActiveClient}
                              pageRangeDisplayed={5}
                              pageCount={activeClientCount}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination paginate"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        </div> */}
                        <div
                          className="tab-pane fade show active "
                          id="pills-all"
                          role="tabpanel"
                          aria-labelledby="pills-all-tab"
                          tabindex="0"
                        >
                          <div className="common-table clients-table rounded-1">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="ps-4 w65-px">
                                    {clientRoles._delete && (
                                      // <div className="form-group">
                                      //   <input
                                      //     type="checkbox"
                                      //     id="item5"
                                      //     checked={
                                      //       allClientList.length ==
                                      //         multiDeleteId.length &&
                                      //       allClientList.length > 0
                                      //     }
                                      //     onClick={() => checkedAllList()}
                                      //   />
                                      //   <label for="item5"></label>
                                      // </div>

                                      <div class="form-check">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckChecked"
                                          checked={
                                            allClientList.length ==
                                            multiDeleteId.length &&
                                            allClientList.length > 0
                                          }
                                          onClick={() => checkedAllList()}
                                        />
                                      </div>
                                    )}
                                  </th>
                                  <th>Name</th>
                                  {/* <th>Company</th> */}
                                  {/* <th>Account Manager</th> */}
                                  <th>Created on</th>
                                  <th>Status</th>
                                  {/* <th>Balance</th> */}
                                  {/* <th>Progress Bar</th> */}
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems.map((item) => {
                                  const date = new Date(
                                    item?.userId?.createdOn
                                  );
                                  const monthName = date.toLocaleString(
                                    "default",
                                    {
                                      month: "short",
                                    }
                                  );
                                  const day = date.getDate();
                                  const updatedDate = `${monthName} ${day}`;
                                  let percentage = 0;
                                  activeClientList.forEach((activeItem) => {
                                    if (
                                      activeItem.user._id == item.userId._id
                                    ) {
                                      if (
                                        activeItem.filter == 0 &&
                                        activeItem.total == 0
                                      ) {
                                        percentage = 0;
                                      } else {
                                        percentage = (
                                          (activeItem.filter /
                                            activeItem.total) *
                                          100
                                        ).toFixed(2);
                                      }
                                    }
                                  });
                                  return (
                                    <tr>
                                      <td className="ps-4 w65-px">
                                        {clientRoles._delete && (
                                          // <div className="form-group">
                                          //   <input
                                          //     type="checkbox"
                                          //     id={"all" + item.userId?._id}
                                          //     checked={multiDeleteId.find(
                                          //       (e) => e === item.userId?._id
                                          //     )}
                                          //     onClick={(e) => {
                                          //       setIsSingleChecked(
                                          //         e.target.checked
                                          //       );
                                          //       deleteIdList(
                                          //         e.target.checked,
                                          //         item.userId?._id
                                          //       );
                                          //     }}
                                          //   />
                                          //   <label
                                          //     for={"all" + item.userId?._id}
                                          //   ></label>
                                          // </div>
                                          <div class="form-check">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id={"all" + item.userId?._id}
                                              checked={multiDeleteId.find(
                                                (e) => e === item.userId?._id
                                              )}
                                              onClick={(e) => {
                                                setIsSingleChecked(
                                                  e.target.checked
                                                );
                                                deleteIdList(
                                                  item.userId?._id,
                                                  currentItems.length ==
                                                  multiDeleteId.length
                                                );
                                              }}
                                            />
                                            <label
                                              class="form-check-label"
                                              for={"all" + item.userId?._id}
                                            ></label>
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        <div className="clientSection"
                                          onClick={() =>
                                            navigate(
                                              `/view-client/${item.userId?._id}`
                                            )
                                          }
                                        >
                                          <div className="img">
                                            <img
                                              src={
                                                item.userId &&
                                                  item.userId.image &&
                                                  item.userId.image != null &&
                                                  item.userId.image != ""
                                                  ? item.userId.image
                                                  : Avatar
                                              }
                                            />
                                          </div>
                                          <div className="clientName">
                                            <p

                                            >
                                              <a>
                                                {`${item.userId?.firstName || ""
                                                  } ${item.userId?.lastName || ""
                                                  }`}
                                              </a>
                                            </p>
                                            <p className="clientEmail">
                                              {item.userId?.email}
                                            </p>
                                          </div>
                                        </div>
                                      </td>

                                      {/* <td>{item?.company}</td> */}
                                      {/* <td>
                                        {item.accountManager
                                          ? item.accountManager.firstName +
                                            " " +
                                            item.accountManager.lastName
                                          : ""}
                                      </td> */}
                                      <td>{updatedDate}</td>
                                      <td>
                                        <span className="pill pill-grey fw-normal fs-12">
                                          {item?.role}
                                        </span>
                                      </td>
                                      {/* <td>$0.00</td> */}
                                      {/* <td>
                                        <Progress_bar
                                          bgcolor="#46850d"
                                          progress={percentage}
                                          height={10}
                                        />
                                      </td> */}
                                      <td>
                                        {clientRoles.add_edit_login && (
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-secondary dropdown-toggle btn-info"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/edit-client/${item.userId?._id}`
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                              // onClick={() =>
                                              //   userLogin(loginUserAccess)
                                              // }
                                            >
                                              Sign in as user
                                            </a>
                                          </li> */}
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  // href="/add-invoice"
                                                  onClick={() =>
                                                    navigate("/add-invoice", {
                                                      state: {
                                                        selectedUser:
                                                          item.userId?._id,
                                                      },
                                                    })
                                                  }
                                                >
                                                  New invoice
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  // href="/add-ticket"
                                                  onClick={() =>
                                                    navigate("/add-ticket", {
                                                      state: {
                                                        mailTo: item.userId,
                                                        from: "client",
                                                      },
                                                    })
                                                  }
                                                >
                                                  New ticket
                                                </a>
                                              </li>
                                              {/* <li>
                                            <a
                                              className="dropdown-item"
                                              href="#"
                                            >
                                              Merge
                                            </a>
                                          </li> */}
                                              {clientRoles._delete && (
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal"
                                                    onClick={() => {
                                                      setOpen(true);
                                                      setClientId(
                                                        item?.userId?._id
                                                      );
                                                      setClientName(
                                                        item?.userId?.email
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                  </a>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {currentItems.length <= 0 && (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="sectionPagination">
                            <NoOfRows />
                            <ReactPaginate
                              nextLabel={
                                <i class="fa-solid fa-angles-right"></i>
                              }
                              previousLabel={
                                <i class="fa-solid fa-angles-left"></i>
                              }
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageCount}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination paginate"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                          {(multiDeleteId.length > 0 || isSingleChecked) && (
                            <div className="table-bottom-option">
                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  {/* <div className="input-group">
                            <select className="form-control">
                              <option>With Selected...</option>
                              <option>Delete</option>
                              <option>Send Welcome Email</option>
                            </select>
                            <button className="btn btn-apply">Apply</button>
                          </div> */}
                                  <div className="input-group">
                                    <select
                                      id="selectOption"
                                      value={selectOption.target}
                                      onChange={(e) => selectOption(e)}
                                      className="form-control"
                                    >
                                      <option value="">With Selected...</option>
                                      <option value="Delete">Delete</option>
                                      {/* <option value="Email">
                                    Send Welcome Email
                                  </option> */}
                                    </select>
                                    <button
                                      className="btn btn-apply"
                                      onClick={() => {
                                        if (selectedOption == "Delete") {
                                          deleteMultiple();
                                        }
                                      }}
                                    >
                                      Apply
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* import modal stasts */}
      <div
        className="modal fade add-order-modals"
        id="importModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <CSVReader
              skipEmptyLines={true}
              worker={true}
              onUploadAccepted={(results) => {
                setCsvData(results.data);
              }}
            >
              {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
              }) => (
                <>
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Import clients
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="add-order-form">
                      <form>
                        <div className="form-group">
                          <label>Select a csv file to import</label>
                          <div className="upload-file" {...getRootProps()}>
                            <i className="fa-solid fa-file-arrow-up"></i>
                            <p>
                              <span>Upload a file</span> or drag and drop
                            </p>
                            <input className="inputfile" />
                          </div>
                          <div>{acceptedFile && acceptedFile.name}</div>
                          <ProgressBar />
                          <small>
                            Download the
                            <a
                              target="_blank"
                              href="https://docs.google.com/spreadsheets/d/1o3Rolh0mG3DXPO7hUuQoDadR0FDvV04dBHZZo4DHY9g/edit#gid=0"
                            >
                              import template here.
                            </a>
                          </small>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary close-btn"
                      data-bs-dismiss="modal"
                      {...getRemoveFileProps()}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary "
                      data-bs-dismiss="modal"
                      onClick={() => setIsImportModuleVisible(true)}
                    // onClick={() => navigate("/accounts/import")}
                    >
                      Preview
                    </button>
                  </div>
                  {/* <div>
                    <button
                      type="button"
                      {...getRootProps()}
                      // style={styles.browseFile}
                    >
                      Browse file
                    </button>
                    <div>{acceptedFile && acceptedFile.name}</div>
                    <button {...getRemoveFileProps()}>Remove</button>
                  </div>
                  <ProgressBar /> */}
                </>
              )}
            </CSVReader>
          </div>
        </div>
      </div>
      {/* import modal ends */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  {`Are you sure you want to delete this account along with all
                  their orders and messages:${clientName}? Their invoices will
                  still be preserved for accounting purposes.`}
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={() => deleteClient(clientId)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Clients;

const ImportModule = ({ csvData }) => {
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const [isSuccessFullyCreated, setIsSuccessFullyCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { apiPostMethod, apiGetMethod, allApiUrl, toastMessage } = context;
  const { ADD_CLIENT } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const createdBy = useSelector(
    (state) => state.loginReducer.loginUserInfo.email
  );
  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };

  const array1 = csvData;
  const firstElement = array1.shift();

  var arrToObj = array1.map((x) => ({
    billingAddress: x[0],
    city: x[1],
    company: x[2],
    country: x[3],
    // createdBy: createdBy,
    email: x[4],
    firstName: x[5],
    lastName: x[6],
    password: x[7],
    phone: x[8],
    postal: x[9],
    role: x[10],
    state: x[10],
    taxId: x[11],
  }));
  const importHandler = (event) => {
    // let arrToObj = arrToObj.splice(-1);
    arrToObj.forEach((client) => addClient(client));
    // setIsLoading(true);
    event.preventDefault();
    function addClient(client) {
      apiPostMethod(ADD_CLIENT, client, header)
        .then((res) => {
          if (res.status === 201) {
            toastMessage("success", res.data.message);
            // setIsSuccessFullyCreated(true);
            // redirectHandler();
          }
        })
        .catch((err) => {
          // redirectToLogin(dispatch, navigate, err);
          // setIsLoading(false);
          // toastMessage("error", err.data.message);
        });
    }
  };
  // function redirectHandler() {
  //   if (isSuccessFullyCreated) {
  //     navigate("/clients");
  //   }
  // }
  return (
    <div className="orders-section commonPadding">
      <div className="container-fluid pl-240">
        <div className="row">
          <div className="">
            <table className="">
              <thead>
                <tr>
                  <th>billingAddress</th>
                  <th>city</th>
                  <th>company</th>
                  <th>country</th>
                  <th>createdBy</th>
                  <th>email</th>
                  <th>firstName</th>
                  <th>lastName</th>
                  <th>password</th>
                  <th>phone</th>
                  <th>postal</th>
                  <th>role</th>
                  <th>state</th>
                  <th>tax</th>
                </tr>
              </thead>
              <tbody>
                {arrToObj.map((item) => {
                  return (
                    <tr>
                      <th>{item.billingAddress}</th>
                      <th>{item.city}</th>
                      <th>{item.company}</th>
                      <th>{item.country}</th>
                      <th>{item.createdBy}</th>
                      <th>{item.email}</th>
                      <th>{item.firstName}</th>
                      <th>{item.lastName}</th>
                      <th>{item.password}</th>
                      <th>{item.phone}</th>
                      <th>{item.postal}</th>
                      <th>{item.role}</th>
                      <th>{item.state}</th>
                      <th>{item.taxId}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="btn-section text-end" onClick={importHandler}>
          <button className="btn btn-save">Save</button>
        </div>
      </div>
    </div>
  );
};

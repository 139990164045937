import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";

import { countries } from "../../shared/constants";
import { AllContext } from "../../context";

const AddClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const [isLoading, setIsLoading] = useState(false);
  const {
    apiPostMethod,
    apiGetMethod,
    allApiUrl,
    toastMessage,
    redirectToLogin,
  } = context;
  const { ADD_CLIENT } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const createdBy = useSelector((state) => state.loginReducer.loginUserInfo.id);
  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [isPurchasingForCompany, setIsPurchasingForCompany] = useState(false);
  const [company, setCompany] = useState("");
  const [taxId, setTaxId] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isSendPasswordEmail, setIsSendPasswordEmail] = useState(false);
  const addClietHandler = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const autoFirstName = email ? email.substring(0, email.indexOf("@")) : "";
    const data = {
      role: "lead",
      createdBy: createdBy ? createdBy : "",
      billingAddress: billingAddress ? billingAddress : "",
      city: city ? city : "",
      country: country ? country : "",
      postal: postal ? postal : "",
      state: state ? state : "",
      company: company ? company : "",
      taxId: taxId ? taxId : "",
      phone: phone ? phone : "",
      firstName: firstName ? firstName : autoFirstName,
      lastName: lastName ? lastName : "",
      email: email ? email : "",
      password: password ? password : "",
      sendEmail: isSendPasswordEmail,
    };
    apiPostMethod(ADD_CLIENT, data, header)
      .then((res) => {
        if (res.status === 201) {
          toastMessage("success", res.data.message);
          navigate(`/view-client/${res.data.id}`);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };
  return (
    <div>
      <Sidebar
        activePage="clients"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />

      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-back"
                onClick={() => navigate("/clients")}
              >
                <a href="">
                  <i class="fa-solid fa-angle-left"></i> Clients
                </a>
              </div>
            </div>

            <div className="col-xxl-5 col-lg-9 mx-auto">
              <div className="setting-form">
                <h3 className="fs-4 mb-4">Add Client</h3>
                <form className="" onSubmit={addClietHandler}>
                  <div className="commonForm rounded-1">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            First Name <small>Optional</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Last Name <small>Optional</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-8  col-md-8">
                        <div className="form-group">
                          <label>Billing address</label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setBillingAddress(e.target.value)}
                            value={billingAddress}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4  col-md-4">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4  col-md-4">
                        <div className="form-group">
                          <label>Country</label>

                          <select
                            className="form-control"
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            {countries.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4  col-md-4">
                        <div className="form-group">
                          <label>State / Province / Region</label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>Postal / Zip Code</label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setPostal(e.target.value)}
                            value={postal}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="purchasingcompany"
                              onClick={() => {
                                setIsPurchasingForCompany(
                                  !isPurchasingForCompany
                                );
                              }}
                              checked={isPurchasingForCompany}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              for="purchasingcompany"
                            >
                              I'm purchasing for a company
                            </label>
                          </div>
                        </div>
                      </div>

                      {isPurchasingForCompany && (
                        <>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                              />
                              <small>Company</small>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setTaxId(e.target.value)}
                                value={taxId}
                              />
                              <small>Tax ID</small>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Phone <small>Optional</small>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>
                            Password <small>Optional</small>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                          <small>
                            Leave this empty to generate a default password.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-0">
                          <div className="form-check mb-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="welcomeEmail"
                              checked={isSendPasswordEmail}
                              onClick={() => {
                                setIsSendPasswordEmail(!isSendPasswordEmail);
                              }}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              for="welcomeEmail"
                            >
                              Send Welcome Email
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-section text-end">
                    <button className="btn btn-save">Add Client</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddClient;

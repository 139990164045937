import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/avatar.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AllContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { handleOffSetDateTime } from "../../shared/functions";
import { useRef } from "react";
import { apiPutMethod } from "../../api/rest";
import PageLoader from "../../components/pageLoader";
const ViewPurchase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiPostMethod,
    redirectToLogin,
    toastMessage,
    apiGetMethod,
  } = context;
  const { id } = useParams();

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const editorRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState({});
  const [messageCotent, setMessageCotent] = useState("");
  const [orderDetail, setOrderDetail] = useState({
    assignedUsers: [],
    cc: [],
    date: "",
    message: [],
    note: "",
    relatedOrder: [],
    status: "open",
    subject: "",
    tags: [],
    to: "",
    title: "",
  });
  const { state } = useLocation();
  const location = useLocation();
  useEffect(() => {
    getAllOrders();
  }, []);
  let orderId = window.location.pathname.split("/")[2];
  async function getAllOrders() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let id = state && state._id;
    try {
      const response = await apiGetMethod(
        allApiUrl.GET_SINGLE_ORDER + "/" + orderId,
        "",
        header
      );
      if (response.status === 200) {
        setOrderDetail(response.data.order);
        setInitialLoading(false);
      }
    } catch (err) {
      if (err.data.message == "Order belongs to other customer" || err.data.message == "No such order found") {
        navigate("/IncorrectOrder");
      } else if (err.data.message == "No such order found") {
        navigate("/purchases");
      }
      // redirectToLogin(dispatch, navigate, err);
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  // console.log("iniriral", initialLoading);
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  function formatAMPM(date) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateString = monthNames[date.getMonth()] + " " + date.getDate() + "th";
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = dateString + " at " + hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  function handleMessageEditorChange(e) {
    // var str = e.target.getContent();
    setMessageCotent(e);
  }
  function updateTicket(data) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(allApiUrl.EDIT_ORDER + "/" + state._id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        setMessageCotent("");
        getAllOrders();
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  console.log("orderDetail", orderDetail);
  function AttachmentFiles() {
    if (selectedFile && selectedFile.length > 0) {
      let files = [...selectedFile];
      return files.map((selected, index) => {
        return (
          <span>
            <a>
              {" "}
              <span className="innerIcon attachIcon me-1">
                <i class="fa-solid fa-link"></i>{" "}
              </span>
              {selected.name}{" "}
              <span className="innerIcon crossIcon ms-2">
                <i
                  class="fa-solid fa-xmark"
                  onClick={() => {
                    var dt = new DataTransfer();
                    let input = document.getElementById("myFileInput");
                    var { files } = input;
                    for (var i = 0; i < files.length; i++) {
                      var file = files[i];
                      if (index !== i) dt.items.add(file);
                      input.files = dt.files;
                    }
                    setSelectedFile(input.files);
                  }}
                ></i>
              </span>
            </a>
          </span>
        );
      });
    }
    return "";
  }
  console.log("iiiiddddd", id);
  function updateTicketMessage(message) {
    setIsLoading(true);
    if (selectedFile.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append("files", selectedFile[i]);
      }
      formData.append("from", userInfo.id);
      formData.append("message", message);
      formData.append("date", new Date().getTime());
      formData.append("to", "Client");
      formData.append("type", "Order");
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
        "Content-Type": "multipart/form-data",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, formData, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          getAllOrders();
          setSelectedFile({});
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    } else {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      let data = {
        from: userInfo.id,
        message: message,
        date: new Date().getTime(),
        to: "Client",
        type: "Order",
      };
      apiPutMethod("/settings/sendMessage" + "/" + id, data, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          setSelectedFile({});
          getAllOrders();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    }
  }

  return (
    <div>
      <Sidebar
        activePage="purchases"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header
        handleSidebar={handleSidebar}
        activePage="purchases"
        isLoading={isLoading}
      />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="viewOrders pt-3 pb-3 horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row mb-3">
              <div className="col-lg-6">
                <div className="section-back">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      navigate("/purchases");
                    }}
                  >
                    <i class="fa-solid fa-angle-left"></i> Purchases
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-6">
              <div className="section-back text-end">
                <a href="#" className="mx-2">
                  <i className="fa-solid fa-angle-up"></i>
                </a>
                <a href="#" className="mx-2">
                  <i className="fa-solid fa-angle-down"></i>
                </a>
              </div>
            </div> */}
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <div className="sectionHeading">
                    <h3>
                      {orderDetail?.title}
                      <span className="pill pill-orange ms-1">
                        {orderDetail.status}
                      </span>
                    </h3>
                  </div>
                  <div className="client-details rounded-1">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-5">
                        <p className="clientLabel"> Order Id</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-7">
                        <p className="clientText">{orderDetail.orderId}</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-5">
                        <p className="clientLabel">Service</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-7">
                        <p className="clientText">{orderDetail.title}</p>
                      </div>
                    </div>

                    {/* <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <p className="clientLabel">Payment Method</p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <p className="clientText">Payment Method Name</p>
                    </div>
                  </div> */}

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-5">
                        <p className="clientLabel mb-0">Created Date</p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-7">
                        <p className="clientText mb-0">
                          {handleOffSetDateTime(
                            orderDetail?.createdDate,
                            companyData.timezone
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  {orderDetail && orderDetail?.status == "pending" && (
                    <div className="projectData mt-4">
                      <div className="sectionHeading mb-0">
                        <h3>Project Data </h3>
                      </div>
                      <div className="projectdataContent rounded-1">
                        <div>
                          <span
                            className="pill pill-grey"
                            style={{
                              cursor: "pointer",
                              background: "#5a4cdc",
                              color: "white",
                            }}
                            onClick={() => {
                              navigate(
                                `/portal/intakeForm/${orderDetail.inTakeForm}`,
                                {
                                  state: {
                                    orderId: orderDetail._id,
                                    mode: "add",
                                  },
                                }
                              );
                            }}
                          >
                            Start Order
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {orderDetail &&
                    orderDetail?.status == "submitted" &&
                    orderDetail &&
                    orderDetail.projectData &&
                    orderDetail.projectData.length > 0 && (
                      <div className="projectData mt-4">
                        <div className="sectionHeading mb-0">
                          <h3>Project Data </h3>
                        </div>
                        <div className="projectdataContent rounded-1">
                          {orderDetail.projectData.map((item) => {
                            return (
                              <div className="row">
                                <div className="questionSection">
                                  <div className="">
                                    {item.type !== "file" && (
                                      <h3>{item.key}</h3>
                                    )}
                                  </div>
                                </div>
                                <div className="">
                                  <div className="d-flex">
                                    {item.type === "file" ? (
                                      item &&
                                      item.value &&
                                      item.value.map((images) => {
                                        return (
                                          <img
                                            className="mx-1"
                                            src={images}
                                            style={{
                                              width: "70px",
                                              height: "70px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        );
                                      })
                                    ) : (
                                      <p>
                                        <small>{item.value}</small>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  <div className="sectionMessage mt-5">
                    <h3>Messages</h3>
                    {orderDetail.message.map((value) => {
                      return (
                        value.to === "Client" && (
                          <div className="clientMessage">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="messageUser">
                                  <div className="userImg">
                                    <img
                                      src={
                                        value.from && value.from.image
                                          ? value.from.image
                                          : Avatar
                                      }
                                    />
                                  </div>
                                  <div className="userName">
                                    <h4>
                                      {value.from ? value.from.firstName : ""}{" "}
                                      {value.from ? value.from.lastName : ""}
                                    </h4>
                                    <p> {handleOffSetDateTime(
                                      value.date,
                                      companyData.timezone
                                    )}</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="message-content">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: value.message,
                                }}
                              ></div>
                            </div>
                            <div id="attachment" className="messageAttachment">
                              {value.attachments.map((attachment) => {
                                return (
                                  <span id="addedfiles">
                                    <a>
                                      {" "}
                                      <span className="innerIcon attachIcon">
                                        <i class="fa-solid fa-link"></i>{" "}
                                      </span>
                                      {attachment.split("/uploads/")[1]}
                                    </a>
                                    {/* <span className="innerIcon downloadIcon mx-2 ms-3" onClick={() => { onButtonClick(attachment) }}><i class="fa-solid fa-download"></i></span> */}
                                    <a href={attachment} target="_blank">
                                      <span className="innerIcon mx-2 ms-3">
                                        <i class="fa-solid fa-eye"></i>
                                      </span>
                                    </a>
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        )
                      );
                    })}

                    <div className="reply-section mb-4">
                      <form className="mt-4 rounded-1">
                        <div className="form-group">
                          <Editor
                            className="form-control"
                            placeholder="Add a note for your team..."
                            apiKey="fjnv8pd6m40tkdl6tva9sklqdppliphhvofstqa48b92mxtb"
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            value={messageCotent}
                            init={{
                              selector: "textarea",
                              height: 200,
                              plugins: "advlist link image lists",
                              menubar: false,
                              toolbar: false,
                              menubar: "custom",
                              menu: {
                                custom: {
                                  title: "custom",
                                  items: "basicitem nesteditem toggleitem",
                                },
                              },
                              toolbar:
                                "bold italic strikethrough link myButton undo redo   bullist numlist | " +
                                "fullpage" +
                                "indent | blockquote | codesample | fontselect ",
                              font_formats:
                                "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                              block_formats:
                                "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                              plugins: [
                                "image",
                                "help",
                                "link",
                                "codesample",
                                "lists",
                              ],
                              branding: false,
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                              menubar: false,
                            }}
                            // onChange={(e) => handleEditorChange(e)}
                            onEditorChange={(e) => handleMessageEditorChange(e)}
                          />
                          <div className="row"></div>
                          <div className="row mt-3">
                            <div className="col-lg-6">
                              <div className="text-start">
                                <div
                                  id="attachment"
                                  className="messageAttachment"
                                >
                                  <AttachmentFiles />
                                </div>
                                <input
                                  type="file"
                                  className="btn btn-secondary"
                                  hidden
                                  multiple
                                  id="myFileInput"
                                  onChange={(e) => {
                                    setSelectedFile(e.target.files);
                                  }}
                                />
                                <span
                                  className="delete"
                                  onClick={() => {
                                    document
                                      .getElementById("myFileInput")
                                      .click();
                                  }}
                                >
                                  <i class="fa-solid fa-link"></i>
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="send-btn">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  value={orderDetail.message}
                                  onClick={(e) => {
                                    if (messageCotent.length > 0) {
                                      updateTicketMessage(messageCotent);
                                      setMessageCotent("");
                                    } else {
                                      alert("Please insert a message");
                                    }
                                  }}
                                >
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      {/* <p className="ccText">
                      Add:{" "}
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#ccModal"
                      >
                        {orderDetail.cc.length > 0 ? orderDetail.cc : "CC"}
                      </a>
                    </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* cc modal */}
      <div
        className="modal fade history-modal"
        id="ccModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
            <div className="modal-body">
              <div className="history-content">
                <form>
                  <div className="form-group">
                    <label>CC</label>
                    <input
                      type="text"
                      className="form-control"
                      value={orderDetail.cc}
                      onChange={(e) => {
                        setOrderDetail((prevData) => {
                          return { ...prevData, cc: [e.target.value] };
                        });
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => getAllOrders()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  if (orderDetail.cc.length && orderDetail.cc.length > 0) {
                    orderDetail.cc.push(e.target.value);
                    updateTicket({ cc: orderDetail.cc });
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* cc modal */}
    </div>
  );
};
export default ViewPurchase;

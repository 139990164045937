import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllContext } from "../../context";
import { formatMonthNameAndDate } from "../../shared/functions";
import {
  saveInvoiceInformation,
  savePublicInvoice,
} from "../../redux/action/login";
import PageLoader from "../../components/pageLoader";

const ViewPayment = () => {
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const { state } = useLocation();
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    allApiUrl,
    apiPostMethod,
    redirectToLogin,
    toastMessage,
    apiGetMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  useEffect(() => {
    getInvoiceDetail();
  }, []);

  let orderId = window.location.pathname.split("/")[2];
  async function getInvoiceDetail() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(
        allApiUrl.INVOICE_BY_ORDER + "/" + orderId,
        "",
        header
      );
      if (response.status === 200) {
        setInvoiceDetail(response.data.invoice);
        setInitialLoading(false);
      }
    } catch (err) {
      if (err.data.message == "Invoice not found") {
        navigate("/payments");
      } else if (err.data.message == "Invoice belong to other customer") {
        navigate("/incorrectInvoice");
      }
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  async function checkoutHandler() {
    navigate("/payment", {
      state: {
        from: "invoice",
        orderId,
        unAssigedInvoice: invoiceDetail.hasOwnProperty("userDetails")
          ? false
          : true,
      },
    });
  }
  function openInNewTab(url) {
    var win = window.open(url, "_self");
    win.focus();
  }
  return (
    <div>
      <Sidebar
        activePage="payments"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="setting-form-section pt-3 horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-back">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      navigate("/payments");
                    }}
                  >
                    <i class="fa-solid fa-angle-left"></i> Invoices
                  </a>
                </div>
              </div>

              <div className="col-xxl-8 col-lg-10 mx-auto">
                <div className="client-heading mt-5">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="d-flex align-items-center">
                        <div className="userName m-0">
                          <h3 className="fs-4">
                            Invoice #{invoiceDetail.orderId}
                            <span
                              className={`pill ${invoiceDetail.status == "unpaid"
                                ? "pill-grey"
                                : "pill-green"
                                } ms-2`}
                            >
                              {invoiceDetail.status}
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-6">
            <div className="btns">
              <a className="btn-edit" href="javacsript:void(0);">
                Download
              </a>
            </div>
          </div> */}
                  </div>
                </div>

                <div className="client-details rounded-1">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <h5 className="clientText invoiceText">Invoiced To:</h5>

                      {invoiceDetail && invoiceDetail.userDetails && (
                        <p className="clientText">
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.firstName && <br />}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.firstName +
                            " " +
                            (invoiceDetail.userDetails.lastName || "")}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.email && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.email) ||
                            ""}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.billingAddress && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.billingAddress) ||
                            ""}

                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.city && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.city) ||
                            ""}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.state && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.state) ||
                            ""}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.postal && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.postal) ||
                            ""}
                          {invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.country && <br />}
                          {(invoiceDetail.userDetails &&
                            invoiceDetail.userDetails.country) ||
                            ""}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientText fw-500">Number</p>
                        </div>
                        <div className="col-lg-6 col-8">
                          <p className="clientText">
                            {invoiceDetail.invoiceId}
                          </p>{" "}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientText fw-500">Unique ID</p>
                        </div>
                        <div className="col-lg-6 col-8">
                          <p className="clientText">#{invoiceDetail.orderId}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientText fw-500">Issued</p>
                        </div>
                        <div className="col-lg-6 col-8">
                          <p className="clientText">
                            {formatMonthNameAndDate(
                              invoiceDetail.createdDate,
                              companyData.timezone,
                              true
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-4">
                          <p className="clientText fw-500">Due</p>
                        </div>
                        <div className="col-lg-6 col-8">
                          <p className="clientText">
                            {formatMonthNameAndDate(
                              invoiceDetail?.dueDate
                                ? invoiceDetail?.dueDate
                                : invoiceDetail.createdDate,
                              companyData.timezone,
                              true
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="client-details mt-5 rounded-1">
                  <div className="common-table clients-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ITEM</th>
                          <th>PRICE</th>
                          <th>QUANTITY</th>
                          <th>ITEM TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceDetail &&
                          invoiceDetail.product &&
                          invoiceDetail.product.map((item) => {
                            return (
                              <tr>
                                <td>
                                  {item.id.oneTimeService ? (
                                    item.id.name
                                  ) : (
                                    <>
                                      <h>{item.id.name}</h>
                                      <small
                                        style={{
                                          display: "block",
                                          color: "#6b778c",
                                        }}
                                      >
                                        {`$${item.id.price.toFixed(2)}` +
                                          "/" +
                                          item.id.recurringOrder.durationTime +
                                          " " +
                                          item.id.recurringOrder.durationPeriod}
                                      </small>
                                    </>
                                  )}
                                </td>
                                {item.id.price == item.id.originalPrice ? (
                                  <td>${item.id.price}</td>
                                ) : (
                                  <>
                                    <td>
                                      {item.id.originalPrice && (
                                        <del>
                                          $
                                          {item.id.originalPrice ||
                                            item.id.price}
                                        </del>
                                      )}

                                      <td>${item.id.price}</td>
                                    </td>
                                  </>
                                )}

                                <td>x{item.quantity}</td>
                                <td>${item.quantity * item.id.price}</td>
                              </tr>
                            );
                          })}
                        {invoiceDetail.paymentCredit ||
                          invoiceDetail.paymentDue ? (
                          <tr>
                            <td>
                              {invoiceDetail.paymentCredit
                                ? "Credit"
                                : "Payment Due Later"}
                            </td>
                            <td>
                              $
                              {invoiceDetail.paymentCredit
                                ? invoiceDetail.paymentCredit
                                : invoiceDetail.paymentDue}
                            </td>
                            <td>x1</td>
                            <td>
                              - $
                              {invoiceDetail.paymentCredit
                                ? invoiceDetail.paymentCredit
                                : invoiceDetail.paymentDue}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}

                        <tr>
                          <td></td>
                          <td></td>
                          <td>Sub total</td>
                          <td>
                            $
                            {invoiceDetail.paymentCredit ||
                              invoiceDetail.paymentDue
                              ? invoiceDetail.totalPrice -
                              (invoiceDetail.paymentCredit
                                ? invoiceDetail.paymentCredit
                                : invoiceDetail.paymentDue)
                              : invoiceDetail.totalPrice}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>
                              {invoiceDetail.status == "Paid" ||
                                invoiceDetail.status == "Paid"
                                ? "Total"
                                : "Payment Due"}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              $
                              {invoiceDetail.paymentCredit ||
                                invoiceDetail.paymentDue
                                ? invoiceDetail.totalPrice -
                                (invoiceDetail.paymentCredit
                                  ? invoiceDetail.paymentCredit
                                  : invoiceDetail.paymentDue)
                                : invoiceDetail.totalPrice}
                              {invoiceDetail &&
                                invoiceDetail.recurringInvoice &&
                                invoiceDetail.recurringInvoice.status ==
                                true && (
                                  <small
                                    style={{
                                      display: "block",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    Then ${" "}
                                    {invoiceDetail.paymentCredit ||
                                      invoiceDetail.paymentDue
                                      ? invoiceDetail.totalPrice -
                                      (invoiceDetail.paymentCredit
                                        ? invoiceDetail.paymentCredit
                                        : invoiceDetail.paymentDue
                                      ).toFixed(2)
                                      : invoiceDetail.totalPrice.toFixed(
                                        2
                                      )}{" "}
                                    /{" "}
                                    {
                                      invoiceDetail.recurringInvoice
                                        .durationTime
                                    }{" "}
                                    {
                                      invoiceDetail.recurringInvoice
                                        .durationPeriod
                                    }
                                  </small>
                                )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {invoiceDetail.note && (
                  <p className="invoiceNote">
                    <strong>Note:</strong> {invoiceDetail.note}
                  </p>
                )}

                <div className="text-end mt-3 mb-3">
                  {invoiceDetail.totalPrice -
                    (invoiceDetail.paymentCredit
                      ? invoiceDetail.paymentCredit
                      : invoiceDetail.paymentDue) ==
                    0 && <p>minimum amount should be greater than zero</p>}
                  {(invoiceDetail.status == "unpaid" ||
                    invoiceDetail.status == "Unpaid") && (
                      <button
                        className="btn btn-primary btn-payment"
                        onClick={checkoutHandler}
                        disabled={
                          invoiceDetail.totalPrice -
                          (invoiceDetail.paymentCredit
                            ? invoiceDetail.paymentCredit
                            : invoiceDetail.paymentDue) ==
                          0
                        }
                      >
                        Continue Payment
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ViewPayment;

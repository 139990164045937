import { apiPostMethod } from "../rest";

export function loginAPi(url, data) {
  return new Promise((resolve, reject) => {
    apiPostMethod(url, data, {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import salesIcon from "../../assets/img/icons/saleIcon.png";
import iconRevenue from "../../assets/img/icons/iconRevenue.png";
import noData from "../../assets/img/nodata-img.svg";
import { AllContext } from "../../context";
import { saveLoginUserAccessToken } from "../../redux/action/login";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Sales = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [adminData, setAdminData] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const context = useContext(AllContext);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const {
    allApiUrl,
    apiGetMethod,
    toastMessage,
    redirectToLogin,
    apiPostMethod,
  } = context;
  const { SALES, SALES_DATE_FILTER } = allApiUrl;

  let name = "";
  if (userInfo?.email) {
    name = userInfo?.email;
  } else {
    name = "Test 123";
  }

  const navigate = useNavigate();
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // if (end) {
    //   getDateFilterData(start, end);
    //   setOpen(false);
    // }
    getDateFilterData(start, end, 'datePicker');
    setOpen(false);
  };

  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");
    // if (loggedIn) {
    //   setIsLoading(false);
    //   navigate("/dashboard");
    // } else {
    //   navigate("/login");
    // }
    getAdminData();
  }, []);

  const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
  const currentList = adminData.slice(itemOffset, endOffset);
  let pageCount = Math.ceil(adminData.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData.length;
    setItemOffset(newOffset);
  };

  function getAdminData() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setIsLoading(true);
    apiGetMethod(SALES, "", header)
      .then((res) => {
        let adminList = []
        res.data.adminData.forEach((item) => {
          if (item.revenueGenerated > 0) {
            adminList.push(item)
          }
        })
        setAdminData(adminList);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function getDateFilterData(start, end, type) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {};
    if (type == "datePicker") {
      if (end && end != startDate) {
        data = {
          startDate: startDate,
          endDate: end,
        };
      } else {
        data = {
          startDate: start ? start : startDate,
        };
      }
    }
    else {
      data = {
        startDate: start,
        endDate: end,
      }
    }
    setIsLoading(true);
    apiPostMethod(SALES_DATE_FILTER, data, header)
      .then((res) => {
        let adminList = []
        res.data.adminData.forEach((item) => {
          if (item.revenueGenerated > 0) {
            adminList.push(item)
          }
        })
        setAdminData(adminList);
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function openCalender(e) {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }
  function customDateSelector(type) {
    if (type === "Last 7 Days") {
      const today = new Date();
      let start = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000))
      // setGraphTitle(type)
      setStartDate(start);
      setEndDate(today);
      getDateFilterData(start, today, "custom");
    }
    else if (type == "Previous Month") {
      // setGraphTitle(type)
      const today = new Date();
      const start = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);
      const end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
      setStartDate(start);
      setEndDate(end);
      getDateFilterData(start, end, "custom");
    }
    else if (type === "Last 3 Months") {
      // setGraphTitle(type)
      const today = new Date();
      const start = new Date(today.getFullYear(), today.getMonth() - 3, 1, 0, 0, 0);
      const end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
      setStartDate(start);
      setEndDate(end);
      getDateFilterData(start, end, "custom");
    }
    else if (type === "Month to Date") {
      // setGraphTitle(type)
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      getDateFilterData(firstDay, date, "custom");
    }
    else if (type == "Quarter to Date") {
      // setGraphTitle(type)
      const today = new Date();
      const currentQuarter = Math.floor((today.getMonth() / 3));
      const year = today.getFullYear();
      let quarterStart = new Date(year, currentQuarter * 3, 1);
      getDateFilterData(quarterStart, today, "custom");
    }
    else if (type == "Year to Date") {
      // setGraphTitle(type)
      let date = new Date()
      var startDate = new Date(date.getFullYear(), 0, 1);
      getDateFilterData(startDate, date, "custom");
    }
  }
  return (
    <div>
      <Sidebar
        activePage="dashboard"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      {!isLoading && (
        <>
          <div className="dashboardSection commonPadding pb-4">
            <div className="container-fluid pl-240">
              <div className="row">
                <div className="col-lg-6 col-md-4">
                  <div className="heading">
                    <h2 style={{ overflow: "hidden" }}>Sale Performance</h2>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8">
                  <div className="dashboardFilters">
                    <div className="dropdown d-flex">
                      <button
                        class="btn btn-secondary dropdown-toggle dashboardDropdown"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ width: "170px" }}
                      >
                        Sale Performance
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => navigate("/revenue")}
                          >
                            Revenue
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => navigate("/service-report")}
                          >
                            Services
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => navigate("/client-report")}
                          >
                            Clients
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={() => navigate("/sales")}
                          >
                            Sale Performance
                          </a>
                        </li>
                        {/* <li>
                        <a class="dropdown-item" href="#">
                          Account Balances
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Coupon usage
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Response times
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Assigned orders
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/completed-order">
                          Completed orders
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="order-status">
                          Order statuses
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          Taxes
                        </a>
                      </li> */}
                      </ul>
                      <input
                        className="form-control"
                        type="text"
                        id="expiryDate"
                        onClick={(e) => {
                          openCalender(e);
                        }}
                        value={`${startDate ? startDate : ""}, ${endDate ? endDate : ""
                          }`}
                      />


                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa-solid fa-calendar"></i>
                      </button>
                      <ul class="dropdown-menu">
                        {["Last 7 Days", "Previous Month", "Last 3 Months", "Month to Date", "Quarter to Date", "Year to Date", "All Time"].map((item) => {
                          return (
                            <li
                              onClick={() => customDateSelector(item)}
                            >
                              <a class="dropdown-item" href="#">
                                {item}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                      {/* <button
                      class="btn btn-primary"
                      type="button"
                      onClick={() =>{getDateFilterData()}}
                    >
                      Filter
                    </button> */}
                    </div>
                    {open === true ? (
                      <span className="tooltipdatepicker">
                        <DatePicker
                          maxDate={moment().toDate()}
                          className=""
                          selected={startDate}
                          onChange={onChange}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          inline
                        />
                        <span className="tooltipdatepickertext">
                          Get date range by dragging on "FROM" date
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="listingSection">
            <div className="container-fluid pl-240">
              <div className="row">
                <div className="col-lg-12">
                  <div className="common-table rounded-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Team Members</th>
                          <th>New Clients</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* no record found */}
                        {/* <tr>
                          <td colSpan={3}>
                            <div className="noData-section">
                              <img src={noData} />
                              <p>No Data Found</p>
                            </div>
                          </td>
                        </tr> */}
                        {/* no record found */}

                        {currentList &&
                          currentList.length > 0 &&
                          currentList.map((item) => {
                            return (
                              <tr>
                                <td>{item.adminName}</td>
                                <td>{item.numberOfClients}</td>
                                <td>${item.revenueGenerated}</td>
                              </tr>
                            );
                          })}
                        {currentList && currentList.length <= 0 && (
                          <tr>
                            <td colSpan={7}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="sectionPagination">
                  <ReactPaginate
                    nextLabel={<i class="fa-solid fa-angles-right"></i>}
                    previousLabel={<i class="fa-solid fa-angles-left"></i>}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination paginate"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Sales;

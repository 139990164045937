import { combineReducers } from "redux";
// TODO Reducer
import loginReducer from "./login";
import csvReducer from "./csv";

const appReducer = combineReducers({
  loginReducer: loginReducer,
  csvReducer: csvReducer,
});

export default appReducer;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { AllContext } from "./context";
import { allData } from "./context";
import rootSaga from "./redux/action";
import store from "./redux/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
store().sagaMiddleware.run(rootSaga);
root.render(
  // <React>
  <Provider store={store().store}>
    <PersistGate loading={null} persistor={store().persistor}>
      <AllContext.Provider value={allData}>
        <App />
      </AllContext.Provider>
    </PersistGate>
  </Provider>
  // </React>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

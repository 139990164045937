import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";

import { AllContext } from "../../context";
import { countries } from "../../shared/constants";
import { useDebugValue } from "react";

const EditClient = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [isPurchasingForCompany, setIsPurchasingForCompany] = useState(false);
  const [company, setCompany] = useState("");
  const [taxId, setTaxId] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isSendPasswordEmail, SetIsSendPasswordEmail] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const { apiPostMethod, apiGetMethod, allApiUrl, toastMessage, apiPutMethod } =
    context;
  const { GET_SPECIFIC_CLIENT, EDIT_CLIENT, GET_ALL_ROLE, redirectToLogin } =
    allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  var header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  const dispatch = useDispatch();
  var specificClientId = window.location.pathname.split("/")[2];
  useEffect(() => {
    getSpecificClientDataHandler();
    getAllRoles();
  }, []);
  function getAllRoles() {
    setIsLoading(true);
    apiGetMethod(GET_ALL_ROLE, "", header)
      .then((res) => {
        if (res.status === 200) {
          let allRoles = [];
          res.data.roles.forEach((item) => {
            if (item.type === "client") {
              allRoles.push(item);
            }
          });
          setAllRoles(allRoles);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function getSpecificClientDataHandler() {
    setIsLoading(true);
    apiGetMethod(`${GET_SPECIFIC_CLIENT}/${specificClientId}`, "", header)
      .then((res) => {
        if (res.status === 200) {
          setIsPurchasingForCompany(
            res.data.user[0].company || res.data.user[0].taxId ? true : false
          );
          getSpecificTeamMemberData(res.data.user[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function getSpecificTeamMemberData(values) {
    setEmail(values.userId.email);
    setFirstName(values.userId.firstName);
    setLastName(values.userId.lastName);
    setBillingAddress(values.billingAddress);
    setCity(values.city);
    setCountry(values.country);
    setState(values.state);
    setPostal(values.postal);
    setCompany(values.company);
    setTaxId(values.taxId);
    setPhone(values.phone);
    // setPassword(values.pas);
    setRole(values.role);
  }
  const editEmailHandler = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const data = {
      email: email ? email : "",
      firstName: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      billingAddress: billingAddress ? billingAddress : "",
      city: city ? city : "",
      country: country ? country : "",
      state: state ? state : "",
      postal: postal ? postal : "",
      company: company ? company : "",
      taxId: taxId ? taxId : "",
      phone: phone ? phone : "",
      password: password ? password : "",
      resetPassword: isSendPasswordEmail,
      role: role ? role : "",
    };
    apiPutMethod(`${EDIT_CLIENT}/${specificClientId}`, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        navigate("/clients");
        getSpecificClientDataHandler();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };
  return (
    <div>
      <Sidebar
        activePage="clients"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-back"
                onClick={() => navigate("/clients")}
              >
                <a href="#">
                  <i class="fa-solid fa-angle-left"></i> Clients
                </a>
              </div>
            </div>

            <div className="col-xxl-5 col-lg-6 mx-auto">
              <div className="setting-form">
                <h3 className="fs-4">Edit account </h3>
                <form onSubmit={editEmailHandler}>
                  <div className="commonForm rounded-1">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            First Name <small>Optional</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Last Name <small>Optional</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-8 col-md-8">
                        <div className="form-group">
                          <label>Billing address</label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setBillingAddress(e.target.value)}
                            value={billingAddress}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>Country</label>
                          <select
                            className="form-control"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            {countries.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>State / Province / Region</label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <label>Postal / Zip Code</label>
                          <input
                            type="text"
                            className="form-control"
                            // required
                            onChange={(e) => setPostal(e.target.value)}
                            value={postal}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-0">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="purchasingcompany"
                              onClick={() => {
                                setIsPurchasingForCompany(
                                  !isPurchasingForCompany
                                );
                              }}
                              checked={isPurchasingForCompany}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              for="purchasingcompany"
                            >
                              I'm purchasing for a company
                            </label>
                          </div>
                        </div>
                      </div>

                      {isPurchasingForCompany && (
                        <>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                              />
                              <small>Company</small>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setTaxId(e.target.value)}
                                value={taxId}
                              />
                              <small>Tax ID</small>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="commonForm mt-5 rounded-1">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Phone <small>Optional</small>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            // required
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Account balance</label>
                          <div className="input-group">
                            <div className="input-group-text">$</div>
                            <input type="text" className="form-control" />
                          </div>
                          <small>
                            Balance can be used to buy your services.
                          </small>
                        </div>
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-4">
                        {/* <select
                          className="form-control"
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          {countries.map((item) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            );
                          })}
                        </select> */}
                        <div className="form-group">
                          <label>Status</label>
                          <select
                            className="form-control"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                          >
                            {allRoles.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="commonForm mt-5 rounded-1">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-1">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="reserpassword"
                              onClick={() => {
                                SetIsSendPasswordEmail(!isSendPasswordEmail);
                              }}
                              checked={isSendPasswordEmail}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              for="reserpassword"
                            >
                              Reset password and send welcome email
                            </label>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="changepassword"
                              onClick={() => {
                                setIsChangePasswordVisible(
                                  !isChangePasswordVisible
                                );
                              }}
                              checked={isChangePasswordVisible}
                            />
                            {/* {
                              isChangePasswordVisible && 
                            } */}
                            <label
                              className="form-check-label checkbox-label d-block"
                              for="changepassword"
                            >
                              Change password
                            </label>
                            {isChangePasswordVisible && (
                              <input
                                className="form-control mt-2"
                                type="password"
                                placeholder="New Password"
                                required
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-section text-end">
                    <button className="btn btn-save">Save Changes</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditClient;

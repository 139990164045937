import { toast } from "react-toastify";
const customId = "custom-id-yes";

function toastMessages(messageType, message) {
  // message = message ? message : "Something went wrong, please try again.";

  function generateCustomID() {
    let id = "id" + Math.random().toString(16).slice(2);
    return id;
  }
  if (message) {
    if (messageType === "success") {
      toast.dismiss();
      toast.success(message, {
        toastId: customId,
        // generateCustomID()
      });
    } else if (messageType === "dismiss") {
      toast.dismiss();
    } else if (messageType === "warn") {
      toast.dismiss();
      toast.warn(message, {
        toastId: customId,
        //  generateCustomID()
      });
    } else {
      // toast.dismiss()
      toast.error(message, {
        toastId: customId,
        // generateCustomID()
      });
    }
  }
}

export default toastMessages;

import { useNavigate } from "react-router-dom";

const IncorrectInvoice = () => {
  const navigate = useNavigate();
  return (
    <div className="section-sermissionDenied">
      <div className="container">
        <div className="row h-80vh mx-0 align-items-center">
          <div className="col-xxl-4 mx-auto">
            <h1>403 Forbidden</h1>
            <p>This invoice belongs to another client.</p>
            <a href="javascript:void(0);" onClick={() => navigate("/home")}>
              <i className="fa-solid fa-angles-left"></i> Go Back
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IncorrectInvoice;

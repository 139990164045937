import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
// import "../../assets/css/custom.css";
import "../../assets/css/customNew.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IncorrectInvoice } from "../../components/PermissionDenied";
import { AllContext } from "../../context";
import loginImg from "../../assets/img/login.png";
import { useSearchParams } from "react-router-dom";
import {
  saveLoginUserAccessToken,
  saveLoginUserInfo,
} from "../../redux/action/login";
import { loginAPi } from "../../api/authApi/login";
import PageLoader from "../../components/pageLoader";
import { useState } from "react";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const context = useContext(AllContext);
  const [searchParams] = useSearchParams();
  let redirectionCode = null;
  let redirection = null;

  const { allApiUrl, toastMessage } = context;
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);

  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");

    if (loggedIn) {
      if (userInfo.roles.type === "admin") {
        navigate("/dashboard");
      } else {
        navigate("/home");
      }
    }
  }, []);
  const LoginSchema = Yup.object({
    email: Yup.string().min(2).max(50).required("Please enter your name"),
    password: Yup.string().required("Please enter your password"),
  });
  const initialValues = {
    email: "",
    password: "",
  };
  const [passwordView, setPasswordView] = React.useState("password");
  const [isLoading, setIsLoading] = useState(false)
  const areFalse = (value) =>
    Object.values(value).every((value) => value === false);
  function handleFormSubmit(values) {
    setIsLoading(true)
    redirectionCode = searchParams.get("id");
    redirection = searchParams.get("redirection");
    const data = {
      email: values.email,
      password: values.password,
    };
    loginAPi(allApiUrl.ADMIN_LOGIN_IN, data, {})
      .then((res) => {
        if (res.status === 200) {
          const {
            clients,
            coupons,
            invoiceAndSubscriptionAccess,
            invoiceManagement,
            messaging,
            orderAccess,
            orderManagement,
            services,
            settingsAndTeam,
            ticketAccess,
            ticketManagement,
          } = res?.data?.roles;
          if (
            areFalse(clients) &&
            areFalse(coupons) &&
            areFalse(invoiceAndSubscriptionAccess) &&
            areFalse(invoiceManagement) &&
            areFalse(messaging) &&
            areFalse(orderAccess) &&
            areFalse(orderManagement) &&
            areFalse(services) &&
            areFalse(settingsAndTeam) &&
            areFalse(ticketAccess) &&
            areFalse(ticketManagement) &&
            res?.data.group === 1
          ) {
            navigate("/permissionDenied", {
              state: {
                noPermission: true,
              },
            });
          } else {
            toastMessage(
              "success",
              `Welcome Back ${res.data?.firstName
                ? res.data?.firstName.replace(
                  /(^\w{1})|(\s+\w{1})/g,
                  (letter) => letter.toUpperCase()
                ) +
                " " +
                res?.data?.lastName.replace(
                  /(^\w{1})|(\s+\w{1})/g,
                  (letter) => letter.toUpperCase()
                )
                : res.data.email
              }`
            );
            dispatch(saveLoginUserAccessToken(res.data.token));
            dispatch(saveLoginUserInfo(res.data));
            localStorage.setItem("userLoggedIn", "true");
            if (res?.data?.group === 1) {
              const redirectPages = ["view-orders", "view-ticket"];
              if (
                (redirection !== null || redirectionCode !== null) &&
                redirectPages.includes(redirection)
              ) {
                let url = `/${redirection}${redirectionCode ? "/" + redirectionCode : ""
                  }`;
                navigate(url);
              } else {
                navigate("/dashboard");
              }
            } else {
              const redirectPages = [
                "view-purchase",
                "view-payment",
                "portal/view-ticket",
                "cart",
              ];

              if (
                (redirection !== null || redirectionCode !== null) &&
                redirectPages.includes(redirection)
              ) {
                let url = `/${redirection}${redirectionCode ? "/" + redirectionCode : ""
                  }`;
                navigate(url);
              } else {
                navigate("/home");
              }
            }
            // if (routeRedirect && routeRedirect.length > 0) {
            //   navigate(routeRedirect);
            // } else if (
            //   retrievedObject == null &&
            //   viewCartInfo == false &&
            //   doesOrderNeedAttention?.orderNeedAttention == false
            // ) {

            // } else if (viewCartInfo == true) {
            //   if (res.data.group == 2) {
            //     navigate("/cart");
            //   } else {
            //     navigate("/dashboard");
            //   }
            // } else if (
            //   doesOrderNeedAttention?.orderNeedAttention == true &&
            //   viewCartInfo == false
            // ) {
            //   if (res.data.group == 1) {
            //     navigate(`/view-orders/${doesOrderNeedAttention?.id}`);
            //   } else {
            //     navigate("/home");
            //   }
            // } else {
            //   if (res.data?.group == 2) {
            //     navigate(`/view-payment/${retrievedObject?.url}`);
            //   } else {
            //     navigate(`/dashboard`);
            //   }
            // }
          }
          setIsLoading(false)
        }
      })
      .catch((err) => {
        toastMessage("warn", err.data?.message);
        setIsLoading(false)
      });
  }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  return (
    <main className="wrapper">
      <PageLoader isLoading={isLoading} />
      <div className="section-login">
        <div className="container">
          <div className="row h-100vh mx-0 align-items-center">
            <div className="col-lg-6 mx-auto formOrder">
              <div className="loginForm">
                <a className="brandName" href="">
                  AgencyCRM
                </a>
                {/* <h3>Welcome back!</h3> */}

                <form className="formLogin" onSubmit={handleSubmit}>
                  <h3>Sign In</h3>
                  <div className="formInner">
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        required
                      />
                      <label for="floatingInput">Email</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type={passwordView}
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        required
                      />
                      <span
                        className="eyeIcon fa-solid fa-eye"
                        onClick={() => {
                          if (passwordView == "password") {
                            setPasswordView("text");
                          } else {
                            setPasswordView("password");
                          }
                        }}
                      ></span>
                      <label for="floatingPassword">Password</label>
                    </div>
                    <a className="forgotLink d-inline-block mb-5 text-start">
                      <span onClick={() => navigate("/forgot-password")}>
                        {" "}
                        Forgot password?
                      </span>
                    </a>
                    <div className="input-group">
                      <button className="btn btn-primary btn-submit">
                        Sign in
                      </button>
                    </div>
                  </div>
                </form>
                <p className="copyrightText">
                  © AgencyCRM. All Rights Reserved.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="loginImg">
                <img src={loginImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Login;

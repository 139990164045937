import React, { useContext, useEffect } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/avatar.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { apiGetMethod } from "../../api/rest";
import { AllContext } from "../../context";

const Checkout = () => {
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiPostMethod,
    apiPutMethod,
    apiGetMethod,
    redirectToLogin,
    toastMessage,
  } = context;

  const [isLoading, setIsLoading] = useState(false);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentAddress = useSelector(
    (state) => state.loginReducer.paymentAddress
  );
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  const [paymentSuccessDetails, setPaymentSuccessDetails] =
    useState(paymentAddress);
  const { EDIT_USER_DETAILS, PAYMENTSUCCESS } = allApiUrl;
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [paymentSuccessFully, setPaymentSuccessFully] = useState([]);
  const [userInformation, setUserInformation] = useState([]);
  const [grandTotal, setGrandTotal] = useState(null);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  useEffect(() => {
    getUser();
  }, []);
  function getUser() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const data = {
      stripeData: paymentAddress.stripeData,
      chargeId: paymentAddress.id,
      billingAddress: paymentAddress.billingAddress,
      city: paymentAddress.city,
      company: paymentAddress.company,
      postal: paymentAddress.postal,
      state: paymentAddress.state,
      taxId: paymentAddress.taxId,
      name: paymentAddress.firstName,
    };
    apiPostMethod(PAYMENTSUCCESS, data, header)
      .then((res) => {
        // alert("working");
      })
      .catch((err) => {
        // alert("not working");
        // toastMessage("error", err.data.message);
      });
  }
  useEffect(() => {
    let total =
      paymentSuccessDetails &&
      paymentSuccessDetails.stripeData &&
      paymentSuccessDetails.stripeData.product &&
      paymentSuccessDetails.stripeData.product.map(
        (item) => item.id.price * item.quantity
      );
    setGrandTotal(
      total.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );
  });
  useEffect(() => {
    getUserDetails(loginUserInfo.id);
  }, []);
  function getUserDetails(userProfileId) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(EDIT_USER_DETAILS, userProfileId, header)
      .then((res) => {
        setUserInformation(res.data.user[0]);
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
      });
  }
  return (
    <div>
      <section className="checkOut-page">
        <div className="container-fluid pl-240 pe-0 overflow-hidden">
          <div className="row h-100vh align-items-center">
            <div className="col-lg-7">
              <div className="checkoutListing">
                <p className="agencyName">AgencyCRM</p>
                <h3>Thank you, {userInformation.userId?.firstName}</h3>
                <p>
                  We've accepted your order, and we're getting it ready. A
                  confirmation email has been sent to{" "}
                  <strong>{userInformation.userId?.email}</strong>.
                </p>
                <p>
                  Go to the{" "}
                  <a
                    href=""
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    client portal
                  </a>{" "}
                  to check the status of this order and receive updates.
                </p>

                <div className="row billingRow">
                  <div className="col-lg-6">
                    <div className="billingAddress">
                      <p>
                        <strong>Billing address</strong>
                      </p>
                      <p>{userInformation.billingAddress}</p>
                      <p>{userInformation.postal}</p>
                      <p>{userInformation.country}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="billingAddress">
                      {/* <p>
                        <strong>Order date</strong>
                      </p>
                      <p>Oct 11, 2022</p> */}
                      <p className="mt-2">
                        <strong>Payment method</strong>
                      </p>
                      {/* <p>Account Balance — $0.00</p> */}
                      <p>Card</p>
                    </div>
                  </div>
                </div>

                <div className="sectionHelp">
                  <p>
                    Need help? <a href="">Contact us</a>
                  </p>
                  <a
                    className="continueBtn"
                    href=""
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    Continue to client portal
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="summaryHead h-100vh">
                <div className="summaryPage">
                  <h4>Summary</h4>
                  {paymentSuccessDetails &&
                    paymentSuccessDetails.stripeData &&
                    paymentSuccessDetails.stripeData.product &&
                    paymentSuccessDetails.stripeData.product.map((item) => {
                      return (
                        <div className="d-flex">
                          <div>
                            <p>{item.id.name}</p>
                            <p>
                              <span>Qty</span> {item.quantity}
                            </p>
                          </div>
                          <div>
                            <div>
                              <p>${item.id.price?.toFixed(2)}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <hr />
                  <div className="d-flex">
                    <div>
                      <p>Total</p>
                      <p>USD</p>
                    </div>
                    <div>
                      <div>
                        <p>${grandTotal?.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Checkout;

import React from "react";
import { redirectToLogin } from "../shared/functions";
import { apiPostMethod,apiGetMethod,apiDeleteMethod,apiPutMethod,apiGetMethodSession,apiGetMethod1 } from "../api/rest";
import { allApiUrl } from "../api/apiRoute";
import toastMessage from "../components/toastMessages";
export const AuthContext = React.createContext();
export const allData = {
  allApiUrl,
  apiPostMethod,
  apiPutMethod,
  apiGetMethod,
  apiDeleteMethod,
  toastMessage,
  redirectToLogin,
  apiGetMethodSession,
  apiGetMethod1
};

export const AllContext = React.createContext(undefined);

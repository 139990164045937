import React from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/dummy-avatar.jpeg";
import noData from "../../assets/img/nodata-img.svg";
import { apiGetMethod, apiPostMethod, apiDeleteMethod } from "../../api/rest";
import { AllContext } from "../../context";
import { useNavigate, Link, useParams } from "react-router-dom";
import moment from "moment";
import { useEffect, useContext } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formatMonthNameAndDate,
  redirectToLogin,
} from "../../shared/functions";
import ReactPaginate from "react-paginate";
import PageLoader from "../../components/pageLoader";

const Subscription = () => {
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const { allApiUrl, apiPutMethod, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [activeSubscription, setActiveSubscription] = useState([]);
  const [cancelSubscription, setCancelSubscription] = useState([]);
  const [voidList, setVoidList] = useState([]);
  const [paidDueSubscription, setPaidDueSubscription] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [allSubscription, setAllSubscription] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [multiDeleteId, setMultiDeleteId] = useState([]);
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const [initialLoading, setInitialLoading] = useState(true);
  useEffect(() => {
    getSubscriptionListing();
  }, []);
  function getSubscriptionListing() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.Subscription, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAllSubscription(res.data.subscriptions);
          setActiveSubscription(
            res.data.subscriptions.filter((item) => {
              if (item.paymentStatus != "past due") {
                return item.serviceStatus === true;
              }
            })
          );
          setCancelSubscription(
            res.data.subscriptions.filter((item) => {
              return item.serviceStatus === false;
            })
          );
          setPaidDueSubscription(
            res.data.subscriptions.filter((item) => {
              return (
                item.paymentStatus === "past due" && item.serviceStatus === true
              );
            })
          );
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        setInitialLoading(false);
      });
  }
  const [itemOffsetActive, setItemOffsetActive] = useState(0);
  const [itemOffsetCancelled, setItemOffsetCancelled] = useState(0);
  const [itemOffsetVoid, setItemOffsetVoid] = useState(0);
  const [itemOffsetAll, setItemOffsetAll] = useState(0);

  //All List
  const endOffsetAll = parseInt(itemOffsetAll) + parseInt(itemsPerPage);
  const currentItems = allSubscription.slice(itemOffsetAll, endOffsetAll);
  let pageCount = Math.ceil(allSubscription.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allSubscription.length;
    setItemOffsetAll(newOffset);
  };
  //Cancel Subscrption
  const endOffsetCancelled =
    parseInt(itemOffsetCancelled) + parseInt(itemsPerPage);
  const cancelledSubscription = cancelSubscription.slice(
    itemOffsetCancelled,
    endOffsetCancelled
  );
  let pageCountCancelSubscription = Math.ceil(
    cancelSubscription.length / itemsPerPage
  );
  const handlePageCancel = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % cancelSubscription.length;
    setItemOffsetCancelled(newOffset);
  };
  //void Subscription
  const endOffsetVoid = parseInt(itemOffsetVoid) + parseInt(itemsPerPage);
  const currentVoidInvoice = paidDueSubscription.slice(
    itemOffsetVoid,
    endOffsetVoid
  );
  let pageCountVoid = Math.ceil(paidDueSubscription.length / itemsPerPage);
  const handlePageVoid = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % paidDueSubscription.length;
    setItemOffsetVoid(newOffset);
  };
  //Active Subscription
  const endOffsetActive = parseInt(itemOffsetActive) + parseInt(itemsPerPage);
  const currentActiveSubscription = activeSubscription.slice(
    itemOffsetActive,
    endOffsetActive
  );
  let pageCountActiveSubscription = Math.ceil(
    activeSubscription.length / itemsPerPage
  );
  const handlePageActiveSubscription = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % activeSubscription.length;
    setItemOffsetActive(newOffset);
  };
  // Check All
  function checkedAll() {
    if (currentItems.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = currentItems.map((e) => {
        return e._id;
      });
      setMultiDeleteId(ids);
    }
  }
  function checkedAllActive() {
    if (currentActiveSubscription.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = currentActiveSubscription.map((e) => {
        return e._id;
      });
      setMultiDeleteId(ids);
    }
  }
  function checkedAllCancelled() {
    if (cancelSubscription.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = cancelSubscription.map((e) => {
        return e._id;
      });
      setMultiDeleteId(ids);
    }
  }
  // function checkedAllDue() {
  //   if (leadClientList.length == multiDeleteId.length) {
  //     setMultiDeleteId([]);
  //   } else {
  //     let ids = leadClientList.map((e) => {
  //       return e.userId?._id;
  //     });
  //     setMultiDeleteId(ids);
  //   }
  // }

  function checkedAllVoid() {
    if (currentVoidInvoice.length == multiDeleteId.length) {
      setMultiDeleteId([]);
    } else {
      let ids = currentVoidInvoice.map((e) => {
        return e._id;
      });
      setMultiDeleteId(ids);
    }
  }

  function deleteIdList(id, status) {
    if (status) {
      setMultiDeleteId([id]);
    } else {
      let exist = multiDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        multiDeleteId.push(id);
        setMultiDeleteId(multiDeleteId);
      } else {
        let exist = multiDeleteId.findIndex((e) => e == id);
        let ids = multiDeleteId.splice(exist + 1, 1);
        setMultiDeleteId(ids);
      }
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  function deleteMultiple() {
    setIsLoading(true);
    multiDeleteId.map((row) => {
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      apiDeleteMethod(allApiUrl.DELETE_SUBSCRIPTION, row, header)
        .then((res) => {
          toastMessage("success", res.data.message);
          getSubscriptionListing();
          setIsLoading(false);
        })
        .catch((err) => {
          redirectToLogin(dispatch, navigate, err);
          setIsLoading(false);
          toastMessage("error", err.data.message);
        });
    });
  }
  useEffect(() => {
    setMultiDeleteId([]);
  }, [isReset]);
  function clearCheckAll() {
    setIsSingleChecked(false);
    setIsReset(!isReset);
  }
  useEffect(() => {
    setMultiDeleteId([]);
  }, [activeTab]);
  return (
    <div>
      <Sidebar
        activePage="subscription"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6">
                <div className="heading">
                  <h2 className="fs-4">Subscriptions</h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-filter">
                  <div className="dropdown">
                    <a
                      className="add-btn"
                      href=""
                      onClick={() =>
                        navigate("/add-invoice", {
                          state: {
                            page: "subscription",
                          },
                        })
                      }
                    >
                      Add Subscription
                    </a>
                    {/* <button
                    className="btn btn-import"
                    data-bs-toggle="modal"
                    data-bs-target="#importModal"
                  >
                    Import
                  </button> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab == "Active" ? "active" : ""
                          }`}
                        id="pills-overdue-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-overdue"
                        type="button"
                        role="tab"
                        aria-controls="pills-overdue"
                        aria-selected="true"
                        onClick={() => {
                          clearCheckAll();
                          setActiveTab("Active");
                        }}
                      >
                        <span>{activeSubscription.length}</span>Active
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab == "Cancelled" ? "active" : ""
                          }`}
                        id="pills-submitted-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-submitted"
                        type="button"
                        role="tab"
                        aria-controls="pills-submitted"
                        aria-selected="true"
                        onClick={() => {
                          clearCheckAll();
                          setActiveTab("Cancelled");
                        }}
                      >
                        <span>{cancelSubscription.length}</span>Cancelled
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab == "pastDue" ? "active" : ""
                          }`}
                        id="pills-working-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-working"
                        type="button"
                        role="tab"
                        aria-controls="pills-working"
                        aria-selected="false"
                        onClick={() => {
                          clearCheckAll();
                          setActiveTab("pastDue");
                        }}
                      >
                        <span>{paidDueSubscription.length}</span>Past Due
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab == "All" ? "active" : ""
                          }`}
                        id="pills-all-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-all"
                        type="button"
                        role="tab"
                        aria-controls="pills-all"
                        aria-selected="false"
                        onClick={() => {
                          // clearCheckAll();
                          setActiveTab("All");
                        }}
                      >
                        <span>{allSubscription.length}</span>
                        All
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade"
                      id="pills-overdue"
                      role="tabpanel"
                      aria-labelledby="pills-overdue-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="item1"
                                    checked={
                                      currentActiveSubscription?.length ==
                                      multiDeleteId?.length &&
                                      currentActiveSubscription?.length > 0
                                    }
                                    onClick={() => checkedAllActive()}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="item1"
                                  ></label>
                                </div>
                              </th>
                              <th>Subscription Id</th>
                              <th>Client</th>
                              <th>Service</th>
                              <th>No of Payments</th>
                              <th>Last payment Date</th>
                              <th>Next Payment Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* no record found */}
                            {/* <tr>
                            <td colSpan={7}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr> */}
                            {/* no record found */}
                            {currentActiveSubscription.map((item) => {
                              return (
                                <tr>
                                  <td className="ps-4">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={"active" + item._id}
                                        checked={multiDeleteId.find(
                                          (e) => e === item._id
                                        )}
                                        onClick={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            item._id,
                                            currentActiveSubscription.length ==
                                            multiDeleteId.length
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        for={"active" + item._id}
                                      ></label>
                                    </div>
                                  </td>

                                  <td>
                                    <a
                                      onClick={() => {
                                        navigate(
                                          `/view-subscription/${item.orderId}`,
                                          {
                                            state: item._id,
                                          }
                                        );
                                      }}
                                    >
                                      {item.subscriptionId
                                        ? item.subscriptionId
                                        : item.orderId}
                                    </a>
                                  </td>
                                  <td>
                                    <div className="clientSection">
                                      <div className="clientName">
                                        <p>
                                          <a
                                            onClick={() => {
                                              navigate(
                                                `/view-client/${item.userDetails._id}`
                                              );
                                            }}
                                          >
                                            {item.userDetails &&
                                              item.userDetails &&
                                              item.userDetails.firstName &&
                                              `${item.userDetails.firstName} ${item.userDetails.lastName || " "
                                              }`}
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.paymentCount}</td>
                                  <td>
                                    {formatMonthNameAndDate(
                                      item.paidDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {formatMonthNameAndDate(
                                      item.nextDueDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td>
                                    <span className="pill pill-green fs-12">
                                      Active
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                            {currentActiveSubscription.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageActiveSubscription}
                          pageRangeDisplayed={5}
                          pageCount={pageCountActiveSubscription}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-submitted"
                      role="tabpanel"
                      aria-labelledby="pills-submitted-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="item1"
                                    checked={
                                      cancelSubscription?.length ==
                                      multiDeleteId?.length &&
                                      cancelSubscription?.length > 0
                                    }
                                    onClick={() => checkedAllCancelled()}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="item1"
                                  ></label>
                                </div>
                              </th>
                              <th>Subscription Id</th>
                              <th>Client</th>
                              <th>Service</th>
                              <th>No of Payments</th>
                              <th>Last payment Date</th>
                              <th>Next Payment Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cancelledSubscription.map((item) => {
                              return (
                                <tr>
                                  <td className="ps-4">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={"cancel" + item._id}
                                        checked={multiDeleteId.find(
                                          (e) => e === item._id
                                        )}
                                        onClick={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            item._id,
                                            cancelSubscription.length ==
                                            multiDeleteId.length
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        for={"cancel" + item._id}
                                      ></label>
                                    </div>
                                  </td>

                                  <td>
                                    <a
                                      onClick={() => {
                                        navigate(
                                          `/view-subscription/${item.orderId}`,
                                          {
                                            state: item._id,
                                          }
                                        );
                                      }}
                                    >
                                      {item.subscriptionId
                                        ? item.subscriptionId
                                        : item.orderId}
                                    </a>
                                  </td>
                                  <td>
                                    <div className="clientSection">
                                      <div className="clientName">
                                        <p>
                                          <a
                                            onClick={() => {
                                              navigate(
                                                `/view-client/${item.userDetails._id}`
                                              );
                                            }}
                                          >
                                            {item.userDetails &&
                                              item.userDetails &&
                                              item.userDetails.firstName &&
                                              `${item.userDetails.firstName} ${item.userDetails.lastName || " "
                                              }`}
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.paymentCount}</td>
                                  <td>
                                    {formatMonthNameAndDate(
                                      item.paidDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {formatMonthNameAndDate(
                                      item.nextDueDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td>
                                    <span className="pill pill-red fs-12">
                                      Cancelled
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                            {cancelledSubscription.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageCancel}
                          pageRangeDisplayed={5}
                          pageCount={pageCountCancelSubscription}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-working"
                      role="tabpanel"
                      aria-labelledby="pills-working-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="item1"
                                    checked={
                                      currentVoidInvoice?.length ==
                                      multiDeleteId?.length &&
                                      currentVoidInvoice?.length > 0
                                    }
                                    onClick={() => checkedAllVoid()}
                                  />
                                  <label
                                    for="item1"
                                    className="form-check-label"
                                  ></label>
                                </div>
                              </th>
                              <th>Subscription Id</th>
                              <th>Client</th>
                              <th>Service</th>
                              <th>No of Payments</th>
                              <th>Last payment Date</th>
                              <th>Next Payment Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentVoidInvoice.map((item) => {
                              return (
                                <tr>
                                  <td className="ps-4">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={"void" + item._id}
                                        checked={multiDeleteId.find(
                                          (e) => e === item._id
                                        )}
                                        onClick={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            item._id,
                                            currentVoidInvoice.length ==
                                            multiDeleteId.length
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        for={"void" + item._id}
                                      ></label>
                                    </div>
                                  </td>

                                  <td>
                                    <a
                                      onClick={() => {
                                        navigate(
                                          `/view-subscription/${item.orderId}`,
                                          {
                                            state: item._id,
                                          }
                                        );
                                      }}
                                    >
                                      {item.subscriptionId
                                        ? item.subscriptionId
                                        : item.orderId}
                                    </a>
                                  </td>
                                  <td>
                                    <div className="clientSection">
                                      <div className="clientName">
                                        <p>
                                          <a
                                            onClick={() => {
                                              navigate(
                                                `/view-client/${item.userDetails._id}`
                                              );
                                            }}
                                          >
                                            {item.userDetails &&
                                              item.userDetails &&
                                              item.userDetails.firstName &&
                                              `${item.userDetails.firstName} ${item.userDetails.lastName || " "
                                              }`}
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.paymentCount}</td>
                                  <td>
                                    {formatMonthNameAndDate(
                                      item.paidDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {formatMonthNameAndDate(
                                      item.nextDueDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td>
                                    <span className="pill pill-blue fs-12">
                                      Past Due
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                            {currentVoidInvoice.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageVoid}
                          pageRangeDisplayed={5}
                          pageCount={pageCountVoid}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                    {/* </div> */}

                    <div
                      className="tab-pane fade show active"
                      id="pills-all"
                      role="tabpanel"
                      aria-labelledby="pills-all-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="item1"
                                    checked={
                                      currentItems?.length ==
                                      multiDeleteId?.length &&
                                      currentItems?.length > 0
                                    }
                                    onClick={() => checkedAll()}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="item1"
                                  ></label>
                                </div>
                              </th>
                              <th>Subscription Id</th>
                              <th>Client</th>
                              <th>Service</th>
                              <th>No of Payments</th>
                              <th>Last payment Date</th>
                              <th>Next Payment Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((item) => {
                              return (
                                <tr>
                                  <td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={"all" + item?._id}
                                        checked={multiDeleteId.find(
                                          (e) => e === item._id
                                        )}
                                        onClick={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            item._id,
                                            currentItems.length ==
                                            multiDeleteId.length
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        for={"all" + item._id}
                                      ></label>
                                    </div>
                                  </td>

                                  <td>
                                    <a
                                      onClick={() => {
                                        navigate(
                                          `/view-subscription/${item.orderId}`,
                                          {
                                            state: item._id,
                                          }
                                        );
                                      }}
                                    >
                                      {item.subscriptionId
                                        ? item.subscriptionId
                                        : item.orderId}
                                    </a>
                                  </td>
                                  <td>
                                    <div className="clientSection">
                                      <div className="clientName">
                                        <p>
                                          <a
                                            onClick={() => {
                                              navigate(
                                                `/view-client/${item.userDetails._id}`
                                              );
                                            }}
                                          >
                                            {item.userDetails &&
                                              item.userDetails &&
                                              item.userDetails.firstName &&
                                              `${item.userDetails.firstName} ${item.userDetails.lastName || " "
                                              }`}
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {item.product.map((item, index) => {
                                      return index == 0
                                        ? " " + item.name
                                        : " , " + item.name;
                                    })}
                                  </td>
                                  <td>{item.paymentCount}</td>
                                  <td>
                                    {formatMonthNameAndDate(
                                      item.paidDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {formatMonthNameAndDate(
                                      item.nextDueDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td>
                                    <span
                                      className={`pill ${item.paymentStatus === "past due"
                                        ? "pill-blue"
                                        : item.serviceStatus == true
                                          ? "pill-green"
                                          : "pill-red"
                                        } fs-12`}
                                    >
                                      {item.paymentStatus === "past due"
                                        ? "Past Due"
                                        : item.serviceStatus == true
                                          ? "Active"
                                          : "Cancelled"}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                            {currentItems.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                    {(multiDeleteId.length > 0 || isSingleChecked) && (
                      <div className="table-bottom-option">
                        <div className="row">
                          <div className="col-lg-3 col-md-4">
                            <div className="input-group">
                              <select
                                id="selectOption"
                                value={selectOption.target}
                                onChange={(e) => selectOption(e)}
                                className="form-control"
                              >
                                <option value="">With Selected...</option>
                                <option value="Delete">Delete</option>
                              </select>
                              <button
                                className="btn btn-apply"
                                onClick={() => {
                                  if (selectedOption == "Delete") {
                                    deleteMultiple();
                                  }
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Subscription;

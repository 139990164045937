import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import Avatar from "../../assets/img/dummy-avatar.jpeg";
import noData from "../../assets/img/nodata-img.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AllContext } from "../../context";
import { formatMonthNameAndDate } from "../../shared/functions";
import ReactPaginate from "react-paginate";
import PageLoader from "../../components/pageLoader";

const Invoices = () => {
  let activeTab = "all";
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const [showDatePicker, setShowDatePicker] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [isActive, setActive] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [bulkDeleteId, setBulkDeleteId] = useState([]);
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [enddateFilter, setendDateFilter] = useState(0);
  const [statusFilter, setStatusFilter] = useState(0);
  const [open, setOpen] = useState("");
  const [paidList, setPaidList] = useState([]);
  const [voidList, setVoidList] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [allInvoiceList, setAllInvoiceList] = useState([]);
  const [isSingleChecked, setIsSingleChecked] = useState(false);
  const [unpaidList, setUnPaidList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [currActiveTab, setCurrActiveTab] = useState("all");
  const [contactClientList, setContactClientList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [markedAsPaid, setMarkAsPaid] = useState();
  const [initialLoading, setInitialLoading] = useState(true);
  let markedAsPaidObj = {};
  const {
    allApiUrl,
    toastMessage,
    redirectToLogin,
    apiDeleteMethod,
    apiGetMethod,
    apiPostMethod,
    apiPutMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [invoiceList, setInvoiceList] = useState([]);
  useEffect(() => {
    getInvoiceListing();
    getClients();
  }, []);
  const ToggleClass = () => {
    setActive(!isActive);
  };
  async function getInvoiceListing() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.INVOICE, "", header);
      if (response.status === 200) {
        setCurrActiveTab("all");
        setAllInvoiceList(response.data.invoice);

        setPaidList(
          response.data.invoice.filter((item) => {
            return item.status === "paid";
          })
        );
        setUnPaidList(
          response.data.invoice.filter((item) => {
            return item.status === "unpaid";
          })
        );
        setVoidList(
          response.data.invoice.filter((item) => {
            return item.status === "void";
          })
        );
        setInitialLoading(false);
        // setIsLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      // setIsLoading(false);
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  function listSelectedClient() {
    let selectedList = "";
    clientList.map((list, index) => {
      selectedList +=
        list.label + `${index !== clientList.length - 1 ? "," : ""}`;
    });
    return selectedList;
  }

  function deleteMultiple() {
    bulkDeleteId.map((row) => {
      deleteInvoice(row);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
    getInvoiceListing();
  }

  function updateMultiple(status) {
    let data = {
      status: status,
    };
    bulkDeleteId.map((row) => {
      updateInvoice(row, data);
    });
    setBulkDeleteId([]);
    setIsSingleChecked(false);
  }

  function updateInvoice(id, data) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPutMethod(allApiUrl.INVOICE + "/" + id, data, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        getInvoiceListing();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function checkedAll() {
    if (allInvoiceList.length == bulkDeleteId.length) {
      setBulkDeleteId([]);
    } else {
      let ids = allInvoiceList.map((e) => {
        return e._id;
      });
      setBulkDeleteId(ids);
    }
  }

  function deleteIdList(status, id) {
    if (status) {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      if (exist == -1) {
        bulkDeleteId.push(id);
        setBulkDeleteId(bulkDeleteId);
      }
    } else {
      let exist = bulkDeleteId.findIndex((e) => e == id);
      let ids = bulkDeleteId.splice(exist + 1, 1);
      setBulkDeleteId(ids);
    }
  }
  function selectOption(selected) {
    setSelectedOption(selected.target.value);
  }
  const filterInvoices = () => {
    const data = {};
    if (open !== "") data.open = open;
    if (clientList.length > 0) {
      let clientSelectedList = [...clientList];
      clientSelectedList.map((list, index) => {
        clientSelectedList[index] = list.value;
      });
      data.client = clientSelectedList;
    }
    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;

    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    let count = 0;
    for (let key in data) {
      count += 1;
    }
    setFilterCount(count);
    apiPostMethod(allApiUrl.FILTER_INVOICES, data, header)
      .then((res) => {
        if (res.status === 200) {
          if (open == null) {
            setCurrActiveTab("all");
          } else {
            setCurrActiveTab(open);
          }
          setAllInvoiceList(res.data);

          setPaidList(
            res.data.filter((item) => {
              return item.status === "paid";
            })
          );
          setUnPaidList(
            res.data.filter((item) => {
              return item.status === "unpaid";
            })
          );
          setVoidList(
            res.data.filter((item) => {
              return item.status === "void";
            })
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const deleteInvoice = (id) => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(allApiUrl.INVOICE + "/" + id, "", header)
      .then((res) => {
        if (res.status === 200) {
          toastMessage("success", res.data.message);

          getInvoiceListing();
        }
      })
      .catch((err) => {
        console.log("error", err);
        // toastMessage("error", err.);
      });
  };

  const getClients = () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.GET_ALL_CLIENT, "", header)
      .then((res) => {
        if (res.status === 200) {
          setAllClient(res.data.team);
          // setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  function updateClient(Details) {
    if (clientList.find((row) => row.value == Details._id)) {
      let index = clientList.findIndex((row) => row.value == Details._id);
      clientList.splice(index, 1);
    } else {
      let label = "";
      if (Details.firstName && Details.lastName) {
        label = Details.firstName + " " + Details.lastName;
      } else {
        if (Details.firstName || Details.lastName) {
          if (Details.firstName) {
            label = Details.firstName;
          } else {
            label = Details.lastName;
          }
        } else {
          label = Details.email;
        }
      }
      let data = {
        label: label,
        value: Details._id,
      };
      clientList.push(data);
    }
    setClientList(clientList);
  }

  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  function formatAMPM(date) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dateString = monthNames[date.getMonth()] + " " + date.getDate() + "th";
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pM" : "aM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = dateString + " at " + hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const [itemOffsetPaid, setItemOffsetPaid] = useState(0);
  const [itemOffsetUnpaid, setItemOffsetUnpaid] = useState(0);
  const [itemOffsetVoid, setItemOffsetVoid] = useState(0);
  const [itemOffsetAll, setItemOffsetAll] = useState(0);

  //Paid
  const endOffsetPaid = parseInt(itemOffsetPaid) + parseInt(itemsPerPage);
  const currentItems = paidList.slice(itemOffsetPaid, endOffsetPaid);
  let pageCount = Math.ceil(paidList.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % paidList.length;
    setItemOffsetPaid(newOffset);
  };
  //unpaid
  const endOffsetUnpaid = parseInt(itemOffsetUnpaid) + parseInt(itemsPerPage);
  const currentItemClientInvoice = unpaidList.slice(
    itemOffsetUnpaid,
    endOffsetUnpaid
  );
  let pageCountClientTeam = Math.ceil(unpaidList.length / itemsPerPage);
  const handlePageClickClienTeam = (event) => {
    const newOffset = (event.selected * itemsPerPage) % unpaidList.length;
    setItemOffsetUnpaid(newOffset);
  };
  //void
  const endOffsetVoid = parseInt(itemOffsetUnpaid) + parseInt(itemsPerPage);
  const currentVoidInvoice = voidList.slice(itemOffsetVoid, endOffsetVoid);
  let pageCountClientList = Math.ceil(voidList.length / itemsPerPage);
  const handlePageClickClientList = (event) => {
    const newOffset = (event.selected * itemsPerPage) % voidList.length;
    setItemOffsetVoid(newOffset);
  };
  //all
  const endOffsetAll = parseInt(itemOffsetAll) + parseInt(itemsPerPage);
  const currenInvoiceList = allInvoiceList.slice(itemOffsetAll, endOffsetAll);
  let pageCountInvoiceList = Math.ceil(allInvoiceList.length / itemsPerPage);
  const handlePageClickLeadList = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allInvoiceList.length;
    setItemOffsetAll(newOffset);
  };

  // const currenContactList = contactClientList.slice(itemOffset, endOffset);
  // let pageCountContactList = Math.ceil(contactClientList.length / itemsPerPage);
  // const handlePageClickContactList = (event) => {
  //   const newOffset =
  //     (event.selected * itemsPerPage) % contactClientList.length;
  //   setItemOffset(newOffset);
  // };

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }
  return (
    <div>
      <Sidebar
        activePage="invoices"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6">
                <div className="heading">
                  <h2 className="fs-4">Invoices</h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-filter">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle btn-filter"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-filter"></i> Filter |{" "}
                      {filterCount}
                    </button>
                    <ul className="dropdown-menu filter-drowdown">
                      <div className="filter-btns">
                        <button
                          className="btn btn-clear"
                          onClick={() => {
                            setFilterCount(0);
                            setOpen(0);
                            setstartDateFilter(0);
                            setendDateFilter(0);
                            // setStatusFilter(0);
                            setClientList([]);
                            getInvoiceListing();
                            setShowDatePicker("");
                          }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-update"
                          onClick={() => filterInvoices()}
                        >
                          Update filter
                        </button>
                      </div>

                      <select
                        className="form-control"
                        onChange={(event) =>
                          setShowDatePicker(event.target.value)
                        }
                        value={setShowDatePicker}
                      >
                        <option value={""}>Any date</option>
                        <option value={"date"}>Date Between</option>
                      </select>

                      {showDatePicker == "date" && (
                        <div className="d-flex dateBetween-filter">
                          <input
                            type="date"
                            name="startDate"
                            className="form-control"
                            onChange={(event) =>
                              setstartDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                          />
                          <span className="mx-2">and</span>
                          <input
                            className="form-control"
                            type="date"
                            name="endDate"
                            onChange={(event) =>
                              setendDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                            id=""
                          />
                        </div>
                      )}
                      <div
                        className="assignedUser Anyclient"
                        onClick={(e) => {
                          e.stopPropagation();
                          ToggleClass();
                        }}
                      >
                        <p>
                          {clientList.length > 0
                            ? listSelectedClient()
                            : "Any Client"}
                        </p>
                        <ul className={isActive ? "active" : ""}>
                          {allClient.map((value, index) => {
                            return (
                              <li
                                onClick={() => {
                                  updateClient(value.userId);
                                }}
                              >
                                <a className="dropdown-item">
                                  {clientList.length > 0 &&
                                    clientList.find(
                                      (row) => row.value == value.userId._id
                                    ) && (
                                      <span
                                        class="fas fa-check check-mark"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                  <div>
                                    {value.userId.firstName &&
                                      value.userId.lastName
                                      ? value.userId.firstName +
                                      "" +
                                      value.userId.lastName
                                      : value.userId.email}{" "}
                                    <br /> <small>{value.role}</small>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <select
                        className="form-control"
                        value={open}
                        onChange={(value) => setOpen(value.target.value)}
                      >
                        <option value="all">Any Status</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                        {/* <option value="Refunded">Refunded</option>
                      <option value="Payment Pending">Payment Pending</option>
                      <option value="Void">Void</option> */}
                      </select>
                    </ul>
                    <a
                      className="btn btn-import mr-2"
                      onClick={() =>
                        navigate("/add-invoice", {
                          state: {
                            page: "invoice",
                          },
                        })
                      }
                    >
                      Add Invoice
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${currActiveTab == "paid" ? "active" : ""
                          }`}
                        id="pills-overdue-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-overdue"
                        type="button"
                        role="tab"
                        aria-controls="pills-overdue"
                        aria-selected="true"
                      >
                        {" "}
                        <span>{paidList.length}</span>Paid
                      </button>
                    </li>

                    <li className="nav-item" role="presentation">
                      <button
                        //   className="nav-link active"
                        className={`nav-link ${currActiveTab == "unpaid" ? "active" : ""
                          // == "unpaid" ? "active" : ""
                          }`}
                        id="pills-submitted-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-submitted"
                        type="button"
                        role="tab"
                        aria-controls="pills-submitted"
                        aria-selected="false"
                      >
                        {" "}
                        <span>{unpaidList.length}</span>Unpaid
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${currActiveTab == "void" ? "active" : ""
                          }`}
                        id="pills-void-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-void"
                        type="button"
                        role="tab"
                        aria-controls="pills-void"
                        aria-selected="false"
                      >
                        {" "}
                        <span>{voidList.length}</span>Void
                      </button>
                    </li>

                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${currActiveTab == "all" ? "active" : ""
                          }`}
                        id="pills-working-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-working"
                        type="button"
                        role="tab"
                        aria-controls="pills-working"
                        aria-selected="false"
                      >
                        {" "}
                        <span>{allInvoiceList.length}</span>All
                      </button>
                    </li>
                  </ul>
                  <div
                    className="tab-content invoiceTable"
                    id="pills-tabContent"
                  >
                    <div
                      className={`tab-pane fade ${currActiveTab == "paid" ? "show active" : ""
                        }`}
                      id="pills-overdue"
                      role="tabpanel"
                      aria-labelledby="pills-overdue-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    class="selectAll form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                    checked={
                                      allInvoiceList.length ==
                                      bulkDeleteId.length &&
                                      allInvoiceList.length > 0
                                    }
                                    defaultValue
                                    onClick={() => checkedAll()}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="selectAll"
                                  ></label>
                                </div>
                              </th>
                              <th>Client</th>
                              <th>Total ($)</th>
                              <th>Status</th>
                              <th>Created Date</th>
                              <th>Paid Date</th>
                              <th>Invoice Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems?.map((item) => {

                              return (
                                <tr style={{ cursor: "pointer" }}>
                                  <td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="item2"
                                        checked={bulkDeleteId.find(
                                          (e) => e == item._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            e.target.checked,
                                            item._id
                                          );
                                        }}
                                      />
                                      <label
                                        for="item2"
                                        class="form-check-label"
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="clientSection">
                                      <div
                                        className="clientName"
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`,
                                            {
                                              state: {
                                                _id: item._id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <p>
                                          <>
                                            <span
                                              style={{
                                                display: "block",
                                                fontWeight: "bold",
                                                color: "black",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {item.userDetails?.firstName
                                                ? `${item.userDetails?.firstName
                                                } ${" "}  ${item.userDetails
                                                  ?.lastName || " "
                                                }`
                                                : item.userDetails?.email}
                                            </span>
                                            <small>{item.orderId}</small>
                                          </>
                                        </p>
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {" "}
                                    ${" "}
                                    {item &&
                                      item.partialUpfront &&
                                      item.partialUpfront.status === true
                                      ? item.paymentDue || item.paymentDue == 0
                                        ? item.totalPrice != 0
                                          ? (
                                            item.totalPrice - item.paymentDue
                                          ).toFixed(2)
                                          : 0.0
                                        : item.totalPrice != 0
                                          ? (
                                            item.totalPrice - item.paymentCredit
                                          ).toFixed(2)
                                          : 0
                                      : item.totalPrice.toFixed(2)}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {item.status}
                                    </span>
                                  </td>

                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {formatMonthNameAndDate(
                                      item.createdDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {item.paidDate
                                      ? formatMonthNameAndDate(
                                        item.createdDate,
                                        companyData.timezone,
                                        true
                                      )
                                      : "--"}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {item.invoiceId}
                                  </td>
                                </tr>
                              );
                            })}
                            {currentItems.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          // pageCount={pageCountContactList}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${currActiveTab == "unpaid" ? "show active" : ""
                        }`}
                      id="pills-submitted"
                      role="tabpanel"
                      aria-labelledby="pills-submitted-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    class="selectAll form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                    checked={
                                      allInvoiceList.length ==
                                      bulkDeleteId.length &&
                                      allInvoiceList.length > 0
                                    }
                                    defaultValue
                                    onClick={() => checkedAll()}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="selectAll"
                                  ></label>
                                </div>
                              </th>
                              <th>Client</th>
                              <th>Total ($)</th>
                              <th>Status</th>
                              <th>Created Date</th>
                              <th>Paid Date</th>
                              <th>Invoice Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItemClientInvoice?.map((item) => {
                              return (
                                <tr style={{ cursor: "pointer" }}>
                                  <td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="item2"
                                        checked={bulkDeleteId.find(
                                          (e) => e == item._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            e.target.checked,
                                            item._id
                                          );
                                        }}
                                      />
                                      <label
                                        for="item2"
                                        class="form-check-label"
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="clientSection">
                                      <div
                                        className="clientName"
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`,
                                            {
                                              state: {
                                                _id: item._id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <p>
                                          {/* <a>
                                          {item.userDetails && item.userDetails.length>0 && item.userDetails.firstName
                                            ? item.userDetails.firstName +
                                              "" +
                                              item.userDetail.lastName
                                            : ''}
                                        </a> */}
                                        </p>
                                        <p>
                                          <>
                                            <span
                                              style={{
                                                display: "block",
                                                fontWeight: "bold",
                                                color: "black",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {item.userDetails?.firstName
                                                ? `${item.userDetails?.firstName
                                                } ${" "}  ${item.userDetails
                                                  ?.lastName || " "
                                                }`
                                                : item.userDetails?.email}
                                            </span>
                                            <small>{item.orderId}</small>
                                          </>
                                        </p>
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {" "}
                                    ${" "}
                                    {item &&
                                      item.partialUpfront &&
                                      item.partialUpfront.status === true
                                      ? item.paymentDue
                                        ? item.totalPrice != 0
                                          ? item.totalPrice - item.paymentDue
                                          : 0
                                        : item.totalPrice != 0
                                          ? item.totalPrice - item.paymentCredit
                                          : 0
                                      : item.totalPrice.toFixed(2)}
                                  </td>
                                  <td>
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {item.status}
                                    </span>
                                  </td>

                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {formatMonthNameAndDate(
                                      item.createdDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {item.paidDate
                                      ? formatMonthNameAndDate(
                                        item.createdDate,
                                        companyData.timezone,
                                        true
                                      )
                                      : "--"}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {item.invoiceId}
                                  </td>
                                </tr>
                              );
                            })}
                            {currentItemClientInvoice.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClickClienTeam}
                          pageRangeDisplayed={5}
                          pageCount={pageCountClientTeam}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade ${currActiveTab == "void" ? "show active" : ""
                        }`}
                      id="pills-void"
                      role="tabpanel"
                      aria-labelledby="pills-void-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    class="selectAll form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                    checked={
                                      allInvoiceList.length ==
                                      bulkDeleteId.length &&
                                      allInvoiceList.length > 0
                                    }
                                    defaultValue
                                    onClick={() => checkedAll()}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="selectAll"
                                  ></label>
                                </div>
                              </th>
                              <th>Client</th>
                              <th>Total ($)</th>
                              <th>Status</th>
                              <th>Created Date</th>
                              <th>Paid Date</th>
                              <th>Invoice Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* no record found */}
                            {/* <tr>
                            <td colSpan={7}>
                              <div className="noData-section">
                                <img src={noData} />
                                <p>No Data Found</p>
                              </div>
                            </td>
                          </tr> */}
                            {/* no record found */}
                            {currentVoidInvoice?.map((item) => {
                              return (
                                <tr style={{ cursor: "pointer" }}>
                                  <td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="item2"
                                        checked={bulkDeleteId.find(
                                          (e) => e == item._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            e.target.checked,
                                            item._id
                                          );
                                        }}
                                      />
                                      <label
                                        for="item2"
                                        class="form-check-label"
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="clientSection">
                                      <div
                                        className="clientName"
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`,
                                            {
                                              state: {
                                                _id: item._id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <p>
                                          {/* <a>
                                        {item.userDetails && item.userDetails.length>0 && item.userDetails.firstName
                                          ? item.userDetails.firstName +
                                            "" +
                                            item.userDetail.lastName
                                          : ''}
                                      </a> */}
                                        </p>
                                        <p>
                                          <>
                                            <span
                                              style={{
                                                display: "block",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {item.userDetails?.firstName
                                                ? `${item.userDetails?.firstName
                                                } ${" "}  ${item.userDetails
                                                  ?.lastName || " "
                                                }`
                                                : item.userDetails?.email}
                                            </span>
                                            <small>{item.orderId}</small>
                                          </>
                                        </p>
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {" "}
                                    ${" "}
                                    {item &&
                                      item.partialUpfront &&
                                      item.partialUpfront.status === true
                                      ? item.paymentDue
                                        ? item.totalPrice != 0
                                          ? item.totalPrice - item.paymentDue
                                          : 0
                                        : item.totalPrice != 0
                                          ? item.totalPrice - item.paymentCredit
                                          : 0
                                      : item.totalPrice.toFixed(2)}
                                  </td>
                                  <td>
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {item.status}
                                    </span>
                                  </td>

                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {formatMonthNameAndDate(
                                      item.createdDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {item.paidDate
                                      ? formatMonthNameAndDate(
                                        item.createdDate,
                                        companyData.timezone,
                                        true
                                      )
                                      : "--"}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {item.invoiceId}
                                  </td>
                                </tr>
                              );
                            })}
                            {currentVoidInvoice.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClickClientList}
                          pageRangeDisplayed={5}
                          pageCount={pageCountClientList}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${currActiveTab == "all" ? "show active" : ""
                        }`}
                      id="pills-working"
                      role="tabpanel"
                      aria-labelledby="pills-working-tab"
                      tabindex="0"
                    >
                      <div className="common-table clients-table rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-4 w65-px">
                                <div className="form-check">
                                  <input
                                    class="selectAll form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                    checked={
                                      allInvoiceList.length ==
                                      bulkDeleteId.length &&
                                      allInvoiceList.length > 0
                                    }
                                    defaultValue
                                    onClick={() => checkedAll()}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="selectAll"
                                  ></label>
                                </div>
                              </th>
                              <th>Client</th>
                              <th>Total ($)</th>
                              <th>Status</th>
                              <th>Created Date</th>
                              <th>Paid Date</th>
                              <th>Invoice Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currenInvoiceList?.map((item) => {
                              return (
                                <tr style={{ cursor: "pointer" }}>
                                  <td className="ps-4 w65-px">
                                    <div className="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="item2"
                                        checked={bulkDeleteId.find(
                                          (e) => e == item._id
                                        )}
                                        onChange={(e) => {
                                          setIsSingleChecked(e.target.checked);
                                          deleteIdList(
                                            e.target.checked,
                                            item._id
                                          );
                                        }}
                                      />
                                      <label
                                        for="item2"
                                        class="form-check-label"
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="clientDetail">
                                      <div
                                        className="clientName"
                                        onClick={() =>
                                          navigate(
                                            `/view-invoice/${item.orderId}`,
                                            {
                                              state: {
                                                _id: item._id,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <p className="name">
                                          <>
                                            <a>
                                              {item.userDetails?.firstName
                                                ? `${item.userDetails?.firstName
                                                } ${" "}  ${item.userDetails
                                                  ?.lastName || " "
                                                }`
                                                : item.userDetails?.email}
                                            </a>

                                            {Object.values(item.tags).map(
                                              (tagItem) => {
                                                return (
                                                  <span style={{}}>
                                                    {tagItem}
                                                  </span>
                                                );
                                              }
                                            )}
                                            <br />
                                            <small>{item.orderId}</small>
                                          </>
                                        </p>
                                      </div>
                                    </div>
                                  </td>

                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {" "}
                                    ${" "}
                                    {item &&
                                      item.partialUpfront &&
                                      item.partialUpfront.status === true
                                      ? item.paymentDue
                                        ? item.totalPrice != 0
                                          ? item.totalPrice - item.paymentDue
                                          : 0
                                        : item.totalPrice != 0
                                          ? item.totalPrice - item.paymentCredit
                                          : 0
                                      : item.totalPrice.toFixed(2)}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <span className="pill pill-grey fw-normal fs-12">
                                      {item.status}
                                    </span>
                                  </td>

                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {formatMonthNameAndDate(
                                      item.createdDate,
                                      companyData.timezone,
                                      true
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {item.paidDate
                                      ? formatMonthNameAndDate(
                                        item.createdDate,
                                        companyData.timezone,
                                        true
                                      )
                                      : "--"}
                                  </td>
                                  <td
                                    onClick={() =>
                                      navigate(
                                        `/view-invoice/${item.orderId}`,
                                        {
                                          state: {
                                            _id: item._id,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    {item.invoiceId}
                                  </td>
                                </tr>
                              );
                            })}
                            {currenInvoiceList.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClickLeadList}
                          pageRangeDisplayed={5}
                          pageCount={pageCountInvoiceList}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                  </div>
                  {(bulkDeleteId.length > 0 || isSingleChecked) && (
                    <div className="table-bottom-option">
                      <div className="row">
                        <div className="col-lg-3 col-md-4">
                          <div className="input-group">
                            <select
                              id="selectOption"
                              value={selectOption.target}
                              onChange={(e) => selectOption(e)}
                              className="form-control"
                            >
                              <option>With Selected...</option>
                              <option value="paid">Mark as paid</option>
                              <option value="unpaid">Mark as unpaid</option>
                              {/* <option>Mark as refund</option>*/}
                              <option value="void">Mark as void</option>
                              <option>Delete</option>
                            </select>
                            <button
                              className="btn btn-apply"
                              onClick={() => {
                                if (selectedOption == "Delete") {
                                  deleteMultiple();
                                }
                                if (
                                  selectedOption == "paid" ||
                                  selectedOption == "unpaid" ||
                                  selectedOption == "void"
                                ) {
                                  updateMultiple(selectedOption);
                                }
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    // </div>
  );
};
export default Invoices;

import React, { useEffect, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import orderIcon from "../../assets/img/icons/orderIcon.png";
import activeOrderIcon from "../../assets/img/icons/activeOrder.png";
import { apiGetMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import { useSelector } from "react-redux";
import PageLoader from "../../components/pageLoader";
import { formatMonthNameAndDate } from "../../shared/functions";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [homePageData, setHomePageData] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    getHomePageData();
  }, []);
  function getHomePageData() {
    var header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(allApiUrl.HOMEPAGE, "", header)
      .then((res) => {
        if (res.status === 200) {
          setInitialLoading(false);
          let openOrder = res.data.orders.filter(
            (openOrder) => openOrder.status != "complete"
          );
          setHomePageData(() => {
            res.data.openOrder = openOrder.length;
            res.data.completeOrder = res.data.orders.length - openOrder.length;
            return [res.data];
          });
        }
      })
      .catch((err) => {
        setInitialLoading(false);
      });
  }

  return (
    <div>
      <Sidebar
        activePage="home"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <PageLoader isLoading={initialLoading} />
      <Header handleSidebar={handleSidebar} />
      {!initialLoading && (
        <div className="sectionHome">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-xxl-12 mx-auto">
                <div className="heading">
                  <h2>
                    Welcome,
                    {homePageData[0]?.invoice[0]?.userDetails?.firstName}!
                  </h2>
                </div>

                <div className="homeTiles rounded-1">
                  <div className="row">
                    <div className="col-xxl-4 col-xl-4 col-md-4">
                      <div className="tile">
                        <div className="row">
                          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-3">
                            <div className="tileImg">
                              <img src={orderIcon} />
                            </div>
                          </div>
                          <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-9">
                            <div className="tileText">
                              <p className="mb-0">Open Orders</p>
                              <h3 className="mb-0">
                                {homePageData[0]?.openOrder}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-4 col-xl-4 col-md-4">
                      <div className="tile">
                        <div className="row">
                          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-3">
                            <div className="tileImg">
                              <img src={activeOrderIcon} />
                            </div>
                          </div>
                          <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-9">
                            <div className="tileText">
                              <p className="mb-0">Completed Orders</p>
                              <h3 className="mb-0">
                                {homePageData[0]?.completeOrder}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-4 col-xl-4 col-md-4">
                      <div className="tile">
                        <div className="row">
                          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-3">
                            <div className="tileImg">
                              <img src={activeOrderIcon} />
                            </div>
                          </div>
                          <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-9">
                            <div className="tileText">
                              <p className="mb-0">Active Subscriptions</p>
                              <h3 className="mb-0">
                                {
                                  homePageData[0]?.subscriptions.filter(
                                    (item) => item.serviceStatus == true
                                  ).length
                                }
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {homePageData[0] &&
                  homePageData[0]?.invoice &&
                  homePageData[0]?.invoice?.length > 0 && (
                    <div className="orders-section mt-4">
                      <div className="heading">
                        <h2 className="fs-5 mb-3">Unpaid Invoices</h2>
                      </div>
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Invoice</th>
                              <th>Date</th>
                              <th>Total ($)</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {homePageData[0] &&
                              homePageData[0]?.invoice
                                .slice(0, 5)
                                .map((item) => {
                                  return (
                                    <tr>
                                      <td>
                                        <a
                                          onClick={() =>
                                            navigate(
                                              `/view-payment/${item.orderId}`
                                            )
                                          }
                                        >
                                          {item.invoiceId}
                                        </a>
                                      </td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.createdDate,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>${item.totalPrice.toFixed(2)}</td>
                                      <td autocapitalize="none">
                                        <span className="pill pill-grey fw-normal fs-12">
                                          unpaid
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                {homePageData[0] &&
                  homePageData[0]?.tickets &&
                  homePageData[0]?.tickets?.length > 0 && (
                    <div className="orders-section mt-4">
                      <div className="heading">
                        <h2 className="fs-5 mb-3">Open tickets</h2>
                      </div>
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Subject</th>
                              <th>Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {homePageData[0] &&
                              homePageData[0]?.tickets
                                ?.slice(0, 5)
                                .map((item) => {
                                  return (
                                    <tr>
                                      <td>
                                        <a
                                          onClick={() =>
                                            navigate(
                                              `/portal/view-ticket/${item._id}`
                                            )
                                          }
                                        >
                                          {item.subject}
                                        </a>
                                      </td>
                                      <td>
                                        {" "}
                                        {formatMonthNameAndDate(
                                          item.date,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>
                                        <span className="pill pill-orange fw-normal fs-12">
                                          Open
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                {homePageData[0] &&
                  homePageData[0]?.orders &&
                  homePageData[0]?.orders?.length > 0 && (
                    <div className="orders-section mt-4">
                      <div className="heading">
                        <h2 className="fs-5 mb-3">Recent orders</h2>
                      </div>
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Order No.</th>
                              <th>Title</th>
                              <th>Paid Date</th>
                              <th>Completed Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {homePageData[0] &&
                              homePageData[0]?.orders
                                ?.slice(0, 5)
                                .map((item) => {
                                  return (
                                    <tr>
                                      <td>
                                        <a
                                          onClick={() =>
                                            navigate(
                                              `/view-purchase/${item?.orderId}`,
                                              {
                                                state: {
                                                  _id: item._id,
                                                },
                                              }
                                            )
                                          }
                                        >
                                          {item?.orderId}
                                        </a>
                                      </td>
                                      <td>{item?.title}</td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.createdDate,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>--</td>
                                      <td>
                                        {(item.status == "pending" ||
                                          item.status == "Pending") &&
                                          item.inTakeForm ? (
                                          <span
                                            className="pill pill-grey fs-12 fw-normal"
                                            style={{
                                              cursor: "pointer",
                                              background: "#c5bff9",
                                              color: "#5a4cdc",
                                            }}
                                            onClick={() => {
                                              navigate(
                                                `/portal/intakeForm/${item.inTakeForm}`,
                                                {
                                                  state: {
                                                    orderId: item._id,
                                                    mode: "add",
                                                  },
                                                }
                                              );
                                            }}
                                          >
                                            Start Order
                                          </span>
                                        ) : (
                                          <span className="pill pill-grey fw-normal fs-12">
                                            {item.status}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                {homePageData[0] &&
                  homePageData[0]?.subscriptions &&
                  homePageData[0]?.subscriptions?.length > 0 && (
                    <div className="orders-section mt-4">
                      <div className="heading">
                        <h2 className="fs-5 mb-3">Active Subscriptions</h2>
                      </div>
                      <div className="common-table invoiceTable rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Service</th>
                              <th>Started</th>
                              <th>Next Payment</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {homePageData[0] &&
                              homePageData[0]?.subscriptions
                                ?.slice(0, 5)
                                .map((item) => {
                                  return (
                                    <tr>
                                      <td>
                                        <a>{item?.subscriptionId}</a>
                                      </td>
                                      <td>
                                        <a>
                                          {item?.product &&
                                            item?.product[0] &&
                                            item.product[0]?.name}
                                        </a>
                                      </td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.startDate,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>
                                        {formatMonthNameAndDate(
                                          item.nextDueDate,
                                          companyData.timezone
                                        )}
                                      </td>
                                      <td>
                                        <span className="pill pill-green fw-normal fs-12">
                                          Active
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Home;

import React from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { useSelector } from "react-redux";

const Settings = () => {
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />
      <div className="section-setting commonPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-xxl-8 col-xl-11 mx-auto">
              <div className="heading">
                <h2 className="fw-semibold">Settings</h2>
              </div>
              <div className="setting-content rounded-1">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="setting-inner">
                      <h4>Your business</h4>

                      <div className="setting">
                        <a >
                          <span onClick={() => navigate("/settings/general")}>
                            Company
                          </span>
                        </a>
                        <p>
                          Set your brand name and colors, upload your logo,
                          connect your domain.
                        </p>
                      </div>
                      <div className="setting">
                        <a>
                          <span onClick={() => navigate("/settings/payment")}>
                            Payments
                          </span>
                        </a>
                        <p>
                          Connect your payment processors, set your currency,
                          invoice and tax settings.
                        </p>
                      </div>

                      <div className="setting">
                        <a>
                          <span onClick={() => navigate("/settings/team")}>
                            Team
                          </span>
                        </a>
                        <p>Invite your team, update permissions.</p>
                      </div>

                      <div
                        className="setting"
                        onClick={() => navigate("/logs")}
                      >
                        <a>Logs</a>
                        <p>View event and payment logs.</p>
                      </div>
                    </div>
                  </div>


                  <div className="col-lg-4">
                    <div className="setting-inner">
                      <h4>Your process</h4>

                      <div
                        className="setting"
                        onClick={() => navigate("/settings/tags")}
                      >
                        <a>Tags</a>
                        <p>Update your list of tags for orders and tickets.</p>
                      </div>

                      <div
                        className="setting"
                        onClick={() => navigate("/messaging")}
                      >
                        <a>Messaging</a>
                        <p>
                          Create reply templates, signatures, and out of office
                          replies.
                        </p>
                      </div>
                      {/* 

                      <div
                        className="setting"
                        onClick={() => navigate("/settings/accounts")}
                      >
                        <a>Accounts</a>
                        <p>Customize account statuses and CRM fields.</p>
                      </div>


                      <div
                        className="setting"
                        onClick={() => navigate("/settings/orders")}
                      >
                        <a>Orders</a>
                        <p>Customize order statuses and how they're handled.</p>
                      </div>


                      <div
                        className="setting"
                        onClick={() => navigate("/settings/tickets")}
                      >
                        <a>Tickets</a>
                        <p>Customize tickets and how they're handled.</p>
                      </div> */}

                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="setting-inner">
                      <h4>Your client portal</h4>

                      <div
                        className="setting"
                        onClick={() => navigate("/settings/email")}
                      >
                        <a>Emails</a>
                        <p>
                          Customize the notification emails that go out to your
                          clients.
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Settings;

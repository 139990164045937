import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import salesIcon from "../../assets/img/icons/saleIcon.png";
import iconRevenue from "../../assets/img/icons/iconRevenue.png";
import { AllContext } from "../../context";
import { saveLoginUserAccessToken } from "../../redux/action/login";
import ReactPaginate from "react-paginate";
import noData from "../../assets/img/nodata-img.svg";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Revenue = () => {
  const [open, setOpen] = useState(false);
  const data = [
    {
      name: "3 Sep",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "4 Sep",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "5 Sep",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "6 Sep",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "7 Sep",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "8 Sep",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "9 Sep",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "10 Sep",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "11 Sep",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "12 Sep",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "13 Sep",
      uv: 2000,
      pv: 3000,
      amt: 2100,
    },
    {
      name: "14 Sep",
      uv: 2000,
      pv: 3000,
      amt: 2100,
    },
    {
      name: "15 Sep",
      uv: 2000,
      pv: 3000,
      amt: 2100,
    },
  ];
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(AllContext);
  // const { toastMessage } = context;
  const navigate = useNavigate();
  const [service, setService] = React.useState([]);
  const [totalRevenue, setTotalRevenue] = React.useState(null);
  const [averageRevenue, setAverageRevenue] = React.useState(null);
  const [numberOfSales, setNumberOfSales] = React.useState(null);
  const [dateRevenue, setDateRevenue] = React.useState([]);
  const [revenueByDate, setRevenueByDate] = useState([]);
  // const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiDeleteMethod,
    apiPutMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { DASHBOARD, DASHBOARD_DATE_FILTER } = allApiUrl;
  // const context = useContext(AllContext);
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    let loggedIn = localStorage.getItem("userLoggedIn");
    // getAllServices();
    getTenDayData();
  }, []);

  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [endDate, setEndDate] = useState(null);
  const [graphTitle, setGraphTitle] = useState("Monthly Revenue")
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);

    setEndDate(end);
    // if (end) {
    //   getDateFilterData(start, end);
    //   setOpen(false);
    // }
    setGraphTitle("Monthly Revenue")
    getDateFilterData(start, end, 'datePicker');
    setOpen(false);
  };
  function formatDateMonthName(d) {
    var date = new Date(d);

    if (isNaN(date.getTime())) {
      return d;
    } else {
      var month = new Array();
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "Mar";
      month[3] = "Apr";
      month[4] = "May";
      month[5] = "Jun";
      month[6] = "Jul";
      month[7] = "Aug";
      month[8] = "Sept";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";

      let day = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      return day + " " + month[date.getMonth()];
    }
  }
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());
    date.setDate(date.getDate());
    const dates = [];
    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
  const currentList = dateRevenue.slice(itemOffset, endOffset);
  let pageCount = Math.ceil(dateRevenue.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % dateRevenue.length;
    setItemOffset(newOffset);
  };

  function getTenDayData() {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {
      startDate: new Date(new Date().setDate(new Date().getDate() - 10)),
      endDate: startDate,
    };
    setIsLoading(true);
    apiPostMethod(DASHBOARD_DATE_FILTER, data, header)
      .then((res) => {
        let servicesList = []
        res.data.data.serviceData.forEach((item) => {
          if (item.unitsSold > 0) {
            servicesList.push(item)
          }
        })
        setService(servicesList);
        let totalRev = 0;
        let average;
        for (let item of res.data.data.serviceData) {
          totalRev += item.revenueGenerated;
        }
        average = parseInt(totalRev / res.data.data.invoiceCount);
        setTotalRevenue(totalRev);
        setAverageRevenue(average);
        setNumberOfSales(res.data.data.invoiceCount);
        let revenueDataTemp = res.data.data.revenueByDate;
        let tempArr = [];
        for (let item of revenueDataTemp) {
          // let data1 = item.split(":");
          if (item.name !== "undefined") {
            let obj = {};
            obj["date"] = item.name;
            obj["revenueGenerated"] = item.revenue;
            tempArr.push(obj);
          }
        }

        setDateRevenue(tempArr);
        //revenuByDate
        let startDateFilter = new Date(
          new Date().setDate(new Date().getDate() - 11)
        );

        let endDateFilter = startDate;
        let startDateSplits = startDateFilter.toLocaleString().split("/");
        let endDateSplits = endDateFilter.toLocaleString().split("/");

        setIsLoading(false);
        let numOfDays = 0;
        let year = startDateSplits[2];
        year = year.split(",")[0];
        if (startDateSplits[1] == endDateSplits[1]) {
          numOfDays = endDateSplits[0] - startDateSplits[0];

          let finalData = [...Array(numOfDays)].map((_a, index) => {
            let currentDate = index + parseInt(startDateSplits[0]) + 1;
            let dateExist = res.data.data.revenueByDate.find((row) => {
              let date = row.name.split("-")[2];
              if (date == currentDate) {
                return true;
              }
              return false;
            });
            if (dateExist) {
              return dateExist;
            } else {
              return {
                name: `${year}-${startDateSplits[1]}-${index + parseInt(startDateSplits[0]) > 9
                  ? currentDate
                  : "0" + currentDate
                  }`,
                revenue: 0,
              };
            }
          });
          finalData.forEach((item, index) => {
            setRevenueByDate(() => {
              finalData[index].name = formatDateMonthName(item.name);
              return [...finalData];
            });
          });
        } else {
          let var1 = new Date(
            startDateFilter.getTime() -
            startDateFilter.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          let var2 = new Date(
            endDateFilter.getTime() - endDateFilter.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          let currStartDate = startDateFilter.toLocaleString().split(",");
          currStartDate = currStartDate[0];
          currStartDate = currStartDate.replaceAll("/", "-");
          const date = currStartDate;
          const [day, month, year] = date.split("-");
          currStartDate = [year, month, day].join("-");
          let currEndDate = endDateFilter.toLocaleString().split(",");
          currEndDate = currEndDate[0];
          currEndDate = currEndDate.replaceAll("/", "-");
          const dateEnd = currEndDate;
          const [dayEnd, monthEnd, yearEnd] = dateEnd.split("-");
          currEndDate = [yearEnd, monthEnd, dayEnd].join("-");
          let dateRangeDate = getDatesInRange(new Date(var1), new Date(var2));
          function formatDate(date) {
            var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
          }
          dateRangeDate = dateRangeDate.flat();
          dateRangeDate = dateRangeDate.map((item) => {
            return formatDate(item);
          });
          let finalData = dateRangeDate.map((_a, index) => {
            let dateExist = res.data.data.revenueByDate.find((row) => {
              let date = row.name.split("-")[2];
              let month = row.name.split("-")[1];
              if (date == _a.split("-")[2] && month == _a.split("-")[1]) {
                return true;
              }
              return false;
            });

            if (dateExist) {
              return dateExist;
            } else {
              return {
                name: _a,
                revenue: 0,
              };
            }
          });
          finalData.forEach((item, index) => {
            setRevenueByDate(() => {
              finalData[index].name = formatDateMonthName(item.name);
              return [...finalData];
            });
          });
        }

        // setNewClients(res.data.data.clientCount);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err?.data?.message);
      });
  }
  function getDateFilterData(start, end, type) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = {};
    if (type == "datePicker") {
      if (end && end != startDate) {
        data = {
          startDate: startDate,
          endDate: end,
        };
      } else {
        data = {
          startDate: start ? start : startDate,
        };
      }
    }
    else {
      data = {
        startDate: start,
        endDate: end,
      }
    }
    setIsLoading(true);
    apiPostMethod(DASHBOARD_DATE_FILTER, data, header)
      .then((res) => {
        let servicesList = []
        res.data.data.serviceData.forEach((item) => {
          if (item.unitsSold > 0) {
            servicesList.push(item)
          }
        })
        setService(servicesList);
        let totalRev = 0;
        let average;
        for (let item of res.data.data.serviceData) {
          totalRev += item.revenueGenerated;
        }
        average = parseInt(totalRev / res.data.data.invoiceCount);
        setTotalRevenue(totalRev);
        setAverageRevenue(average);
        setNumberOfSales(res.data.data.invoiceCount);
        let revenueDataTemp = res.data.data.revenueByDate;
        let tempArr = [];
        for (let item of revenueDataTemp) {
          // let data1 = item.split(":");
          if (item.name !== "undefined") {
            let obj = {};
            obj["date"] = item.name;
            obj["revenueGenerated"] = item.revenue;
            tempArr.push(obj);
          }
        }
        setDateRevenue(tempArr);


        let startDateMonthDiff
        let endDateMonthDiff
        if (data.startDate && data.endDate) {
          startDateMonthDiff = new Date(data.startDate)
          endDateMonthDiff = new Date(data.endDate)
        }
        else {
          startDateMonthDiff = new Date(data.startDate);
          endDateMonthDiff = new Date(data.startDate);
        }
        console.log('351', startDateMonthDiff, endDateMonthDiff)
        function monthDiff(d1, d2) {
          var months;
          months = (d1.getFullYear() - d2.getFullYear()) * 12;
          months -= d2.getMonth();
          months += d1.getMonth();
          return months; // <= 0 ? 5 : months;
        }
        let diff = (data.startDate && data.endDate) ? monthDiff(endDateMonthDiff, startDateMonthDiff) : 5
        console.log('diff', diff)
        if (diff >= 4) {
          console.log('res.data.data.revenueDate', res.data.data.revenueByDate)
          let revenueByDateList = []
          res.data.data.revenueByDate.forEach((value) => {
            revenueByDateList.push({ name: value.name, revenue: value.revenue })
          })
          setRevenueByDate(revenueByDateList)
          // setRevenueByDate(() => {
          //   res.data.data.revenueByDate[index].name = formatDateMonthName(
          //     item.name
          //   );
          //   return [...res.data.data.revenueByDate];
          // });
        } else {
          if (startDate && end) {
            let startingDate = startDate
            if (type == "custom") {
              startingDate = start
            }
            let startDateSplits = startingDate.toLocaleString().split("/");
            let endDateSplits = end.toLocaleString().split("/");
            let numOfDays = 0;
            let year = startDateSplits[2];
            year = year.split(",")[0];
            if (startDateSplits[1] == endDateSplits[1]) {
              numOfDays = endDateSplits[0] - startDateSplits[0];
              numOfDays += 1;
              let finalData = [...Array(numOfDays)].map((_a, index) => {
                let currentDate = index + parseInt(startDateSplits[0]);
                let dateExist = res.data.data.revenueByDate.find((row) => {
                  let date = row.name.split("-")[2];
                  if (date == currentDate) {
                    return true;
                  }
                  return false;
                });
                if (dateExist) {
                  return dateExist;
                } else {
                  return {
                    name: `${year}-${startDateSplits[1]}-${index + parseInt(startDateSplits[0]) > 9
                      ? currentDate
                      : "0" + currentDate
                      }`,
                    revenue: 0,
                  };
                }
              });
              finalData.forEach((item, index) => {
                setRevenueByDate(() => {
                  finalData[index].name = formatDateMonthName(item.name);
                  return [...finalData];
                });
              });
            } else {
              // else case
              let startingDate = startDate
              if (type == "custom") {
                startingDate = start
              }
              let var1 = new Date(
                startingDate.getTime() - startingDate.getTimezoneOffset() * 60000
              )
                .toISOString()
                .split("T")[0];
              let var2 = new Date(end.getTime() - end.getTimezoneOffset() * 60000)
                .toISOString()
                .split("T")[0];
              let currStartDate = startDate.toLocaleString().split(",");
              currStartDate = currStartDate[0];
              currStartDate = currStartDate.replaceAll("/", "-");
              const date = currStartDate;
              const [day, month, year] = date.split("-");
              currStartDate = [year, month, day].join("-");
              let currEndDate = end.toLocaleString().split(",");
              currEndDate = currEndDate[0];
              currEndDate = currEndDate.replaceAll("/", "-");
              const dateEnd = currEndDate;
              const [dayEnd, monthEnd, yearEnd] = dateEnd.split("-");
              currEndDate = [yearEnd, monthEnd, dayEnd].join("-");

              let dateRangeDate = getDatesInRange(new Date(var1), new Date(var2));
              function formatDate(date) {
                var d = new Date(date),
                  month = "" + (d.getMonth() + 1),
                  day = "" + d.getDate(),
                  year = d.getFullYear();

                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;

                return [year, month, day].join("-");
              }
              dateRangeDate = dateRangeDate.flat();
              dateRangeDate = dateRangeDate.map((item) => {
                return formatDate(item);
              });
              let finalData = dateRangeDate.map((_a, index) => {
                let dateExist = res.data.data.revenueByDate.find((row) => {
                  let date = row.name.split("-")[2];
                  let month = row.name.split("-")[1];
                  if (date == _a.split("-")[2] && month == _a.split("-")[1]) {
                    return true;
                  }
                  return false;
                });

                if (dateExist) {
                  return dateExist;
                } else {
                  return {
                    name: _a,
                    revenue: 0,
                  };
                }
              });
              finalData.forEach((item, index) => {
                setRevenueByDate(() => {
                  finalData[index].name = formatDateMonthName(item.name);
                  return [...finalData];
                });
              });
            }
          } else {
            let singleDate = new Date(
              start.getTime() - start.getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0];
            if (res.data.data.revenueByDate.length === 0) {
              res.data.data.revenueByDate[0] = {
                name: singleDate,
                revenue: 0,
              };
            }
            res.data.data.revenueByDate.forEach((item, index) => {
              setRevenueByDate(() => {
                res.data.data.revenueByDate[index].name = formatDateMonthName(
                  item.name
                );
                return [...res.data.data.revenueByDate];
              });
            });
          }
        }


        setIsLoading(false);
        // setNewClients(res.data.data.clientCount);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err?.data?.message);
      });
  }
  function customDateSelector(type) {
    if (type === "Last 7 Days") {
      const today = new Date();
      let start = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000))
      setGraphTitle(type)
      setStartDate(start);
      setEndDate(today);
      getDateFilterData(start, today, "custom");
    }
    else if (type == "Previous Month") {
      setGraphTitle(type)
      const today = new Date();
      const start = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);
      const end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
      setStartDate(start);
      setEndDate(end);
      getDateFilterData(start, end, "custom");
    }
    else if (type === "Last 3 Months") {
      setGraphTitle(type)
      const today = new Date();
      const start = new Date(today.getFullYear(), today.getMonth() - 3, 1, 0, 0, 0);
      const end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
      setStartDate(start);
      setEndDate(end);
      getDateFilterData(start, end, "custom");
    }
    else if (type === "Month to Date") {
      setGraphTitle(type)
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      getDateFilterData(firstDay, date, "custom");
    }
    else if (type == "Quarter to Date") {
      setGraphTitle(type)
      const today = new Date();
      const currentQuarter = Math.floor((today.getMonth() / 3));
      const year = today.getFullYear();
      let quarterStart = new Date(year, currentQuarter * 3, 1);
      getDateFilterData(quarterStart, today, "custom");
    }
    else if (type == "Year to Date") {
      setGraphTitle(type)
      let date = new Date()
      var startDate = new Date(date.getFullYear(), 0, 1);
      getDateFilterData(startDate, date, "custom");
    } else if (type = "All Time") {
      console.log("598 type all time")
      setGraphTitle(type)
      let date = "" //new Date()
      var startDate = ""//new Date(date.getFullYear(), 0, 1);
      getDateFilterData(startDate, date, "custom");
    }
  }
  function openCalender(e) {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const formatter = (data) => {
    return `$${data}`;
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0 && payload[0].payload) {
      return (
        <div className="custom-tooltip" style={{ background: "white" }}>
          <p>{payload[0].payload.name}</p>
          <p>
            Revenue <b>${payload[0].payload.revenue.toFixed(2)}</b>
          </p>
        </div>
      );
    }
  };
  return (
    <div>
      <Sidebar
        activePage="dashboard"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="dashboardSection commonPadding pb-4 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-6 col-md-4">
              <div className="heading">
                <h2>Revenue</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="dashboardFilters">
                <div className="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle dashboardDropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Revenue
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => navigate("/revenue")}
                      >
                        Revenue
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => navigate("/service-report")}
                      >
                        Services
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => navigate("/client-report")}
                      >
                        Clients
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => navigate("/sales")}
                      >
                        Sale Performance
                      </a>
                    </li>
                    {/* <li>
                      <a class="dropdown-item" href="#">
                        Account Balances
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Coupon usage
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Response times
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Assigned orders
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/completed-order">
                        Completed orders
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="order-status">
                        Order statuses
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Taxes
                      </a>
                    </li> */}
                  </ul>

                  {/* <button
                    class="btn btn-secondary dropdown-toggle dashboardDropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Export
                  </button> */}
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        Accounts
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Orders
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Transactions
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Invoices
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Form Date
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Revenue By Client
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Ticket Rating
                      </a>
                    </li>
                  </ul>

                  {/* <input type="date" className="form-control" /> */}
                  <input
                    className="form-control"
                    type="text"
                    id="expiryDate"
                    onClick={(e) => {
                      openCalender(e);
                    }}
                    value={`${startDate ? startDate : ""}, ${endDate ? endDate : ""
                      }`}
                  />
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-calendar"></i>
                  </button>
                  <ul class="dropdown-menu">
                    {
                      ["Last 7 Days", "Previous Month", "Last 3 Months", "Month to Date", "Quarter to Date", "Year to Date", "All Time"].map((item) => {
                        return (
                          <li
                            onClick={() => customDateSelector(item)}
                          >
                            <a class="dropdown-item" href="#">
                              {item}
                            </a>
                          </li>
                        )
                      })
                    }
                  </ul>
                  {/* <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() =>{getDateFilterData()}}
                  >
                    Filter
                  </button> */}

                  {open === true ? (
                    <span className="tooltipdatepicker">
                      <DatePicker
                        maxDate={moment().toDate()}
                        className=""
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                      />
                      <span className="tooltipdatepickertext">Get date range by dragging on "FROM" date</span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-xxl-12">
              <div className="row mt-3">
                <div className="col-lg-4 col-md-4">
                  <div className="tile">
                    <div className="row">
                      <div className="col-xxl-2 col-xl-3 col-lg-3">
                        <div className="tileImg">
                          <img src={iconRevenue} />
                        </div>
                      </div>
                      <div className="col-xxl-10 col-xl-9 col-lg-9">
                        <p>Revenue</p>
                        <h3>${totalRevenue ? totalRevenue : "NA"}</h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4">
                  <div className="tile">
                    <div className="row">
                      <div className="col-xxl-2 col-xl-3 col-lg-3">
                        <div className="tileImg">
                          <img src={salesIcon} />
                        </div>
                      </div>
                      <div className="col-xxl-10 col-xl-9 col-lg-9">
                        <p>Sales</p>
                        <h3>{numberOfSales ? numberOfSales : "NA"}</h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4">
                  <div className="tile">
                    <div className="row">
                      <div className="col-xxl-2 col-xl-3 col-lg-3">
                        <div className="tileImg">
                          <img src={salesIcon} />
                        </div>
                      </div>
                      <div className="col-xxl-10 col-xl-9 col-lg-9">
                        <p>Average Sale</p>
                        <h3>${averageRevenue ? averageRevenue : "NA"}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-4 mb-4">
                <div className="card-header">
                  <h3 className="mb-0">{graphTitle}</h3>
                </div>
                <div className="card-body">
                  <BarChart
                    width={1500}
                    height={300}
                    data={revenueByDate}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    barSize={20}
                  >
                    <XAxis
                      dataKey="name"
                      scale="point"
                      padding={{ left: 10, right: 10 }}
                    />
                    <YAxis tickFormatter={formatter} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <Bar
                      dataKey="revenue"
                      fill="#8884d8"
                      background={{ fill: "#eee" }}
                    />
                  </BarChart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentList && currentList.length > 0 ? (
        <div className="listingSection horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-12">
                <div className="common-table rounded-1">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentList &&
                        currentList.length > 0 &&
                        currentList.map((item, index) => {
                          return (
                            <tr>
                              <td>{item.date}</td>
                              <td>${item.revenueGenerated}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="sectionPagination">
                <ReactPaginate
                  nextLabel={<i class="fa-solid fa-angles-right"></i>}
                  previousLabel={<i class="fa-solid fa-angles-left"></i>}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination paginate"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default Revenue;

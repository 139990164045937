import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";
import data from "../../api/data.json";
import { nanoid } from "nanoid";
import { AllContext } from "../../context";
import { apiGetMethod, apiPostMethod, apiDeleteMethod } from "../../api/rest";

const ClientTeam = () => {
  const loginUserInfo = useSelector(
    (state) => state.loginReducer.loginUserInfo
  );
  useEffect(() => {
    loginUserInfo.teamId.length > 0 ? getMembers() : getClients();
  }, []);
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [contacts, setContacts] = useState([]);
  const [addFormData, setAddFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let currRemVal = "";

  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );

  const context = useContext(AllContext);
  const { allApiUrl, redirectToLogin, toastMessage } = context;
  const { GET_ALL_CLIENT_TEAM_MEMBERS, GET_ALL_TEAM_MEMBERS } = allApiUrl;

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const settingCurrRemVal = (id) => {
    currRemVal = id;
  };

  const getClients = () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(
      GET_ALL_CLIENT_TEAM_MEMBERS + "/" + loginUserInfo.id,
      "",
      header
    )
      .then((res) => {
        if (res.status === 200) {
          setContacts(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const getMembers = () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };



    apiGetMethod(GET_ALL_TEAM_MEMBERS + "/" + loginUserInfo.id, "", header)
      .then((res) => {
        if (res.status === 200) {
          let arr1 = [];
          for (let item of res.data.data) {
            for (let el of item.teamId) {
              arr1.push({ userId: el });
            }
          }
          setContacts(arr1);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err);
      });
  };

  const addTeamMember = () => {
    const data = {};
    if (addFormData.email !== "") data.email = addFormData.email;
    if (addFormData.firstName !== "") {
      data.firstName = addFormData.firstName;
    } else {

      data.firstName = data.email.substring(0, data.email.indexOf("@"));
    }
    if (addFormData.lastName !== "") data.lastName = addFormData.lastName;
    if (loginUserInfo.group !== "") data.groupId = loginUserInfo.group;
    // if (addFormData.lastName !== "")
    data.role = "client team";
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(GET_ALL_CLIENT_TEAM_MEMBERS, data, header)
      .then((res) => {
        if (res.status === 200) {
          getClients();
          setAddFormData({
            firstName: "",
            lastName: "",
            email: "",
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err);
      });
  };

  const deleteTeamMember = () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = currRemVal + "/" + loginUserInfo.id;
    apiDeleteMethod(GET_ALL_CLIENT_TEAM_MEMBERS, data, header)
      .then((res) => {
        if (res.status == 200) {
          getClients();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err);
      });
  };

  const leaveTeam = () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    let data = loginUserInfo.id + "/" + currRemVal;
    apiDeleteMethod(GET_ALL_CLIENT_TEAM_MEMBERS, data, header)
      .then((res) => {
        if (res.status == 200) {
          getMembers();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err);
      });
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    const newContact = {
      id: nanoid(),
      firstName: addFormData.firstName,
      lastName: addFormData.lastName,
      email: addFormData.email,
    };
    const newContacts = [...contacts, newContact];
    setContacts(newContacts);
  };

  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  return (
    <div>
      <Sidebar
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} />

      <div className="setting-form-section pt-3">
        <div className="container-fluid pl-240">
          <div className="row">
            {/* <div className="col-lg-12">
              <div
                className="section-back"
                onClick={() => navigate("/clients")}
              >
                <a href="">
                  <i className="fa-solid fa-chevron-left"></i> Clients
                </a>
              </div>
            </div> */}

            <div className="col-xxl-6 col-lg-8 mx-auto">
              <div className="setting-form">
                {!loginUserInfo.teamId.length > 0 && (
                  <h3 className="d-flex justify-content-between">
                    Team{" "}
                    <button
                      className="btn btn-primary addTeam-btn m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#addteamModal"
                    >
                      Add
                    </button>
                  </h3>
                )}
                <div className="commonForm rounded-1 p-0">
                  <div className="table-resposnive">
                    <table className="table mb-0 rounded-1">
                      <tbody>
                        {contacts.map((contact) => {
                          return (
                            (contact.userId || contact._id) && (
                              <tr>
                                <td>
                                  <a href={``}>
                                    {contact.userId.firstName
                                      ? contact.userId.firstName
                                      : "" + " " + contact.userId.lastName
                                      ? contact.userId.lastName
                                      : ""}
                                  </a>
                                </td>
                                <td>
                                  <span className="pill pill-orange">
                                    {contact.userId.email}
                                  </span>
                                </td>
                                <td>
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-secondary dropdown-toggle btn-filter"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </button>
                                    <ul className="dropdown-menu filter-drowdown">
                                      <li>
                                        {!loginUserInfo.teamId.length > 0 && (
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteModal"
                                            onClick={() =>
                                              settingCurrRemVal(
                                                contact.userId._id
                                              )
                                            }
                                          >
                                            Remove
                                          </a>
                                        )}
                                        {loginUserInfo.teamId.length > 0 && (
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteModal"
                                            onClick={() =>
                                              settingCurrRemVal(
                                                contact.userId._id
                                              )
                                            }
                                          >
                                            Leave
                                          </a>
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            )
                          );
                        })}

                        <tr>
                          {/* <td></td>
                          <td>test@yopmail.com</td> */}
                          {/* <td>
                            <div className="dropdown">
                                <button
                                  className="btn btn-secondary dropdown-toggle btn-filter"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                                <ul className="dropdown-menu filter-drowdown">
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteModal"
                                    >
                                      Remove
                                    </a>
                                  </li>
                                </ul>
                              </div>
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* add team */}

      <div
        className="modal fade history-modal"
        id="addteamModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <form onSubmit={handleAddFormSubmit}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Add a coworker
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="history-content">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label>First Name</label>
                        <input
                          type="text"
                          name="firstName"
                          className="form-control"
                          value={addFormData.firstName}
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label>Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          className="form-control"
                          value={addFormData.lastName}
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="name@domain.com"
                          value={addFormData.email}
                          required
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                  onClick={() => {
                    if (addFormData.email.length > 0) {
                      let status = String(addFormData.email)
                        .toLowerCase()
                        .match(
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                      if (status != null) {
                        addTeamMember();
                      } else {
                        toastMessage("error", "Invalid Email Address");
                      }
                    } else {
                      toastMessage("error", "Email Address Is Empty");
                    }
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* add team */}

      {/* {delete modal} */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Are you sure you want to delete client team member{" "}
                  {/* <strong>{couponCode}</strong>? */}
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={() => deleteTeamMember()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClientTeam;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirect, Redirect, useLocation } from "react-router-dom";
import AvatarImg from "../../assets/img/avatar.png";
import ArrowDown from "../../assets/img/arrow-down.svg";
import iconToggle from "../../assets/img/menu-icon.svg";
import {
  saveAddToCartInformation,
  saveLoginUserAccessToken,
  saveOrderNeedAttention,
  savePublicInvoice,
} from "../../redux/action/login";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import Dashboard from "../../screens/dashboard";
import { AllContext } from "../../context";
import { useContext } from "react";
import { saveLoginUserInfo } from "../../redux/action/login";
import { redirectToLogin } from "../../shared/functions";
import { rolesData } from "../../shared/constants";
const Header = ({ isLoading, handleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const { apiGetMethod, allApiUrl, toastMessage, apiGetMethodSession } =
    context;
  const { SESSION } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  function checkSession() {
    let page = window.location.pathname.split("/");
    var headers = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(SESSION, "", headers)
      .then((res) => {
        userInfo.roles = res?.data?.permissions;
        if (userInfo.group == 2) {
          userInfo.teamId = res?.data?.teamId;
        }
        dispatch(saveLoginUserInfo(userInfo));
        if (res.status === 400) {
          toastMessage("error", res.data.message);
          redirectToLogin(dispatch, navigate, res);
        }
      })
      .catch((err) => {
        toastMessage("error", err.data.message);
        redirectToLogin(dispatch, navigate, err, page);
      });
  }
  useEffect(() => {
    checkSession();
  }, []);
  function signoutHandler() {
    dispatch(saveLoginUserAccessToken(""));
    dispatch(saveLoginUserInfo(""));
    localStorage.removeItem("userLoggedIn");
    navigate("/logout");
  }

  return (
    <div className="topbar">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-5 col-7">
            <div className="search-parent">
              <div className="hamburger">
                <img onClick={() => handleSidebar()} src={iconToggle} />
              </div>
              {/* <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-7 col-5">
            <div>
              <Loader isLoading={isLoading} />
            </div>
            <div className="sidemenus">
              <ul>
                {/* <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-circle-question"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Documentation
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Product Updates
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Keyboard Shortcuts
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Refer a friend
                        </a>
                      </li>
                      <hr />
                      <li>
                        <a className="dropdown-item" href="#">
                          Contact Support
                        </a>
                      </li>
                    </ul>
                  </div>
                </li> */}
                {/* <li>
                  <a href="">
                    <i className="fa-solid fa-bell"></i>
                  </a>
                </li> */}
                <li>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          userInfo.image && userInfo.image != ""
                            ? userInfo.image
                            : AvatarImg
                        }
                      />{" "}
                      <img className="arrowImg" src={ArrowDown} />
                    </button>
                    <ul className="dropdown-menu">
                      <li onClick={() => navigate("/profile")}>
                        <a className="dropdown-item" href="#">
                          Your Profile
                        </a>
                      </li>
                      {userInfo.group == 2 && (
                        <li onClick={() => navigate("/clientTeam")}>
                          <a className="dropdown-item" href="#">
                            Team
                          </a>
                        </li>
                      )}
                      {/* <hr /> */}
                      <li>
                        <a
                          href="javascript:void(0);"
                          className="dropdown-item"
                          onClick={signoutHandler}
                        >
                          Sign out
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DummyAvatar from "../../assets/img/dummyImg.jpeg";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import ServiceCart from "../../components/serviceCart";
import { ClientModal } from "../../components/Modals/ClientModal";
import { AllContext } from "../../context";
import ReactPaginate from "react-paginate";
import PageLoader from "../../components/pageLoader";

const Shop = () => {
  //constant
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    toastMessage,
    redirectToLogin,
    apiDeleteMethod,
  } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const addToCartInfo = useSelector(
    (state) => state.loginReducer.addToCartInfo
  );
  //useState
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [itemOffset, setItemOffset] = useState(0);
  const [cartCount, setCartCount] = useState(null);
  const [limit, setPageLimit] = useState("");
  const [currentPage, setCurrentPage] = useState();
  const [initialLoading, setInitialLoading] = useState(true);

  const [couponCodeAppliedSuccessfully, setCouponCodeAppliedSuccessfully] =
    useState(false);
  //functions
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  useEffect(() => {
    getAllServices();
  }, [itemOffset, itemsPerPage]);
  const [showCart, setShowCart] = useState(false);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % getServicesList.length;
    setItemOffset(newOffset);
  };

  async function getAllServices() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    try {
      const response = await apiGetMethod(allApiUrl.SHOP_ARTICLE, "", header);
      if (response.status === 200) {
        // setIsLoading(false);
        setInitialLoading(false);
        setAllServices(response.data.services);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      //  setIsLoading(false);
      setInitialLoading(false);
      toastMessage("error", err.data.message);
    }
  }
  const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);
  var getServicesList = allServices;
  var getServicesListLength = allServices.length;
  const serviceResult = getServicesList.slice(itemOffset, endOffset);
  let pageCountDup = Math.ceil(getServicesListLength / itemsPerPage);
  async function deleteHandler(productId) {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiDeleteMethod(allApiUrl.CART, productId, header);
      if (response.status === 200) {
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      toastMessage("error", err.data.message);
    }
  }

  // function NoOfRows() {
  //   return (
  //     <div>
  //       <label>Number of entries</label>
  //       <select
  //         value={itemsPerPage}
  //         onChange={(e) => {
  //           setItemsPerPage(e.target.value)
  //         }}
  //       >
  //         <option value={20}>20</option>
  //         <option value={50}>50</option>
  //         <option value={100}>100</option>
  //       </select>
  //     </div>
  //   );
  // }

  return (
    <div>
      <Sidebar
        activePage="shop"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="shopSection commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-12">
                <div className="heading mb-3">
                  <h2 className="fs-4">
                    Services
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/cart")}
                    >
                      View Cart | {cartCount}
                    </button>
                  </h2>
                </div>
              </div>
            </div>

            <div className="row">
              {serviceResult.map((service) => {
                return (
                  <ServiceCart
                    {...service}
                    setShowCart={setShowCart}
                    setCouponCodeAppliedSuccessfully={
                      setCouponCodeAppliedSuccessfully
                    }
                  />
                );
              })}
            </div>
            <div className="sectionPagination">
              {/* <NoOfRows /> */}
              <ReactPaginate
                nextLabel={<i class="fa-solid fa-angles-right"></i>}
                previousLabel={<i class="fa-solid fa-angles-left"></i>}
                onPageChange={handlePageClick}
                pageCount={pageCountDup}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination "
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      )}

      <ClientModal
        setIsLoading={setIsLoading}
        setShowCart={setShowCart}
        showCart={showCart}
        deleteHandler={deleteHandler}
        setCartCount={setCartCount}
        couponCodeAppliedSuccessfully={couponCodeAppliedSuccessfully}
        setCouponCodeAppliedSuccessfully={setCouponCodeAppliedSuccessfully}
      />
    </div>
  );
};
export default Shop;

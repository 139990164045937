import React, { useContext, useEffect, useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";

import { AllContext } from "../../context";
import { convertToUpperCase } from "../../shared/functions";
const AddTeamMember = () => {
  const dispatch = useDispatch();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [rolesList, setAllRolesList] = useState([]);
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const {
    apiPostMethod,
    apiGetMethod,
    allApiUrl,
    toastMessage,
    redirectToLogin,
  } = context;
  const { ADD_TEAM_MEMBER, GET_ALL_ROLE } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const createdBy = useSelector((state) => state.loginReducer.loginUserInfo.id);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roles, SetRoles] = useState("");
  const [password, setPassword] = useState("");
  const [sendWelcomeMail, setSendWelcomeMail] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiGetMethod(GET_ALL_ROLE, "", header)
      .then((res) => {
        if (res.status === 200) {
          let AdminRoleList = [];
          res.data.roles.forEach((role) => {
            if (role.type === "admin") {
              const label = convertToUpperCase(role.name);
              if (role.name !== "system") {
                AdminRoleList.push({ value: role.name.trim(), label });
              }
            }
          });
          setAllRolesList(AdminRoleList);
          SetRoles(AdminRoleList[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }, []);
  const addTeamMemberHandler = (event) => {
    setIsLoading(true);
    event.preventDefault();
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    const autoFirstName = email.substring(0, email.indexOf("@"));
    const data = {
      email,
      lastName,
      firstName: firstName ? firstName : autoFirstName,
      password,
      role: roles.value,
      createdBy,
      sendEmail: sendWelcomeMail,
    };
    apiPostMethod(ADD_TEAM_MEMBER, data, header)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 201) {
          setIsLoading(false);
          toastMessage("success", res.data.message);
          navigate(`/accounts/team/${res.data.id}`);
        } else if (res.status === 400) {
          toastMessage("error", res.data.message);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const onSelectRole = (e) => {
    SetRoles(e);
  };

  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />

      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-back"
                onClick={() => navigate("/settings/team")}
              >
                <a href="">
                  <i class="fa-solid fa-angle-left"></i> Team
                </a>
              </div>
            </div>

            <div className="col-lg-5 mx-auto">
              <div className="setting-form">
                <h3 className="fs-4 mb-4">Add Team Member</h3>
                <form className="" onSubmit={addTeamMemberHandler}>
                  <div className="commonForm rounded-1">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            First name <small>optional</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={(e) => setFirstName(e.target.value)}
                            name="firstName"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            Last name <small>optional</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="lastName"
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Role</label>

                          <Select
                            isSearchable
                            value={roles}
                            options={rolesList}
                            onChange={onSelectRole}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder=""
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <small>
                            Leave this empty to generate a default password.
                          </small>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group checkbox-group mb-0">
                          <div class="form-check">
                            <input
                              // onChange={handleChange}
                              className="form-check-input"
                              type="checkbox"
                              id="item1"
                              name="sendWelcomeMail"
                              onClick={() => {
                                setSendWelcomeMail(!sendWelcomeMail);
                              }}
                              checked={sendWelcomeMail}
                            />
                            <label class="form-check-label" for="item1">
                              Send welcome email
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-section text-end mb-0">
                    <button className="btn btn-save">Add Account</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddTeamMember;

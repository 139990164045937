import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
// import "../../assets/css/custom.css";
import "../../assets/css/customNew.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveLoginUserAccessToken, saveCompanyData } from "../../redux/action/login";
import homeIcon from "../../assets/img/icons/homeIcon.svg";
import orderIcon from "../../assets/img/icons/orderIcon.svg";
import iconUser from "../../assets/img/icons/iconUser.svg";
import fileIcon from "../../assets/img/icons/fileIcon.svg";
import ticketIcon from "../../assets/img/icons/ticketIcon.svg";
import invoiceIcon from "../../assets/img/icons/invoiceIcon2.svg";
import couponIcon from "../../assets/img/icons/couponIcon.svg";
import serviceIcon from "../../assets/img/icons/serviceIcon.svg";
import settingIcon from "../../assets/img/icons/settingIcon2.svg";
import subscribeIcon from "../../assets/img/icons/subscribeIcon.svg";
import { apiGetMethod } from "../../api/rest";
import { redirectToLogin } from "../../shared/functions";
import toastMessages from "../../components/toastMessages";
import { allApiUrl } from "../../api/apiRoute";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currTab, setCurrTab] = useState("");
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => { }, [currTab]);
  const areFalse = (value) =>
    Object.values(value).every((value) => value === false);
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const roleList = useSelector((state) => state?.loginReducer?.loginUserInfo);
  let loggedIn = localStorage.getItem("userLoggedIn");
  useEffect(() => {
    if (
      loggedIn &&
      areFalse(roleList?.roles?.clients) &&
      areFalse(roleList?.roles?.coupons) &&
      areFalse(roleList?.roles?.invoiceAndSubscriptionAccess) &&
      areFalse(roleList?.roles?.invoiceManagement) &&
      areFalse(roleList?.roles?.messaging) &&
      areFalse(roleList?.roles?.orderAccess) &&
      areFalse(roleList?.roles?.orderManagement) &&
      areFalse(roleList?.roles?.services) &&
      areFalse(roleList?.roles?.settingsAndTeam) &&
      areFalse(roleList?.roles?.ticketAccess) &&
      areFalse(roleList?.roles?.ticketManagement) &&
      roleList?.roles?.name === "admin"
    ) {
      dispatch(saveLoginUserAccessToken(""));
      localStorage.removeItem("userLoggedIn");
      navigate("/permissionDenied", {
        state: {
          noPermission: true,
        },
      });
    }

    if (!companyData || !Object.keys(companyData).length) {
      getCompanyData();
    }



  }, []);

  useEffect(() => {
    setCurrTab(props.activePage);
  });

  const getCompanyData = async () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.COMPANY, "", header);
      if (response.status === 200) {
        dispatch(saveCompanyData(response.data));
        // setInitialLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      // setIsLoading(false);
      // setInitialLoading(false);
      toastMessages("error", err.data.message);
    }
  }

  function homePageRouteHandler() {
    if (roleList?.roles?.name === "admin") {
      navigate("/dashboard");
    } else {
      navigate("/home");
    }
  }
  function activTabHandler(value) {
    setCurrTab(value);
    navigate(`/${value}`);
  }
  const allTrueChecker = (value) => {
    let result = Object.values(value).every((v) => v === false);
    return result;
  };
  return (
    <div
      className={
        props.toggleSideBar ? "sectionSidebar sidebarActive" : "sectionSidebar"
      }
    >
      <div className="sidebarInner">
        <span
          className="iconCross"
          onClick={() => props.setToggleSidebar(false)}
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
        <h4 onClick={homePageRouteHandler}>
          <a>AgencyCRM</a>
        </h4>
        {roleList?.roles?.type === "admin" ? (
          <ul>
            <li className="menuHeading">ACTIVITY</li>
            <li
              className={currTab === "dashboard" && "active"}
              onClick={() => activTabHandler("dashboard")}
            >
              <a>
                <span>
                  <img src={homeIcon} />
                </span>
                Dashboard
              </a>
            </li>

            {roleList?.roles?.orderAccess &&
              roleList?.roles?.orderManagement &&
              (!allTrueChecker(roleList?.roles?.orderAccess) ||
                !allTrueChecker(roleList?.roles?.orderManagement)) && (
                <li
                  className={currTab === "orders" && "active"}
                  onClick={() => activTabHandler("orders")}
                >
                  <a>
                    <span>
                      {/* <i className="fa-solid fa-inbox"></i> */}
                      <img src={orderIcon} />
                    </span>
                    Orders
                  </a>
                </li>
              )}

            {roleList?.roles?.clients?.view && (
              <li
                className={currTab === "clients" && "active"}
                onClick={() => activTabHandler("clients")}
              >
                <a>
                  <span>
                    {/* <i className="fa-solid fa-user"></i> */}
                    <img src={iconUser} />
                  </span>
                  Clients
                </a>
              </li>
            )}

            <li
              className={currTab === "files" && "active"}
              onClick={() => activTabHandler("files")}
            >
              <a>
                <span>
                  {/* <i className="fa-solid fa-file"></i> */}
                  <img src={fileIcon} />
                </span>
                Files
              </a>
            </li>
            {roleList?.roles?.orderAccess &&
              roleList?.roles?.orderManagement &&
              (!allTrueChecker(roleList?.roles?.ticketAccess) ||
                !allTrueChecker(roleList?.roles?.ticketManagement)) && (
                <li
                  className={currTab === "tickets" && "active"}
                  onClick={() => activTabHandler("tickets")}
                >
                  <a>
                    <span>
                      {/* <i className="fa-solid fa-comment"></i> */}
                      <img src={ticketIcon} />
                    </span>
                    Tickets
                  </a>
                </li>
              )}
            {(roleList?.roles?.invoiceAndSubscriptionAccess.allInvoices ||
              roleList?.roles?.invoiceAndSubscriptionAccess.ownInvoices) && (
                <>
                  <li className="menuHeading">Billing</li>
                  <li
                    className={currTab === "invoices" && "active"}
                    onClick={() => activTabHandler("invoices")}
                  >
                    <a>
                      <span>
                        {/* <i className="fa-solid fa-file-invoice"></i> */}
                        <img src={invoiceIcon} />
                      </span>
                      Invoices
                    </a>
                  </li>

                  <li
                    className={currTab === "subscription" && "active"}
                    onClick={() => activTabHandler("subscription")}
                  >
                    <a>
                      <span>
                        {/* <i className="fa-solid fa-arrows-rotate"></i> */}
                        <img src={subscribeIcon} />
                      </span>
                      Subscriptions
                    </a>
                  </li>
                </>
              )}
            {roleList?.roles?.coupons?.view_add_edit_delete && (
              <>
                <li className="menuHeading">Marketing</li>

                <li
                  className={currTab === "coupons" && "active"}
                  onClick={() => activTabHandler("coupons")}
                >
                  <a>
                    <span>
                      {/* <i className="fa-solid fa-ticket"></i> */}
                      <img src={couponIcon} />
                    </span>
                    Coupons
                  </a>
                </li>
              </>
            )}

            {(roleList?.roles?.services?.view_add_edit_delete ||
              roleList?.roles?.settingsAndTeam?.view_add_edit) && (
                <li className="menuHeading">Setup</li>
              )}
            {roleList?.roles?.services?.view_add_edit_delete && (
              <li
                className={currTab === "services" && "active"}
                onClick={() => activTabHandler("services")}
              >
                <a>
                  <span>
                    {/* <i className="fa-solid fa-suitcase"></i> */}
                    <img src={serviceIcon} />
                  </span>
                  Services
                </a>
              </li>
            )}
            {roleList?.roles?.settingsAndTeam?.view_add_edit && (
              <li
                className={currTab === "settings" && "active"}
                onClick={() => activTabHandler("settings")}
              >
                <a>
                  <span>
                    {/* <i className="fa-solid fa-gear"></i> */}
                    <img src={settingIcon} />
                  </span>
                  Settings
                </a>
              </li>
            )}
          </ul>
        ) : (
          <ul className="clientMenus">
            <li className="menuHeading">ACTIVITY</li>
            <li
              className={currTab === "home" && "active"}
              onClick={() => activTabHandler("home")}
            >
              <a>
                <span>
                  <i class="fa-solid fa-house"></i>
                </span>
                Home
              </a>
            </li>

            <li
              className={currTab === "purchases" && "active"}
              onClick={() => activTabHandler("purchases")}
            >
              <a>
                <span>
                  <i class="fa-solid fa-ticket"></i>
                </span>
                My Purchases
              </a>
            </li>

            <li
              className={currTab === "shop" && "active"}
              onClick={() => activTabHandler("shop")}
            >
              <a>
                <span>
                  <i class="fa-solid fa-newspaper"></i>
                </span>
                Shop Articles
              </a>
            </li>

            <li
              className={currTab === "help" && "active"}
              onClick={() => activTabHandler("help")}
            >
              <a>
                <span>
                  <i class="fa-solid fa-handshake-angle"></i>
                </span>
                Help
              </a>
            </li>

            <li className="menuHeading">BILLING</li>

            <li
              className={currTab === "payments" && "active"}
              onClick={() => activTabHandler("payments")}
            >
              <a>
                <span>
                  <i class="fa-solid fa-money-bill"></i>
                </span>
                Payments
              </a>
            </li>

            <li className="menuHeading">Account</li>

            <li
              className={currTab === "profile" && "active"}
              onClick={() => activTabHandler("profile")}
            >
              <a>
                <span>
                  <i class="fa-solid fa-user"></i>
                </span>
                My Profile
              </a>
            </li>
            <li
              className={currTab === "clientTeam" && "active"}
              onClick={() => activTabHandler("clientTeam")}
            >
              <a>
                <span>
                  <i class="fa-solid fa-user"></i>
                </span>
                Team
              </a>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};
export default Sidebar;

import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import ReactPaginate from "react-paginate";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { AllContext } from "../../context";
import { formatMonthNameAndDate } from "../../shared/functions";
import PageLoader from "../../components/pageLoader";

const Files = () => {
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const navigate = useNavigate();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startdateFilter, setstartDateFilter] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [limit, setPageLimit] = useState("");
  const [enddateFilter, setendDateFilter] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [users, setUsers] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [filterCount, setFilterCount] = useState(0);
  const [itemPerPageFilterApplied, setItemPerPageFilterApplied] =
    useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const dispatch = useDispatch();
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const context = useContext(AllContext);
  const { allApiUrl, redirectToLogin, toastMessage } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [orderList, setOrderList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({ label: "", value: "" });
  const [selectedTicket, setSelectedTicket] = useState({
    label: "",
    value: "",
  });
  const [isOrderActive, setOrderActive] = useState(false);
  const [isTicketActive, setTicketActive] = useState(false);
  useEffect(() => {
    getClients();
    getOrderListing();
    getTickets();
  }, [itemsPerPage]);

  useEffect(() => {
    getFiles();
  }, [itemsPerPage]);

  const { FILES, GET_ALL_CLIENT, FILTER_FILES } = allApiUrl;

  const getClients = () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(GET_ALL_CLIENT, "", header)
      .then((res) => {
        if (res.status === 200) {
          setUsers(res.data.team);
          // setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  useEffect(() => {
    getFiles(itemOffset);
  }, [itemOffset, pageCount]);
  const getFiles = async () => {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(FILES + "/" + itemOffset + "&" + itemsPerPage, "", header)
      .then(async (res) => {
        if (res.status === 200) {
          var files = res.data.docs;
          var fileCount = res.data.totalFiles;
          setCurrentPage(res.data.currentPage);
          setPageCount(Math.ceil(fileCount / itemsPerPage));
          setPageLimit(res.data.limit || itemsPerPage);
          setFiles(files);
          setInitialLoading(false);
          // setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  const [itemPerPageFilter, setItemPerPageFilter] = useState(10);
  const filterFiles = () => {
    let count = 0;
    if (itemPerPageFilter && itemPerPageFilterApplied) {
      setItemsPerPage(itemPerPageFilter);
      count += 1;
    }
    const data = {};
    if (accountId !== "") data.accountId = accountId;
    if (startdateFilter > 0) data.startdateFilter = startdateFilter;
    if (enddateFilter > 0) data.enddateFilter = enddateFilter;
    if (selectedOrder.value !== "" || selectedTicket.value !== "") {
      let resourceId = [];
      if (selectedOrder.value != "") resourceId.push(selectedOrder.value);
      if (selectedTicket.value != "") resourceId.push(selectedTicket.value);
      data.resourceId = resourceId;
    }
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    for (let key in data) {
      if (key == "resourceId" && data[key].length > 1) {
        count += 1;
      }
      count += 1;
    }
    setFilterCount(count);
    apiPostMethod(FILTER_FILES, data, header)
      .then((res) => {
        if (res.status === 200) {
          setFiles(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  async function getOrderListing() {
    // setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.ORDER, "", header);
      if (response.status === 200) {
        setOrderList([...response.data.order]);
        // setIsLoading(false);
      }
    } catch (err) {
      redirectToLogin(dispatch, navigate, err);
      // setIsLoading(false);
      toastMessage("error", err.data.message);
    }
  }

  const getTickets = () => {
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };

    apiGetMethod(allApiUrl.GET_TICKET, "", header).then((res) => {
      if (res.status === 200) {
        setTicketList(res.data);
      }
    });
  };

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
            setItemPerPageFilterApplied(true);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={1000}>100</option>
        </select>
      </div>
    );
  }

  return (
    <div>
      <Sidebar
        activePage="files"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6 col-6">
                <div className="heading">
                  <h2 className="fs-4">Files</h2>
                </div>
              </div>
              <div className="col-lg-6 col-6">
                <div className="section-filter">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle btn-filter"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-filter"></i> Filter |{" "}
                      {filterCount}
                    </button>
                    <ul className="dropdown-menu filter-drowdown">
                      <div className="filter-btns">
                        <button
                          className="btn btn-clear"
                          onClick={() => {
                            // setResourceType("");
                            // setAccountType("");
                            setFilterCount(0);
                            setAccountId("");
                            setstartDateFilter(0);
                            setendDateFilter(0);
                            setShowDatePicker(false);
                            getFiles();
                            setSelectedOrder({ label: "", value: "" });
                            setSelectedTicket({ label: "", value: "" });
                            setItemPerPageFilterApplied(false);
                            setItemPerPageFilter(10);
                            // setUsers([]);
                            // getLogs();
                          }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-update"
                          onClick={() => filterFiles()}
                        >
                          Update filter
                        </button>
                      </div>

                      {/* <select className="form-control">
                      <option>Any date</option>
                      <option>Any date</option>
                    </select> */}

                      <select
                        className="form-control"
                        onChange={(event) =>
                          setShowDatePicker("true" === event.target.value)
                        }
                        value={showDatePicker}
                      >
                        <option value={false}>Any date</option>
                        <option value={true}>Date Between</option>
                      </select>
                      {showDatePicker && (
                        <div className="d-flex dateBetween-filter">
                          <input
                            type="date"
                            name="startDate"
                            className="form-control"
                            onChange={(event) =>
                              setstartDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                            id=""
                          />
                          <span className="mx-2">and</span>
                          <input
                            className="form-control"
                            type="date"
                            name="endDate"
                            onChange={(event) =>
                              setendDateFilter(
                                new Date(event.target.value).getTime()
                              )
                            }
                            id=""
                          />
                        </div>
                      )}

                      <div
                        className="assignedUser Anyclient"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOrderActive(!isOrderActive);
                          setTicketActive(false);
                        }}
                      >
                        <p>
                          {selectedOrder.label.length > 0
                            ? selectedOrder.label
                            : "Any Order"}{" "}
                        </p>
                        <ul className={isOrderActive ? "active" : ""}>
                          <li
                            onClick={() => {
                              setSelectedOrder({ label: "", value: "" });
                              setOrderActive(false);
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              <div>Any Order</div>
                            </a>
                          </li>
                          {orderList.map((order, index) => {
                            return (
                              <li
                                onClick={() => {
                                  setSelectedOrder({
                                    label: order.title,
                                    value: order.orderId,
                                  });
                                  setOrderActive(false);
                                }}
                              >
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <div>
                                    {order.title}
                                    <br /> <small>{order.orderId}</small>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <div
                        className="assignedUser Anyclient"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTicketActive(!isTicketActive);
                          setOrderActive(false);
                        }}
                      >
                        <p>
                          {selectedTicket.label !== ""
                            ? selectedTicket.label
                            : "Any Ticket"}{" "}
                        </p>
                        <ul className={isTicketActive ? "active" : ""}>
                          <li
                            onClick={() => {
                              setSelectedTicket({ label: "", value: "" });
                              setOrderActive(false);
                            }}
                          >
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              <div>Any Ticket</div>
                            </a>
                          </li>
                          {ticketList.map((ticket, index) => {
                            return (
                              <li
                                onClick={() => {
                                  setSelectedTicket({
                                    label: ticket.to,
                                    value: ticket._id,
                                  });
                                  setTicketActive(false);
                                }}
                              >
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <div>
                                    {ticket.to}
                                    <br /> <small>{ticket.subject}</small>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <select
                        className="form-control"
                        value={accountId}
                        onChange={(value) => setAccountId(value.target.value)}
                      >
                        <option value="">Any Client</option>
                        {users.length > 0 &&
                          users.map((user) => {
                            if (user.userId)
                              return (
                                <option value={user.userId._id}>
                                  {user.userId.firstName +
                                    " " +
                                    user.userId.email}
                                </option>
                              );
                          })}
                      </select>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive common-table mt-3 files-table rounded-1">
                  <table className="table logs-table mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Size</th>
                        <th>Created Date</th>
                        <th>resource type</th>
                        <th>owner</th>
                        <th>resource Id</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* no record found */}
                      {/* <tr>
                        <td colSpan={6}>
                          <div className="noData-section">
                            <img src={noData} />
                            <p>No Data Found</p>
                          </div>
                        </td>
                      </tr> */}
                      {/* no record found */}
                      {files &&
                        files.length > 0 &&
                        files.map((value, key) => {
                          return (
                            <tr>
                              <td>
                                <a href={value?.url} target="_blank">
                                  {value?.fileName}
                                </a>
                              </td>
                              <td>{formatBytes(value?.fileSize)}</td>
                              <td>
                                {formatMonthNameAndDate(value?.createdOn, companyData.timezone)}
                              </td>
                              <td>{value?.eventType}</td>
                              <td>
                                <a
                                  href=""
                                  onClick={() =>
                                    navigate(
                                      `/${value.userId.group == 1
                                        ? "accounts/team"
                                        : "view-client"
                                      }/${value.userId._id}`
                                    )
                                  }
                                >
                                  {value &&
                                    value.userId &&
                                    value.userId.firstName &&
                                    value.userId.lastName
                                    ? value.userId.firstName +
                                    " " +
                                    value.userId.lastName
                                    : value.userId.email}
                                </a>
                              </td>
                              <td>
                                <a
                                  href=""
                                  onClick={() =>
                                    navigate(
                                      `/${value?.eventType == "Order"
                                        ? "view-orders"
                                        : "view-ticket"
                                      }/${value.resourceId}`
                                    )
                                  }
                                >
                                  {value?.resourceId}
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      {files && files.length <= 0 && (
                        <tr>
                          <td colSpan={7}>
                            <div className="noData-section">
                              <img src={noData} />
                              <p>No Data Found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* <ReactPaginate
              nextLabel={<i class="fas fa-arrow-right"></i>}
              previousLabel={<i class="fas fa-arrow-left"></i>}
              onPageChange={handlePageClick}
              pageCount={pageCount}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination paginate"
              activeClassName="active"
              renderOnZeroPageCount={null}
            /> */}
              </div>
            </div>
            <div className="sectionPagination">
              <NoOfRows />
              <ReactPaginate
                nextLabel={<i class="fa-solid fa-angles-right"></i>}
                previousLabel={<i class="fa-solid fa-angles-left"></i>}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      )}

      {/* add order modal stasts */}
      <div
        className="modal fade add-order-modals"
        id="addorderModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add order
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="add-order-form">
                <div className="add-alert">
                  <p>
                    Adding an order manually will not trigger any notifications
                    or payments. To add a paid order you can create a new
                    invoice and mark it as paid.
                  </p>
                </div>
                <form>
                  <div className="form-group">
                    <label>Clinet</label>
                    <select className="form-control">
                      <option>Client Name</option>
                      <option>Client Name</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Service</label>
                    <select className="form-control">
                      <option>Service 1</option>
                      <option>Service 2</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary close-btn"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Add order
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* add order modal ends */}
    </div>
  );
};
export default Files;

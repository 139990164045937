import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Sidebar from "../../../widgets/sidebar";
import Header from "../../../widgets/header";
import PageLoader from "../../../components/pageLoader";
// import DummyAvatar from "../../../assets/img/dummy-avatar.jpeg";
import DummyAvatar from "../../../assets/img/avatar.png";
import noData from "../../../assets/img/nodata-img.svg";
import { PermissionDenied } from "../../PermissionDenied";
import { AllContext } from "../../../context";

const Team = () => {
  const dispatch = useDispatch();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [allRoles, setAllRoles] = useState([]);
  const [teamAccountList, setTeamAccountList] = useState([]);
  const [modalAction, setModalAction] = useState("");
  const [teamId, setTeamId] = useState("");
  const [teamName, setTeamName] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const context = useContext(AllContext);
  const navigate = useNavigate();
  const {
    apiGetMethod,
    allApiUrl,
    apiDeleteMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { GET_ALL_ROLE, GET_ALL_TEAM, EDIT_ROLE, DELETE_TEAM_MEMBER } =
    allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  useEffect(() => {});
  const header = {
    Authorization: `Bearer ${loginUserAccessToken}`,
  };
  useEffect(() => {
    getAllTeam();
    getAllRoles();
  }, []);
  function getAllRoles() {
    // setIsLoading(true);
    apiGetMethod(GET_ALL_ROLE, "", header)
      .then((res) => {
        if (res.status === 200) {
          let allRoles = [];
          res.data.roles.forEach((item) => {
            if (item.type === "admin") {
              allRoles.push(item);
            }
          });
          setAllRoles(allRoles);
          setInitialLoading(false);
          // setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function getAllTeam() {
    // setIsLoading(true);
    apiGetMethod(GET_ALL_TEAM, "", header)
      .then((res) => {
        if (res.status === 200) {
          setTeamAccountList(res.data.team);
          // setIsLoading(false);
          setInitialLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        // setIsLoading(false);
        setInitialLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function deleteRole(deleteId) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(EDIT_ROLE, deleteId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        getAllRoles();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function deleteTeam(deleteId) {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiDeleteMethod(DELETE_TEAM_MEMBER, deleteId, header)
      .then((res) => {
        toastMessage("success", res.data.message);
        getAllTeam();
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div>
      <Sidebar
        activePage="settings"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <>
          <div className="setting-tab">
            <div className="container-fluid pl-240">
              <div className="row">
                <div className="col-lg-12">
                  <div className="tabs-content">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <div
                          className="section-back"
                          onClick={() => navigate("/settings")}
                        >
                          <a href="#" className="nav-link">
                            <i class="fa-solid fa-angle-left"></i> Settings
                          </a>
                        </div>
                      </li>
                      {/* <li
                    className="nav-item"
                    role="presentation"
                    on
                    onClick={() => navigate("/settings/general")}
                  >
                    <button
                      className="nav-link"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Company
                    </button>
                  </li> */}
                      <li
                        className="nav-item"
                        role="presentation"
                        onClick={() => navigate("/settings/payment")}
                      >
                        <button
                          className="nav-link "
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Payments
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          Team
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-back pl-240 mt-3">
            <a href="/settings" className="horizontalPadding">
              <i class="fa-solid fa-angle-left"></i> Settings
            </a>
          </div>

          <div className="setting-form-section mt-5 mb-4">
            <div className="container-fluid pl-240">
              <div className="row">
                <div className="col-xxl-5 col-lg-8 mx-auto">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                      tabindex="0"
                    >
                      {/* Teams */}
                      <div className="setting-form">
                        <form className="commonForm rounded-1">
                          <h3 className="mt-0">Roles</h3>
                          <div className="table-resposnive">
                            <table className="table ">
                              <tbody>
                                {/* no record found */}
                                {/* <tr>
                              <td colSpan={2}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr> */}
                                {/* no record found */}
                                {allRoles.map((role) => {
                                  return (
                                    role.name != "system" && (
                                      <tr>
                                        <td>
                                          <a
                                            // href=""
                                            onClick={() => (
                                              <>
                                                {userInfo.roles.name === "admin"
                                                  ? navigate(
                                                      `/role/role/${role._id}`
                                                    )
                                                  : navigate(
                                                      "/permissionDenied",
                                                      {
                                                        state: {
                                                          noPermission: false,
                                                        },
                                                      }
                                                    )}
                                              </>
                                            )}
                                          >
                                            {capitalizeFirstLetter(role.name)}
                                          </a>
                                        </td>
                                        <td>
                                          <div class="dropdown">
                                            <button
                                              class="btn btn-secondary dropdown-toggle"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i class="fa-solid fa-ellipsis-vertical"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                              <li
                                                onClick={() => (
                                                  <>
                                                    {userInfo.roles.name ===
                                                    "admin"
                                                      ? navigate(
                                                          `/role/role/${role._id}`
                                                        )
                                                      : navigate(
                                                          "/permissionDenied",
                                                          {
                                                            state: {
                                                              noPermission: false,
                                                            },
                                                          }
                                                        )}
                                                  </>
                                                )}
                                              >
                                                <a
                                                  class="dropdown-item"
                                                  href=""
                                                >
                                                  Edit
                                                </a>
                                              </li>
                                              {role.name !== "admin" && (
                                                <li>
                                                  <a
                                                    class="dropdown-item"
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target={`${
                                                      userInfo.roles.name ===
                                                        "admin" &&
                                                      "#deleteModal"
                                                    }`}
                                                    onClick={() => {
                                                      // setOpen(true);
                                                      if (
                                                        userInfo.roles.name ===
                                                        "admin"
                                                      ) {
                                                        setRoleId(role._id);
                                                        setRoleName(role.name);
                                                        setModalAction("role");
                                                      } else {
                                                        toastMessage(
                                                          "error",
                                                          "You dont have permission to access this resource"
                                                        );
                                                      }
                                                    }}
                                                    // onClick={()=>}
                                                  >
                                                    Delete
                                                  </a>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  );
                                })}
                                {allRoles && allRoles.length <= 0 && (
                                  <tr>
                                    <td colSpan={7}>
                                      <div className="noData-section">
                                        <img src={noData} />
                                        <p>No Data Found</p>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div
                            className="add-role"
                            onClick={() => {
                              userInfo.roles.name === "admin"
                                ? navigate("/role/role")
                                : navigate("/permissionDenied", {
                                    state: {
                                      noPermission: false,
                                    },
                                  });
                            }}
                          >
                            <a>+ Add Role</a>
                          </div>

                          <h3>Team accounts</h3>
                          <div className="team-table">
                            <table className="table ">
                              <tbody>
                                {/* no record found */}
                                {/* <tr>
                              <td colSpan={3}>
                                <div className="noData-section">
                                  <img src={noData} />
                                  <p>No Data Found</p>
                                </div>
                              </td>
                            </tr> */}
                                {/* no record found */}
                                {teamAccountList.map((teamAccount) => {
                                  return (
                                    <tr>
                                      <td>
                                        <div className="team-detail">
                                          <div className="img">
                                            <img
                                              src={
                                                teamAccount.userId &&  teamAccount.userId.image &&
                                                teamAccount.userId.image != ""
                                                  ? teamAccount.userId.image
                                                  : DummyAvatar
                                              }
                                            />
                                          </div>
                                          <div className="name">
                                            <p
                                              style={{ cursor: "pointer" }}
                                              className="userName"
                                              onClick={() => {
                                                userInfo.roles.name === "admin"
                                                  ? navigate(
                                                      `/accounts/team/${teamAccount.userId._id}`
                                                    )
                                                  : navigate(
                                                      "/permissionDenied",
                                                      {
                                                        state: {
                                                          noPermission: false,
                                                        },
                                                      }
                                                    );
                                              }}
                                            >
                                              {teamAccount &&
                                              teamAccount.userId &&
                                              teamAccount.userId.firstName
                                                ? `${capitalizeFirstLetter(
                                                    teamAccount.userId.firstName
                                                  )} ${
                                                    teamAccount.userId
                                                      .lastName || ""
                                                  }`
                                                : ""}
                                            </p>
                                            <p>
                                              {teamAccount.userId &&
                                                teamAccount.userId.email}
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="userRoles">
                                          <p
                                            onClick={() => {
                                              userInfo.roles.name === "admin"
                                                ? navigate(
                                                    `/role/role/${teamAccount.userId?.roleId}`
                                                  )
                                                : navigate(
                                                    "/permissionDenied",
                                                    {
                                                      state: {
                                                        noPermission: false,
                                                      },
                                                    }
                                                  );
                                            }}
                                          >
                                            <a href="">{teamAccount.role}</a>
                                            {teamAccount.userId.accountOwner &&
                                              "Account Owner" && (
                                                <span
                                                  style={{
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  Account Owner
                                                </span>
                                              )}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                          </button>
                                          <ul className="dropdown-menu">
                                            <li
                                              onClick={() => {
                                                userInfo.roles.name === "admin"
                                                  ? navigate(
                                                      `/accounts/edit/${teamAccount.userId._id}`
                                                    )
                                                  : navigate(
                                                      "/permissionDenied",
                                                      {
                                                        state: {
                                                          noPermission: false,
                                                        },
                                                      }
                                                    );
                                              }}
                                            >
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                              >
                                                Edit
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                class="dropdown-item"
                                                // href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target={`${
                                                  userInfo.roles.name ===
                                                    "admin" && "#deleteModal"
                                                }`}
                                                onClick={() => {
                                                  if (
                                                    userInfo.roles.name ===
                                                    "admin"
                                                  ) {
                                                    setTeamId(
                                                      teamAccount.userId._id
                                                    );
                                                    setTeamName(
                                                      teamAccount?.userId
                                                        ?.firstName
                                                        ? teamAccount?.userId
                                                            ?.firstName
                                                        : teamAccount.userId
                                                            .email
                                                    );
                                                    setModalAction("team");
                                                  } else {
                                                    toastMessage(
                                                      "error",
                                                      "You dont have permission to access this resource"
                                                    );
                                                  }
                                                }}
                                              >
                                                Delete
                                              </a>
                                            </li>
                                            {/* <li>
                                          <a className="dropdown-item" href="#">
                                            Sign in as user
                                          </a>
                                        </li> */}
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                                {teamAccountList &&
                                  teamAccountList.length <= 0 && (
                                    <tr>
                                      <td colSpan={7}>
                                        <div className="noData-section">
                                          <img src={noData} />
                                          <p>No Data Found</p>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </div>
                          <div
                            className="add-role"
                            onClick={() => navigate("/accounts/add/2")}
                          >
                            <a href="">+ Add User</a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Delete Modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  {`Are you sure you want to delete this ${
                    modalAction === "role" ? "role" : "team member"
                  }: `}
                  <span style={{ fontWeight: "bold" }}>
                    {modalAction === "role" ? roleName : teamName}
                  </span>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={() =>
                  modalAction === "role"
                    ? deleteRole(roleId)
                    : deleteTeam(teamId)
                }
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Team;

const INITIAL_STATE = {
  loginUserInfo: {},
  rememberUserInfo: {
    email: "",
    password: "",
  },
  loginUserAccessToken: "",
  addToCartInfo: false,
  doesOrderNeedAttention: { orderNeedAttention: false, id: "" },
  invoiceInfo: {},
  publicInvoice: false,
  paymentAddress: {},
  companyData: {},
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN_USER_INFO":
      return { ...state, loginUserInfo: action.data };
    case "LOGIN_USER_TOKEN":
      return { ...state, loginUserToken: action.data };

    case "LOGIN_USER_ACCESS_TOKEN":
      return { ...state, loginUserAccessToken: action.data };
    case "ADD_TO_CART":
      return { ...state, addToCartInfo: action.data };
    case "SAVE_INVOICE":
      return { ...state, invoiceInfo: action.data };
    case "PUBLIC_INVOICE":
      return { ...state, publicInvoice: action.data };
    case "PAYMENT_ADDRESS":
      return { ...state, paymentAddress: action.data };
    case "ORDER_NEED_ATTENTION":
      return { ...state, doesOrderNeedAttention: action.data };
    case "COMPANY_DATA":
      return { ...state, companyData: action.data };
    default:
      return state;
  }
};
export default loginReducer;

import React, { useEffect, useContext } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import DummyAvatar from "../../assets/img/dummy-avatar.jpeg";
import { AllContext } from "../../context";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { array } from "yup";
import moment from "moment";

const AddCoupon = () => {
  const dispatch = useDispatch();
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const navigate = useNavigate();
  const context = useContext(AllContext);
  const {
    allApiUrl,
    apiGetMethod,
    apiPostMethod,
    toastMessage,
    redirectToLogin,
  } = context;
  const { COUPON, SERVICES } = allApiUrl;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState();
  const [description, setDescription] = useState();
  const [services, setServices] = useState([]);
  const [usePerCustomer, setOneUsePerCustomer] = useState(false);
  const [multipleDiscountCount, setMultipleDiscountCount] = useState(1);
  const [newCustomerOnly, setNewCustomerOnly] = useState(false);
  const [expiryDate, setExpiryDate] = useState();
  const [loader, setLoader] = useState(false);
  const [fixedAmount, setFixedAmount] = useState(true);
  const [percentage, setPercentage] = useState(false);
  const [durationForever, setDurationForever] = useState(true);
  const [firstPayment, setFirstPayment] = useState(false);
  const [multipleDiscountList, setMultipleDiscountList] = useState([
    {
      service: [],
      discount: 0,
    },
  ]);

  useEffect(() => {
    getAllServices();
  }, []);
  function getAllServices() {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    setLoader(true);
    apiGetMethod(SERVICES, "", header)
      .then((res) => {
        let serviceList = res.data.data;
        setServices(
          serviceList.map((row) => {
            return {
              label: row.name,
              value: row._id,
              isDisabled: false,
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function handleFormSubmit(e) {
    setIsLoading(true);
    e.preventDefault();
    multipleDiscountList.map((row, index) => {
      multipleDiscountList[index].service = row.service.map((service) => {
        if (service.value) return service.value;
      });
    });
    const data = {
      couponCode: coupon,
      description: description,
      fixedAmount: fixedAmount,
      durationForever: durationForever,
      applies_to: multipleDiscountList,
      redemption_limit_one_use_per_customer: usePerCustomer,
      redemption_limit_to_new_customer: newCustomerOnly,
      expiryDate: expiryDate,
    };
    let header = {
      Authorization: `Bearer ${loginUserAccessToken}`,
    };
    apiPostMethod(COUPON, data, header)
      .then((res) => {
        if (res.status === 201) {
          navigate("/coupons");
          toastMessage("success", res.data.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        setIsLoading(false);
        toastMessage("error", err.data.message);
      });
  }

  function openCalender(e) {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }
  function generateRandomNumber() {
    const n = 8;
    let randomString = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (let i = 0; i < n; i++) {
      randomString += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCoupon(randomString);
  }
  const increaseDiscount = (event) => {
    event.preventDefault();
    setMultipleDiscountCount(multipleDiscountCount + 1);
  };
  const decreaseDiscountCount = (event, index) => {
    event.preventDefault();
    if (multipleDiscountCount > 1 && multipleDiscountList.length > 0) {
      let multipleDiscountListCopy = [...multipleDiscountList];
      multipleDiscountListCopy.splice(index, 1);
      setMultipleDiscountList(multipleDiscountListCopy);
      setMultipleDiscountCount(multipleDiscountCount - 1);
    }
  };
  useEffect(() => {
    services.map((row, index) => {
      services[index].isDisabled = false;
    });
    if (multipleDiscountList.length > 0) {
      multipleDiscountList.map((row) => {
        if (row && row.service && row.service.length > 0)
          row.service.map((service) => {
            let data = services.findIndex((row) => row == service);
            if (data > -1) services[data].isDisabled = true;
          });
      });
      setServices(services);
    }
  }, [multipleDiscountList]);

  return (
    <div>
      <Sidebar
        activePage="coupons"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />

      <div className="setting-form-section pt-3 horizontalPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-back">
                <a href="/coupons">
                  {" "}
                  <i class="fa-solid fa-angle-left"></i> Coupons{" "}
                </a>
              </div>
            </div>

            <div className="col-lg-8 col-xxl-5 mx-auto">
              <div className="setting-form">
                <h3 className="couponHeading fs-4 mb-4">
                  Add Coupon
                  <div className="dropdown">
                    {/*  <button
                      className="btn btn-secondary dropdown-toggle btn-info"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                       <i className="fa-solid fa-ellipsis-vertical"></i> 
                    </button>*/}
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          href="javsccript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </h3>
                <form className="couponForm">
                  <div className="commonForm rounded-1">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <label>Coupon code</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control text-uppercase"
                              placeholder="E.G. 25OFF"
                              value={coupon}
                              required
                              onChange={(e) => {
                                setCoupon(e.target.value);
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-generate"
                              onClick={() => generateRandomNumber()}
                            >
                              Generate
                            </button>
                          </div>
                          <small>
                            This is what clients will use to get the discount.
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>
                            Description <small>optional</small>
                          </label>
                          <textarea
                            className="form-control"
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          ></textarea>
                          <small>
                            Not visible to clients, helps you remember what the
                            coupon is for.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-7">
                        <div className="form-group">
                          <label>Discount type</label>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="fixedAmount"
                                  id="fixedAmount"
                                  checked={fixedAmount}
                                  onChange={(e) => {
                                    setFixedAmount(e.target.checked);
                                    setPercentage(false);
                                  }}
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  for="fixedAmount"
                                >
                                  Fixed amount
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="percentage"
                                  id="percentage"
                                  checked={percentage}
                                  onChange={(e) => {
                                    setPercentage(e.target.checked);
                                    setFixedAmount(false);
                                  }}
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  for="percentage"
                                >
                                  Percentage
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-7">
                        <div className="form-group">
                          <label>
                            Discount duration for recurring services
                          </label>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="forever"
                                  id="forever"
                                  checked={durationForever}
                                  onChange={(e) => {
                                    setDurationForever(e.target.checked);
                                    setFirstPayment(false);
                                  }}
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  for="forever"
                                >
                                  Forever
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="firstPayment"
                                  id="firstPayment"
                                  checked={firstPayment}
                                  onChange={(e) => {
                                    setFirstPayment(e.target.checked);
                                    setDurationForever(false);
                                  }}
                                />
                                <label
                                  className="form-check-label checkbox-label"
                                  for="firstPayment"
                                >
                                  First payment
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="addMore-discount">
                      {[...Array(multipleDiscountCount)].map((value, index) => {
                        return (
                          <div className="row">
                            <div className="col-lg-8 col-md-8">
                              <div className="form-group">
                                <label>Applies to...</label>
                                <Select
                                  className="my-react-select my-react-select--compact"
                                  id={"select" + index}
                                  isMulti
                                  value={multipleDiscountList[index]?.service}
                                  options={services}
                                  placeholder="All Services"
                                  onChange={(e) => {
                                    setMultipleDiscountList((prevData) => {
                                      if (prevData[index] == undefined) {
                                        prevData[index] = {
                                          service: e,
                                          discount: 0,
                                        };
                                      } else {
                                        prevData[index].service = e;
                                      }
                                      return [...prevData];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                              <div className="form-group">
                                <label>
                                  Discount {fixedAmount == false ? "%" : "$"}
                                </label>
                                <div className="input-group">
                                  <input
                                    type="Number"
                                    className="form-control text-uppercase"
                                    required
                                    id={"discount" + index}
                                    value={
                                      multipleDiscountList[index]
                                        ? multipleDiscountList[index].discount
                                        : 0
                                    }
                                    onChange={(e) => {
                                      setMultipleDiscountList((prevData) => {
                                        if (prevData[index] == undefined) {
                                          prevData[index] = {
                                            applies_to: [],
                                            discount: e.target.value,
                                          };
                                        } else {
                                          prevData[index].discount =
                                            e.target.value;
                                        }
                                        return [...prevData];
                                      });
                                    }}
                                  />
                                  {multipleDiscountCount > 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-generate ms-3"
                                      onClick={(event) =>
                                        decreaseDiscountCount(event, index)
                                      }
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </button>
                                  )}
                                </div>

                                {index == multipleDiscountCount - 1 && (
                                  <a
                                    className="discountLink"
                                    onClick={(event) => increaseDiscount(event)}
                                  >
                                    + Add discount
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-0">
                          <label>Redemption limits</label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="purchasingcompany"
                              checked={usePerCustomer}
                              onChange={(e) => {
                                setOneUsePerCustomer(e.target.checked);
                              }}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              htmlFor="purchasingcompany"
                            >
                              Limit to one use per customer
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="newCustomers"
                              checked={newCustomerOnly}
                              onChange={(e) => {
                                setNewCustomerOnly(e.target.checked);
                              }}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              htmlFor="newCustomers"
                            >
                              Limit to new customers only
                            </label>
                          </div>

                          <div className="form-check expiryDate-field">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="expiryDate"
                              onClick={(e) => {
                                openCalender(e);
                              }}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              for="expiryDate"
                            >
                              Set expiry date
                            </label>
                            <div className="col-lg-4">
                              {open === true ? (
                                <DatePicker
                                  // maxDate={moment().toDate()}
                                  className="form-control expiryDate"
                                  selected={new Date(expiryDate).getTime()}
                                  minDate={new Date()}
                                  dateFormat="MMMM dd,yyyy"
                                  onChange={(e) => setExpiryDate(e)}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="btn-section text-end">
                  <button
                    className="btn btn-save"
                    disabled={coupon == "" || coupon == undefined}
                    onClick={(e) => {
                      let check = true;
                      [...Array(multipleDiscountCount)].map((row, index) => {
                        if (
                          multipleDiscountList[index] &&
                          multipleDiscountList[index].discount <= 0
                        ) {
                          check = false;
                        }
                        if (multipleDiscountList[index] == undefined) {
                          check = false;
                        }
                      });
                      if (check) {
                        handleFormSubmit(e);
                      } else {
                        toastMessage(
                          "error",
                          "Discount value must be greater than 0"
                        );
                      }
                    }}
                  >
                    Add Coupon
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* delete modal */}
      <div
        className="modal fade history-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Merge Gamanjit Singh</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
            <div className="modal-body">
              <div className="delete-content">
                <p>
                  Are you sure you want to delete coupon{" "}
                  <strong>IJ0P2E (Copy) (Copy)</strong>?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal */}
    </div>
  );
};
export default AddCoupon;

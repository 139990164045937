const INITIAL_STATE = {
  csvData: [],
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SAVE_CSV_DATA":
      return { ...state, csvData: action.data };
    default:
      return state;
  }
};
export default loginReducer;

import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import { AllContext } from "../../context";
import noData from "../../assets/img/nodata-img.svg";
import { saveLoginUserAccessToken } from "../../redux/action/login";

const CompletedOrders = () => {
  const [toggleSideBar, setToggleSidebar] = React.useState(false);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.loginReducer.loginUserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(AllContext);

  return (
    <div>
      <Sidebar activePage="dashboard" setToggleSidebar={setToggleSidebar} toggleSideBar={toggleSideBar} />
      <Header isLoading={isLoading} handleSidebar={handleSidebar} />
      <div className="dashboardSection commonPadding">
        <div className="container-fluid pl-240">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading">
                <h2>Completed Orders</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="dashboardFilters">
                <div className="dropdown">

                  <button class="btn btn-secondary dropdown-toggle dashboardDropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Reports
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="/revenue">Revenue</a></li>
                    <li><a class="dropdown-item" href="/service-report">Services</a></li>
                    <li><a class="dropdown-item" href="/client-report">Clients</a></li>
                    <li><a class="dropdown-item" href="#">Account Balances</a></li>
                    <li><a class="dropdown-item" href="#">Coupon usage</a></li>
                    <li><a class="dropdown-item" href="#">Response times</a></li>
                    <li><a class="dropdown-item" href="#">Assigned orders</a></li>
                    <li><a class="dropdown-item" href="/completed-order">Completed orders</a></li>
                    <li><a class="dropdown-item" href="/order-status">Order statuses</a></li>
                    <li><a class="dropdown-item" href="#">Taxes</a></li>
                  </ul>

                  <button class="btn btn-secondary dropdown-toggle dashboardDropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Export
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Accounts</a></li>
                    <li><a class="dropdown-item" href="#">Orders</a></li>
                    <li><a class="dropdown-item" href="#">Transactions</a></li>
                    <li><a class="dropdown-item" href="#">Invoices</a></li>
                    <li><a class="dropdown-item" href="#">Form Date</a></li>
                    <li><a class="dropdown-item" href="#">Revenue By Client</a></li>
                    <li><a class="dropdown-item" href="#">Ticket Rating</a></li>
                  </ul>

                  <input type="date" className="form-control" />

                  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa-solid fa-calendar"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Last 7 Days</a></li>
                    <li><a class="dropdown-item" href="#">Last 4 Weeks</a></li>
                    <li><a class="dropdown-item" href="#">Last 3 Months</a></li>
                    <li><a class="dropdown-item" href="#">Previous Month</a></li>
                    <li><a class="dropdown-item" href="#">Month to Date</a></li>
                    <li><a class="dropdown-item" href="#">Quarter to Date</a></li>
                    <li><a class="dropdown-item" href="#">Year to Date</a></li>
                    <li><a class="dropdown-item" href="#">All Time</a></li>
                  </ul>
                  </div>
              </div>
            </div>
            <div className="col-xxl-12">
              <div className="listingSection mt-4">
                <div className="common-table rounded-1">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Service Name</th>
                        <th>No. of orders</th>
                      </tr>
                    </thead>
                    <tbody>

                      {/* no record found */}
                      {/* <tr>
                        <td colSpan={3}>
                          <div className="noData-section">
                            <img src={noData} />
                            <p>No Data Found</p>
                          </div>
                        </td>
                      </tr> */}
                    {/* no record found */}
                      <tr>
                        <td>Website Audit</td>
                        <td>3</td>
                      </tr>
                      <tr>
                        <td>Website Audit</td>
                        <td>3</td>
                      </tr>
                      <tr>
                        <td>Website Audit</td>
                        <td>3</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompletedOrders;

import React from "react";
import { SpinnerCircularFixed } from "spinners-react";

const Loader = ({ isLoading }) => {
  return (
    <div>
      {
isLoading && 
      <div className="loaderouter">
        <div>
          <SpinnerCircularFixed
            enabled={isLoading}
            size="25"
            secondaryColor=""
            color="#201b50"
            thickness="200"
          />
        </div>
        <div className="sectionLoading">Loading..</div>
      </div>
      }
    </div>
  );
};
Loader.defaultProps = {
  isLoading: false,
};
export default Loader;

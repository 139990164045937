import React, { useContext, useState } from "react";
import Sidebar from "../../widgets/sidebar";
import Header from "../../widgets/header";
import noData from "../../assets/img/nodata-img.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { orderDummyDataClient, tabList } from "../../shared/constants";
import { useEffect } from "react";
import { formatMonthNameAndDate } from "../../shared/functions";
import { AllContext } from "../../context";
import ReactPaginate from "react-paginate";
import PageLoader from "../../components/pageLoader";

const Purchases = () => {
  const navigate = useNavigate();
  const companyData = useSelector((state) => state?.loginReducer?.companyData);
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currActiveTab, setCurrActiveTab] = useState("All");
  const [orderListCompeleteData, setOrderListCompeleteData] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [tabListArr, setTabListArr] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [initialLoading, setInitialLoading] = useState(true);
  let activeTab = "All";
  const context = useContext(AllContext);
  const dispatch = useDispatch();
  const { allApiUrl, toastMessage, redirectToLogin, apiGetMethod } = context;
  const loginUserAccessToken = useSelector(
    (state) => state.loginReducer.loginUserAccessToken
  );
  useEffect(() => {
    async function getOrderDetail() {
      // setIsLoading(true);
      let header = {
        Authorization: `Bearer ${loginUserAccessToken}`,
      };
      try {
        const response = await apiGetMethod(allApiUrl.CLIENT_ORDER, "", header);
        if (response.status === 200) {
          if (currActiveTab === "All") {
            setOrderListCompeleteData(response.data);
            setOrderList([...response.data.order]);
            let Status = [];
            response.data.order.forEach((item) => {
              Status.push(item.status);
            });
            const array = [...new Set([...Status])];
            array.push("all");
            setTabListArr(
              tabList.filter((e) => array.indexOf(e.toLowerCase()) !== -1)
            );
          } else {
            setOrderList(
              response.data.order.filter(
                (item) =>
                  item.status.toLowerCase() ===
                  currActiveTab.toLocaleLowerCase()
              )
            );
          }
          setIsLoading(false);
          setInitialLoading(false);
        }
      } catch (err) {
        redirectToLogin(dispatch, navigate, err);
        setInitialLoading(false);
        // setIsLoading(false);
        toastMessage("error", err.data.message);
      }
    }
    getOrderDetail();
  }, [currActiveTab]);
  const handleSidebar = () => {
    setToggleSidebar(!toggleSideBar);
  };

  const [itemOffset, setItemOffset] = useState(0);

  // let itemsPerPage = 9;
  const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);

  const currentItems = orderList.slice(itemOffset, endOffset);
  let pageCount = Math.ceil(orderList.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orderList.length;
    setItemOffset(newOffset);
  };

  const currenContactList = orderList.slice(itemOffset, endOffset);
  let pageCountContactList = Math.ceil(orderList.length / itemsPerPage);
  const handlePageClickContactList = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orderList.length;
    setItemOffset(newOffset);
  };

  function NoOfRows() {
    return (
      <div>
        <label>Number of entries</label>
        <select
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(e.target.value);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  }

  return (
    <div>
      <Sidebar
        activePage="purchases"
        setToggleSidebar={setToggleSidebar}
        toggleSideBar={toggleSideBar}
      />
      <Header handleSidebar={handleSidebar} isLoading={isLoading} />
      <PageLoader isLoading={initialLoading} />
      {!initialLoading && (
        <div className="orders-section commonPadding horizontalPadding">
          <div className="container-fluid pl-240">
            <div className="row">
              <div className="col-lg-6">
                <div className="heading">
                  <h2>My Purchases</h2>
                </div>
              </div>
              {/* <div className="col-lg-6">
              <div className="form-group searchFilter">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </div> */}
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="common-tabs orderTabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    {tabListArr.map((tabItem) => {
                      return (
                        <li
                          className="nav-item"
                          role="presentation"
                          onClick={() => {
                            setIsLoading(true);
                            setCurrActiveTab(tabItem);
                          }}
                        >
                          <button
                            className={`nav-link ${activeTab === tabItem && "active"
                              }`}
                            id="pills-overdue-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-overdue"
                            type="button"
                            role="tab"
                            aria-controls="pills-overdue"
                            aria-selected="true"
                          >
                            <span>
                              {tabItem === "All"
                                ? orderListCompeleteData.order?.length
                                : orderListCompeleteData.order &&
                                orderListCompeleteData.order?.filter(
                                  (item) =>
                                    item.status.toLowerCase() ===
                                    tabItem.toLowerCase()
                                ).length}
                            </span>
                            {tabItem}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-overdue"
                      role="tabpanel"
                      aria-labelledby="pills-overdue-tab"
                      tabindex="0"
                    >
                      <div className="common-table table-responsive rounded-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Order ID</th>
                              <th>Title</th>
                              <th>Paid Date</th>
                              <th>Completed Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((item) => {
                              return (
                                <tr>
                                  <td>
                                    <a
                                      onClick={() =>
                                        navigate(
                                          `/view-purchase/${item?.orderId}`,
                                          {
                                            state: {
                                              _id: item._id,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {item?.orderId}
                                    </a>
                                  </td>
                                  <td>{item?.title}</td>
                                  <td>
                                    {formatMonthNameAndDate(
                                      item.createdDate,
                                      companyData.timezone
                                    )}
                                  </td>
                                  <td>--</td>
                                  <td>
                                    {(item.status == "pending" ||
                                      item.status == "Pending") &&
                                      item.inTakeForm ? (
                                      <span
                                        className="pill pill-grey fs-12 fw-normal"
                                        style={{
                                          cursor: "pointer",
                                          background: "#c5bff9",
                                          color: "#5a4cdc",
                                        }}
                                        onClick={() => {
                                          navigate(
                                            `/portal/intakeForm/${item.inTakeForm}`,
                                            {
                                              state: {
                                                orderId: item._id,
                                                mode: "add",
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        Start Order
                                      </span>
                                    ) : (
                                      <span className="pill pill-grey fs-12 fw-normal">
                                        {item.status}
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                            {currentItems.length <= 0 && (
                              <tr>
                                <td colSpan={7}>
                                  <div className="noData-section">
                                    <img src={noData} />
                                    <p>No Data Found</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="sectionPagination">
                        <NoOfRows />
                        <ReactPaginate
                          nextLabel={<i class="fa-solid fa-angles-right"></i>}
                          previousLabel={
                            <i class="fa-solid fa-angles-left"></i>
                          }
                          onPageChange={handlePageClickContactList}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination paginate"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Purchases;

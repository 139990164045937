import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
// import "../../assets/css/custom.css";
import "../../assets/css/customNew.css";
import { useNavigate, useLocation } from "react-router-dom";
import resetImg from "../../assets/img/reset-password.png";
import queryString from "query-string";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { AllContext } from "../../context";

const ResetPassword = () => {
  const context = useContext(AllContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { allApiUrl, toastMessage, apiPostMethod, redirectToLogin } = context;
  const { PASSWORD_RESET } = allApiUrl;
  const [passwordView, setPasswordView] = React.useState("password")
  const [confirmPasswordView, setConfirmPasswordView] = React.useState("password")
  let { token } = queryString.parse(location.search);
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  function handleFormSubmit(values) {
    const data = {
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    let header = {
      Authorization: `Bearer ${token}`,
    };
    apiPostMethod(PASSWORD_RESET, data, header)
      .then((res) => {
        if (res.status === 201) {
          toastMessage("success", res.data.message);
          navigate("/login");
        }
      })
      .catch((err) => {
        redirectToLogin(dispatch, navigate, err);
        toastMessage("error", err.data.message);
      });
  }
  const PasswordSchema = Yup.object({
    password: Yup.string()
      .min(6)
      .max(50)
      .required("Please Enter your Password"),
    confirmPassword: Yup.string()
      .min(6)
      .max(50)
      .required()
      .oneOf([Yup.ref("password"), null], "Password must Match"),
  });
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: PasswordSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  return (
    <main className="wrapper">
      <div className="section-login">
        <div className="container">
          <div className="row h-100vh mx-0 align-items-center">
            <div className="col-lg-6 formOrder">
              <div className="loginForm">
                <a className="brandName" href="">
                  AgencyCRM
                </a>
                {/* <h3>Reset Password</h3> */}

                <form className="formLogin" onSubmit={handleSubmit}>
                  {/* {errors?.password && <p>{errors.password}</p>} */}
                  <h3>Reset Password</h3>
                  <div className="formInner">
                    <div className="form-floating mb-3">
                      <input
                        type={passwordView}
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        required
                      />
                      <span className="eyeIcon fa-solid fa-eye" onClick={() => {
                        if (passwordView == "password") {
                          setPasswordView("text")
                        } else {
                          setPasswordView("password")
                        }
                      }}></span>
                      <label for="floatingInput">New Password</label>
                      {errors &&
                        errors.confirmPassword == "Password must Match" &&
                        values.confirmPassword.length > 6 && (
                          <small className="error">{errors.confirmPassword}</small>
                        )}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type={confirmPasswordView}
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                        name="confirmPassword"
                        onChange={handleChange}
                        value={values.confirmPassword}
                        required
                      />
                      <span className="eyeIcon fa-solid fa-eye" onClick={() => {
                        if (confirmPasswordView == "password") {
                          setConfirmPasswordView("text")
                        } else {
                          setConfirmPasswordView("password")
                        }
                      }}></span>
                      <label for="floatingInput">Confirm New Password</label>
                    </div>

                    <div className="input-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-submit"
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </form>
                <p className="copyrightText">© AgencyCRM. All Rights Reserved. </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="loginImg">
                <img src={resetImg} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default ResetPassword;
